angular
  .module('website')
  .factory('CalendarService', function (
    Restangular,
    DataService,
    $q
  ) {
    return {
      getEvent: (id) => {
        const deferred = $q.defer();
        if (id) {
          const domain = DataService.domain;
          const Calendar = Restangular.all('websites').all(domain).all('calendar');
          Calendar.one('event', id).get().then((event) => {
            deferred.resolve(event);
          }, () => deferred.resolve());
        } else {
          deferred.resolve();
        }
        return deferred.promise;
      }
    };
  });
