import underscored from 'underscore.string/underscored';

angular
  .module 'gym.components'
  .directive 'stripePaymentDetails', ->
    restrict: 'EA'
    scope:
      paymentMethods: '='
      setDefault: '&'
      remove: '&'
      reload: '&'
      permissions: '='
      contact: '='
    template: require('templates/components/contacts/wallet/stripe_payment_details.html.slim')
    controller: ($scope, $modal, AdminUserService) ->
      $scope.adminHasPermission = AdminUserService.has

      $scope.contactsType = (type) ->
        "#{underscored(type)}s"

      $scope.unlink = (item) ->
        $modal.open
          template: require('templates/gyms/contacts/payment_details/unlink_stripe_payment_method_modal.html.slim')
          resolve:
            contact: -> $scope.contact
            item: -> item
            reload: -> $scope.reload
          controller: ($scope, $modalInstance, contact, item, reload) ->
            $scope.contact = contact
            $scope.item = item

            $scope.unlink = ->
              $scope.isLoading = true
              item.one('unlink_contact').remove().then () ->
                reload()
                $modalInstance.close()

            $scope.cancel = ->
              $modalInstance.dismiss()
