angular
  .module 'gym.components'
  .directive 'skipSequenceCondition', ->
    restrict: 'EA'
    template: require('templates/components/sequences/skip_sequence_condition.html.slim')
    replace: true
    scope:
      sequence: '='
      step: '='
      condition: '='
      index: '='
      removeCondition: '&'
      reports: '='
    controller: ($scope) ->
      $scope.remove = ->
        $scope.removeCondition({step: $scope.step, index: $scope.index})

      $scope.isConditionDisplaying = ->
        $scope.condition.type is 'LinkedReportSkipSequenceCondition'

      $scope.displayReportName = ->
        report = _.findWhere $scope.reports, id: $scope.condition.report_id
        report.name
