angular
  .module 'gym.components'
  .directive 'customFormSection', ->
    restrict: 'E'
    template: require('templates/components/contacts/forms/custom_form_section.html.slim')
    scope:
      contact: '='
      form: '='
      editForm: '&'
    controller: ($scope, FormFieldDisplayingService) ->

      $scope.params = {}
      $scope.isWaiverAnswers = $scope.form.name is 'waiver_answers'

      $scope.isFieldAvailable = (field) ->
        if $scope.isWaiverAnswers && !$scope.params.blankVisible && !$scope.contact[$scope.form.name][field.name]
          return false
        FormFieldDisplayingService.isDisplaying($scope.contact.type, field)

      $scope.isFormCompact = ->
        compactFields =  [
          'unsubscribes',
          'marketing_information',
          'body_composition',
          'key_demographics',
          'fitness_goals'
        ]

        $scope.form.name in compactFields
