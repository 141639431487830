import random from 'helpers/random'
import {SMS_OPT_OUT_INSTRUCTIONS_POLICY_OPTIONS} from 'react/types/sms_settings'
import {SMS_OPT_OUT_POLICY_HINT_TEXT} from 'react/types/constants'

angular
  .module 'gym.components'
  .directive 'sequencePayloadEditor', (
    $filter,
    $modal,
    flash,
    DefaultReplyAddressService,
    SmsValidationService,
    PushNotificationValidationService,
    PushNotificationOptionsService,
    SMSesSentThisMonthService,
    FeatureAvailabilityService,
    MERGE_TAG_LIST,
    MEMBER_PORTAL_MERGE_TAG_LIST,
    PAYMENT_MERGE_TAGS,
    CREDIT_VOUCHER_MERGE_TAGS,
    MEMBERSHIP_MERGE_TAGS,
    BOOKING_MERGE_TAGS,
    GRADING_MERGE_TAGS,
    STYLE_MERGE_TAGS,
    SUSPENSION_MERGE_TAGS,
    RECURRING_BOOKING_MERGE_TAGS,
    LATE_CANCELLED_BOOKING_MERGE_TAGS,
    BOOKING_CANCELLATION_MERGE_TAGS,
    WAITLIST_ATTENDEE_MERGE_TAGS,
    PERFORMED_WORKOUT_MERGE_TAGS,
    MOBILE_APP_EMAIL_INVITE_MERGE_TAG_LIST,
    MOBILE_APP_SMS_INVITE_MERGE_TAG_LIST,
  ) ->
    restrict: 'EA'
    template: require('templates/components/sequences/sequencePayloadEditor.html.slim')
    scope:
      gym: '='
      payload: '='
      sequence: '='
      error: '='
      isBookingSequence: '&'
      isPaymentSequence: '&'
      isPaymentRelatedSequence: '&'
      isMembershipSequence: '&'
      isStyleSequence: '&'
      isGradingSequence: '&'
      isSuspensionSequence: '&'
      isRecurringBookingSequence: '&'
      isLateBookingCancellationSequence: '&'
      isBookingCancellationSequence: '&'
      isWaitlistAttendeeSequence: '&'
      isPerformedWorkoutSequence: '&'
      isSendMobileAppInviteEmailStep: '='
      isSendMobileAppInviteSmsStep: '='
      humanizedRecipientChoice: '&'
      recipientChoiceHint: '&'
    link:
      pre: ($scope) ->
        $scope.id = random()
        $scope.merge_tags = [ MERGE_TAG_LIST ]
        $scope.payload.reply_address = DefaultReplyAddressService.getDefaultEmail()
        $scope.emailSenders = $scope.gym.reply_email_addresses
        $scope.replyEmails = (sender.email for sender in $scope.emailSenders)
        $scope.smsOptOutInstructionsOptions = SMS_OPT_OUT_INSTRUCTIONS_POLICY_OPTIONS;
        $scope.smsHintText = SMS_OPT_OUT_POLICY_HINT_TEXT

        $scope.pushNotificationRoutesContext = ->
          if $scope.isPaymentSequence()
            'payment_sequence'
          else if $scope.isBookingSequence()
            'booking_sequence'
          else if $scope.isMembershipSequence()
            'membership_sequence'
          else if $scope.isPerformedWorkoutSequence()
            'workout_sequence'
          else
            ''

        $scope.pushNotificationRoutes = [{label: "No preference (open app to last viewed screen)", value: ''}]

        $scope.loadPushNotificationRoutes = ->
          PushNotificationOptionsService.getOptions($scope.gym.id, $scope.pushNotificationRoutesContext())
            .then (data) ->
              if data.length
                $scope.pushNotificationRoutes = [{label: "No preference (open app to last viewed screen)", value: ''}].concat(data)
              else
                $scope.pushNotificationRoutes = data
            .catch (error) ->
              flash.error error

        $scope.loadPushNotificationRoutes()

        $scope.$watch 'sequence.trigger', (newSequenceTrigger, oldSequenceTrigger) ->
          if newSequenceTrigger isnt oldSequenceTrigger
            $scope.loadPushNotificationRoutes()

        if $scope.payload.type == 'PushNotificationSequencePayload' && $scope.pushNotificationRoutes.length
          $scope.payload?.push_notification_route_name = $scope.payload?.push_notification_route_name || $scope.pushNotificationRoutes[0].value

        $scope.checkEmailAuthorization = (email) ->
          isEmailAuthorized = $scope.emailSenders.find((item) -> item.email == email)
          if isEmailAuthorized
            return isEmailAuthorized.authorized
          else
            return false

        if $scope.gym.member_portal_enabled
          $scope.merge_tags = [$scope.merge_tags[0].concat(MEMBER_PORTAL_MERGE_TAG_LIST)]

        if $scope.gym.mobile_app_enabled && !$scope.isSendMobileAppInviteEmailStep && !$scope.isSendMobileAppInviteSmsStep
          $scope.merge_tags = [$scope.merge_tags[0].concat(['mobile-app-ios-app-store-link', 'mobile-app-google-app-store-link'])]

        if $scope.isSendMobileAppInviteEmailStep
          $scope.merge_tags = [$scope.merge_tags[0].concat(MOBILE_APP_EMAIL_INVITE_MERGE_TAG_LIST)]

        if $scope.isSendMobileAppInviteSmsStep
          $scope.merge_tags = [$scope.merge_tags[0].concat(MOBILE_APP_SMS_INVITE_MERGE_TAG_LIST)]

        $scope.payment_merge_tags = if FeatureAvailabilityService.isAvailable('credit_vouchers')
          [ PAYMENT_MERGE_TAGS.concat(CREDIT_VOUCHER_MERGE_TAGS) ]
        else
          [ PAYMENT_MERGE_TAGS ]
        $scope.membership_merge_tags = [ MEMBERSHIP_MERGE_TAGS ]
        $scope.booking_merge_tags = [ BOOKING_MERGE_TAGS ]
        $scope.grading_merge_tags = [ GRADING_MERGE_TAGS ]
        $scope.style_merge_tags = [ STYLE_MERGE_TAGS ]
        $scope.suspension_merge_tags = [ SUSPENSION_MERGE_TAGS ]
        $scope.recurring_booking_merge_tags = [ RECURRING_BOOKING_MERGE_TAGS ]
        $scope.late_cancelled_booking_merge_tags = [ LATE_CANCELLED_BOOKING_MERGE_TAGS ]
        $scope.booking_cancellation_merge_tags = [ BOOKING_CANCELLATION_MERGE_TAGS ]
        $scope.waitlist_attendee_merge_tags = [ WAITLIST_ATTENDEE_MERGE_TAGS ]
        $scope.performed_workout_merge_tags = [ PERFORMED_WORKOUT_MERGE_TAGS ]
    controller: ($scope, $rootScope, FeatureAvailabilityService) ->
      $scope.replyEmails = $rootScope.gym.reply_email_addresses
      SMSesSentThisMonthService.load()
      $scope.getSmsesSentThisMonth = SMSesSentThisMonthService.getCount
      $scope.users = $rootScope.gym.users.asJson()
      $scope.useNewEditor = $rootScope.gym.gym_config.use_slate_editor

      paymentMergeTags = if FeatureAvailabilityService.isAvailable('credit_vouchers')
        PAYMENT_MERGE_TAGS.concat(CREDIT_VOUCHER_MERGE_TAGS)
      else
        PAYMENT_MERGE_TAGS

      angular.element('.not-sortable')[0].addEventListener 'touchstart', (e) ->
        e.stopPropagation()

      $scope.getErrors = ->
        if $scope.error
          return $scope.error

        template =
          channel: $scope.payload.type is 'SmsSequencePayload' && 'sms'
          content: $scope.payload.body
          sms_opt_out_instructions_policy: $scope.payload.sms_opt_out_instructions_policy
          push_notification_route_name: $scope.payload.push_notification_route_name

        SmsValidationService.getErrors(template)

      $scope.getTitleErrorsArray = ->
        if !$scope.payload.subject && $scope.sequence.errors['sequence_steps.sequence_payloads.subject']
          ['The message subject is required']

      getMergeTagGroups = ->
        contactMergeTags = MERGE_TAG_LIST

        if $rootScope.gym.member_portal_enabled
          contactMergeTags = contactMergeTags.concat(MEMBER_PORTAL_MERGE_TAG_LIST)

        if $rootScope.gym.mobile_app_enabled && !$scope.isSendMobileAppInviteEmailStep && !$scope.isSendMobileAppInviteSmsStep
          contactMergeTags = contactMergeTags.concat(['mobile-app-ios-app-store-link', 'mobile-app-google-app-store-link'])

        if $scope.isSendMobileAppInviteEmailStep
          contactMergeTags = contactMergeTags.concat(MOBILE_APP_EMAIL_INVITE_MERGE_TAG_LIST)

        if $scope.isSendMobileAppInviteSmsStep
          contactMergeTags = contactMergeTags.concat(MOBILE_APP_SMS_INVITE_MERGE_TAG_LIST)

        groups = [{title: 'Contact merge tags', tags: contactMergeTags}]
        if $scope.isPaymentSequence()
          groups.push({title: 'Payment merge tags', tags: paymentMergeTags})
        if $scope.isBookingSequence()
          groups.push({title: 'Booking merge tags', tags: BOOKING_MERGE_TAGS})
        if $scope.isMembershipSequence()
          groups.push({title: 'Membership merge tags', tags: MEMBERSHIP_MERGE_TAGS})
        if $scope.isGradingSequence()
          groups.push({title: 'Grading merge tags', tags: GRADING_MERGE_TAGS})
        if $scope.isStyleSequence()
          groups.push({title: 'Style merge tags', tags: STYLE_MERGE_TAGS})
        if $scope.isSuspensionSequence()
          groups.push({title: 'Suspension merge tags', tags: SUSPENSION_MERGE_TAGS})
        if $scope.isRecurringBookingSequence()
          groups.push({title: 'Recurring booking merge tags', tags: RECURRING_BOOKING_MERGE_TAGS})
        if $scope.isLateBookingCancellationSequence()
          groups.push({title: 'Late Booking Cancellation merge tags', tags: LATE_CANCELLED_BOOKING_MERGE_TAGS})
        if $scope.isBookingCancellationSequence()
          groups.push({title: 'Booking Cancellation merge tags', tags: BOOKING_CANCELLATION_MERGE_TAGS})
        if $scope.isWaitlistAttendeeSequence()
          groups.push({title: 'Waitlist attendee merge tags', tags: WAITLIST_ATTENDEE_MERGE_TAGS})
        if $scope.isPerformedWorkoutSequence()
          groups.push({title: 'Performed workout merge tags', tags: PERFORMED_WORKOUT_MERGE_TAGS})
        groups

      $scope.$watch 'sequence.trigger', () ->
        $scope.mergeTagGroups = getMergeTagGroups()

      $scope.isEmailUserPayload = ->
        $scope.payload.type == 'EmailUserSequencePayload'

      $scope.isEmailPayload = ->
        $scope.payload.type == 'EmailSequencePayload' || $scope.isEmailUserPayload() || $scope.isSendMobileAppInviteEmailStep

      $scope.isSmsPayload = ->
        $scope.payload.type == 'SmsSequencePayload' || $scope.isSendMobileAppInviteSmsStep

      $scope.isPushNotificationPayload = ->
        $scope.payload.type == 'PushNotificationSequencePayload'

      $scope.isSendMobileAppInviteStep = ->
        $scope.payload.type == 'MobileAppSequencePayload' && $scope.payload.send_mobile_app_invite

      $scope.onEmailUserChange = () ->
        user = $scope.users.find (u) -> u.id is $scope.payload.user_id
        $scope.payload.user_name = user?.name
        $scope.payload.user_email = user?.email

      $scope.saveLabel = ->
        switch
          when $scope.isEmailPayload()
            'Email'
          when $scope.isSmsPayload()
            'SMS'
          when $scope.isPushNotificationPayload()
            'Push Notification'

      $scope.sendToOptions = ['send_to_contact_only', 'send_to_matching_recipients', 'send_to_matching_recipients_with_contact_fallback']

      $scope.save = ->
        $scope.payload.editable = false

      $scope.getRemainingCharacters = ->
        if $scope.isSmsPayload()
          return SmsValidationService.getRemainingCharacters($scope.payload.body)
        if $scope.isPushNotificationPayload()
          PushNotificationValidationService.getRemainingCharacters($scope.payload.body)

      $scope.displayRemainingCharacters = ->
        if $scope.isSmsPayload()
          return SmsValidationService.displayRemainingCharacters($scope.payload.body)
        if $scope.isPushNotificationPayload()
          PushNotificationValidationService.displayRemainingCharacters($scope.payload.body)

      $scope.displayRemainingCharactersDetails = ->
        $modal.open
          template: require('templates/gyms/bulk_messages/remaining_characters_details.html.slim')
          controller: 'RemainingCharactersDetailsModal'

      $scope.isValid = ->
        if $scope.isSmsPayload() || $scope.isPushNotificationPayload()
          $scope.getRemainingCharacters() >= 0
        else
          true
