angular
  .module 'gym.components'
  .directive 'signedWaiver', ->
    restrict: 'EA'
    scope:
      waiver: '='
      currency: '='
      internal: '='
    template: require('templates/components/waivers/signed_waiver.html.slim')
    controller: (
      $scope,
      $rootScope,
      $filter
    ) ->
      $scope.walletDetails = $scope.waiver.signed_waiver_wallet_detail_attributes
      $scope.contact = $scope.waiver.signed_waiver_contacts_attributes[0]

      country = $rootScope.gym?.country || window.DATA.gym_country

      $scope.serviceAgreementUrl = switch country
        when 'AU'
          'https://static.ezidebit.com.au/ServiceAgreement/AU/DDR_Service_Agreement.html'
        when 'NZ'
          'https://static.ezidebit.com.au/ServiceAgreement/NZ/DDR_Service_Agreement.html'

      $scope.replaceMergeTags = ->
        contact = $scope.waiver.signed_waiver_contacts_attributes[0]
        country = $scope.waiver.waiver_header_address_country
        waiverDateValue = moment($scope.waiver.signed_at).format('MMMM Do YYYY')
        waiverForm = $scope.waiver.signed_waiver_form_attributes
        waiverForm.gym_tacs_content =
          $filter('replaceWaiverMergeTags')(waiverForm.gym_tacs_content, contact, country)
        waiverForm.electronic_consent_content =
          $filter('replaceWaiverMergeTags')(waiverForm.electronic_consent_content, contact, country, waiverDateValue)
        waiverForm.waiver_release_content =
          $filter('replaceWaiverMergeTags')(waiverForm.waiver_release_content, contact, country)

      $scope.replaceMergeTags()

      $scope.membershipTerm = (membership) ->
        if membership.term_number
          number = membership.term_number
          unit = $filter('pluralize')(membership.term_unit, number)
          "for #{number} #{unit}"
        else
          'until further notice'

      $scope.displayMemberNumber = (contact) ->
        signed_waiver = $scope.waiver.signed_waiver_form_attributes
        signed_waiver.accept_member_number && contact.attending
