angular
  .module 'gym.components'
  .directive 'contactAttendance', ->
    restrict: 'E'
    template: require('templates/components/contacts/attendance/contact_attendance.html.slim')
    scope:
      gym: '='
      contact: '='
      attendanceForm: '='
      isVisible: '='
      attendanceTab: '='
      setTab: '&'
      reloadContact: '&'
      addBooking: '&'
      bookFriend: '&'
    controller: (
      $scope,
      $rootScope,
      $window,
      $http,
      $state,
      $modal,
      $timeout,
      flash,
      ngTableParams,
      graphService,
      LateCancellationService,
      EmploymentService,
      AdminUserService,
      EventService,
      Contacts,
      MakeUpClasses,
      MessageService
    ) ->
      $scope.permissions = EmploymentService.get().permissions
      $scope.adminHasPermission = AdminUserService.has

      contact = $scope.contact
      $scope.bookingFilters = {}
      $scope.bookings = []
      $scope.pastBookings = []
      $scope.makeUpClassesFilters =
        'filters[contact_id]': $scope.contact.id
      $scope.isMa = $scope.gym.is_martial_arts
      $scope.currency = $scope.gym.currency_symbol

      $rootScope.$on 'contact:make_up_classes:reload', ->
        $scope.loadMakeUpClasses()

      $scope.$watch 'isVisible', (visible) ->
        if visible
          loadWaitlist() if $scope.contact.on_waitlist
          loadFutureBookings() if !$scope.isNonAttending()
          $scope.reloadAttendance()

      loadMakeUpClasses = (page) ->
        $scope.makeUpClassesLoading = true
        params = angular.extend {}, page: page, $scope.makeUpClassesFilters
        MakeUpClasses.getList(params).then (makeUpClasses) ->
          if page > 1
            result = $scope.makeUpClasses.concat makeUpClasses
            result.meta = makeUpClasses.meta
          else
            result = makeUpClasses
          $scope.makeUpClasses = result
          $scope.makeUpClassesLoading = false

      $scope.loadMakeUpClasses = () ->
        loadMakeUpClasses(1)

      moreMakeUpClassesAvailable = ->
        makeUpClasses = $scope.makeUpClasses
        !makeUpClasses || (makeUpClasses.length and makeUpClasses.length < makeUpClasses.meta.total)

      $scope.loaMoredMakeUpClasses =  _.throttle ( ->
        if !$scope.makeUpClassesLoading and moreMakeUpClassesAvailable()
          page = if $scope.makeUpClasses then $scope.makeUpClasses.meta.page + 1 else 1
          loadMakeUpClasses(page)
      ), 500

      $scope.deleteMakeUpClass = (makeUpClass) ->
        if $window.confirm 'Are you sure you want to remove this make-up class?'
          makeUpClass.remove().then ->
            $scope.loadMakeUpClasses()

      $scope.showEvent = (booking) ->
        EventService.showAttendees(booking.event, showCalendarLink: true)

      $scope.editMakeUpClass = (makeUpClass) ->
        modalInstance = $modal.open
          template: require('templates/components/membership/membership_make_up_modal.html.slim')
          resolve:
            membership: ->
              id: makeUpClass.membership_id
              label: makeUpClass.membership_name
              expiration_date: makeUpClass.membership_expiration_date
            makeUpClass: -> makeUpClass
            contact: -> $scope.contact
          controller: 'MembershipMakeUpClassModalCtrl'

        modalInstance.result.then ->
          $scope.loadMakeUpClasses()

      $scope.setAttendanceTab = (tab) ->
        $scope.setTab(tab: tab)
        $scope.loadMakeUpClasses() if tab is 'make_up_classes'

      loadFutureBookings = (page) ->
        $scope.bookingsMode = 'future'
        $scope.isLoading = true
        contact.all('bookings').all('future_bookings').getList(page: page)
          .then (bookings) ->
            if page > 1
              result = $scope.bookings.concat bookings
              result.meta = bookings.meta
            else
              result = bookings
            $scope.bookings = result
            $scope.isLoading = false

      $scope.generalAttendanceTable = new ngTableParams
        page: 1
        count: 10
      ,
        counts: []
        total: 0
        getData: ($defer, $params) ->
          contact.all('general_attendances').getList($params.url())
            .then (data) ->
              $params.total data.meta.total
              $scope.generalAttendance = data
              $defer.resolve data

      $scope.generalAttendanceRange = ->
        return unless $scope.generalAttendance
        return 0 unless $scope.generalAttendance.length
        page = $scope.generalAttendance.meta.page
        from = ((page - 1) * 10) + 1
        to = ((page - 1) * 10) + $scope.generalAttendance.length
        "#{from} - #{to}"

      $scope.updateAttendance = (booking, previousValue) ->
        url = "gyms/#{$rootScope.gym.id}/calendar/events/#{booking.event_id}"
        $http.get(url).then (response) ->
          event = response.data
          EventService.confirmUpdateAttendance({
            event: event
            booking: booking
            studentName: $scope.contact.name
            previousValue: previousValue
            reloadAttendance: $scope.reloadAttendance
          })

      $scope.addAppointment = ->
        EventService.addAppointment(contact_id: contact.id)

      $scope.reloadAttendance = ->
        $scope.editableSectionName = false
        $scope.reloadContact().then ->
          setupGraph()

      $scope.deleteAttendance = (attendance) ->
        if $window.confirm 'Are you sure you want to delete this attendance?'
          attendance.remove().then ->
            $scope.reloadAttendance()
            $scope.generalAttendanceTable.reload()

      $scope.getExportUrl = ->
        "#{contact.getRestangularUrl()}/contact_attendances.csv"

      $scope.isNonAttending = ->
        $scope.contact.type is 'non_attending_contact'

      moreResultsAvailable = ->
        bookings = $scope.bookings
        $scope.bookingsMode isnt 'filtered' and
          bookings.length and bookings.length < bookings.meta.total

      $scope.loadFutureBookings =  _.throttle ( ->
        if !$scope.isLoading and moreResultsAvailable()
          loadFutureBookings($scope.bookings.meta.page + 1)
      ), 500

      $scope.showPastBookings = ->
        $scope.bookingsMode = 'past'
        contact.all('bookings').all('past_bookings').getList()
          .then (bookings) ->
            $scope.pastBookings = bookings.plain()

      $scope.hidePastBookings = ->
        $scope.bookingsMode = 'future'

      $scope.attendanceFilterOptions =
        [{id: -1, label: "From other Members"}]
          .concat($scope.contact.memberships)

      $scope.loadBookings = ->
        params =
          'filters[starts_before]': $scope.bookingFilters.starts_before
          'filters[starts_after]': $scope.bookingFilters.starts_after
          'filters[membership_id]': $scope.bookingFilters.membership_id
          count: 1000
        contact.all('bookings')
          .getList(params).then (bookings) ->
            $scope.bookings = bookings.plain()

      $scope.filterBookings = ->
        $timeout ->
          $scope.bookingsMode = 'filtered'
          $scope.loadBookings()

      $scope.clearAttendanceFilters = ->
        $scope.bookingFilters = {}
        loadFutureBookings(1)

      $scope.showInfo = (booking) ->
        modalInstance = $modal.open
          template: require('templates/gyms/calendar/events/booking_info_modal.html.slim')
          resolve:
            booking: -> booking
          controller: 'BookingInfoCtrl'

      $scope.removeBooking = (booking, form) ->
        modalInstance =
          if booking.restrict_cancellations && !booking.cancellable && booking.allow_late_cancellations
            LateCancellationService.cancel(booking)
          else
            $modal.open
              template: require('templates/gyms/calendar/cancel_bookings_modal.html.slim')
              resolve:
                booking: ->
                  booking
                contact: ->
                  contact
                fromContactProfile: ->
                  true
              controller: 'CancelBookingsCtrl'

        modalInstance.result.then ->
          $scope.reloadAttendance()
          reloadBookings()

      reloadBookings = ->
        if $scope.bookingsMode is 'filtered'
          $scope.loadBookings()
        else
          loadFutureBookings()
          if $scope.bookingsMode is 'past'
            $scope.showPastBookings()

      $scope.goToCustomFields = ->
        $state.go('gym.form-library')

      $rootScope.$on 'calendar:updated', ->
        $scope.clearAttendanceFilters()

      $rootScope.$on 'contact:attendance:updated', (event, contactId) ->
        if contact.id is contactId
          $scope.reloadContact().then ->
            setupGraph()
          $scope.generalAttendanceTable.reload()

      $scope.deleteWaitlist = (waitlist) ->
        EventService.removeFromWaitlist(waitlist).then ->
          loadWaitlist()

      $scope.isBookedForFriend = (booking) ->
        booking.membership_taken_from_contact_id &&
        booking.membership_taken_from_contact_id isnt booking.student_id

      loadWaitlist = ->
        contact.all('waitlist_attendees').getList().then (waitlist) ->
          $scope.waitlistItems = waitlist

      visitsGraphParams =
        lineColor: '#94bf1f'
        selector: '#users-chart svg'
        yFormat: '2d'
        fillArea: true
        lineType: 'monotone'
        margin: {top: 10, left: 40, right: 15, bottom: 20}
        hideMaxMin: true

      setupGraph = ->
        graphService.setupGraph(
          angular.copy($scope.contact.last_year_visits),
          visitsGraphParams
        )

      $scope.trialReminderIsAvailable =
        $rootScope.gym.on_trial_period && $rootScope.gym.trialling_highest_plan && $rootScope.gym.fitgro_gym
