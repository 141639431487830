angular.module 'shared'
  .factory 'ActivityService', ->

    getIconClass: (event) ->
      switch
        when event.type in ['attendance_log_entry', 'contact_attendance_log_entry', 'roll_call_booking_log_entry']
          'activity-attendance'
        when event.type in ['booking_log_entry', 'waitlist_log_entry', 'recurring_booking_log_entry']
          'activity-booking'
        when event.type in ['late_booking_cancellation_log_entry', 'booking_cancellation_log_entry']
          'activity-booking activity--failed'
        when event.type is 'kiosk_checkin_alert_log_entry'
          'activity-warning'
        when event.type is 'contact_flag_log_entry'
          'activity-flag'
        when event.type is 'contact_relationship_log_entry'
          'activity-family'
        when event.type is 'credit_voucher_log_entry'
          'activity-credit-voucher'
        when event.type in ['signed_waiver_log_entry', 'signed_waiver_merge_log_entry', 'signed_waiver_review_log_entry', 'signed_waiver_consent_request_log_entry']
          'activity-waiver'
        when event.type is 'contact_workout_log_entry'
          'activity-workout'
        when event.type in ['grading_log_entry', 'grading_event_log_entry', 'roll_call_promotion_log_entry', 'member_style_log_entry']
          'activity-style'
        when event.type is 'member_style_delete_log_entry'
          'activity-style activity--failed'
        when event.type is 'sequence_log_entry'
          'activity-sequence'
        when event.type is 'task_log_entry'
          'activity-task'
        when event.type is 'sale_log_entry'
          'activity-sale'
        when event.type is 'contact_comms_recipient_log_entry'
          'activity-comms-recipient'
        when event.type is 'data_series_log_entry'
          'activity-data-series'
        when event.type in ['contact_brivo_detail_log_entry', 'contact_brivo_credential_log_entry', 'contact_brivo_door_access_log_entry', 'contact_innertelligence_credential_log_entry']
          'activity-access-control'
        when event.type in ['report_log_entry', 'static_report_exclusion_log_entry']
          'activity-report'
        when event.type is 'member_mobile_app_detail_log_entry'
          'activity-mobile-app'
        when event.type in ['email_log_entry', 'email_event_log_entry', 'contact_email_verification_log_entry']
          'activity-email'
        when event.type is 'contact_email_unsubscribe_log_entry'
          'activity-email activity--failed'
        when event.type in ['member_log_entry', 'prospect_log_entry', 'contact_log_entry', 'contact_nac_conversion_log_entry', 'contact_suspension_log_entry', 'contact_conversion_log_entry', 'prospect_signup_log_entry', 'privacy_policy_acceptance_log_entry']
          'activity-user'
        when event.type in ['invoice_log_entry', 'failure_fee_log_entry', 'payment_plan_log_entry']
          'activity-payment'
        when event.type in ['payment_method_log_entry', 'stripe_payment_method_auth_log_entry', 'wallet_sharing_log_entry', 'gocardless_mandate_log_entry']
          'activity-wallet'
        when event.type in ['membership_log_entry', 'membership_expired_log_entry', 'membership_replacement_log_entry']
          'activity-membership'
        when event.type in ['membership_deletion_log_entry', 'membership_cancellation_log_entry']
          'activity-membership activity--failed'
        when event.type is 'conversation_log_entry' and event.data.channel is 'email'
          'activity-email'
        when event.type is 'conversation_log_entry' and event.data.channel is 'sms'
          'activity-sms'
        when event.type is 'conversation_log_entry' and event.data.channel is 'push_notification'
          'activity-push-notification'
        when event.type is 'push_notification_failure_log_entry'
          'activity-push-notification activity--failed'
        when event.type in ['sms_failure_log_entry', 'contact_sms_unsubscribe_log_entry']
          'activity-sms activity--failed'
        when event.type is 'sms_reply_log_entry'
          'activity-sms sms-reply'
        when event.type is 'note_log_entry'
          'activity-note'
        else
          'activity-default'

    payloadTypeClass: (type) ->
      switch type
        when 'EmailSequencePayload'
          'activity-email'
        when 'EmailUserSequencePayload'
          'activity-email'
        when 'SmsSequencePayload'
          'activity-sms'
        when 'PushNotificationSequencePayload'
          'activity-push-notification'
        when 'NewMembershipSequencePayload'
          'activity-membership'
        when 'ContactWorkoutSequencePayload'
          'activity-workout'
        when 'NewMemberStyleSequencePayload'
          'activity-style'
        when 'AddCreditVoucherSequencePayload'
          'activity-credit-voucher'
        when 'TaskSequencePayload'
          'activity-task'
        when 'MemberPortalSequencePayload'
          'activity-user'
        when 'MobileAppSequencePayload'
          'activity-mobile-app'
        when 'ProspectStatusChangeSequencePayload'
          'activity-prospect-status-change'
        when 'SendWaiverSequencePayload'
          'activity-waiver'
        when 'AddMakeupClassSequencePayload'
          'activity-make-up-class'
        when 'ConvertToNacSequencePayload'
          'activity-user'
        when 'AddContactFlagSequencePayload'
          'activity-flag'
        when 'FormFieldUpdateSequencePayload'
          'activity-user'
        when 'AddNoteSequencePayload'
          'activity-note'
        when 'AddBrivoCredentialSequencePayload'
          'activity-access-control'
