import plural from './plural';
import underscored from './underscored';

type ContactType = 'members' | 'prospects' | 'non_attending_contacts';

const contactType = (type): ContactType => {
  return underscored(plural(type, 2));
};

export default contactType;
