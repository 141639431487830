angular
  .module('shared')
  .factory 'FeatureAvailabilityService', ($rootScope, $modal) ->

    isAvailable: (feature) ->
      $rootScope.gym?.features_enabled && feature in $rootScope.gym.features_enabled

    showNotAvailableMessage: (feature) ->
      $modal.open
        template: require('templates/gyms/subscriptions/feature_not_available_modal.html.slim')
        resolve:
          feature: -> feature

        controller: ($scope, $modalInstance, feature) ->
          $scope.isPayor = window.currentUser.account_owner

          $scope.feature = feature

          $scope.close = ->
            $modalInstance.dismiss('cancel')
