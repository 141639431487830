angular
  .module 'shared'
  .directive 'modalWindow', () ->
    restrict: 'A'
    link: ($scope, element, attrs) ->
      $scope.isDrawer = attrs.windowClass is 'drawer__wrapper'
    controller: ($scope, $timeout) ->
      $timeout ->
        if $scope.isDrawer
          angular.element('body').addClass('modal-open__drawer')

          $scope.$on '$destroy', () ->
            angular.element('body').removeClass('modal-open__drawer')
