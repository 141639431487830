angular
  .module 'shared'
  .directive 'dateInput', ($timeout) ->
    restrict: 'E'
    template: require('templates/shared/date_input.html.slim')
    scope:
      model: '='
      dropdown: '='
      minDate: '='
      maxDate: '='
      allowRemove: '='
      isDisabled: '='
      alwaysOpen: '='
      inputSm: '='
      isSm: '='
      compact: '='
      fullWidth: '='
      appendToBody: '='
      name: '@'
      placeholder: '@'
      elementId: '@'
      modelFormat: '@'
      displayFormat: '@'
      mode: '@'
      position: '@'
      onChange: '&'
      variant: '@'
      newIcon: '='
      clearWithNull: '='
    controller: ($scope) ->
      $scope.model_format = $scope.modelFormat || 'YYYY-MM-DD'
      $scope.display_format = $scope.displayFormat || 'dd MMM yyyy'
      $scope.isOpen = true if $scope.alwaysOpen
      $scope.id = Math.floor(Math.random() * 10000)

      $scope.onDateChanged = ->
        $timeout ->
          $scope.onDateClick()
          $scope.onChange()

      $scope.remove = (event) ->
        event.preventDefault()
        event.stopPropagation()
        $scope.model = if $scope.clearWithNull
          null
        else
          undefined
        $scope.onDateChanged()
    link: ($scope, element) ->
      pxToInteger = (val) ->
        +(val.replace('px', ''))

      $scope.isTop = ->
        inputPositions = element[0].getBoundingClientRect()
        $scope.position is 'top' || (inputPositions.bottom + 320 > window.innerHeight && inputPositions.top > 320)

      $scope.onDateClick = ->
        element.find('input').blur()

      $scope.toggle = (event) ->
        unless $scope.isDisabled or $scope.alwaysOpen
          $scope.isOpen = !$scope.isOpen

        if $scope.isOpen
          if $scope.appendToBody
            $timeout ->
              angular.element('[datepicker-popup-wrap]').each (index, item) ->
                positions = item.getBoundingClientRect()
                if positions.width && positions.width + positions.left > window.innerWidth
                  angular.element(item).css({right: 0, left: 'unset'})
                if positions.bottom > window.innerHeight
                  newTop = pxToInteger(angular.element(item).css('top')) - 245
                  angular.element(item).css({top: newTop})
          else
            $timeout ->
              inputPositions = element[0].getBoundingClientRect()
              popup = element.find('[datepicker-popup-wrap]')
              popupPositions = popup[0]?.getBoundingClientRect()
              if popupPositions?.width && popupPositions.width + popupPositions.left > window.innerWidth
                diff = window.innerWidth - (popupPositions.width + inputPositions.left)
                popup.css({left: diff})

        event.preventDefault()
        event.stopPropagation()
