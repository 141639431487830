angular
  .module 'gym.components'
  .directive 'waiverPrivacyPolicySection', ->
    restrict: 'EA'
    scope:
      waiver: '='
      privacyPolicyResponses: '='
      readOnly: '='
      internal: '='
    template: require('templates/components/waivers/waiver_privacy_policy_section.html.slim')
    controller: ($scope) ->
      $scope.isGDPREnabled = ->
        form = $scope.waiver.signed_waiver_form_attributes
        form.contact_age_check_privacy_policy_checkbox_active ||
          form.contact_information_privacy_policy_checkbox_active
