angular
  .module 'shared'
  .directive 'switch', ->
    restrict: 'E'
    template: require('templates/shared/switch.html.slim')
    scope:
      ngModel: '='
      ngChange: '&'
      disabled: '='
      inverse: '='
    controller: (
      $scope,
      $timeout
    ) ->
      $scope.id = Math.floor(Math.random() * 10000)

      $scope.isChecked = ->
        if $scope.inverse
          !$scope.ngModel
        else
          $scope.ngModel

      $scope.onChange = ->
        $timeout ->
          $scope.ngChange()
