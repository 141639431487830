angular
  .module 'gym.components'
  .directive 'integrapayCreditCardForm', ->
    restrict: 'EA'
    scope:
      card: '='
      contact: '='
      customer: '='
      form: '='
      gym: '='
      isDisabled: '='
      token: '='
    template: require('templates/components/contacts/wallet/integrapay_credit_card_form.html.slim')
    controller: (
      $scope,
      $sce,
      CreditCardValidationService,
      CreditCardExpirationDatesService
    ) ->
      $scope.years = CreditCardExpirationDatesService.getYears()
      $scope.months = CreditCardExpirationDatesService.getMonths()

      $scope.resourceUrl = $sce.trustAsResourceUrl($scope.token.vault_url)

      $scope.checkCardNumber = ->
        $scope.card.CreditCardNumber =
          $scope.card.CreditCardNumber?.replace(/[^0-9]/g, '')
        $scope.creditCardValid =
          CreditCardValidationService.check($scope.card.CreditCardNumber)

      $scope.checkName = (name) ->
        $scope.nameValid = CreditCardValidationService.nameValid(name)
