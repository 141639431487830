angular.module 'gym.components'
  .controller 'SendInvitationEmailCtrl', (
    $scope,
    $rootScope,
    $modalInstance,
    $http,
    TemplateSanitizeService,
    template,
    baseUrl
    isUnsubscribedFromEmail
  ) ->
    $scope.template = template
    $scope.isUnsubscribedFromEmail = isUnsubscribedFromEmail
    $scope.gym = $rootScope.gym
    $scope.submit = ->
      $scope.isLoading = true
      data = TemplateSanitizeService.sanitize($scope.template)
      params =
        invite_email_subject: data.subject
        invite_email_content: data.content
      url = "#{baseUrl}/send_invitation"

      $http.put(url, params).then () ->
        $modalInstance.close()
      , (response) ->
        $scope.error = response.data.error_message
        $scope.isLoading = false

    $scope.cancel = ->
      $modalInstance.dismiss('cancel')
