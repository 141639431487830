angular.module 'shared'
  .factory 'AssetHashCheckerService', ($q, $stateParams, $http) ->
    loadDebounced = _.debounce ((deferred) ->
      $http.get('/asset_hash').then (response) ->
        deferred.resolve response.data
      , (data) ->
        deferred.reject data
    ), 500

    load: () ->
      deferred = $q.defer()
      loadDebounced(deferred)
      deferred.promise


angular
  .module 'shared'
  .directive 'assetHashChecker', () ->
    restrict: 'E'
    template: '''
      <div class="assets-outdated-warning" ng-if="outdated">
        <div class="assets-outdated-warning__icon">
          <i class="icon-gear"></i>
        </div>
        <div>
          Clubworx has been updated!
          </br>
          <a href="" ng-click="reload()">Click here to reload the page.</a>
        </div>
      </div>
    '''
    controller: ($scope, AssetHashCheckerService) ->
      checkHashes = ->
        now = new Date().getTime()
        if !$scope.outdated && (!$scope.lastCheckTimetamp || now - $scope.lastCheckTimetamp >= 300000)
          AssetHashCheckerService.load().then (hashes) ->
            $scope.lastCheckTimetamp = now
            $scope.outdated = $scope.hashes && ($scope.hashes.application_css isnt hashes.application_css ||
              $scope.hashes.application_js isnt hashes.application_js)
            $scope.hashes = hashes

      document.addEventListener "visibilitychange", () ->
        if (document.visibilityState is "visible")
          checkHashes()

      window.setInterval checkHashes, 300000

      $scope.reload = ->
        window.location.reload()
