angular
  .module 'shared'
  .directive 'longEmailWrapper', () ->
    restrict: 'E'
    scope:
      email: '='
    template: '''
      <span style="display: flex; flex-wrap: wrap;">
        <span ng-repeat="component in emailComponents">{{component}}</span>
      </span>
    '''
    controller: ($scope) ->
      splitBySymbol = (email, symbol) ->
        if Array.isArray email
          email.map (item) -> splitBySymbol(item, symbol)
        else
          email.split(symbol).map (item, index) ->
            if index > 0
              "#{symbol}#{item}"
            else
              item

      getEmailComponents = (email) ->
        components = splitBySymbol(email, '@')
        components = splitBySymbol(components, '+')
        components = splitBySymbol(components, '.')
        $scope.emailComponents = _.flatten components


      $scope.$watch 'email', (email) -> getEmailComponents(email || '')
