import classnames from 'classnames'

angular
  .module 'shared'
  .directive 'htmlEditor', () ->
    restrict: 'E'
    template: require('templates/shared/html_editor.html.slim')
    scope:
      object: '='
      onlyLink: '='
      key: '@'
      titleKey: '='
      placeholder: '@'
      mergeTags: '='
      valueLabel: '='
      titleLabel: '='
      titleError: '='
      error: '='
      titleWrapperClass: '@'
      titleLabelClass: '@'
      titleInputClass: '@'
      mergeTagGroups: '='
      hideToolbar: '='
      plain: '='
      disabled: '='
      singleLine: '='
      disableImage: '='
      disableToggleHtml: '='
      disableBold: '='
      disableFontSelection: '='
      disableColorPickers: '='
      isSms: '='
      isPushNotification: '='
    controller: ($scope, $rootScope) ->
      $scope.toolbar = switch
        when $scope.hideToolbar || $scope.plain
          []
        when $scope.onlyLink
          [['insertHttpsLink']]
        else
          [_.compact([
            !$scope.disableBold && 'bold',
            'italics',
            'underline',
            'ul',
            'ol',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            !$scope.disableImage && 'insertImage',
            !$scope.disableImage && 'insertAssetImage',
            'insertHttpsLink'
          ])]

      $scope.editorClass = classnames({
        'form-control': true
        'form-subject': $scope.singleLine
      })

      $scope.useNewEditor = $rootScope.gym.gym_config.use_slate_editor

      $scope.onChange = (val) ->
        $scope.object[$scope.key] = val

      if $scope.titleKey
        $scope.onTitleChange = (val) ->
          $scope.object[$scope.titleKey] = val
