import 'jquery';
import 'jquery-ujs';
import 'angular';
import 'vendor/angular-ui/bootstrap';
import 'ng-rollbar';
import 'angular-ui-router';
import 'vendor/angular-flash';
import 'vendor/ng-table';
import 'vendor/text-angular.js';
import 'vendor/text-angular-sanitize.min.js';
import 'angular-scroll';
import 'components-jquery-htmlclean';
import 'angular-file-model';
import 'restangular';
import 'ng-infinite-scroll';
import 'ng-tags-input';
import 'angular-clipboard';
import 'ng-mask';
import 'vendor/fullcalendar.js';
import 'vendor/angular-ui/calendar.js';
import 'angular-bootstrap-colorpicker';
import 'timepicker';
import 'vendor/angular-signature';
import 'modules/http_interceptors.js';
import 'vendor/ezidebit_2_0_0.min.js';
import 'ngreact';

const requireAll = (requireContext) => {
  requireContext.keys().map((item) => requireContext(item));
};

requireAll(require.context('modules/website', true));
requireAll(require.context('modules/shared', true));
requireAll(require.context('modules/filters', true));
requireAll(require.context('modules/components', true));

import 'vendor/google_analytics.js';
import 'modules/shared/_shared.js.coffee';
import 'react/entry_points/shared/recaptcha.tsx';
