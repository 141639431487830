angular
  .module 'shared'
  .factory 'UnitSymbols', ->
    {
      'Inches': 'in',
      'Yards': 'yd',
      'Miles': 'mi',
      'Centimetres': 'cm',
      'Metres': 'm',
      'Kilometres': 'km',
      'Pounds': 'lb',
      'Grams': 'g',
      'Kilograms': 'kg'
    }
