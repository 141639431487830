angular
  .module 'shared'
  .directive 'iconRepeat', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.553 5.445c-2.037-2.037-4.924-3.212-8.098-2.887-4.587.463-8.361 4.187-8.874 8.774-.687 6.061 4 11.173 9.911 11.173 3.987 0 7.411-2.337 9.011-5.7.4-.837-.2-1.799-1.125-1.799-.462 0-.9.25-1.1.662-1.412 3.037-4.799 4.962-8.498 4.137-2.775-.612-5.012-2.874-5.599-5.649a7.502 7.502 0 0 1 7.311-9.148c2.075 0 3.924.862 5.274 2.224L14.88 9.12c-.787.787-.237 2.137.875 2.137h4.487c.687 0 1.25-.563 1.25-1.25V5.52c0-1.112-1.35-1.674-2.138-.887l-.8.812Z" fill="{{color || 'currentColor'}}"></path></svg>
    '''
