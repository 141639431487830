angular
  .module 'gym.components'
  .directive 'contactDataSeries', ->
    restrict: 'E'
    template: require('templates/components/contacts/data_series/data_series.html.slim')
    scope:
      contact: '='
    controller: (
      $scope,
      $rootScope,
      $filter,
      $window,
      loadingAnimation,
      EmploymentService,
      graphService,
      DataSeries,
    ) ->
      $scope.permissions = EmploymentService.get().permissions
      $scope.pageParams = {}

      $scope.showGeneralView = ->
        $window.scrollTo(0, 0)
        $scope.currentView = 'general'
        loadMetrics()
        $scope.loadGraph()

      $scope.loadGraph = ->
        params = metric_id: $scope.pageParams.selectedMetricId
        $scope.contact.all('data_series_results').get('graph', params).then (data) ->
          $scope.pageParams.selectedMetricId = data.metric_id
          $scope.graphData = data.graph_data
          if data.graph_data
            graphService.setupGraph(
              angular.copy(data.graph_data),
              graphParams
            )

      loadMetrics = ->
        DataSeries.all('metrics').all('enabled_only').getList(count: 100).then (data) ->
          $scope.metrics = data

      $scope.showGeneralView()

      $scope.record = (date = moment().format('YYYY-MM-DD'), editing) ->
        $scope.currentView = 'record'
        $scope.pageParams.editing = editing
        $scope.editRecordDate = date

      $scope.compare = ->
        $scope.currentView = 'compare'

      graphParams =
        lineColor: '#94bf1f'
        selector: '#data-series-chart svg'
        yFormat: ',2f'
        fillArea: true
        lineType: 'monotone'
        margin: {top: 20, left: 40, right: 15, bottom: 20}
        hideMaxMin: true
  
      $scope.isFreeTrialRemindMessageAvailable =
        $rootScope.gym.on_trial_period && $rootScope.gym.trialling_highest_plan
  
      $scope.plan = 'Established'
