angular
  .module 'gym.components'
  .directive 'kioskLinks', ->
    restrict: 'E'
    template: require('templates/components/calendar/kiosk_links.html.slim')
    scope:
      dashboard: '='
    controller: (
      $scope,
      $stateParams,
      flash,
      EmploymentService,
      KioskService
    ) ->
      $scope.permissions = EmploymentService.get().permissions

      $scope.selectedOption = 'general_attendance'

      $scope.setOption = (option) ->
        $scope.selectedOption = option
        $scope.enter()

      $scope.enter = ->
        switch $scope.selectedOption
          when 'general_attendance'
            $scope.onKioskModeClick()
          when 'class'
            $scope.onClassKioskModeClick()
          when 'roll_call'
            $scope.onRollCallClick()

      showError = ->
        flash.error = 'Sorry, you do not have permission to view this page.'

      $scope.onKioskModeClick = () ->
        if $scope.permissions.general_kiosk_mode
          KioskService.enterKioskMode()
        else
          showError()

      $scope.onClassKioskModeClick = () ->
        if $scope.permissions.class_kiosk_mode
          params =
            location_id: $stateParams.location_id
          KioskService.enterClassKioskMode(params)
        else
          showError()

      $scope.onRollCallClick = () ->
        if $scope.permissions.roll_call
          KioskService.enterRollCall()
        else
          showError()
