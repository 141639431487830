angular
  .module 'gym.components'
  .directive 'questionnaireForm', ->
    restrict: 'EA'
    scope:
      title: '='
      description: '='
      questionnaire: '='
      displayTitle: '='
      preview: '='
      errors: '='
    template: require('templates/components/waivers/questionnaire_form.html.slim')
    controller: ($scope) ->
      parseQuestionnaire = ->
        questionnaire = $scope.questionnaire
        $scope.questionnaireList = []
        _.times 50, (i) ->
          if questionnaire["question_#{i+1}_type"]
            options =
              if questionnaire["question_#{i+1}_type"] is 'select'
                questionnaire["question_#{i+1}_options"].map (option) ->
                  text: option
              else questionnaire["question_#{i+1}_options"]
            question =
              active: questionnaire["question_#{i+1}_active"]
              type: questionnaire["question_#{i+1}_type"]
              title: questionnaire["question_#{i+1}_title"]
              options: options
              required: questionnaire["question_#{i+1}_required"]
              position: i+1
            $scope.questionnaireList.push question

            if question.type is 'checkbox'
              $scope.questionnaire['question_'+ (i + 1) + '_answer'] =
                $scope.questionnaire['question_'+ (i + 1) + '_answer'] in ['t', true]

      parseQuestionnaire()
