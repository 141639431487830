angular
  .module 'shared'
  .directive 'iconPos', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><g stroke="{{color || 'currentColor'}}" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M18.93 19.99H5.56C4.14 19.99 3 18.84 3 17.43V6.56C3 5.14 4.15 4 5.56 4h13.37c1.42 0 2.56 1.15 2.56 2.56v10.87c0 1.41-1.15 2.56-2.56 2.56ZM3.4 8.96h17.68M5.95 12.6h3.58"/></g></svg>
    '''
