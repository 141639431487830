export const SMS_OPT_OUT_INSTRUCTIONS_POLICY_OPTIONS = [
  {
    label: 'Add instructions to all messages',
    value: 'add_sms_opt_out_instructions_for_all_contacts',
  },
  {
    label: 'Add instructions for messages sent to Prospects only',
    value: 'add_sms_opt_out_instructions_for_prospects_only',
  },
  {
    label: 'Add instructions for messages sent to Prospects / cancelled Members only',
    value: 'add_sms_opt_out_instructions_for_prospects_and_cancelled_members_only',
  },
];
