angular.module 'shared'
  .factory 'GTMService', ($rootScope) ->
    track: _.debounce () ->
      if window.dataLayer?.push && $rootScope.gym
        user = window.currentUser
        gym = $rootScope.gym
        window.dataLayer.push(
          "user_signup_date": user.created_at_date,
          "user_email": user.email,
          "user_id": user.id,
          "user_is_account_owner": user.account_owner,
          "user_session_count": user.sign_in_count,
          "buss_name": gym.name,
          "buss_type": gym.business_type,
          "bus_id": gym.id,
          "bus_country": gym.country,
          "bus_timezone": gym.tz,
          "bus_currency": gym.units?.currency,
          "bus_subscription_plan": gym.braintree_customer_plan_name,
          "bus_account_status": gym.account_status,
          "bus_monthly_amount": gym.braintree_customer_payment_amount,
          "bus_features_account_has_access_to": gym.features_enabled.join(', '),
          "bus_payment_provider": gym.payment_providers.join(', '),
          "bus_is_credit_card_saved": gym.has_subscription,
          "bus_count_of_users": gym.user_count,
          "bus_number_of_sites": gym.location_count,
          "bus_number_of_membership_plans": gym.membership_plan_count,
          "bus_number_of_members": gym.member_count,
          "bus_number_of_active_members": gym.active_member_count,
          "bus_referrer_code": gym.referrer_code
        )
    , 100

    trackPage: (page, action) ->
      return unless window.dataLayer
      window.dataLayer.push(page_name: page, action: action)

    push: (data) ->
      return unless window.dataLayer
      window.dataLayer.push(data)
