angular
  .module 'gym.components'
  .directive 'waiverAnswers', ($timeout) ->
    restrict: 'E'
    scope:
      data: '='
    template: require('templates/components/contacts/comms/waiver_answers.html.slim')
    controller: ($scope) ->
      $scope.visible = false
      $scope.toggle = ->
        $scope.visible = !$scope.visible
