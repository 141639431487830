angular
  .module 'shared'
  .directive 'input', ($timeout) ->
    restrict: 'E'
    link: (scope, element, attrs) ->
      if attrs.type is 'number'
        element.bind 'keydown', (event) ->
          if event.key is 'ArrowDown' || event.key is 'ArrowUp'
            event.preventDefault()
            element[0].blur()


        element.bind 'wheel', (event) ->
          if document.activeElement is element[0]
            element[0].blur()
            event.stopPropagation()
            $timeout () ->
              element[0].focus()
