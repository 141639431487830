angular.module 'website'
  .controller 'website.galleryCtrl', (
    $scope,
    $stateParams,
    $http,
    $document,
    ngTableParams
  ) ->
    $scope.tableParams = new ngTableParams
      page: 1
      count: 10
    ,
      counts: []
      total: 0
      getData: ($defer, params) ->
        $scope.isLoading = true
        url = "/websites/#{$stateParams.domain}/gallery_images"
        $http.get(url, params: params.url()).then (response) ->
          $document.scrollTop(0)
          $scope.isLoading = false
          params.total response.data.total
          $scope.images = response.data.collection
          $defer.resolve response.data.collection
