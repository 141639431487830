import dompurify from 'dompurify'
angular
  .module 'filters'
  .filter 'domPurify', ->
    (data) ->
      dompurify.addHook 'beforeSanitizeElements', (currentNode) ->
        if (currentNode.tagName?.toLowerCase() is 'img')
          currentNode.setAttribute('alt', ' ') unless currentNode.getAttribute('alt')
        currentNode
      dompurify.sanitize data, ADD_ATTR: ['target', 'alt', 'data-asset-id']
