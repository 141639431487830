angular
  .module 'shared'
  .factory 'AssetLibraryService', (ReactModal) ->
    AssetLibraryService =
      open: (params) ->
        ReactModal.open({
          component: 'AssetDrawer',
          drawer: true,
          props:
            params
        })

    return AssetLibraryService
