angular
  .module 'shared'
  .controller 'ManageTemplatesCategoryModal', (
    $scope,
    $modalInstance,
    $timeout,
    CommunicationTemplateCategories,
    categories
  ) ->
    $scope.categories = categories
    $scope.newCategory = {}

    reload = ->
      CommunicationTemplateCategories.getList(count: 100).then (categories) ->
        $scope.categories = categories

    clearErrors = ->
      $scope.error = undefined
      $scope.newCategory.errors = undefined

    $scope.ok = ->
      $modalInstance.close()

    $scope.save = (category) ->
      clearErrors()
      category.save().then ->
        reload()
      , (response) ->
        category.errors = response.data.errors

    $scope.edit = (category) ->
      clearErrors()
      category._edit = true
      category.old_name = category.name

    $scope.reset = (category) ->
      clearErrors()
      category.name = category.old_name
      category.old_name = undefined
      category._edit = undefined
      category.errors = {}

    $scope.cancel = ->
      $modalInstance.dismiss('cancel')

    $scope.create = ->
      clearErrors()
      CommunicationTemplateCategories.post($scope.newCategory).then (category) ->
        $scope.newCategory = {}
        $scope.categories.push category
      , (response) ->
        $scope.newCategory.errors = response.data.errors

    $scope.delete = (category) ->
      clearErrors()
      category.remove().then ->
        reload()
      , (response) ->
        $scope.error = 'This category is in use and cannot be deleted at this time.'
