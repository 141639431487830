angular
  .module 'gym.components'
  .directive 'addBrivoDetail', ->
    restrict: 'E'
    template: require('templates/components/contacts/access_control/add_brivo_detail.html.slim')
    scope:
      contact: '='
      loadBrivoDetail: '&'
    controller: (
      $scope,
      $stateParams,
      $http,
    ) ->
      $scope.brivoDetail =
        isNew: true

      $scope.onDetailChange = ->
        if $scope.brivoDetail.isNew
          $scope.brivoDetail.brivo_reference = ''

      $scope.loadUsers = (refresh) ->
        refreshParams = if refresh then '&refresh=1' else ''
        url = "/gyms/#{$stateParams.gymId}/access_control/users?count=10000&contact_id=#{$scope.contact.id}#{refreshParams}"
        $scope.usersLoading = true
        $http.get(url).then (response) ->
          $scope.usersLoading = false
          $scope.users = response.data.collection
        , () ->
          $scope.usersLoading = false

      $scope.loadUsers()

      $scope.isFormReady = ->
        !$scope.isLoading &&
        ($scope.brivoDetail.isNew || $scope.brivoDetail.brivo_reference)

      $scope.save = ->
        $scope.isLoading = true
        url =
          "/gyms/#{$stateParams.gymId}/contacts/#{$scope.contact.id}/brivo_details"
        $http.post(url, contact_brivo_detail: $scope.brivoDetail).then (data) ->
          $scope.loadBrivoDetail()
          $scope.isLoading = false
        , () ->
          $scope.isLoading = false
