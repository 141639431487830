angular
  .module 'shared'
  .directive 'templateEditor', () ->
    restrict: 'E'
    template: require('templates/shared/template_editor.html.slim')
    scope:
      template: '='
      hideName: '='
      displayPurpose: '='
      displaySequenceMergeTags: '='
      displayRollCallMergeTags: '='
      displayBookingMergeTags: '='
      displayMembershipMergeTags: '='
      displayPaymentMergeTags: '='
      displayGradingEventMergeTags: '='
      error: '='
      disabled: '='
      toolbarStatic: '='
      onLoad: '&'
    controller: ($scope, $rootScope) ->
      $scope.useNewEditor = $rootScope.gym.gym_config.use_slate_editor
