angular
  .module 'shared'
  .directive 'iconInfo', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.25 9A8.251 8.251 0 0 0 .75 9a8.251 8.251 0 0 0 16.5 0Zm-6.69-4.215a.913.913 0 0 1-.269.628.841.841 0 0 1-.627.27.913.913 0 0 1-.628-.27.841.841 0 0 1-.27-.628c0-.233.108-.466.27-.627a.841.841 0 0 1 .628-.27c.233 0 .466.108.627.27.18.161.27.394.27.627Zm-2.439 9.057c.108.09.251.144.395.144.09 0 .18-.018.25-.054l1.848-.807c.323-.143.466-.502.323-.825a.617.617 0 0 0-.825-.323l-.753.323.914-4.663a.657.657 0 0 0-.25-.628.626.626 0 0 0-.682-.036l-1.668.95a.64.64 0 0 0-.233.862.64.64 0 0 0 .86.233l.485-.27-.879 4.485a.605.605 0 0 0 .215.61ZM9 15.815A6.812 6.812 0 0 0 15.815 9 6.812 6.812 0 0 0 9 2.185 6.812 6.812 0 0 0 2.185 9 6.812 6.812 0 0 0 9 15.815Z" fill="{{color || 'currentColor'}}"/>
    '''
