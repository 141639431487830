import dompurify from 'dompurify';

angular
  .module 'shared'
  .factory 'MessageService', ($modal) ->
    MessageService =
      message: (params) ->
        modalInstance = $modal.open
          template: require('templates/shared/message.html.slim')
          windowClass: 'message-modal'
          size: params.size
          resolve:
            params: -> params
          controller: ($scope, $modalInstance, params) ->
            $scope.title = params.title
            $scope.message = params.message
            $scope.bindHtml = params.bindHtml
            $scope.smallMargins = params.smallMargins
            $scope.confirmButtonText = params.confirmButtonText || 'Ok'
            $scope.cancelButtonText = if params.displayCancelButton then 'Cancel' else params.cancelButtonText
            $scope.multiParagraphMessage = Array.isArray params.message
            $scope.displayOkButton = !!params.confirmButtonText || params.displayOkButton

            $scope.ok = ->
              $modalInstance.close()

            $scope.purify = (message) ->
              dompurify.sanitize message

            $scope.cancel = ->
              $modalInstance.dismiss('cancel')

        modalInstance.result

    return MessageService
