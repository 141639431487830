angular
  .module 'shared'
  .directive 'iconMarkCircle', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.00016 14.1668C4.5935 14.1668 1.8335 11.4068 1.8335 8.00016C1.8335 4.5935 4.5935 1.8335 8.00016 1.8335C11.4068 1.8335 14.1668 4.5935 14.1668 8.00016C14.1668 11.4068 11.4068 14.1668 8.00016 14.1668Z" stroke="{{color || 'currentColor'}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path d="M9.26023 6.72021L6.74023 9.28022" stroke="{{color || 'currentColor'}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path d="M6.74023 6.72021L9.26023 9.28022" stroke="{{color || 'currentColor'}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg>
    '''
