angular.module 'gym.components'
  .controller 'SendInvitationSmsCtrl', (
    $scope,
    $rootScope,
    $modalInstance,
    $http,
    TemplateSanitizeService,
    template,
    baseUrl,
    isUnsubscribedFromMobile
  ) ->
    $scope.template = template
    $scope.gym = $rootScope.gym
    $scope.isUnsubscribedFromMobile = isUnsubscribedFromMobile
    $scope.submit = ->
      $scope.isLoading = true
      data = TemplateSanitizeService.sanitize($scope.template)
      params =
        invite_sms_content: data.content
      url = "#{baseUrl}/send_invitation_sms"

      $http.put(url, params).then () ->
        $modalInstance.close()
      , (response) ->
        $scope.error = response.data.error_message
        $scope.isLoading = false

    $scope.cancel = ->
      $modalInstance.dismiss('cancel')
