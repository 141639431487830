angular
  .module 'gym.components'
  .directive 'contactSubscription', ->
    restrict: 'E'
    scope:
      contact: '='
      reloadContact: '&'
      updateChannel: '&'
    template: require('templates/components/contacts/comms/contact_subscription.html.slim')
    controller: ($scope) ->

      $scope.reactivateEmail = ->
        $scope.contact.customPUT({}, 'reactivate_email').then (data) ->
          reload()

      $scope.reactivateSms = ->
        $scope.contact.customPUT({}, 'reactivate_sms').then (data) ->
          reload()

      reload = ->
        $scope.reloadContact().then ->
          $scope.updateChannel()
