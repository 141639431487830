angular
  .module 'shared'
  .directive 'iconTickCircle', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="6.05" stroke="{{color || 'currentColor'}}" stroke-width="1.4" /><path d="M7.83419 11.4941L5.47169 9.2312C5.32975 9.09525 5.32975 8.87481 5.47169 8.73885L5.98569 8.2465C6.12762 8.11053 6.35777 8.11053 6.4997 8.2465L8.0912 9.77091L11.5 6.50577C11.6419 6.36982 11.8721 6.36982 12.014 6.50577L12.528 6.99813C12.67 7.13408 12.67 7.35451 12.528 7.49048L8.3482 11.4942C8.20625 11.6301 7.97612 11.6301 7.83419 11.4941Z" fill="{{color || 'currentColor'}}"/></svg>
    '''
