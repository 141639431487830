angular
  .module 'gym.components'
  .directive 'memberMobileAppDetails', ->
    restrict: 'E'
    template: require('templates/components/contacts/forms/member_mobile_app_details.html.slim')
    scope:
      contact: '='
      mobileAppSettings: '='
      reloadMobileAppSettings: '&'
      openMemberMobileAppTab: '&'
    controller: ($scope, $stateParams, $document, $rootScope, MobileAppService) ->
      $scope.accessStatusVisible = ->
        $scope.contact.member_mobile_app_details?.enabled && $scope.contact.status in ['Suspended', 'Cancelled']

      $scope.accessStatus = ->
        if $scope.contact.member_mobile_app_details?.access_override_policy is 'allowed_override'
          'allowed'
        else
          $scope.contact.member_mobile_app_details?.access_override_policy

      $scope.openTab = ->
        $scope.openMemberMobileAppTab()
        $scope.$emit('contact:scrollToMainSection');

      $scope.isInvitationAvailable = ->
        MobileAppService.isInvitationAvailable($scope.mobileAppSettings)

      $scope.familyMembersStatus = ->
        familyMembersSetting = $scope.contact.member_mobile_app_details?.family_member_settings
        if !familyMembersSetting?.length
          return null

        count = familyMembersSetting.filter((item) -> item.manageable).length

        if count > 0
          membersLabel = if count is 1 then 'member' else 'members'
          "Manages #{count} family #{membersLabel}"
        else
          'Does not manage family members'

      grantMobileAppAccessRequest = () ->
        if $stateParams.mobile_app_access_request_id
          mobileAppDetails = $scope.contact.one('mobile_app_details', $scope.contact.member_mobile_app_details.id)
          params =
            withoutNamespace: true
            mobile_app_access_request_id: +$stateParams.mobile_app_access_request_id
          mobileAppDetails.doPUT(params, 'grant_mobile_app_access_request')

      $scope.disabledFeaturesCount = ->
        features = $scope.contact.member_mobile_app_details.features_disabled || []
        disabledFeatures = features.filter (feature) ->
          feature in $scope.contact.member_mobile_app_details.features_available_to_gym
        disabledFeatures.length

      $scope.onToggleChange = () ->
        if $scope.mobileAppSettings.mobile_app_invite_quota_exceeded && $scope.contact.member_mobile_app_details.enabled
          $scope.contact.member_mobile_app_details =
            enabled: false
          $scope.openTab()
          return

        if $scope.contact.member_mobile_app_details?.id
          mobileAppDetails = $scope.contact.one('mobile_app_details', $scope.contact.member_mobile_app_details.id)
          action = if $scope.contact.member_mobile_app_details.enabled
            mobileAppDetails.doPUT({}, 'enable')
          else
            mobileAppDetails.doPUT({}, 'disable')
          action.then () ->
            if $scope.mobileAppSettings.review_status isnt 'review_approved'
              $scope.reloadMobileAppSettings()
        else
          $scope.contact.all('mobile_app_details').doPOST({}, 'upsert').then (response) ->
            $scope.contact.member_mobile_app_details = response
            grantMobileAppAccessRequest()
