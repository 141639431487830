import underscored from 'underscore.string/underscored';

angular
  .module 'gym.components'
  .directive 'bluefinPaymentDetails', ->
    restrict: 'EA'
    scope:
      paymentMethods: '='
      permissions: '='
      contact: '='
      remove: '&'
      setDefault: '&'
    template: require('templates/components/contacts/wallet/bluefin_payment_details.html.slim')
    controller: ($scope, AdminUserService) ->
      $scope.adminHasPermission = AdminUserService.has
      $scope.contactsType = (type) ->
        "#{underscored(type)}s"
