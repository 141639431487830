angular
  .module 'filters'
  .filter 'membershipInitialPaymentFilter', ($rootScope, $filter) ->
    (membership) ->
      if membership.cost_upfront > 0
        symbol = $rootScope.gym.currency_symbol
        amount = $filter('currency')(membership.cost_upfront, symbol)
        date = $filter('stringToDate')(membership.upfront_payment_on)
        "#{amount} on #{date}"
      else
        "No initial payment"
