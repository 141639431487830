angular
  .module 'shared'
  .factory 'TableService', (ngTableParams) ->
    init: (params) ->
      tableData = {}
      new ngTableParams
        page: 1
        count: params.count || 25
        filters: params.filters
        order: params.order
        filterDelay: params.filterDelay
      ,
        counts: []
        total: 0
        getData: ($defer, $params) ->
          query = $params.url()
          beforeLoad = (queryParams) ->
            prev = _.omit tableData.prevQuery, 'page'
            current = _.omit queryParams, 'page'
            if tableData.prevQuery && !_.isEqual(prev, current) && (tableData.prevQuery.page is queryParams.page)
              return $params.page(1)
            tableData.prevQuery = queryParams

          resolve = (items) ->
            $defer.resolve items
            if !items.length && tableData.prevQuery && tableData.prevQuery.page > 1
              return $params.page(tableData.prevQuery.page - 1)

          params.getData(resolve, $params, beforeLoad)
