import pluralize from 'pluralize'

angular
  .module 'filters'
  .filter 'membershipRecurringPaymentFilter', ($rootScope, $filter) ->
    (membership) ->
      if membership.cost_repeat > 0 && membership.plan_type is 'recurring'
        symbol = $rootScope.gym.currency_symbol
        cost_repeat = $filter('currency')(membership.cost_repeat, symbol)
        startingDate = $filter('stringToDate')(membership.recurring_payments_start_on)
        paymentWithPeriod =
          if membership.charge_interval_number > 1
            "#{cost_repeat} every #{membership.charge_interval_number} #{membership.charge_interval_unit}"
          else
            "#{cost_repeat}/#{pluralize.singular(membership.charge_interval_unit)}"
        "#{paymentWithPeriod} starting on #{startingDate}"
      else
        "No recurring payment"
