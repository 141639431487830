import plural from 'helpers/plural'

angular
  .module 'gym.components'
  .directive 'waiverMembershipSummary', (MembershipPlanService) ->
    restrict: 'E'
    scope:
      waiver: '='
      currency: '='
      internal: '='
    template: require('templates/components/waivers/waiver_membership_summary.html.slim')
    controller: ($scope, $filter) ->
      $scope.familyMembershipStrategy =
        $scope.waiver.signed_waiver_form_attributes.family_membership_strategy

      $scope.membershipPlan = (id) ->
        $scope.waiver.signed_waiver_form_attributes.membership_plan_options.find (m) -> m.id is id

      $scope.isNotAttending = (contact) ->
        !contact.attending && $scope.familyMembershipStrategy is 'every_attendee_pays_membership'

      $scope.membershipLabel = (contact) ->
        match = $scope.membershipPlan(contact.membership_plan_id)
        match?.label || 'None selected'

      $scope.activeContacts = ->
        $scope.waiver.signed_waiver_contacts_attributes.filter (c) -> !c._destroy

      $scope.noCharge = (contact) ->
        $scope.familyMembershipStrategy is 'only_parent_pays_membership' && contact.position isnt 1

      $scope.hasPayment = (contact) ->
        plan = $scope.membershipPlan(contact.membership_plan_id)
        plan.cost_upfront > 0 || plan.cost_repeat > 0

      $scope.selectDate = (contact, event) ->
        event.preventDefault()
        event.stopPropagation()
        $scope.maxChangeDate = $scope.getMaxChangeDate(contact)
        contact.rescheduling = true

      $scope.contactName = (c) ->
        if c.first_name && c.last_name
          "#{c.first_name} #{c.last_name}"
        else
          if c.position is 1
            'Parent / Guardian'
          else
            "Dependant / Child ##{c.position - 1}"

      $scope.minChangeDate = moment().toDate()

      $scope.getMaxChangeDate = (contact) ->
        plan = $scope.membershipPlan(contact.membership_plan_id)
        if $scope.internal || !plan.waiver_start_date_limit_number
          moment().add(365, 'days').toDate()
        else
          moment().add(plan.waiver_start_date_limit_number, plan.waiver_start_date_limit_unit).toDate()

      $scope.membershipStartDate = (contact) ->
        if contact.requested_membership_start_date
          return $filter('date')(contact.requested_membership_start_date)

        plan = $scope.membershipPlan(contact.membership_plan_id)
        startDate = MembershipPlanService.getStartDate(plan)
        if startDate
          $filter('date')(startDate)
        else
          'immediately'

      $scope.specifiedStartDate = (contact) ->
        plan = $scope.membershipPlan(contact.membership_plan_id)
        plan.specified_start_date

      $scope.membershipExpires = (contact) ->
        plan = $scope.membershipPlan(contact.membership_plan_id)
        if plan.term_unit && plan.term_number > 0
          period = plural(plan.term_unit, plan.term_number, true)
          "after #{period}"
        else
          'does not expire'

      $scope.startDateChangeAvailable = (contact) ->
        plan = $scope.membershipPlan(contact.membership_plan_id)
        plan.allow_customer_to_choose_start_date_on_waiver

      $scope.isStartDatePassed = (id) ->
        plan = $scope.membershipPlan(id)
        plan.specified_start_date && moment(plan.specified_start_date).isBefore(moment(), 'day')

      $scope.selectedStartDate = (contact) ->
        plan = $scope.membershipPlan(contact.membership_plan_id)
        contact.requested_membership_start_date || plan?.specified_start_date

      isUpfront = (plan) ->
        plan.cost_upfront > 0

      isRecurring = (plan) ->
        plan.cost_repeat > 0 && plan.charge_interval_unit

      $scope.recurringPaymentsOnSecondRow = (contact) ->
        plan = $scope.membershipPlan(contact.membership_plan_id)
        startDate = $scope.selectedStartDate(contact)
        isRecurring(plan) && isUpfront(plan) && startDate

      $scope.paymentsLabel = (contact) ->
        plan = $scope.membershipPlan(contact.membership_plan_id)
        if !isRecurring(plan) && isUpfront(plan)
          return 'Payment'
        if $scope.recurringPaymentsOnSecondRow(contact)
          return 'Initial payment'
        'Payments'

      $scope.fullWidth = ->
        _.find $scope.activeContacts(), (contact) ->
          plan = $scope.membershipPlan(contact.membership_plan_id)
          startDate = $scope.selectedStartDate(contact)
          startDate && isRecurring(plan)

      $scope.paymentsSecondRow = (contact) ->
        plan = $scope.membershipPlan(contact.membership_plan_id)
        repeatPayment = $filter('currency')(plan.cost_repeat, $scope.currency)
        intervalUnit =
          plural(plan.charge_interval_unit, plan.charge_interval_number, plan.charge_interval_number > 1)
        startDate = $scope.selectedStartDate(contact)
        paymentDate = switch plan.default_repeat_date_strategy
          when 'start_repeat_payments_one_period_after_upfront'
            moment(startDate).add(plan.charge_interval_number, plan.charge_interval_unit)
          when 'start_repeat_payments_on_membership_start_date'
            startDate
          when 'start_repeat_payments_relative_to_start_or_upfront'
            moment(startDate).add(plan.relative_repeat_date_number, plan.relative_repeat_date_unit)
        date = moment(paymentDate).format('D MMMM YYYY')
        "#{repeatPayment} every #{intervalUnit}, starting #{date} until membership expires"

      upfrontPaymentDate = (contact) ->
        plan = $scope.membershipPlan(contact.membership_plan_id)
        startDate = $scope.selectedStartDate(contact)
        paymentDate = switch plan.default_upfront_date_strategy
          when 'charge_upfront_amount_on_start_date'
            moment(startDate)
          when 'charge_upfront_amount_asap'
            moment()
          when 'charge_upfront_amount_relative_to_start_date'
            if plan.relative_upfront_date_direction is 'before'
              moment(startDate).subtract(plan.relative_upfront_date_number, plan.relative_upfront_date_unit)
            else
              moment(startDate).add(plan.relative_upfront_date_number, plan.relative_upfront_date_unit)

      $scope.paymentsFirstRow = (contact) ->
        plan = $scope.membershipPlan(contact.membership_plan_id)
        startDate = $scope.selectedStartDate(contact)
        upfrontPayment = $filter('currency')(plan.cost_upfront, $scope.currency)
        upfrontPaymentDue = if startDate
          date = moment(upfrontPaymentDate(contact)).format('D MMMM YYYY')
          ", due #{date}"
        else
          ''
        repeatPayment = $filter('currency')(plan.cost_repeat, $scope.currency)
        recurringInterval =
          plural(plan.charge_interval_unit, plan.charge_interval_number, plan.charge_interval_number > 1)

        if isRecurring(plan)
          if isUpfront(plan)
            if $scope.recurringPaymentsOnSecondRow(contact)
              "#{upfrontPayment}#{upfrontPaymentDue}"
            else
              "Initial payment #{upfrontPayment} and then #{repeatPayment} every #{recurringInterval}"
          else
            if startDate
              $scope.paymentsSecondRow(contact)
            else
              "#{repeatPayment} paid every #{recurringInterval}"

        else
          if isUpfront(plan)
            if $scope.recurringPaymentsOnSecondRow(contact)
              "#{upfrontPayment}#{upfrontPaymentDue}"
            else
              "one-off payment of #{upfrontPayment}#{upfrontPaymentDue}"
          else
            "none"
