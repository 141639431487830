angular.module 'website'
  .controller 'website.waiverReceiptCtrl', (
    $scope,
    $stateParams,
    DataService,
    Restangular,
    receipt
  ) ->
    $scope.waiver = receipt
    waiver = Restangular.one('websites', $stateParams.domain)
      .one('waivers', $stateParams.waiverName)
      .one('signed_waivers', $stateParams.signedWaiverId)

    $scope.currency = DataService.currency
    $scope.params = {}

    $scope.resendEmail = () ->
      $scope.params.error = undefined
      $scope.params.resendSuccessful = false
      $scope.params.resendLoading = true
      waiver.doPUT({}, 'resend_receipt_email').then () ->
        $scope.params.resendSuccessful = true
        $scope.params.resendLoading = false

    $scope.submitPassword = () ->
      return if !$scope.params.passcode || $scope.params.submitLoading
      $scope.params.error = undefined
      $scope.params.resendSuccessful = false
      $scope.params.submitLoading = true
      params =
        withoutNamespace: true
        waiver_password: $scope.params.passcode
      waiver.doPUT(params, "authenticate_receipt").then () ->
        waiver.doGET('receipt').then (receipt) ->
          $scope.params.submitLoading = false
          $scope.waiver = receipt
      , (response) ->
        $scope.params.submitLoading = false
        if response.data.error is 'password_incorrect'
          $scope.params.error = 'Incorrect passcode provided.'
        if response.data.error is 'link_expired'
          $scope.params.error = 'Password expired. Click "Re-send Waiver email" to generate a new one.'
