import dompurify from 'dompurify'

angular
  .module 'shared'
  .directive 'htmlContainer', () ->
    restrict: 'E'
    scope:
      html: '='
    template: '
      <div class="html-container" ng-bind-html="content"></div>
    '
    controller: ($scope, $sce) ->
      $scope.$watch 'html', (val) ->
        purifiedContent = dompurify.sanitize val.replaceAll('\n', '<br/>'), ADD_ATTR: ['target', 'alt']
        $scope.content = $sce.trustAsHtml(purifiedContent)
