angular
  .module 'filters'
  .filter 'membershipPlanPaymentTypeFilter', ->
    (membershipPlan) ->
      switch
        when membershipPlan.cost_repeat > 0 && membershipPlan.type is 'recurring'
          'Repeating payment'
        when membershipPlan.cost_upfront && membershipPlan.cost_upfront > 0
          'Up-front payment'
        else
          'No payment'
