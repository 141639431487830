angular
  .module 'gym.components'
  .directive 'reportDateFilterInput', ->
    restrict: 'E'
    template: require('templates/components/reports/report_date_filter_input.html.slim')
    controller: ($scope) ->

      $scope.relativeDateId =  Math.floor Math.random() * 1000

      $scope.periods = [
        'days'
        'weeks'
        'months'
        'years'
      ]

      $scope.onRelativeChange = ->
        if $scope.newFilter.relative_date
          $scope.newFilter.user_value = ""

      $scope.multipliers = if $scope.disableFromNow
        ['ago']
      else
        ['from_now', 'ago']

      $scope.newFilter.relative_date ||= false
      $scope.newFilter.multiplier ||= $scope.multipliers[0]
      $scope.newFilter.period ||= $scope.periods[0]
