import _ from 'underscore'

angular
  .module 'gym.components'
  .directive 'addMakeupClassesForMissedClassesFields', ->
    restrict: 'EA'
    template: require('templates/components/membership/add_makeup_classes_for_missed_classes_fields.html.slim')
    scope:
      data: '='
      contact: '='
      isMembership: '='
    controller: (
      $scope,
      $rootScope,
      MembershipIntervalUnits
    ) ->
      $scope.daysWeeksMonthsYearsOptions = _.pairs MembershipIntervalUnits
      gym = $rootScope.gym

      $scope.params =
        expireMakeupClasses: !!$scope.data.missed_class_makeup_class_expiry_offset_number

      $scope.addMakeupClassesForMissedClassesAvailable = ->
        if $scope.isMembership && $scope.contact.type is 'non_attending_contact'
          return false
        plan = $scope.data
        usesAnniversaryLimits = gym.gym_membership_detail.membership_limit_type is 'anniversary_membership_limits'

        plan.classes_interval_unit && plan.classes_interval_number > 0 && plan.classes_limit > 0 &&
          (usesAnniversaryLimits || plan.classes_interval_number is 1)

      clearAddMakeupClassesForMissedClassesFields = ->
        $scope.data.add_makeup_classes_for_missed_classes = false
        $scope.data.missed_class_makeup_class_expiry_offset_number = null
        $scope.data.missed_class_makeup_class_expiry_offset_unit = null
        $scope.data.missed_class_threshold = null
        $scope.data.total_makeup_class_limit_for_missed_classes = null
        $scope.data.missed_class_calculation_strategy = null
        $scope.data.missed_class_replacement_strategy = null
        $scope.data.missed_class_replacement_fixed_number = null

      $scope.onAddMakeupClassesForMissedClassesToggle = ->
        if $scope.data.add_makeup_classes_for_missed_classes
          $scope.data.missed_class_threshold = 1
          $scope.data.missed_class_makeup_class_expiry_offset_unit = 'days'
          $scope.data.missed_class_calculation_strategy = 'count_missed_classes_using_unbooked_events_only'
          $scope.data.missed_class_replacement_strategy = 'generate_one_allowance_for_every_class_missed'
        else
          clearAddMakeupClassesForMissedClassesFields()

      $scope.missedClassCalculationStrategyOptions = [
        {value: 'count_missed_classes_using_unbooked_events_only', label: 'Unbooked events'},
        {value: 'count_missed_classes_using_unbooked_events_and_absent_bookings', label: 'Unbooked events, and/or unattended bookings'}
      ]

      $scope.missedClassReplacementStrategyOptions = [
        {value: 'generate_one_allowance_for_every_class_missed', label: 'Add one make-up class for each class missed'},
        {value: 'generate_fixed_number_of_allowances_for_all_classes_missed', label: 'Add a fixed number of make-up classes'}
      ]
