angular.module('shared')
  .factory 'SessionCheckerService', ['$q', '$stateParams', '$http', ($q, $stateParams, $http) ->
    serviceRunning = true

    loadDebounced = _.debounce((deferred) ->
      if serviceRunning
        gymId = $stateParams.gymId
        $http.get("/gyms/#{gymId}/session_status").then((response) ->
          deferred.resolve response?.data
        , (error) ->
          if error.data.login_status == 'signed_out' || error.data.login_status == 'timed_out'
             deferred.resolve { error: true }
        )
    , 500)

    load: () ->
      deferred = $q.defer()
      loadDebounced(deferred)
      return deferred.promise

    stopService: () ->
      serviceRunning = false
  ]


angular.module('shared')
  .directive 'sessionChecker', () ->
    restrict: 'E'
    controller: ['$scope', 'SessionCheckerService', 'ReactModal', ($scope, SessionCheckerService, ReactModal) ->
      checkSession = ->
        SessionCheckerService.load().then((sessionChecks) ->
          if sessionChecks?.error
            $scope.openModal()
            SessionCheckerService.stopService()
        )
      $scope.openModal = () ->
        ReactModal.open(
          component: 'SessionTimedOutModal'
        )
      window.setInterval(checkSession, 60000)
    ]
