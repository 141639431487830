import capitalize from 'underscore.string/capitalize';
import plural from 'helpers/plural'

angular
  .module 'filters'
  .filter 'membershipPlanDetailedPayments', ($rootScope, $filter) ->
    (plan, currency) ->
      upfrontPayment = $filter('currency')(plan.cost_upfront, currency)
      repeatPayment = $filter('currency')(plan.cost_repeat, currency)
      intervalUnit = plural(plan.charge_interval_unit, plan.charge_interval_number, plan.charge_interval_number > 1)

      if plan.cost_repeat > 0 and plan.charge_interval_unit
        if plan.cost_upfront > 0
          "First Payment #{upfrontPayment} and then #{repeatPayment} every #{intervalUnit}"
        else
          "#{repeatPayment} paid every #{intervalUnit}"

      else
        if plan.cost_upfront > 0
          "Up-front payment of #{upfrontPayment}"
        else
          "Free plan"
