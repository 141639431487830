angular
  .module 'shared'
  .directive 'contactLink', ($filter, ContactBadgeService) ->
    restrict: 'E'
    scope:
      id: '='
      type: '='
      name: '='
      firstName: '='
      lastName: '='
      status: '='
      archived: '='
      hideBadge: '='
      linkClass: '='
      onClick: '&'
      target: '@'
      tabName: '@'
    template: require('templates/shared/contact_link.html')
    link: ($scope) ->
      $scope.contactName = ->
        if $scope.name
          $scope.name
        else
          "#{$scope.firstName || ''} #{$scope.lastName || ''}"

      $scope.contactBadgeClass = ->
        ContactBadgeService.get($scope.type, $scope.status)

      $scope.contactType = ->
        $filter('underscored')($scope.type) + 's'
