import plural from 'helpers/plural'

angular
  .module 'filters'
  .filter 'membershipMakeupClassFilter', ($filter) ->
    (plan) ->
      expires = if plan.expiration_date
        "expires #{$filter('date')(plan.expiration_date)}"
      else
        'does not expire'
      makeupClassesCount = plan.makeup_class_allowances_count
      makeupClasses = if makeupClassesCount
        "#{makeupClassesCount} make-up #{plural('classes', makeupClassesCount)} added previously"
      else
        'has no existing make-up classes'
      "#{plan.label} (#{expires}, #{makeupClasses})"
