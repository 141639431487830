angular
  .module 'shared'
  .directive 'quickAddButton', ($rootScope, $timeout) ->
    restrict: 'E'
    template:
      '''
      <div class="btn-quick-add-container" ng-if="isVisible()">
        <ui class="sidebar-dropdown-menu quick-add-menu" role="menu" ng-show="visible" ng-click="onBlur()">
          <li>
            <a ui-sref="gym.contacts.new({contactType: 'prospects', section: undefined})" ng-click="onNewContactClicked('prospects')">
              Prospect
            </a>
          </li>
          <li>
            <a ui-sref="gym.contacts.new({contactType: 'members', section: undefined})" ng-click="onNewContactClicked('members')">
              Member
            </a>
          </li>
          <li>
            <a ui-sref="gym.contacts.new({contactType: 'non_attending_contacts', section: undefined})" ng-click="onNewContactClicked('non_attending_contacts')">
              Non-attending contact
            </a>
          </li>
           <li ng-if="permissions.manage_events">
            <a ng-click="addEvent()">Class / event</a>
          </li>
          <li ng-if="permissions.manage_events">
            <a ng-click="addAppointment()">Appointment</a>
          </li>
          <li ng-if="gradingEventsAvailable() && permissions.manage_grading_events">
            <a ui-sref="gym.grading.grading_events.new" ng-click="onNewGradingEventClicked()">
              Grading event
            </a>
          </li>
          <li ng-if="permissions.manage_tasks">
            <a ng-click="addTask()">Task</a>
          </li>
          <li ng-if="permissions.manage_membership_plans">
            <a ui-sref="gym.membership-plans.new">Membership plan</a>
          </li>
          <li ng-if="permissions.send_bulk_comms">
            <a ui-sref="gym.bulkMessages.new">Bulk message</a>
          </li>
        </ui>
        <button class="btn-quick-add" ng-click="toggle()" ng-blur="onBlur()">
          <i class="icon-plus"></i>
          <span class="hidden-mobile">Add</span>
        </button>
      </div>
      '''
    controller: ($scope, $timeout, $state, $stateParams, NewContactService, EventService, FeatureAvailabilityService) ->
      $scope.visible = false
      $scope.toggle = ->
        $scope.visible = !$scope.visible

      $scope.gradingEventsAvailable = () ->
        FeatureAvailabilityService.isAvailable('grading_events')

      $scope.addEvent = ->
        $state.go $rootScope.defaultCalendarView
        EventService.addEvent()

      $scope.addAppointment = ->
        $state.go $rootScope.defaultCalendarView
        EventService.addAppointment()

      $scope.isVisible = ->
        $state.current.name not in ['gym.setup']

      $scope.onNewContactClicked = (contactType) ->
        if $state.current.name is 'gym.contacts.new' && $stateParams.contactType is contactType
          return
        NewContactService.clear()

      $scope.onNewGradingEventClicked = ->
        if $state.current.name is 'gym.grading.grading_events.new'
          return

      $scope.onBlur = ->
        $timeout ->
          $scope.visible = false
        , 300
