angular
  .module 'gym.components'
  .directive 'failureFeeForm', () ->
    restrict: 'E'
    template: require('templates/components/gym_profile/failure_fee_form.html.slim')
    scope:
      data: '='
      errors: '='
      membershipPage: '='
      membershipPlanPage: '='
    controller: (
      $scope,
      $rootScope,
      ReactModal
    ) ->
      $scope.hasPaymentProvider = $rootScope.gym.has_payment_provider
      $scope.currency = $rootScope.gym.currency_symbol
      gymMembershipDetail = $rootScope.gym.gym_membership_detail

      prefillDefaults = () ->
        plan = $scope.data?.membership_plan
        defaults = if plan?.id
          failure_fee_amount: plan.failure_fee_amount
          failure_fee_auto_reschedule_status: plan.failure_fee_auto_reschedule_status
          failure_fee_invoice_email_status: plan.failure_fee_invoice_email_status
          failure_fee_lead_days: plan.failure_fee_lead_days
          failure_fee_schedule_strategy: plan.failure_fee_schedule_strategy
        else
          failure_fee_amount: gymMembershipDetail.failure_fee_amount
          failure_fee_auto_reschedule_status: gymMembershipDetail.failure_fee_auto_reschedule_status || 'use_membership_auto_reschedule_setting'
          failure_fee_invoice_email_status: gymMembershipDetail.failure_fee_invoice_email_status || 'use_membership_invoice_email_setting'
          failure_fee_lead_days: gymMembershipDetail.failure_fee_lead_days
          failure_fee_schedule_strategy: gymMembershipDetail.failure_fee_schedule_strategy || 'bundle_failure_fee_with_next_payment'
        angular.extend $scope.data, defaults

      if !$scope.data.id && !$scope.data.failure_fee_schedule_strategy && ($scope.membershipPage || $scope.membershipPlanPage) && $scope.hasPaymentProvider
        prefillDefaults()

      $scope.params =
        failureFeeEnabled: $scope.data.failure_fee_amount > 0

      $scope.failureFeeInvoiceEmailOptions = [
        ['use_membership_invoice_email_setting', 'Use membership setting']
        ['skip_invoice_email_for_failure_fee', 'Invoice email disabled']
      ]

      $scope.failureAutoRescheduleOptions = [
        ['use_membership_auto_reschedule_setting', 'Use membership setting']
        ['skip_auto_reschedule_for_failure_fee', 'Auto-reschedule disabled']
      ]

      $scope.failureFeeLeadDaysValid = () ->
        $scope.data.failure_fee_lead_days >= 1 && $scope.data.failure_fee_lead_days <= 30

      $scope.onFailureFeeChanged = ->
        if $scope.params.failureFeeEnabled && !$scope.data.id && ($scope.membershipPage || $scope.membershipPlanPage)
          prefillDefaults()
        else
          $scope.data.failure_fee_amount =
            if $scope.params.failureFeeEnabled then undefined else 0
          $scope.data.failure_fee_schedule_strategy = 'bundle_failure_fee_with_next_payment'
          $scope.data.failure_fee_invoice_email_status = 'use_membership_invoice_email_setting'
          $scope.data.failure_fee_auto_reschedule_status = 'use_membership_auto_reschedule_setting'

      $scope.displayFailureFeeDescription = (event) ->
        event.preventDefault()
        ReactModal.open
          component: 'FailureFeeDescriptionModal'
