angular
  .module 'shared'
  .factory 'SelectAllPagesService', ($modal) ->
    confirmDeselect: (params) ->
      modalInstance = $modal.open
        template: require('templates/shared/select_all_pages_confirm_deselect.html.slim')
        resolve:
          params: -> params
        controller: ($scope, $modalInstance, params) ->
          $scope.name = params.name

          $scope.submit = ->
            $modalInstance.close()

          $scope.cancel = ->
            $modalInstance.dismiss('cancel')

      modalInstance.result
