angular
  .module 'shared'
  .directive 'iconMessage', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.667 7.896c0-4.037 3.951-7.23 8.75-7.23 4.798 0 8.75 3.193 8.75 7.23 0 4.037-3.952 7.23-8.75 7.23-.833 0-1.65-.096-2.435-.283l-4.931 2.49.675-4.773-.04-.037C1.456 11.302.734 9.768.671 8.13l-.004-.235Zm15.909 0c0-3.061-3.171-5.623-7.16-5.623-3.987 0-7.159 2.562-7.159 5.623 0 1.395.655 2.72 1.836 3.753l.328.287-.367 2.588 2.75-1.387.292.079c.74.2 1.52.303 2.32.303 3.989 0 7.16-2.562 7.16-5.623Z" fill="{{color || 'currentColor'}}" fill-rule="evenodd" /></svg>
    '''
