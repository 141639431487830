angular
  .module 'filters'
  .filter 'sanitizeEditorField', ->
    (data, replaceSpaces, params) ->
      allowedTags = if params?.target is 'subject'
        ['mark']
      else
        ['mark', 'br', 'img']
      withBreaks = data
        .replaceAll('\n', '<br/>')
        .replace(/<p>/g, '<br/><p>')
        .replace(/<div>/g, '<br/><div>')
        .replace(/^<br\/>/g, '')
        .replace(/<\/p><p>/g, '</p><br/><p>')

      text = $.htmlClean(withBreaks, allowedTags: allowedTags)
        .replace(/(\S)<mark>{/g, '$1 <mark>{')

      if replaceSpaces
        text.replace /&#160;/g, ' '
      else
        text
