angular.module 'gym.components'
  .controller 'ShowSuspensionHistoryModalCtrl', (
    $scope,
    $rootScope,
    $modalInstance,
    $http,
    flash,
    suspension
  ) ->
    gym = $rootScope.gym

    loadHistory = () ->
      url = "/gyms/#{gym.id}/suspension_histories?contact_suspension_id=#{suspension.id}&count=100"
      $http.get(url).then (response) ->
        $scope.history = response.data.collection
      , () ->
        flash.error = "There was an error processing the request."

    loadHistory()

    $scope.cancel = ->
      $modalInstance.dismiss('cancel')
