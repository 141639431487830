angular
  .module 'gym.components'
  .directive 'calendarFilters', ->
    restrict: 'E'
    template: require('templates/components/calendar/calendar_filters.html.slim')
    scope:
      locations: '='
      instructors: '='
      eventTypes: '='
      styles: '='
      selectedLocations: '='
      selectedInstructors: '='
      selectedEventTypes: '='
      selectedStyles: '='
      multiSelect: '='
      displayOldEventsHiddenWarning: '='
      oldEventDisplayThresholdDate: '='
      unableToManageOtherContacts: '='
      disableShowAllInstructors: '='
      hideInstructor: '='
      collapseMobile: '='
      internal: '='
      params: '='
      onParamsChange: '&'
      onLocationsChange: '&'
      onInstructorsChange: '&'
      onEventTypesChange: '&'
      onStylesChange: '&'
    controller: ($scope, $filter, $stateParams, $modal, MasqueradingService) ->
      $scope.stylesFilterAvailable = ->
        $scope.internal && $scope.styles?.length > 1

      $scope.isMasquerading = MasqueradingService.isMasquerading()

      $scope.selectedLocationLabel = ->
        if $scope.selectedLocations?.length && $scope.selectedLocations.length isnt $scope.locations.length
          if $scope.selectedLocations.length > 1
            "#{$scope.selectedLocations.length} locations"
          else
            $filter('limitString')($scope.locationName($scope.selectedLocations[0]), 40)
        else
          "All Locations"

      $scope.locationName = (location) ->
        if location.active
          location.name
        else
          "[Deactivated] #{location.name}"

      $scope.instructorLabel = ->
        if $scope.selectedInstructors?.length && $scope.selectedInstructors.length isnt $scope.instructors.length
          if $scope.selectedInstructors.length > 1
            "#{$scope.selectedInstructors.length} instructors"
          else
            instructor = $scope.selectedInstructors[0]
            name = instructor.name || "#{instructor.first_name} #{instructor.last_name}"
            $filter('limitString')(name, 40)
        else
          "All Instructors"

      $scope.eventFiltersMap =
        empty: 'Empty events'
        full: 'Full events'
        not_empty: 'Non-empty events'
        not_full: 'Non-full events'
        free: '"Free" events'
        not_free: 'Non-"free" events'
        public: 'Website-visible events'
        private: 'Website-hidden events'
        waitlist_enabled: 'Waitlist-enabled events'
        waitlist_disabled: 'Waitlist-disabled events'
        waitlist_not_empty: 'Events with non-empty waitlists'
        infinite_event_series: 'Infinite event series'

      $scope.eventsLabel = ->
        $scope.eventFiltersMap[$scope.params?.general_filter] || "All events"

      $scope.eventFilters = [
        'empty'
        'full'
        'not_empty'
        'not_full'
        'free'
        'not_free'
        'public'
        'private'
        'waitlist_enabled'
        'waitlist_disabled'
        'waitlist_not_empty'
      ]

      if $scope.isMasquerading
        $scope.eventFilters.push('infinite_event_series')


      $scope.selectGeneralFilter = (val) ->
        if $scope.params
          $scope.params.general_filter = val
        $scope.onParamsChange()

      $scope.isInstructorSelected = (instructor) ->
        $scope.selectedInstructors &&
          !!_.find($scope.selectedInstructors, (item) -> item.id is instructor.id)

      $scope.isLocationSelected = (location) ->
        $scope.selectedLocations &&
          !!_.find($scope.selectedLocations, (item) -> item.id is location.id)

      $scope.isFiltersAvailable = ->
        $scope.internal || $scope.locations.length > 1 || $scope.instructors.length > 1 || $stateParams.event_type_id || $stateParams['event_type_id[]']

      $scope.instructorName = (instructor) ->
        instructor.first_name + ' ' + instructor.last_name

      $scope.resetLocations = ->
        $scope.onLocationsChange(locations: [])

      $scope.resetInstructors = ->
        $scope.onInstructorsChange(instructors: [])

      $scope.selectLocation = (location) ->
        selectedLocations =
          if $scope.multiSelect
            if $scope.isLocationSelected(location)
              $scope.selectedLocations.filter (item) -> item.id isnt location.id
            else
              $scope.selectedLocations.concat(location)
          else
            [location]
        if selectedLocations.length is $scope.locations.length
          selectedLocations = []

        $scope.onLocationsChange(locations: selectedLocations)

      $scope.selectInstructor = (instructor) ->
        selectedInstructors =
          if $scope.multiSelect
            if $scope.isInstructorSelected(instructor)
              $scope.selectedInstructors.filter (item) -> item.id isnt instructor.id
            else
              $scope.selectedInstructors.concat(instructor)
          else
            [instructor]
        if selectedInstructors.length is $scope.instructors.length
          selectedInstructors = []

        $scope.onInstructorsChange(instructors: selectedInstructors)

      $scope.eventTypeName = (eventType) ->
        if eventType.active
          eventType.name
        else
          "[Deactivated] #{eventType.name}"

      $scope.selectedEventTypesLabel = ->
        if $scope.selectedEventTypes?.length && $scope.selectedEventTypes.length isnt $scope.eventTypes.length
          if $scope.selectedEventTypes.length > 1
            "#{$scope.selectedEventTypes.length} types"
          else
            $filter('limitString')($scope.eventTypeName($scope.selectedEventTypes[0]), 40)
        else
          "All Types"

      $scope.selectEventType = (eventType) ->
        selectedEventTypes =
          if $scope.multiSelect
            if $scope.isEventTypeSelected(eventType)
              $scope.selectedEventTypes.filter (item) -> item.id isnt eventType.id
            else
              $scope.selectedEventTypes.concat(eventType)
          else
            [eventType]
        if selectedEventTypes.length is $scope.eventTypes.length
          selectedEventTypes = []

        $scope.onEventTypesChange(eventTypes: selectedEventTypes)

      $scope.resetEventTypes = ->
        $scope.onEventTypesChange(eventTypes: [])

      $scope.selectEventType = (eventType) ->
        selectedEventTypes =
          if $scope.multiSelect
            if $scope.isEventTypeSelected(eventType)
              $scope.selectedEventTypes.filter (item) -> item.id isnt eventType.id
            else
              $scope.selectedEventTypes.concat(eventType)
          else
            [eventType]
        if selectedEventTypes.length is $scope.eventTypes.length
          selectedEventTypes = []

        $scope.onEventTypesChange(eventTypes: selectedEventTypes)

      $scope.isEventTypeSelected = (eventType) ->
        $scope.selectedEventTypes &&
          !!_.find($scope.selectedEventTypes, (item) -> item.id is eventType.id)

      $scope.styleName = (style) ->
        style.name

      $scope.selectedStylesLabel = ->
        if $scope.selectedStyles?.length && $scope.selectedStyles.length isnt $scope.styles.length
          if $scope.selectedStyles.length > 1
            "#{$scope.selectedStyles.length} styles"
          else
            $filter('limitString')($scope.styleName($scope.selectedStyles[0]), 40)
        else
          "All Styles"

      $scope.selectStyle = (style) ->
        selectedStyles =
          if $scope.multiSelect
            if $scope.isStyleSelected(style)
              $scope.selectedStyles.filter (item) -> item.id isnt style.id
            else
              $scope.selectedStyles.concat(style)
          else
            [style]
        if selectedStyles.length is $scope.styles.length
          selectedStyles = []

        $scope.onStylesChange(styles: selectedStyles)

      $scope.resetStyles = ->
        $scope.onStylesChange(styles: [])

      $scope.selectStyle = (style) ->
        selectedStyles =
          if $scope.multiSelect
            if $scope.isStyleSelected(style)
              $scope.selectedStyles.filter (item) -> item.id isnt style.id
            else
              $scope.selectedStyles.concat(style)
          else
            [style]
        if selectedStyles.length is $scope.styles.length
          selectedStyles = []

        $scope.onStylesChange(styles: selectedStyles)

      $scope.isStyleSelected = (style) ->
        $scope.selectedStyles &&
          !!_.find($scope.selectedStyles, (item) -> item.id is style.id)

      $scope.displayModal = ->
        modalInstance = $modal.open
          template: require('templates/components/calendar/calendar_filters_modal.html.slim')
          controller: 'CalendarFiltersModalCtrl'
          resolve:
            locations: () -> $scope.locations
            instructors: () -> $scope.instructors
            eventTypes: () -> $scope.eventTypes
            styles: () -> if $scope.stylesFilterAvailable() then $scope.styles else []
            selectedLocations: () -> [].concat $scope.selectedLocations
            selectedInstructors: () -> [].concat $scope.selectedInstructors
            selectedEventTypes: () -> [].concat $scope.selectedEventTypes
            selectedStyles: () -> [].concat $scope.selectedStyles
            unableToManageOtherContacts: () -> $scope.unableToManageOtherContacts
            disableShowAllInstructors: () -> $scope.disableShowAllInstructors
            hideInstructor: () -> $scope.hideInstructor
            multiSelect: () -> $scope.multiSelect
            eventFilters: () -> $scope.eventFilters
            eventFiltersMap: () -> $scope.eventFiltersMap
            params: () -> angular.extend {}, $scope.params

        modalInstance.result.then (result) ->
          $scope.selectGeneralFilter(result.general_filter)
          if result.locations
            $scope.onLocationsChange(locations: result.locations)
          if result.instructors
            $scope.onInstructorsChange(instructors: result.instructors)
          if result.eventTypes
            $scope.onEventTypesChange(eventTypes: result.eventTypes)
          if result.styles
            $scope.onStylesChange(styles: result.styles)
