angular
  .module 'shared'
  .directive 'formattedDateBinding', ->
    require: 'ngModel',
    link: (scope, element, attrs, ngModel) ->
      ngModel.$parsers.push (viewValue) ->
        if moment(viewValue).isValid()
          moment(viewValue).format(attrs.formattedDateBinding)

      ngModel.$formatters.push (viewValue) ->
        if moment(viewValue, 'DD-MM-YYYY', true).isValid()
          return moment(viewValue, 'DD-MM-YYYY').format(attrs.datepickerPopup.toUpperCase())
        if moment(viewValue, 'YYYY-MM-DD', true).isValid()
          moment(viewValue, 'YYYY-MM-DD').format(attrs.datepickerPopup.toUpperCase())
