angular.module 'website'
  .controller 'website.termsCtrl', (
    $scope,
    DataService
  ) ->
    $scope.gymName = DataService.gym_name
    $scope.terms = DataService.terms
    $scope.title = DataService.terms_title
    $scope.termsTitle = if $scope.title
      $scope.title
    else
      "#{$scope.gymName} Terms and Conditions"
