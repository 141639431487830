angular
  .module 'shared'
  .directive 'logItemBody', () ->
    restrict: 'E'
    scope:
      log: '='
      displayGym: '='
      variant: '@'
    template: require('templates/shared/log_item_body.html.slim')
    controller: ($scope) ->
      $scope.isCreateGroup = ->
        $scope.log.filter_group is 'create_group'

      $scope.isCommunicationGroup = ->
        $scope.log.filter_group is 'communication_group'

      $scope.toggleChanges = ->
        $scope.changesOpened = !$scope.changesOpened

      $scope.hasDetails = ->
        $scope.hasCreateValues() ||
          $scope.hasUpdateValues() ||
          $scope.hasNestedUpdateValues() ||
          $scope.isCommunicationGroup()

      $scope.hasCreateValues = ->
        !_.isEmpty $scope.log.fragments.create_values

      $scope.hasUpdateValues = ->
        !_.isEmpty $scope.log.fragments.update_values?.changes

      $scope.hasNestedUpdateValues = ->
        $scope.log.fragments.update_values?.nested_changes?.length

      $scope.changesOpened = $scope.hasDetails() && $scope.isCreateGroup()
