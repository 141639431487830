angular
  .module 'gym.components'
  .directive 'contactInformation', ->
    restrict: 'E'
    template: require('templates/components/contacts/contact_information.html.slim')
    scope:
      contact: '='
      gocardlessDefaultMandateInvalid: '='
      formValue: '='
      form: '='
      permissions: '='
      editableSectionName: '='
      editForm: '&'
      deleteContact: '&'
      saveImage: '&'
      flags: '='
      addFlag: '&'
      showFlags: '&'
      save: '&'
      cancel: '&'
      manageComms: '&'
    controller: (
      $scope,
      $rootScope,
      $timeout,
      $document,
      FormFieldDisplayingService,
      ContactBadgeService,
      flash
    ) ->
      $scope.units = $rootScope.gym.units

      $scope.isFieldAvailable = (field) ->
        hiddenFields = [
          'id'
          'first_name'
          'last_name'
          'prospect_status'
          'member_status'
          'non_attending_contact_status'
          'created_at'
          'updated_at'
          'owner_id'
          'street_address_line_2'
          'street_address_city'
          'street_address_state'
          'street_address_postcode'
        ]
        FormFieldDisplayingService.isDisplaying($scope.contact.type, field) and
          field.name not in hiddenFields

      isPermissionsAvailable = (field) ->
        switch field.name
          when 'owner_id'
            $scope.permissions.change_contact_owner
          else
            true

      $scope.fieldLabel = (field) ->
        if field.name is 'street_address_line_1'
          'Address'
        else
          field.label

      $scope.fieldValue = (field) ->
        if field.name is 'street_address_line_1'
          $scope.contact.address
        else
          $scope.contact.contact_information[field.name]

      $scope.isEditFieldAvailable = (field) ->
        return false if field.archived || field.read_only
        FormFieldDisplayingService.isDisplaying($scope.contact.type, field) &&
          isPermissionsAvailable(field)

      $scope.getYears = ->
        moment().diff($scope.contact.contact_information.dob, 'years')

      $scope.scrollToWallet = ->
        $document.scrollToElementAnimated(angular.element('#walletContainer'))

      $scope.getContactBadge = (type, status) ->
        ContactBadgeService.get(type, status)

      $scope.hasPendingCancellation = ->
        !!_.find $scope.contact.memberships, (item) ->
          item.active && item.membership_cancellation

      $scope.scrollToMemberships = ->
        $document.scrollToElementAnimated(angular.element('#memberships-section-container'))

      $scope.selectPhoto = ->
        angular.element('#contact-photo-input').click()
        return true

      $scope.$watch 'imageData.file', (file) ->
        if file?.size
          if file.size > 5000000
            flash.error = 'Image size must be 5MB or lower'
            return
          dataUrlReader = new window.FileReader()
          dataUrlReader.readAsDataURL file
          dataUrlReader.onloadend = ->
            $timeout ->
              $scope.imageData.imagePreview = dataUrlReader.result
              $scope.contact.image =
                dataUrlReader.result.replace /^data:image\/\w*;base64,/, ''
              $scope.saveImage()
