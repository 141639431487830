angular.module 'shared'
  .controller 'FiltersModalCtrl', (
    $scope,
    $modalInstance,
    filters,
    options
  ) ->
    $scope.filters = angular.extend {}, filters
    $scope.options = options
    $scope.cancel = ->
      $modalInstance.dismiss('cancel')

    $scope.submit = ->
      $modalInstance.close($scope.filters)
