angular
  .module 'shared'
  .directive 'multipleSelect', () ->
    restrict: 'E'
    template: require('templates/shared/multiple_select.html.slim')
    replace: true
    scope:
      ngModel: '='
      collection: '='
      displaySelectAll: '='
      ngDisabled: '='
      limitString: '='
      singleOption: '='
      fullWidth: '='
      fullOptionsWidth: '='
      variant: '@'
      ngChange: '&'
      size: '@'
      labelKey: '@'
      idKey: '@'
      placeholder: '@'
      selectAllLabel: '@'
    controller: ($scope, $filter, $timeout) ->
      $scope.displaySelectedItems = ->
        if $scope.isNoneItemsSelected()
          $scope.placeholder || 'Select'
        else
          return unless $scope.collection.length
          selectedItems = _.compact($scope.ngModel.map (elem) ->
            item = _.find $scope.collection, (item) ->
              if $scope.idKey
                item.id is elem[$scope.idKey] && !elem._destroy
              else
                item.id is elem
            return null unless item
            item[$scope.labelKey || 'name']
          )
          if $scope.fullWidth
            selectedItems.join(', ')
          else
            $filter('limitString')(selectedItems.join(', '), $scope.limitString || 20)

      clearRemovedItems = (collection, value) ->
        if collection?.length && value?.length
          updated = value.filter (item) ->
            _.find collection, (option) ->
              if $scope.idKey
                option.id is item[$scope.idKey]
              else
                option.id is item
          if updated.length isnt value.length
            $scope.ngModel = updated
            $timeout ->
              $scope.ngChange()

      $scope.$watchCollection 'ngModel', (ngModel) -> clearRemovedItems($scope.collection, ngModel)
      $scope.$watchCollection 'collection', (collection) -> clearRemovedItems(collection, $scope.ngModel)

      $scope.showAllItems = ->
        $scope.ngModel = []
        $timeout ->
          $scope.ngChange()

      $scope.isItemSelected = (plan) ->
        if $scope.idKey
          _.find $scope.ngModel, (item) ->
            item[$scope.idKey] is plan.id && !item._destroy
        else
          $scope.ngModel.indexOf(plan.id) > -1

      $scope.isNoneItemsSelected = ->
        return true if !$scope.ngModel?.length
        if $scope.idKey
          !_.filter($scope.ngModel, (item) -> !item._destroy).length

      $scope.selectItem = (plan) ->
        item = if $scope.idKey
          obj = {}
          obj[$scope.idKey] = plan.id
          obj
        else
          plan.id
        if $scope.isNoneItemsSelected()
          $scope.ngModel = [item]
        else
          if $scope.isItemSelected(plan)
            $scope.ngModel = if $scope.idKey
              $scope.ngModel.map (elem) ->
                if plan.id is elem[$scope.idKey]
                  elem._destroy = 1
                elem
            else
              _.without $scope.ngModel, plan.id
          else
            if $scope.singleOption
              $scope.ngModel = [item]
            else
              if $scope.idKey && _.find($scope.ngModel, (elem) -> plan.id is elem[$scope.idKey])
                $scope.ngModel = $scope.ngModel.map (elem) ->
                  if plan.id is elem[$scope.idKey]
                    elem._destroy = undefined
                  elem
              else
                $scope.ngModel.push(item)
              $scope.ngModel = _.filter $scope.ngModel, (elem) ->
                !!_.find $scope.collection, (item) ->
                  if $scope.idKey
                    item.id is elem[$scope.idKey]
                  else
                    item.id is elem
        $timeout ->
          $scope.ngChange()
