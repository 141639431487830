angular
  .module 'gym.components'
  .directive 'sequenceCondition', ->
    restrict: 'EA'
    template: require('templates/components/sequences/sequenceCondition.html.slim')
    replace: true
    scope:
      sequence: '='
      step: '='
      condition: '='
      index: '='
      removeCondition: '&'
      reports: '='
    controller: ($scope) ->
      $scope.defaultConditionLabel = ->
        trigger = $scope.sequence.trigger
        $scope.sequence.default_condition_labels[trigger]

      $scope.remove = ->
        $scope.removeCondition({step: $scope.step, index: $scope.index})

      $scope.isConditionDisplaying = ->
        trigger = $scope.sequence.trigger

        ($scope.condition.type is 'LinkedReportSequenceCondition') ||
          ($scope.condition.type is 'DefaultSequenceCondition' && trigger)

      $scope.displayReportName = ->
        report = _.findWhere $scope.reports, id: $scope.condition.report_id
        report.name
