import unescape from 'helpers/unescape';

angular.module('website').factory('DataService', function () {
  return {
    ...window.DATA,
    gym_name: unescape(window.DATA.gym_name),
    gym_email: unescape(window.DATA.gym_email),
    gym_phone_number: unescape(window.DATA.gym_phone_number),
    gym_tz: unescape(window.DATA.gym_tz),
    gym_country: unescape(window.DATA.gym_country),
    terms: unescape(window.DATA.terms),
    terms_title: unescape(window.DATA.terms_title),
  };
});
