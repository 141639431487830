angular
  .module 'shared'
  .directive 'iconPlus', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2Z" fill="{{color || 'currentColor'}}"/></svg>
    '''
