angular
  .module 'shared'
  .factory 'DrawerService', ($rootScope, $modal) ->
    open: (params) ->
      $modal.open({
        template: params.template,
        windowClass: 'drawer__wrapper',
        backdropClass: 'modal-backdrop--drawer',
        resolve: params.resolve,
        controller: params.controller,
      })

    closeOnRouteChange: ($scope, close) ->
      unsubscribe = $rootScope.$on '$stateChangeSuccess', () ->
        close()

      $scope.$on '$destroy', () ->
        unsubscribe()
