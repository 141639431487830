import React from 'react';

const DrawerPlaceholder = () => {
  return (
    <>
      <div className="drawer__backdrop"></div>
      <div className="drawer">
        <div className="drawer__title">
          <div className="loading-placeholder" style={{ height: 30 }}></div>
        </div>
        <button type="button" className="drawer__close">
          <i className="icon-cross" />
        </button>
        <div className="drawer__inner">
          <div className="loading-placeholder" style={{ height: 50, marginBottom: 15 }}></div>
          <div className="loading-placeholder" style={{ height: 50, marginBottom: 15 }}></div>
          <div className="loading-placeholder" style={{ height: 50 }}></div>
        </div>
      </div>
    </>
  );
};

export default DrawerPlaceholder;
