angular
  .module 'shared'
  .directive 'iconCalendar', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.283 8.395a.962.962 0 0 1-.979-.964V4.964c0-.54.43-.964.979-.964.547 0 .978.424.978.964V7.43c0 .54-.43.964-.978.964Zm7.434 0a.962.962 0 0 0 .979-.964V4.964c0-.54-.43-.964-.979-.964a.962.962 0 0 0-.978.964V7.43c0 .54.43.964.978.964ZM21 19.23V6.198c0-.425-.352-.849-.783-.849h-1.82a.78.78 0 0 0-.782.771.78.78 0 0 0 .783.772h1.037v3.277H4.565V6.892h1.037a.78.78 0 0 0 .783-.772.78.78 0 0 0-.783-.77h-1.82c-.43 0-.782.423-.782.848v13.03a.78.78 0 0 0 .783.772h16.434a.78.78 0 0 0 .783-.771Zm-1.565-.771v-6.94H4.565v6.94h14.87ZM13.233 6.892h-2.466a.78.78 0 0 1-.782-.772.78.78 0 0 1 .782-.77h2.466a.78.78 0 0 1 .782.77.78.78 0 0 1-.782.772Z" fill="{{color || 'currentColor'}}"/></svg>
    '''
