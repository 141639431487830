angular
  .module 'gym.components'
  .directive 'privacyModeBar', ->
    restrict: 'EA'
    template: require('templates/components/dashboard/privacy_mode_bar.html.slim')
    scope:
      disable: '&'
    controller: (DashboardPrivacyModeService, $scope) ->
      $scope.isBarVisible = DashboardPrivacyModeService.isBarVisible()

      $scope.hide = ->
        $scope.isBarVisible = false
        DashboardPrivacyModeService.hideBar()
