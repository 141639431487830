# coffeelint: disable
angular.module 'shared'
  .factory 'CreditCardValidationService', ->
# Luhn algorithm: validate credit card number
# https://en.wikipedia.org/wiki/Luhn_algorithm
    luhnCheck = ((arr) ->
      (ccNum) ->
        len = ccNum.length
        bit = 1
        sum = 0
        val = undefined
        while len
          val = parseInt(ccNum.charAt(--len), 10)
          sum += if (bit ^= 1) then arr[val] else val
        sum and sum % 10 == 0
    )([0, 2, 4, 6, 8, 1, 3, 5, 7, 9])

    check: (creditCard) ->
      if creditCard
        luhnCheck creditCard

    nameValid: (name) ->
      name && !!name.match(/[a-z]/i)
# coffeelint: enable
