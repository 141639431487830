angular
  .module 'filters'
  .filter 'membershipPlanStartDate', ($rootScope, $filter, Months) ->
    (plan) ->
      if plan.specified_start_date
        date = $filter('date')(plan.specified_start_date)
        return "Specific date: #{date}"

      if plan.default_start_date_strategy is 'start_on_next_upcoming'
        day = $filter('ordinalize')(plan.start_on_next_upcoming_day)
        month = if plan.start_on_next_upcoming_month
          Months[plan.start_on_next_upcoming_month - 1][1]
        else
          'any month'
        return "On the next #{day} day of #{month}"

      if plan.default_start_date_strategy is 'start_relative_to_purchase_date'
        period = $filter('pluralize')(plan.relative_start_date_unit, plan.relative_start_date_number, true)
        return "#{period} after purchase date"

      'Date of purchase / waiver review'
