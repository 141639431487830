import random from 'helpers/random'

angular
  .module 'gym.components'
  .directive 'sequenceTaskPayloadEditor', () ->
    restrict: 'EA'
    template: require('templates/components/sequences/sequenceTaskPayloadEditor.html.slim')
    scope:
      payload: '='
      sequence: '='
      taskTypes: '='
      displayErrors: '='
    controller: ($scope, $rootScope, EventDefaultsService, isInteger) ->
      $scope.id = random()
      $scope.users = $rootScope.gym.users
      $scope.params = {}
      if $scope.payload.due_date_time
        timeAry = $scope.payload.due_date_time.split(':')
        $scope.params.dueTime = moment().hours(timeAry[0]).minutes(timeAry[1])
        $scope.payload.dueDateEnabled = $scope.payload.due_date_day_delta > -1

      $scope.deltaInvalid = ->
        !isInteger($scope.payload.due_date_day_delta) || $scope.payload.due_date_day_delta < 0

      $scope.onTimeChange = ->
        if $scope.params.dueTime
          $scope.payload.due_date_time = moment($scope.params.dueTime).format('HH:mm')
        else
          $scope.payload.due_date_time = undefined

      $scope.toggleDueDate = (enabled) ->
        unless enabled
          $scope.payload.due_date_day_delta = undefined
          $scope.payload.due_date_time = undefined
        $scope.payload.dueDateEnabled = enabled

      $scope.toggleTime = (val) ->
        if val
          $scope.params.dueTime = EventDefaultsService.getRoundedTime(moment(), 15)
        else
          $scope.params.dueTime = undefined
        $scope.onTimeChange()
