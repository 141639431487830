angular
  .module 'filters'
  .filter 'membershipTaxStatus', ->
    (status) ->
      switch status
        when 'inc_tax'
          'Tax inclusive'
        when 'ex_tax'
          'Tax exclusive'
        when 'tax_exempt'
          'Tax exempt'
        else
          'Not Set'
