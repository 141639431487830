angular
  .module 'gym.components'
  .directive 'perEventTypeDailyLimit', () ->
    restrict: 'E'
    template: require('templates/components/membership_plan/per_event_type_daily_limit.html.slim')
    scope:
      data: '='
      types: '='
    controller: (
      $scope,
      $rootScope,
      isInteger
    ) ->
      $scope.typeLimits = {}
      $scope.limitOptions = [undefined].concat([1..20])
      $scope.dateUnits = [['days', 'day(s)'],['weeks', 'week(s)'],['months', 'month(s)']]

      getTypeLimits = (id) ->
        _.find $scope.data.per_event_type_limits, (item) ->
          item.event_type_id is id

      $scope.$watch 'data.event_type_ids', (ids) ->
        if ids
          _.each ids, (id) ->
            existingLimit = getTypeLimits(id)
            $scope.typeLimits[id] =
              event_type_id: id
              limit: existingLimit?.limit || $scope.typeLimits[id]?.limit
              date_range_number: existingLimit?.date_range_number || $scope.typeLimits[id]?.date_range_number || 1
              date_range_unit: existingLimit?.date_range_unit || $scope.typeLimits[id]?.date_range_unit || 'days'
        $scope.updateLimits()
      , true

      $scope.numberInvalid = (typeId) ->
        number = $scope.typeLimits[typeId].date_range_number
        $scope.typeLimits[typeId].limit && (!isInteger(number) || !number || number < 1)

      $scope.updateLimits = () ->
        newLimits = _.map $scope.typeLimits, (limits, id) ->
          return undefined unless limits.limit
          limits
        $scope.data.per_event_type_limits = _.compact newLimits

      $scope.getType = (id) ->
        _.find $scope.types, (type) -> type.id is id
