angular
  .module 'shared'
  .factory 'CreditCardExpirationDatesService', ->
    getYears: () ->
      expirationYearStart = new Date().getFullYear()
      expirationYearEnd = expirationYearStart + 9
      [expirationYearStart..expirationYearEnd]

    getMonths: () ->
      [1..12].map (num) ->
        if num < 10 then "0#{num}" else num.toString()
