import underscored from 'underscore.string/underscored';

angular
  .module 'shared'
  .factory 'ContactBadgeService', ->
    get: (type, status) ->
      t = underscored type
      s = status?.toLowerCase()
      switch
        when t is 'prospect'
          'prospect-badge'
        when t is 'non_attending_contact'
          'non-attending-contact-badge'
        when t is 'member' && s is 'active'
          'member-badge'
        when t is 'member' && s is 'cancelled'
          'cancelled-badge'
        when t is 'member' && s is 'suspended'
          'suspended-badge'
