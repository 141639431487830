angular
  .module 'gym.components'
  .directive 'featureToggle', () ->
    restrict: 'E'
    template: require('templates/components/shared/feature_toggle.html.slim')
    scope:
      isFeatureEnabled: '='
      name: '@'
      optIn: '&'
      onViewPricingClick: '&'
    controller: ($scope) ->
      $scope.isPayor = window.currentUser.account_owner
