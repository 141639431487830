angular
  .module 'gym.components'
  .directive 'editAvailability', () ->
    restrict: 'E'
    template: require('templates/components/profile/editAvailability.html.slim')
    scope:
      trainerAvailability: '='
      showAvailability: '&'
    controller: (
      $scope,
      $timeout,
      $document,
      flash,
      loadingAnimation,
      EditAvailabilityService,
      Locations,
      Availability,
      AppointmentTypes
    ) ->
      $scope.appointmentTypes = AppointmentTypes.getList(count: 1000).$object
      $scope.timeBuffers = [0, 5, 10, 15, 20, 25, 30]
      $scope.isFormReady = ->
        true

      loadLocations = () ->
        $scope.locations = []
        Locations.getList(count: 1000).then (locations) ->
          $scope.locations = locations
          if !$scope.trainerAvailability.id && locations.length
            $scope.trainerAvailability.trainer_availability_blocks
              .forEach (block) ->
                block.location_id = locations[0].id

      loadLocations()

      $scope.onTimeChanged = (block) ->
        unless moment(block.start_time_obj).isValid()
          block.start_time_obj = moment().toDate()
        unless moment(block.end_time_obj).isValid()
          block.end_time_obj = moment().toDate()
        block.start_time = moment(block.start_time_obj).format('HHmm')
        block.end_time = moment(block.end_time_obj).format('HHmm')

      $scope.toggleAppointment = (block, id) ->
        if isAppointmentSelected(block, id)
          block.appointment_type_ids =
            _.without block.appointment_type_ids, id
        else
          block.appointment_type_ids.push id

      $scope.isAppointmentSelected = isAppointmentSelected = (block, id) ->
        _.find block.appointment_type_ids, (item) -> item is id

      $scope.addTimeslot = ->
        blocks = $scope.trainerAvailability.trainer_availability_blocks
        block = EditAvailabilityService.getNewAvailabilityBlock()
        if $scope.locations.length
          block.location_id = $scope.locations[0].id
        blocks.push block

      $scope.removeBlock = (index) ->
        blocks = $scope.trainerAvailability.trainer_availability_blocks
        if $scope.trainerAvailability.edit_type is 'recurring'
          blocks.splice(index, 1)
        else
          blocks[index].available = false

      save = ->
        Availability.post($scope.trainerAvailability).then ->
          $scope.showAvailability()
        , (response) ->
          $document.scrollTop(0)
          flash.error = response.data.message if response.data.message
          $scope.trainerAvailability.errors = response.data.errors

      $scope.save = ->
        EditAvailabilityService.validate($scope.trainerAvailability).then () ->
          save()
        , (response) ->
          $document.scrollTop(0)
          flash.error = response.data.message if response.data.message
          $scope.trainerAvailability.errors = response.data

      $scope.confirmSave = ->
        save()

      $scope.cancel = ->
        $scope.showAvailability()
