angular
  .module 'shared'
  .directive 'imageSelect', ->
    restrict: 'E'
    template: require('templates/shared/image_select.html.slim')
    scope:
      onSelect: '&'
      src: '='
      maxWidth: '='
      maxHeight: '='
      fromAssetLibrary: "="
      errorText: "@"
    controller: (
      $scope,
      $timeout,
      flash,
      AssetLibraryService
    ) ->
      $scope.id = Math.floor(Math.random() * 10000)
      $scope.imageData = {}

      $scope.selectImage = ->
        if $scope.errorText
          flash.error = $scope.errorText
        else
          if $scope.fromAssetLibrary
            AssetLibraryService.open().then (image) ->
              $scope.onSelect({image: image})
          else
            angular.element("#image-select-#{$scope.id}").click()
            return true

      $scope.$watch 'imageData.file', (file) ->
        if file?.size
          if file.size > 5000000
            flash.error = 'Image size must be 5MB or lower'
            return
          dataUrlReader = new window.FileReader()
          dataUrlReader.readAsDataURL file
          dataUrlReader.onloadend = ->
            $timeout ->
              image = dataUrlReader.result.replace /^data:image\/\w*;base64,/, ''
              $scope.onSelect({image: image, preview: dataUrlReader.result})
