angular
.module 'shared'
.controller 'ConfirmModalCtrl', (
  $scope,
  $modalInstance,
) ->
  $scope.ok = ->
    $modalInstance.close()

  $scope.cancel = ->
    $modalInstance.dismiss()
