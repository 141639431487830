angular
  .module 'filters'
  .filter 'membershipPeriodFilter', ($filter) ->
    (membership) ->
      start = moment(membership.start_date)
      end = moment(membership.expiration_date)

      if membership.expiration_date
        if start.format('YYYY') is end.format('YYYY')
          "#{start.format('MMM D')} - #{end.format('MMM D, YYYY')}"
        else
          "#{start.format('MMM D, YYYY')} - #{end.format('MMM D, YYYY')}"
      else
        "#{start.format('MMM D, YYYY')} - no expiry date"
