angular
  .module 'gym.components'
  .directive 'membershipSecondStep', ->
    restrict: 'EA'
    template: require('templates/components/membership/membership_second_step.html.slim')
    scope:
      gym: '='
      membership: '='
      save: '&'
      cancel: '&'
    controller: (
      $scope,
      $filter,
      MembershipIntervalUnits,
      PeriodicalUnits
      ReactModal
    ) ->

      $scope.membership.cc_invoice_receipt_enabled =
        !!$scope.membership.cc_invoice_receipts_to
      membership = $scope.membership
      $scope.membership_interval_unit_pairs = _.pairs MembershipIntervalUnits
      $scope.charge_interval_unit_pairs =
        _.pairs _.omit(MembershipIntervalUnits, 'years')
      $scope.automaticRescheduleLimits = [1..10]

      $scope.currency = $scope.gym.attributes.currency_symbol
      $scope.membershipTaxRateSet =
        $scope.gym.attributes.gym_membership_detail.membership_tax_rate_set

      $scope.onUpfrontCostChange = ->
        if $scope.membership.cost_upfront && !$scope.membership.upfront_payment_on
          $scope.membership.upfront_payment_on = $scope.gym.attributes.today

      $scope.onSendInvoiceReceiptChanged = ->
        $scope.membership.tax_status = ''
        unless $scope.membership.send_invoice_receipts
          $scope.membership.cc_invoice_receipt_enabled = false
          $scope.membership.cc_invoice_receipts_to = ''

      $scope.onCcInvoiceReceiptToggleChanged = ->
        unless $scope.membership.cc_invoice_receipt_enabled
          $scope.membership.cc_invoice_receipts_to = ''

      $scope.onPlanTypeChanged = ->
        if $scope.isUpfront()
          membership.recurring_payments_start_on = undefined
          membership.cost_repeat = undefined

      $scope.isUpfront = ->
        membership.plan_type is 'upfront'

      $scope.saveMembership = ->
        $scope.membership.cost_upfront ||= 0
        $scope.isLoading = true
        $scope.save().finally ->
          $scope.isLoading = false

      $scope.getMembershipTerm = ->
        if membership.term_number
          number = membership.term_number
          unit = $filter('pluralize')(membership.term_unit, number)
          "for #{number} #{unit}"
        else
          'until further notice'

      $scope.getReccuringPeriod = ->
        interval_number = membership.charge_interval_number
        interval_unit = membership.charge_interval_unit

        if interval_number is 1
          return PeriodicalUnits[interval_unit]
        else
          return "every #{interval_number} #{interval_unit}"

      $scope.onAutomaticRescheduleToggle = ->
        if $scope.membership.perform_automatic_reschedule
          $scope.membership.automatic_reschedule_days = 1
          $scope.membership.automatic_reschedule_limit = 3
        else
          $scope.membership.automatic_reschedule_days = 0
          $scope.membership.automatic_reschedule_limit = 0

      $scope.hasSinglePayment = ->
        $scope.membership.cost_upfront && $scope.membership.upfront_payment_on

      $scope.isUpfrontPaymentBeforeToday = ->
        $scope.membership.cost_upfront &&
            moment($scope.membership.upfront_payment_on).isBefore(moment().tz($scope.gym.tz), 'day')

      $scope.isRecurringPaymentBeforeToday = ->
        $scope.membership.recurring_payments_start_on &&
            moment($scope.membership.recurring_payments_start_on).isBefore(moment().tz($scope.gym.tz), 'day')

      $scope.isRecurringPaymentDateAfterExpiry = ->
        $scope.membership.recurring_payments_start_on &&
          $scope.membership.term_number &&
            moment($scope.membership.recurring_payments_start_on).isAfter(
              moment($scope.membership.start_date)
                .add($scope.membership.term_number, $scope.membership.term_unit)
                .subtract(1, 'day')
            )

      $scope.autoFailPolicy =
      switch $scope.gym.attributes.gym_membership_detail.autofail_override_policy
        when 'autofail_policy_skipped' then $scope.autoFailPolicy = 'Skipped'
        when 'autofail_policy_failed' then $scope.autoFailPolicy = 'Failed'
        when 'autofail_policy_process_manually' then $scope.autoFailPolicy = 'Process Manually'
        when 'autofail_policy_paid_by_cash' then $scope.autoFailPolicy = 'Paid (by Cash)'
        when 'autofail_policy_paid_by_pos' then $scope.autoFailPolicy = 'Paid (by POS)'
        when 'autofail_policy_paid_by_other' then $scope.autoFailPolicy = 'Paid (by Other)'
        else $scope.autoFailPolicy = 'Failed'


      $scope.hasRecurringPayment = ->
        $scope.membership.cost_repeat &&
          $scope.membership.recurring_payments_start_on &&
          $scope.membership.charge_interval_number

      $scope.openPaymentDateInPastModal = () ->
        ReactModal.open(
          component: 'MembershipPaymentDateWarningModal'
          props:
            autoFailPolicy: $scope.autoFailPolicy
        )
