angular
  .module 'gym.components'
  .directive 'suspensionList', ->
    restrict: 'E'
    template: require('templates/components/contacts/forms/suspension_list.html.slim')
    scope:
      contact: '='
      suspensions: '='
      form: '='
      archive: '&'
    controller: ($scope, $rootScope, $state, $modal) ->
      today = $rootScope.gym.today
      $scope.isCollapsed = true

      $scope.pastSuspensions = ->
        _.filter $scope.suspensions, (suspension) ->
          moment(suspension.end_on).isBefore(today)

      $scope.getSuspension = ->
        if $scope.isCollapsed
          _.filter $scope.suspensions, (suspension) ->
            moment(suspension.end_on).isSameOrAfter(today)
        else
          $scope.suspensions

      hasActiveMembership = ->
        !!_.find $scope.contact.memberships, (membership) -> membership.active

      $scope.remove = (suspension) ->
        if hasActiveMembership()
          $state.go 'gym.contacts.confirmRemoveSuspension', {contactId: $scope.contact.id, suspensionId: suspension.id}
        else
          $scope.archive({suspension: suspension, form: $scope.form})

      $scope.toggleList = ->
        $scope.isCollapsed = !$scope.isCollapsed

      $scope.showHistory = (suspension) ->
        $modal.open
          template: require('templates/components/contacts/forms/suspension_history_modal.html.slim')
          size: 'lg'
          resolve:
            suspension: -> suspension
          controller: 'ShowSuspensionHistoryModalCtrl'
