angular
  .module 'gym.components'
  .directive 'memberDashlets', ->
    restrict: 'E'
    template: require('templates/components/contacts/member_dashlets.html.slim')
    scope:
      contact: '='
      currency: '&'
      setTab: '&'
    controller: ($scope, $rootScope, FeatureAvailabilityService) ->
      $scope.attendance = -> $scope.contact.attendance_dashlet
      $scope.balance = -> $scope.contact.balance_dashlet
      $scope.membership = -> $scope.contact.membership_dashlet
      $scope.creditVouchersAvailable = FeatureAvailabilityService.isAvailable('credit_vouchers')
