angular
  .module 'shared'
  .factory 'MembershipPlanService', (
    $rootScope,
    $q,
    flash,
    MessageService,
    isInteger
  ) ->
    build: (params, gym) ->
      gym ||= $rootScope.gym
      tax_status_options =
        if gym.gym_membership_detail?.membership_tax_rate_set && !gym.gym_membership_detail?.membership_tax_rate_id
          [['Tax exempt', 'tax_exempt']]
        else
          [
            ['Inc tax', 'inc_tax'],
            ['Tax exempt', 'tax_exempt']
          ]

      if params?.id
        _.omit params, ['id', 'position']
      else
        plan_type: 'upfront'
        term_unit: 'years'
        classes_interval_unit: 'weeks'
        charge_interval_unit: 'months'
        not_classes_interval: true
        classes_unlimited: true
        event_type_ids: []
        style_ids: []
        tax_status_options: tax_status_options
        invoice_receipt_cc_options: params.invoice_receipt_cc_options
        allow_book_and_pay_from_calendar: params.allow_book_and_pay_from_calendar
        allow_customer_to_choose_start_date_on_member_portal: params.allow_customer_to_choose_start_date_on_member_portal
        allow_customer_to_choose_start_date_on_waiver: params.allow_customer_to_choose_start_date_on_waiver
        allow_membership_purchase_from_member_portal: params.allow_membership_purchase_from_member_portal
        member_portal_start_date_limit_number: params.member_portal_start_date_limit_number
        member_portal_start_date_limit_unit: params.member_portal_start_date_limit_unit
        allow_recurring_bookings: gym.gym_config.recurring_bookings_feature_enabled
        show_when_adding_membership_internally: params.show_when_adding_membership_internally

    hasInvalidTypeLimit: (limits) ->
      _.find limits, (limit) ->
        number = limit.date_range_number
        limit.limit && (!isInteger(number) || !number || number < 1)

    validate: (plan) ->
      deferred = $q.defer()
      errors = {}

      if plan.default_start_date_strategy is 'start_on_specific_date' && !plan.specified_start_date
        errors.specified_start_date = ['Is required']

      if plan.default_upfront_date_strategy is 'charge_upfront_amount_relative_to_start_date' && !plan.relative_upfront_date_number
        errors.relative_upfront_date_number = ['Is required']

      if plan.default_repeat_date_strategy is 'start_repeat_payments_relative_to_start_or_upfront' && !plan.relative_repeat_date_number
        errors.relative_repeat_date_number = ['Is required']

      if plan.default_start_date_strategy is 'start_on_next_upcoming' && plan.purchase_from_kiosk_mode
        errors.purchase_from_kiosk_mode = ['Cannot be enabled for memberships set to start on the next day/month']

      if plan.default_start_date_strategy is 'start_relative_to_purchase_date' && plan.purchase_from_kiosk_mode
        errors.purchase_from_kiosk_mode = ['Cannot be enabled for memberships set to start relative to purchase date']

      if plan.default_start_date_strategy is 'start_relative_to_purchase_date' && !plan.relative_start_date_number
        errors.relative_start_date_number = ['Is required']

      if plan.add_makeup_classes_for_missed_classes
        if !isInteger(plan.missed_class_threshold) || !plan.missed_class_threshold || plan.missed_class_threshold < 1
          errors.missed_class_threshold = ['Is invalid']

        if plan.total_makeup_class_limit_for_missed_classes && (!isInteger(plan.total_makeup_class_limit_for_missed_classes) || plan.total_makeup_class_limit_for_missed_classes < 1)
          errors.total_makeup_class_limit_for_missed_classes = ['Is invalid']

        if plan.missed_class_makeup_class_expiry_offset_number && (!isInteger(plan.missed_class_makeup_class_expiry_offset_number) || plan.missed_class_makeup_class_expiry_offset_number < 1)
          errors.missed_class_makeup_class_expiry_offset_number = ['Is invalid']

      if @hasInvalidTypeLimit(plan.per_event_type_limits)
        errors.per_event_type_limits = ['Is invalid']

      plan.errors = errors
      if _.isEmpty errors
        deferred.resolve()
      else
        flash.error = "Create failed. Please fix the errors below"
        deferred.reject(errors)
      deferred.promise

    getStartDate: (plan) ->
      if plan.specified_start_date
        return plan.specified_start_date
      if plan.default_start_date_strategy is 'start_on_next_upcoming'
        date = moment().date(plan.start_on_next_upcoming_day)
        if plan.start_on_next_upcoming_month
          date.month(plan.start_on_next_upcoming_month - 1)
        if moment().isAfter(date, 'day')
          if plan.start_on_next_upcoming_month
            date.add(1, 'years')
          else
            date.add(1, 'months')
        return date.format('YYYY-MM-DD')
      if plan.default_start_date_strategy is 'start_relative_to_purchase_date'
        return moment().add(plan.relative_start_date_number, plan.relative_start_date_unit).format('YYYY-MM-DD')

    displayTrialMembershipDescription: () ->
      MessageService.message({
        title: 'About Trial Memberships'
        message: [
          "<strong>Trial memberships make tracking your new members easy</strong>",
          'Trial memberships are just like regular memberships, but they give you more control when onboarding new customers.',
          "You'll be able to manage your trialling members in places like:",
          "<ul><li>Building custom reports, and sending bulk messages</li><li>In Sequences, for example when a trial membership is starting, or coming to an end</li><li>In the Active Member dashboard report</li></ul>",
          'You can toggle a membership\'s "trial" status at any time, so it\'s easy to change an existing membership to a trial membership (or vice versa) at any time.'
        ]
        bindHtml: true
      })
