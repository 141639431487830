angular
  .module 'shared'
  .directive 'autoFocus', ($rootScope, $timeout) ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      focusElement = ->
        angular.element("[auto-focus]").focus()

      $rootScope.$on 'shared:autofocus:restart', (event) ->
        focusElement()

      $timeout ->
        focusElement()
      , +attrs.autoFocus || 50
