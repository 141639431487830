angular
  .module 'shared'
  .factory 'CwStorage', ->
    data = {}
    hasLocalStorage = false

    try
      x = 'testStorage'
      localStorage.setItem(x, x)
      localStorage.removeItem(x)
      hasLocalStorage = true
     catch error
       hasLocalStorage = false

    Storage =
      setItem: (key, value) ->
        if hasLocalStorage
          localStorage.setItem(key, value)
        else
          data[key] = value

      getItem: (key) ->
        if hasLocalStorage
          localStorage.getItem(key)
        else
          data[key] || 'null'

      removeItem: (key) ->
        if hasLocalStorage
          localStorage.removeItem(key)
        else
          data[key] = null

      clear: () ->
        if hasLocalStorage
          localStorage.clear()
        else
          data = {}

    return Storage
