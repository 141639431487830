angular
  .module 'gym.components'
  .directive 'nonAttendingContactDashlets', ->
    restrict: 'E'
    template: require('templates/components/contacts/non_attending_contact_dashlets.html.slim')
    scope:
      contact: '='
      convertToMember: '&'
      convertToProspect: '&'
      currency: '&'
      setTab: '&'
    controller: ($scope, FeatureAvailabilityService) ->
      $scope.balance = -> $scope.contact.balance_dashlet
      $scope.membership = -> $scope.contact.membership_dashlet
      $scope.creditVouchersAvailable = FeatureAvailabilityService.isAvailable('credit_vouchers')
