import pluralize from 'pluralize'

angular
  .module 'filters'
  .filter 'membershipPlanPaymentFilter', ($rootScope, $filter) ->
    (membershipPlan, currency) ->
      if membershipPlan.cost_repeat > 0 && 'recurring' in [membershipPlan.type, membershipPlan.plan_type]
        symbol = currency || $rootScope.gym.currency_symbol
        cost_repeat = $filter('currency')(membershipPlan.cost_repeat, symbol)
        if membershipPlan.charge_interval_number > 1
          "#{cost_repeat} every #{membershipPlan.charge_interval_number} #{membershipPlan.charge_interval_unit}"
        else
          "#{cost_repeat}/#{pluralize.singular(membershipPlan.charge_interval_unit)}"
      else
        "No recurring payment"
