angular
  .module 'filters'
  .filter 'waiverGymAddress', ->
    (waiver) ->
      _.compact([
        waiver.waiver_header_address_suburb,
        waiver.waiver_header_address_state,
        waiver.waiver_header_address_postcode,
        waiver.waiver_header_address_country
      ]).join(', ')
