angular
  .module 'gym.components'
  .directive 'compareDataSeries', ->
    restrict: 'E'
    template: require('templates/components/contacts/data_series/compare.html.slim')
    scope:
      contact: '='
      metrics: '='
      showGeneralView: '&'
    controller: (
      $scope,
      $modal,
      $window,
      flash,
      loadingAnimation,
      DataSeries,
      AdminUserService
    ) ->
      $scope.adminHasPermission = AdminUserService.has
      $scope.isLoading = true
      Report = $scope.contact.all('data_series_results').all('comparison_report')
      $scope.contact.all('data_series_results').all('recording_dates').getList().then (data) ->
        $scope.recordingDates = data

      $scope.contact.all('data_series_recordings').getList(count: 2).then (recordings) ->
        $scope.dates = recordings.map (recording) -> recording.recorded_on
        loadReport()

      loadReport = ->
        $scope.dates = _.sortBy $scope.dates, (i) -> i
        $scope.isLoading = true
        Report.getList('dates[]': $scope.dates).then (data) ->
          $scope.comparisons = data.plain()
          $scope.isLoading = false

      $scope.onDateChange = (date, index) ->
        $scope.dates.splice index, 1, date
        loadReport()

      $scope.addDate = ->
        date = _.last($scope.dates)
        $scope.dates.push(date)
        loadReport()

      $scope.removeDate = (index) ->
        $scope.dates.splice index, 1
        loadReport()

      $scope.downloadCsv = ->
        params = angular.element.param(dates: $scope.dates)
        url = "#{Report.getRestangularUrl()}.csv?#{params}"
        $window.open url, '_blank'
        return true

      $scope.emailCsv = ->
        $modal.open
          template: require('templates/components/contacts/data_series/send_email_modal.html.slim')
          resolve:
            contact: -> $scope.contact
            dates: -> $scope.dates
          controller: ($scope, $modalInstance, flash, contact, dates) ->
            $scope.params =
              withoutNamespace: true
              dates: dates
              email: contact.contact_information.email

            $scope.send = ->
              $scope.isLoading = true
              contact.all('data_series_results')
                .doPOST($scope.params, 'email_comparison_report').then (data) ->
                  flash.success = 'Report sent successfully'
                  $modalInstance.close()
                , () ->
                  $scope.isLoading = false
                  flash.error = "Request could not be completed. Please try again"

            $scope.cancel = ->
              $modalInstance.dismiss('cancel')
