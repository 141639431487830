angular
  .module 'filters'
  .filter 'membershipPlanKioskPricingFilter', ($filter) ->
    (plan, currency) ->
      upfrontPayment =
        $filter('currency')(plan.cost_upfront_cents / 100, currency)
      repeatPayment =
        $filter('currency')(plan.cost_repeat_cents / 100, currency)

      if plan.cost_repeat_cents > 0 and plan.charge_interval_unit
        if plan.cost_upfront_cents > 0
          "#{upfrontPayment} up-front + #{repeatPayment} recurring"
        else
          "#{repeatPayment} recurring"

      else
        if plan.cost_upfront_cents > 0
          "#{upfrontPayment} up-front"
        else
          "FREE"
