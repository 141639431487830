angular
  .module 'gym.components'
  .directive 'recordContactDataSeriesResult', ->
    restrict: 'E'
    template: require('templates/components/contacts/data_series/record_contact_data_series_result.html.slim')
    scope:
      contact: '='
      initialDate: '='
      editing: '='
      showGeneralView: '&'
    controller: (
      $scope,
      $modal,
      flash,
      loadingAnimation,
      EmploymentService,
      DataSeries,
    ) ->
      $scope.permissions = EmploymentService.get().permissions

      loadMetrics = (date = $scope.recording.recorded_on) ->
        DataSeries.all('metrics').all('enabled_only').getList(count: 100).then (data) ->
          $scope.metrics = data
          $scope.createRecording(date)

      loadMetrics($scope.initialDate)

      $scope.manageMetrics = ->
        roomModal = $modal.open
          template: require('templates/components/contacts/data_series/manage_metrics.html.slim')
          controller: 'ManageDataSeriesMetricsCtrl'
          resolve:
            metrics: (DataSeries) -> DataSeries.all('metrics').getList(count: 100)
            meta: (DataSeries) ->
              DataSeries.all('metrics').all('meta').getList()

        roomModal.result.then ->
          loadMetrics()
        , ->
          loadMetrics()

      loadRecordings = (day) ->
        $scope.contact.one('data_series_recordings', 'for_date')
          .get('filters[date]': day)

      $scope.createRecording = (day) ->
        loadRecordings(day).then (data) ->
          recording = data
          recordedEntries = data?.data_series_entries || []
          $scope.recording =
            id: recording?.id
            rootObjectName: 'recording'
            recorded_on: day
            show_in_member_portal: true
            data_series_entries_attributes:
              $scope.metrics.map (item) ->
                recorded = _.find recordedEntries, data_series_metric_id: item.id
                params =
                  data_series_metric_id: item.id
                  metric_name: item.name
                  metric_label: item.label
                  metric_description: item.description

                if recorded
                  params.id = recorded.id
                  params.data_series_recording_id = recorded.data_series_recording_id
                  params.metric_value = parseFloat recorded.metric_value
                  params.comments = recorded.comments
                params

      save = ->
        if $scope.recording.id
          $scope.contact.one('data_series_recordings', $scope.recording.id).doPUT($scope.recording)
        else
          $scope.contact.all('data_series_recordings').post($scope.recording)

      $scope.isReady = ->
        !$scope.isLoading && $scope.recording

      $scope.save = ->
        $scope.isLoading = true
        save().then (data) ->
          $scope.showGeneralView()
          flash.success = "Saved successfully"
        , (response) ->
          $scope.isLoading = false
          $scope.recording.errors = response.data.errors
          flash.error = "Save failed. Please fix the errors below"
