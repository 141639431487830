angular
  .module 'gym.components'
  .directive 'integrapayPaymentDetails', ->
    restrict: 'EA'
    scope:
      gym: '='
      paymentMethods: '='
      permissions: '='
      remove: '&'
    template: require('templates/components/contacts/wallet/integrapay_payment_details.html.slim')
    controller: ($scope, AdminUserService) ->
      $scope.adminHasPermission = AdminUserService.has
