import humanize from 'helpers/humanize'
import random from 'helpers/random'

angular
  .module 'gym.components'
  .directive 'editReportFilters', ->
    restrict: 'E'
    template: require('templates/components/reports/edit_report_filters.html.slim')
    scope:
      report: '='
      reports: '='
    controller: ($rootScope, $scope, $filter, $modal, Reports, MessageService) ->
      $scope.inReportFilterAvailable = $rootScope.gym.gym_config.can_use_in_other_report_filters
      $scope.relativeDateId = random()

      inReportOptions = [
        {
          label: 'Is in other report',
          operator: 'IN REPORT'
          inReport: true
        },
        {
          label: 'Is not in other report',
          operator: 'NOT IN REPORT'
          inReport: true
        }
      ]

      $scope.periods = [
        'days'
        'weeks'
        'months'
        'years'
      ]

      $scope.multipliers = ['from_now', 'ago']

      $scope.addFilter = (filter) ->
        $scope.report.report_filters_attributes ||= []
        report_filter =
          if filter.column.inReport
            operator: filter.column.operator
            operator_label: humanize(filter.column.operator)
            user_value: filter.user_value
            database_value_label: 'Contact'
            database_value: 'contacts:id'
          else
            database_value:
              filter.column.form_field_id ||
              filter.column.table_and_column ||
              filter.column.unique_id
            database_value_label: filter.column.label
            data_type: filter.column.data_type
            operator: filter.condition[1]
            operator_label: filter.condition[0]
            user_value: filter.user_value
            relative_date: filter.relative_date
            multiplier: filter.multiplier
            period: filter.period
            number: filter.number

        $scope.newFilter = {}

        $scope.report.report_filters_attributes.push report_filter

      $scope.deleteFilter = (filter, index) ->
        if filter.id
          filter._destroy = 1
        else
          $scope.report.report_filters_attributes.splice index, 1

      $scope.onEditFilter = (filter, index) ->
        modalInstance = $modal.open
          template: require('templates/components/reports/manage_filter_conditions.html.slim')
          controller: 'ManageFilterConditionsCtrl'
          size: 'md'
          resolve:
            report: -> $scope.report
            reports: -> $scope.reports
            inReportOptions: -> inReportOptions
            inReportFilterAvailable: -> $scope.inReportFilterAvailable
            displayInReportFilterDescription: -> $scope.displayInReportFilterDescription
            filter: -> angular.extend {}, filter

        modalInstance.result.then (data) ->
          $scope.report.report_filters_attributes[index] = data

      $scope.onRelativeChange = ->
        if $scope.newFilter.relative_date
          $scope.newFilter.user_value = ''
          $scope.newFilter.relative_date ||= false
          $scope.newFilter.multiplier ||= $scope.multipliers[0]
          $scope.newFilter.period = 'months'

      $scope.appliedFiltersCount = ->
        filters = _.filter($scope.report.report_filters_attributes, (filter) ->
          filter._destroy isnt 1
        )
        filters.length

      $scope.displayInReportFilterDescription = ->
        MessageService.message
          title: 'Using "Is in / Is not in other report" filters'
          message: [
            'Please be aware that the "Is in other report" / "Is not in other report" filters rely on data that is refreshed frequently, but may not always be 100% up to date.',
            'Because of this, there may be a short period after a contact has entered or left the other report, where these filters can return inaccurate results.'
          ]
          confirmButtonText: 'Ok'

      $scope.displaySectionName = (section) ->
        $scope.report.column_section_name_map[section] || humanize(section)

      $scope.availableColumns = ->
        columns = $scope.report.report_columns_options?[$scope.newFilter?.section]
        forbiddenForFiltration = ['Contact ID']
        available = _.filter columns, (column) ->
          column.label not in forbiddenForFiltration
        availableColumns = _.map available, (val) -> val
        if $scope.inReportFilterAvailable && $scope.newFilter?.section is 'contact'
          inReportOptions.concat(availableColumns)
        else
          availableColumns

      $scope.onColumnChanged = ->
        $scope.newFilter.condition = ''
        $scope.newFilter.relative_date = false

      $scope.onConditionChanged = ->
        $scope.newFilter.user_value = ''
        $scope.newFilter.number = ''
        $scope.newFilter.relative_date = false
        $scope.newFilter.multiplier = ''
        $scope.newFilter.period = ''

      $scope.requireUserValue = ->
        blankValueConditions = ['Is blank', 'Is not blank']
        $scope.newFilter?.condition?[0] not in blankValueConditions

      relativeDateReady = ->
        filter = $scope.newFilter
        filter.relative_date &&
          (($filter('filterInt')(filter.number) >= 0) && filter.period && filter.multiplier)

      userValueReady = ->
        !$scope.requireUserValue() || $scope.newFilter.user_value?.toString() || relativeDateReady()

      $scope.isFilterReady = ->
        if $scope.newFilter?.column?.inReport
          userValueReady()
        else
          $scope.newFilter?.column && $scope.newFilter.condition && userValueReady()
