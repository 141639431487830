angular
  .module 'shared'
  .directive 'gtm', ->
    restrict: 'E'
    scope:
      gtmId: '='
    controller: ($scope, $sce) ->
      $scope.enabled = window.DATA?.embed_pixels && $scope.gtmId

      $scope.getId = ->
        $scope.gtmId?.replace(/([^a-z0-9\-]+)/gi, '')

      content = "
        <script>
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','#{$scope.getId()}');
        </script>
      "
      $scope.content = $sce.trustAsHtml(content)
    template: '<div ng-if="enabled" ng-bind-html="content"></div>'
