angular.module 'shared'
  .factory 'PingService', (
    $rootScope,
    $http,
  ) ->
    ping: () ->
      gymId = $rootScope.gym.id
      pingIntervalId = window.setInterval ->
        $http.get("/gyms/#{gymId}/ping")
      , 120000

      unsubscribe = $rootScope.$on '$stateChangeStart', (event, toState, toParams) ->
        window.clearInterval(pingIntervalId) if pingIntervalId
        unsubscribe()
