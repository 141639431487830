import plural from 'helpers/plural'

angular
  .module 'filters'
  .filter 'membershipPlanClassAccessFilter', ->
    (membershipPlan) ->
      classString = plural('classes', membershipPlan.classes_limit)
      intervalUnitString =
        plural(membershipPlan.classes_interval_unit, membershipPlan.classes_interval_number)

      intervalNumberString =
        if membershipPlan.classes_interval_number > 1
          membershipPlan.classes_interval_number
        else ''

      intervalString =
        if membershipPlan.classes_interval_number
          "every #{intervalNumberString} #{intervalUnitString}"
        else
          "anytime"

      switch
        when membershipPlan.classes_unlimited
          "Unlimited"
        when membershipPlan.classes_limit
          "#{membershipPlan.classes_limit} #{classString} to use #{intervalString}"
        else
          "#{membershipPlan.classes_limit} #{classString}"
