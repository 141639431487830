import dompurify from 'dompurify'
import autolinker from 'autolinker';

angular
  .module 'shared'
  .directive 'textareaContent', ->
    restrict: 'E'
    scope:
      text: '='
    controller: ($scope) ->
      $scope.getText = ->
        escaped = _.escape($scope.text)
        withLinks = autolinker.link(escaped)
        withBreaks = withLinks.replace(/\n/g, '<br/>')
        dompurify.sanitize withBreaks, ALLOWED_TAGS: ['a', 'br'], ALLOWED_ATTR: ['target', 'href', 'rel']

    template: '''<div ng-bind-html="getText()"></div>'''
