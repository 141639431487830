angular
  .module 'shared'
  .directive 'autoHideSuccessAlert', ($timeout) ->
    restrict: 'A'
    link: (scope, element) ->
      $timeout ->
        scope.unbindWatcher = scope.$on '$locationChangeSuccess', ->
          hide()

      $timeout ->
        if angular.element('.worx-loading-background').hasClass('hidden')
          hide()
      , 3000

      hide = ->
        if element.hasClass('flash-success')
          element.removeClass('active')
          scope.unbindWatcher()
