angular
  .module 'gym.components'
  .directive 'gocardlessPaymentDetails', ->
    restrict: 'EA'
    scope:
      paymentMethods: '='
      setDefault: '&'
      reloadPaymentMethods: '&'
      permissions: '='
    template: require('templates/components/contacts/wallet/gocardless_payment_details.html.slim')
    controller: ($scope, $window, ContactGoCardlessService, AdminUserService) ->
      $scope.adminHasPermission = AdminUserService.has

      $scope.setDefault = (item) ->
        if $window.confirm 'This will change the Mandate used for payment processing. Are you sure?'
          ContactGoCardlessService.setDefault(item).then () ->
            $scope.reloadPaymentMethods()
            $scope.$emit 'contact:mandates:reload'

      $scope.getAvailableItems = ->
        _.filter $scope.paymentMethods, (item) -> item.show_in_wallet
