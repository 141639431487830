# Fix for paginated table footer since ng-table doesn't support dynamic column size
angular
  .module 'gym.components'
  .directive 'colspanFix', ($timeout) ->
    restrict: 'A'
    link: ->
      $timeout ->
        colspanValue = angular.element('thead tr th').length
        footer = angular.element('tfoot td')[0]
        angular.element(footer).attr 'colspan', colspanValue
