angular.module 'website'
  .controller 'BookingModalCtrl', (
    $scope,
    $modalInstance,
    $http,
    $timeout,
    DataService,
    event,
    isFreeTrials,
    onBookingSuccess
  ) ->
    $scope.event = event
    $scope.units = DataService.units
    $scope.currency = DataService.currency
    $scope.showInstructor = DataService.gym_config.show_instructor_on_website_calendar
    $scope.showCapacity = DataService.gym_config.show_capacity_on_website_calendar
    $scope.allowBookings = DataService.allow_bookings
    $scope.contact =
      contact_information: {}
    hiddenFields = ['prospect_status']
    $scope.pageParams = {}

    clearMessages = ->
      $scope.errorMessage = ''
      $scope.successMessage = ''

    $scope.showContactForm = ->
      clearMessages()
      $scope.isContactFormDisplaying = true
      $http.get("/gyms/#{DataService.gym_id}/forms?contact_type=prospects").then (response) ->
        $scope.forms = _.chain(response.data)
          .map (form) ->
            if form.on_website && form.show_on_new_record
              $scope.contact[form.name] ||= {}
              form.fields = _.filter form.fields, (field) ->
                field.on_prospect && !field.read_only && field.name not in hiddenFields
              form
          .compact()
          .value()
      , (response) ->
        $scope.isContactFormDisplaying = false
        $scope.errorMessage = 'An error occured, please try again later.'

    $scope.showContactForm() if isFreeTrials

    $scope.displayMemberForm = ->
      $scope.isContactFormDisplaying = false

    $http.get("/gyms/#{DataService.gym_id}/privacy_policies?filters[policy_context]=gym_website").then (response) ->
      $scope.privacyPolicies = response.data.collection
      setLinksTarget()
      $scope.contact.privacy_policy_responses_attributes =
        $scope.privacyPolicies.map (item) ->
          privacy_policy_id: item.id, accepted: false

    setLinksTarget = ->
      $timeout ->
        angular.element('.form-group a').each (i, element) ->
          element.target = '_blank'

    $scope.isPrivacyPoliciesFormValid = ->
      _.every $scope.contact.privacy_policy_responses_attributes, (item) ->
        item.accepted

    $scope.isFieldRequired = (field) ->
      if field.customizable_required_settings
        field.required_on_website
      else
        field.required

    scrollModal = ->
      angular.element(".modal").scrollTo(0, 0)

    $scope.isFormReady = ->
      !_.find $scope.forms, (form) ->
        form.on_website && _.find form.fields, (field) ->
          field.on_prospect && field.on_website && $scope.isFieldRequired(field) &&
            !field.archived && !$scope.contact[form.name][field.name]

    onBookingComplete = ->
      eventParams =
        event_name: $scope.event.title
        event_time: moment(event.start).format()
      onBookingSuccess(eventParams)

    $scope.saveContact = ->
      clearMessages()
      $scope.contact.booking =
        event_id: $scope.event.id
      $scope.isLoading = true

      $http.post("/websites/#{DataService.domain}/free_trials", {contact: $scope.contact})
        .then (response) ->
          contactResponse = response.data.error
          if contactResponse.id
            onBookingComplete()
            $scope.successMessage = 'Booked successfully'
            $timeout ->
              $modalInstance.close(contactResponse)
            , 3000
          else
            $scope.isLoading = false
            if _.isString contactResponse
              $scope.errorMessage = contactResponse
            else
              $scope.forms.forEach (form) ->
                $scope.contact[form.name].errors =
                  contactResponse[form.name].errors
            scrollModal()

    bookMember = ->
      params =
        website_id: DataService.domain
        event_id: $scope.event.id
        member_email: !$scope.pageParams.matchingContactId && $scope.contact.contact_information.email
        matching_contact_id: $scope.pageParams.matchingContactId
        privacy_policy_responses_attributes:
          $scope.contact.privacy_policy_responses_attributes

      $http.post("/websites_bookings", params).then((response)->
        if response.data.success
          onBookingComplete()
          $scope.successMessage = response.data.success
          $timeout ->
            $modalInstance.close(response.data)
          , 3000
        else
          $scope.isLoading = false
          $scope.multipleContactsFoundMessage = response.data.error
          $scope.contactMatches = response.data.matches
          $scope.bookAndPayOptions = response.data.book_and_pay_options
          $scope.errorMessage = response.data.error
          scrollModal()

      , (response) ->
        $scope.isLoading = false
        error = response.data.error
        if event.open_to_prospects && error is 'No matching customer for the provided email address.'
          $scope.emailNotFound = true
          $scope.showContactForm()
          $scope.contact.contact_information =
            email: params.member_email
        else
          $scope.errorMessage = error
          scrollModal()
      )

    inviteToWaitList = ->
      url = "/websites/#{DataService.domain}/waitlist_attendees"
      params =
        contact_email: $scope.contact.contact_information.email
        event_id: $scope.event.id
        privacy_policy_responses_attributes:
          $scope.contact.privacy_policy_responses_attributes

      $http.post(url, params).then (response) ->
        $scope.successMessage = "Waitlist joined"
        $timeout ->
          $modalInstance.close(response.data)
        , 3000
      , (response) ->
        $scope.errorMessage = response.data.error_message
        $scope.isLoading = false
        scrollModal()

    $scope.submit = ->
      $scope.isLoading = true
      if $scope.event.invite_to_waitlist
        inviteToWaitList()
      else
        bookMember()

    $scope.cancel = ->
      $modalInstance.dismiss('cancel')
