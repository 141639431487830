angular
  .module 'shared'
  .directive 'iconFlag', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.189.979C6.273.481 5.527.25 4.5.25 2.804.25 1.363.827.22 1.97L0 2.19v11.56h1.5V9.575l.091-.08C2.39 8.83 3.35 8.5 4.5 8.5c.8 0 1.348.188 2.16.651l.654.372C7.909 9.84 8.412 10 9 10c.906 0 1.799-.297 2.666-.876L12 8.9V.348l-1.166.778-.189.12c-.564.34-1.11.504-1.645.504-.303 0-.583-.089-.98-.3a7.006 7.006 0 0 1-.065-.036L7.189.98ZM1.59 2.746C2.39 2.08 3.35 1.75 4.5 1.75c.8 0 1.348.188 2.16.651l.654.372c.595.317 1.098.477 1.686.477l.209-.005a4.348 4.348 0 0 0 1.241-.251l.05-.02v5.104l-.043.025C9.96 8.37 9.476 8.5 9 8.5c-.303 0-.583-.089-.98-.3a7.006 7.006 0 0 1-.065-.036l-.766-.435C6.273 7.231 5.527 7 4.5 7l-.252.004c-1.002.035-1.912.277-2.72.724l-.028.015V2.825l.091-.08Z" fill="{{color || 'currentColor'}}" fill-rule="evenodd"/></svg>
    '''
