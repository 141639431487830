angular
  .module 'shared'
  .directive 'truncatedMessage', ($timeout) ->
    restrict: 'E'
    scope:
      data: '='
      maxHeight: '@'
    template: require('templates/components/contacts/comms/truncated_message.html.slim')
    link:
      post: ($scope, element) ->
        trimmedContent = ->
          angular.element(element[0].querySelector('.ta-trimmed-content'))

        fullContent = ->
          angular.element(element[0].querySelector('.ta-full-content'))

        imageContent = ->
          angular.element(element[0].querySelectorAll('img'))

        $scope.isEmail = ->
          $scope.data.channel is 'email'

        $scope.shouldBeTruncated = ->
          parseInt(fullContent().css('height')) > parseInt($scope.maxHeight)

        truncateContent = ->
          $timeout ->
            if $scope.shouldBeTruncated()
              trimmedContent().css('max-height': $scope.maxHeight)

        $timeout ->
          imageContent().on('load', -> truncateContent())
          truncateContent()

        $scope.toggle = ->
          height = if $scope.expanded then $scope.maxHeight else 'none'
          trimmedContent().css('max-height': height)
          $scope.expanded = !$scope.expanded
