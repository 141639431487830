export const withoutFiltersWrapper = (filters) => {
  const params = {};
  _.each(filters, (val, key) => {
    const newKey = key.replace('filters[', '').replace(']', '');
    params[newKey] = val;
  });

  return params;
};

export const stringifyParams = (params) => {
  const data = {};
  _.each(params, (param, wrapperKey) => {
    if (typeof param === 'object' && !Array.isArray(param)) {
      _.each(param, (val, key) => {
        let newKey = `${wrapperKey}[${key}]`;
        if (Array.isArray(val)) {
          newKey = newKey + '[]';
        }

        data[newKey] = val;
      });
    } else {
      data[wrapperKey] = param;
    }
  });

  return data;
};
