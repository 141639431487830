angular.module 'shared'
  .factory 'WaiverService', ($q, $http) ->

    getParentDetails: (waiver) ->
      parent = waiver.signed_waiver_contacts_attributes[0]
      fields = _.compact([
        'email'
        @isFieldAvailable(waiver, 'phone_number') && 'phone_number'
        @isFieldAvailable(waiver, 'address') && 'address_line_1'
        @isFieldAvailable(waiver, 'address') && 'address_line_2'
        @isFieldAvailable(waiver, 'address') && 'address_suburb'
        @isFieldAvailable(waiver, 'address') && 'address_postcode'
        @isFieldAvailable(waiver, 'address') && 'address_state'
      ])
      _.pick parent, fields

    prepopulateChildDetails: (waiver) ->
      waiver.signed_waiver_contacts_attributes.forEach (contact, index) =>
        parent = @getParentDetails(waiver)
        if index isnt 0
          contact.email ||= parent.email
          contact.phone_number ||= parent.phone_number
          contact.address_line_1 ||= parent.address_line_1
          contact.address_line_2 ||= parent.address_line_2
          contact.address_suburb ||= parent.address_suburb
          contact.address_postcode ||= parent.address_postcode
          contact.address_state ||= parent.address_state

    syncContactFields: (waiver) ->
      (contact, oldContact) ->
        return if !contact || !oldContact
        watchFields = ['email', 'phone_number', 'address_line_1', 'address_line_2', 'address_suburb', 'address_state', 'address_postcode']
        watchFields.forEach (field) ->
          if contact[field] isnt oldContact[field]
            waiver.signed_waiver_contacts_attributes.forEach (c, index) ->
              if index > 0 && c[field] is oldContact[field]
                c[field] = contact[field]

    initPaymentDetails: (waiver) ->
      waiverWallet = waiver.signed_waiver_wallet_detail_attributes
      if waiver.signed_waiver_form_attributes.payment_authority_active
        if waiver.signed_waiver_form_attributes.restrict_payment_methods_to?.length
          waiverWallet.disablePaymentMethodSelect = true
        defaultPaymentMethod = switch
          when waiver.signed_waiver_form_attributes.restrict_payment_methods_to?.length
            waiver.signed_waiver_form_attributes.restrict_payment_methods_to[0]
          when waiver.signed_waiver_form_attributes.payment_authority_preferred_payment_method
            waiver.signed_waiver_form_attributes.payment_authority_preferred_payment_method
          else
            'credit_card'
        waiverWallet.payment_method ||= defaultPaymentMethod
        waiverWallet.payment_method_changed = true

        walletDetails =
          payment_method: waiverWallet.payment_method
          editable: !waiverWallet.customer_ref || !waiverWallet.last4
          cardValid: true
          disablePaymentMethodSelect: waiverWallet.disablePaymentMethodSelect


        if waiverWallet.last4
          if waiverWallet.payment_method is 'credit_card'
            cardNumber = "xxxx xxxx xxxx #{waiverWallet.last4}"
          if waiverWallet.payment_method is 'bank_account'
            accountNumber = "xxxx xxxx xxxx #{waiverWallet.last4}"
          waiverWallet.payment_method_changed = false

          angular.extend walletDetails, {
            cardNumber: cardNumber
            expiryMonth: parseInt(waiverWallet.exp_month, 10) || ''
            expiryYear: parseInt(waiverWallet.exp_year, 10) || ''
            nameOnCard: waiverWallet.full_name
            bsb: waiverWallet.bsb
            accountNumber: accountNumber
            accountName: waiverWallet.full_name
          }

        walletDetails

    getNewPaymentDetails: (waiver) ->
      waiver.signed_waiver_wallet_detail_attributes.payment_method_changed = true
      if waiver.signed_waiver_form_attributes.restrict_payment_methods_to?.length
        disablePaymentMethodSelect = true
        defaultPaymentMethod = waiver.signed_waiver_form_attributes.restrict_payment_methods_to[0]

      walletDetails =
        editable: true
        payment_method: defaultPaymentMethod || 'credit_card'
        disablePaymentMethodSelect: disablePaymentMethodSelect

    isBankAccountProviderAvailable: (provider) ->
      provider is 'ezidebit'

    isCreditCardProviderAvailable: (provider) ->
      provider in ['ezidebit', 'stripe']

    isFieldRequired: (waiver, field, first) ->
      fields = waiver.signed_waiver_form_attributes.required_fields
      fields[field] is 'required_for_all_signees' ||
        fields[field] is 'required_for_primary_signee' && first

    isFieldAvailable: (waiver, field) ->
      waiver.signed_waiver_form_attributes.required_fields[field] isnt 'hidden'

    hasPayment: (waiver) ->
      waiver.signed_waiver_contacts_attributes.find (contact) ->
        plan = waiver.signed_waiver_form_attributes.membership_plan_options.find (m) ->
          m.id is contact.membership_plan_id
        !contact._destroy && (plan?.cost_upfront > 0 || plan?.cost_repeat > 0)

    getEzidebitFees: (waiver, gymId) ->
      deferred = $q.defer()
      if waiver.signed_waiver_form_attributes?.membership_options_active && waiver.gym_payment_provider is 'ezidebit'
        excludeFee = if waiver.primary_signee_active_on_ezidebit then "&exclude_setup_fee=true" else ''
        $http.get("/fee_schedules?gym_id=#{gymId}#{excludeFee}").then (respose) ->
          deferred.resolve respose.data.fees
      deferred.promise

    setEzidebitCustomerContactDetails: (waiver, country) ->
      deferred = $q.defer()
      if country in ['AU', 'NZ']
        primaryContact = _.find(waiver.signed_waiver_contacts_attributes, (contact) -> contact.primary)
        emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
        if emailPattern.test(primaryContact.email.trim())
          primaryContact.cleanContactEmail = primaryContact.email.trim()
        url = "/phone_numbers/localize?country_code=#{country}&phone_number=#{primaryContact.phone_number}"
        $http.get(url).then (response) ->
          primaryContact.cleanContactPhoneNumber = response.data.localized_phone_number
          deferred.resolve()
        , () ->
          deferred.resolve()
      else
        deferred.resolve()
      deferred.promise

    getPreselectedMembership: (waiver, params) ->
      defaultMembershipPlanId = waiver.signed_waiver_form_attributes.default_membership_plan_id
      waiverForm = waiver.signed_waiver_form_attributes
      plans = waiverForm.membership_plan_options
      filteredPlans = _.filter plans, (plan) ->
        not plan.purchase_limit or plan.purchases_remaining
      return unless waiverForm.membership_options_active && plans.length

      preselectedMembershipPlanId = if waiverForm.family_membership_strategy is 'every_attendee_pays_membership'
        waiver.signed_waiver_contacts_attributes[1]?.membership_plan_id
      else
        waiver.signed_waiver_contacts_attributes[0]?.membership_plan_id

      if preselectedMembershipPlanId
        return _.find filteredPlans, (plan) -> plan.id is preselectedMembershipPlanId

      if params?.clickAndPayId
        clickAndPayPlan = _.find filteredPlans, (plan) -> plan.id is params.clickAndPayId
        return clickAndPayPlan if clickAndPayPlan

      if defaultMembershipPlanId
        defaultMembershipPlan = _.find filteredPlans, (plan) -> plan.id is defaultMembershipPlanId
        return defaultMembershipPlan if defaultMembershipPlan

      filteredPlans[0]
