angular.module 'website'
  .controller 'website.requestAccessCtrl', (
    $scope,
    $http,
    $timeout,
    $q,
    DataService
  ) ->
    $scope.pixelId = DataService.gym_config.facebook_pixel_id_for_contact_us_page
    $scope.pixelEvent = DataService.gym_config.facebook_pixel_event_for_contact_us_page
    $scope.gtmId = DataService.gym_config.gtm_code_for_contact_us_page

    $scope.contact = {}

    $http.get("/gyms/#{DataService.gym_id}/privacy_policies?filters[policy_context]=gym_website").then (response) ->
      $scope.privacyPolicies = response.data.collection
      $scope.contact.privacy_policy_responses_attributes =
        $scope.privacyPolicies.map (item) ->
          privacy_policy_id: item.id, accepted: false
      setLinksTarget()

    setLinksTarget = ->
      $timeout ->
        angular.element('.form-group a').each (i, element) ->
          element.target = '_blank'

    $scope.isFormInvalid = ->
      false

    validate = ->
      deferred = $q.defer()
      $scope.contact.errors = {}

      if !$scope.contact.contact_first_name?.trim()
        $scope.contact.errors.contact_first_name = ['This field is required']

      if !$scope.contact.contact_last_name?.trim()
        $scope.contact.errors.contact_last_name = ['This field is required']

      if !$scope.contact.email?.trim()
        $scope.contact.errors.email = ['This field is required']

      if _.isEmpty $scope.contact.errors
        deferred.resolve()
      else
        deferred.reject()
      deferred.promise

    $scope.isSaved = false

    $scope.save = ->
      validate().then () ->
        $scope.isLoading = true
        $scope.errorMessage = ""
        $http.post("/websites/#{DataService.domain}/access_requests", { mobile_app_access_request: $scope.contact }).then (response) ->
          $scope.isSaved = true
          $scope.isLoading = false
        , (response) ->
          $scope.contact.errors = response.data.errors
          $scope.errorMessage = "Please ensure that your email is correct and that you've filled out the required fields"
          $scope.isLoading = false
