import React from 'react';

interface BlockPlaceholderProps {
  isLoading?: boolean;
  pastDelay?: boolean;
  noMargin?: boolean;
}

const BlockPlaceholder = ({ isLoading, pastDelay, noMargin }: BlockPlaceholderProps = {}) => {
  if (isLoading && !pastDelay) return null;

  return (
    <div style={{ padding: noMargin ? 0 : 25 }}>
      <div className="loading-placeholder" style={{ height: 300 }}></div>
      <div className="loading-placeholder" style={{ height: 300, marginTop: 30 }}></div>
    </div>
  );
};

export default BlockPlaceholder;
