angular
  .module 'shared'
  .directive 'iconCake', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.1562 16.6406H2.09375C1.76541 16.6406 1.5 16.3582 1.5 16.01C1.5 15.6618 1.76541 15.3793 2.09375 15.3793H2.6875V12.8561C2.6875 12.0346 3.18506 11.3407 3.875 11.0797V9.07023C3.875 8.02694 4.67419 7.17764 5.65625 7.17764H8.03125V5.8228C7.33478 5.61048 6.84375 5.03796 6.84375 4.33969C6.84375 4.31378 6.84553 4.2885 6.84791 4.26323C6.8675 3.74252 7.08362 3.36653 7.27541 3.03288C7.44937 2.72893 7.60019 2.46731 7.63641 2.0711C7.65541 1.85877 7.77416 1.67109 7.95228 1.57188C8.12922 1.47267 8.34237 1.47646 8.51812 1.58136C8.70872 1.69511 10.3564 2.7245 10.4027 4.2727C10.4051 4.29482 10.4062 4.31694 10.4062 4.33969C10.4062 5.03796 9.91522 5.60985 9.21875 5.8228V7.17764H11.5938C12.5758 7.17764 13.375 8.02694 13.375 9.07023V11.0797C14.0643 11.3407 14.5625 12.0346 14.5625 12.8561V15.3793H15.1562C15.4846 15.3793 15.75 15.6618 15.75 16.01C15.75 16.3582 15.4846 16.6406 15.1562 16.6406ZM9.21632 4.33968C9.21632 3.91566 8.89569 3.49101 8.5626 3.17063C8.47473 3.36716 8.37735 3.53651 8.28948 3.6907C8.12144 3.98138 8.03416 4.14315 8.03416 4.33968C8.03416 4.34663 8.03416 4.35421 8.03357 4.36116C8.05969 4.47491 8.27938 4.65437 8.62494 4.65437C8.97288 4.65437 9.19316 4.47238 9.21632 4.35864C9.21632 4.35295 9.21632 4.346 9.21632 4.33968ZM12.1875 9.07022C12.1875 8.72267 11.9215 8.43957 11.5938 8.43957H5.65625C5.3285 8.43957 5.0625 8.72267 5.0625 9.07022V10.9635H12.1875V9.07022ZM13.3749 12.8561C13.3749 12.5079 13.1089 12.2248 12.7812 12.2248H4.46869C4.14094 12.2248 3.87494 12.5079 3.87494 12.8561V15.3793H13.3749V12.8561Z" fill="{{color || 'currentColor'}}"/></svg>
    '''
