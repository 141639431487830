angular.module 'httpInterceptors', []
  .factory 'http401Interceptor', ($q, $window, $location, $timeout) ->
    'response': (response) ->
      if response.data?.kiosk_mode && response.data?.redirect_path
        window.ignoreRollbarErrors = true
      if response.data?.redirect_url && response.config?.url.indexOf('kiosk') >= 0
        window.ignoreRollbarErrors = true
        $window.location = response.data.redirect_url

      response
    ,
    responseError: (response) ->
      switch response.status
        when 401
          if response.config.url.includes('session_status') && response.data.error == 'Please sign in to continue.'
            return
          window.ignoreRollbarErrors = true
          $window.location = "/users/sign_in?notice=Your session has expired. Please sign in to continue."
        when 422
          window.ignoreRollbarErrors = true
          message = "Reloading after new sign-in. Please try again."
          $location.search('notice', message)
          $timeout ->
            $window.location.reload()
        else
          url = response.config.url
          ignoredRequest = url.indexOf('unread_count') >= 0 ||
            url.indexOf('pending_count') >= 0 ||
            url.indexOf('gym_invoices') >= 0 ||
            response.config?.params?.all_contacts_search
          if response.data?.redirect_path && !ignoredRequest
            window.ignoreRollbarErrors = true
            url = response.data.redirect_path
            if response.data.message
              joinSymbol = if response.data.redirect_path.indexOf('?') >= 0 then '&' else '?'
              url = "#{url}#{joinSymbol}notice=#{response.data.message}"

            $window.location = url
          else
            $q.reject(response)

  .factory 'jsonApiInterceptor', () ->
    response: (response) ->
      switch
        when _.isArray(response.data.data) && (response.data.meta || _.keys(response.data).length is 1)
          collection = response.data.data.map (item) ->
            angular.extend {id: item.id, type: item.type}, item.attributes
          if response.data.meta
            collection.meta = response.data.meta
          response.data = collection
        when response.data.data && response.data.data.attributes
          item = response.data.data
          data = angular.extend {id: item.id, type: item.type}, item.attributes
          response.data = data
      response
