angular.module 'website'
  .controller 'website.waiverChangesCtrl', (
    $scope,
    $filter,
    $http,
    $document,
    $modal,
    $stateParams,
    DataService,
    MessageService,
    flash,
    loadingAnimation,
    waiver
  ) ->
    $scope.waiver = waiver
    $scope.signature = {}

    $scope.currency = DataService.currency
    $scope.params = {}

    $scope.contact = _.find $scope.waiver.signed_waiver_contacts_attributes, (c) -> c.primary
    $scope.waiver.signed_waiver_form_attributes.electronic_consent_acceptance = false

    $scope.replaceMergeTags = ->
      contact = $scope.waiver.signed_waiver_contacts_attributes[0]
      country = $scope.waiver.waiver_header_address_country
      waiverForm = $scope.waiver.signed_waiver_form_attributes
      waiverDateValue = if $scope.waiver.signed_at then moment($scope.waiver.signed_at).format('MMMM Do YYYY') else moment().tz($scope.tz).format('MMMM Do YYYY')
      waiverForm.gym_tacs_content =
        $filter('replaceWaiverMergeTags')(waiverForm.gym_tacs_content, contact, country)
      waiverForm.electronic_consent_content =
        $filter('replaceWaiverMergeTags')(waiverForm.electronic_consent_content, contact, country, waiverDateValue)
      waiverForm.waiver_release_content =
        $filter('replaceWaiverMergeTags')(waiverForm.waiver_release_content, contact, country)

    $scope.replaceMergeTags()

    $scope.contactMembership = (id) ->
      $scope.waiver.signed_waiver_consent_request_attributes.proposed_changes[id].membership

    $scope.hasMembershipChanges = (id) ->
      membership = $scope.contactMembership(id)
      membership.expires_after[0] isnt membership.expires_after[1] ||
        membership.membership_cost_repeat[0] isnt membership.membership_cost_repeat[1] ||
        membership.membership_cost_upfront[0] isnt membership.membership_cost_upfront[1] ||
        membership.membership_label[0] isnt membership.membership_label[1] ||
        membership.recurring_payment_frequency[0] isnt membership.recurring_payment_frequency[1] ||
        membership.membership_start_date[0] isnt membership.membership_start_date[1] ||
        membership.membership_upfront_payment_on[0] isnt membership.membership_upfront_payment_on[1] ||
        membership.membership_recurring_payments_start_on[0] isnt membership.membership_recurring_payments_start_on[1]

    $scope.reject = () ->
      modalInstance = $modal.open
        template: require('templates/website/waivers/reject_changes_modal.html.slim')
        controller: ($scope, $modalInstance) ->
          $scope.params = {}
          $scope.reject = ->
            $modalInstance.close($scope.params.reason)

          $scope.cancel = ->
            $modalInstance.dismiss('cancel')

      modalInstance.result.then (reason) ->
        $scope.isLoading = true
        $scope.waiver.signed_waiver_consent_request_attributes.errors = {}
        $scope.waiver.errors = {}
        params =
          signed_waiver_consent_request:
            reject_reason: reason

        url = "/websites/#{$stateParams.domain}/waivers/#{$stateParams.waiverName}/waiver_changes/#{$stateParams.waiverChangesId}/reject"
        $http.put(url, params).then () ->
          $scope.complete = true
        , (response) ->
          flash.error = response.data.service_error_message
          $scope.isLoading = false

    $scope.approve = () ->
      sectionsWithErrors = []
      if !$scope.waiver.signed_waiver_form_attributes.electronic_consent_acceptance
        $scope.waiver.errors['signed_waiver_form.electronic_consent'] = ['Electronic Consent agreement is required']
        sectionsWithErrors.push('#electronicConsent')

      signature = $scope.signature.accept()

      if signature.isEmpty
        $scope.waiver.signed_waiver_consent_request_attributes.errors['signature'] = ['Signature required']
        sectionsWithErrors.push('#signatureField')

      if sectionsWithErrors.length
        $document.scrollToElementAnimated(angular.element(sectionsWithErrors[0]))
        return

      MessageService.message(
        title: 'Approve proposed changes'
        message: 'By submitting this form, you agree to the proposed changes described on this page.'
        confirmButtonText: 'Approve'
      ).then () ->
        submit(signature)

    submit = (signature) ->
      $scope.isLoading = true
      $scope.waiver.signed_waiver_consent_request_attributes.errors = {}
      $scope.waiver.errors = {}
      params =
        signed_waiver_consent_request:
          electronic_consent_acceptance: $scope.waiver.signed_waiver_form_attributes.electronic_consent_acceptance
          signature: signature.dataUrl.replace /^data:image\/\w*;base64,/, ''
      loadingAnimation.show()

      url = "/websites/#{$stateParams.domain}/waivers/#{$stateParams.waiverName}/waiver_changes/#{$stateParams.waiverChangesId}/accept"
      $http.put(url, params).then () ->
        $scope.complete = true
        loadingAnimation.hide()
      , (response) ->
        flash.error = response.data.service_error_message
        $scope.isLoading = false
        loadingAnimation.hide()
