angular
  .module 'shared'
  .directive 'iconEdit', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 6.17157L18 10.1716M18.4142 4.58579L19.5858 5.75736C20.3668 6.53841 20.3668 7.80474 19.5858 8.58579L8 20.1716H4V16.1716L15.5858 4.58579C16.3668 3.80474 17.6332 3.80474 18.4142 4.58579Z" stroke="{{color || 'currentColor'}}" stroke-width="2" stroke-linecap="square"/></svg>
    '''
