angular.module 'shared'
  .factory 'PushNotificationValidationService', ($filter) ->
    clean: (content) ->
      cleanContent = @decodeHTML($filter('sanitizeEditorField')(content || ''))
      mergeTagPlaceholder = [0..9].join('')
      cleanContent.replace(/(<mark>.+?<\/mark>)/g, mergeTagPlaceholder)

    decodeHTML: (html) ->
      unless @txt
        @txt = document.createElement('textarea')
      @txt.innerHTML = html
      @txt.value

    getRemainingCharacters: (content) ->
      2000 - @clean(content).length

    isLengthValid: (content) ->
      @getRemainingCharacters(content) >= 0

    displayRemainingCharacters: (content) ->
      remaining = @getRemainingCharacters(content)
      charactersLabel = if remaining in [1, -1]
        'character'
      else
        'characters'

      if remaining < 0
        "Character limit exceeded: please remove #{-remaining} #{charactersLabel}."
