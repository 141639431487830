angular
  .module 'shared'
  .directive 'freemiumPlanSmsUnavailableMessage', () ->
    restrict: 'E'
    template: '''
      <div class="alert alert-danger is-sm alert--links-underscored">
        To start using SMSes, please
        <a ui-sref="gym.subscriptions" target="_self">add a subscription payment method here</a>.
        Once your payment details are confirmed, SMS usage will be billed on a monthly basis.
      </div>
    '''
