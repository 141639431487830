angular
.module 'gym.components'
.controller 'ManageDataSeriesMetricsCtrl', (
  $scope,
  $window,
  $modalInstance,
  DataSeries,
  metrics,
  meta
) ->
  Metrics = DataSeries.all('metrics')
  $scope.metrics = metrics
  $scope.labels = _.flatten(meta.map((item) ->
    item[1].map (unit) ->
      value: unit
      group: item[0]
  ), true)

  $scope.edit = (metric) ->
    $scope.editMetric = metric
    $scope.editMetric.enabled = true unless metric.enabled is false
    $scope.editMetric.label = '' unless metric.label

  $scope.edit({}) unless metrics.length

  reload = ->
    Metrics.getList(count: 100).then (metrics) ->
      $scope.metrics = metrics

  clearErrors = ->
    $scope.error = undefined
    $scope.editMetric?.errors = undefined

  $scope.ok = ->
    $modalInstance.close()

  $scope.isMetricReady = ->
    metric = $scope.editMetric
    metric.name

  $scope.save = ->
    clearErrors()
    save().then ->
      reload()
      $scope.editMetric = undefined
    , (response) ->
      $scope.editMetric.errors = response.data.errors

  save = ->
    clearErrors()
    if $scope.editMetric.id
      $scope.editMetric.save()
    else
      Metrics.post($scope.editMetric)

  $scope.cancel = ->
    $modalInstance.dismiss('cancel')

  $scope.cancelCreating = ->
    $scope.editMetric = undefined

  $scope.delete = (metric) ->
    if $window.confirm "Are you sure?"
      metric.remove().then ->
        reload()
      , (response) ->
        $scope.error = 'This metric is in use and cannot be deleted at this time.'
