const colors = {
  primary: '#f26f21',
  success: '#94bf1f',
  successV2: '#43b837',
  info: '#4ea1e0',
  danger: '#f43f5d',
  dangerV2: '#ed0000',
};

export const colorPickerColors = ['#F29A21', '#ED0000', '#6000AE', '#615FFF', '#2197F3', '#43B837'];

export default colors;
