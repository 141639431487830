angular.module 'shared'
  .factory 'SMSesSentThisMonthService', (
    $rootScope,
    $stateParams,
    $http,
  ) ->
    count = 0

    getCount: () -> count
    load: () ->
      gymId = $rootScope.gym?.id || $stateParams.gymId
      url = "/gyms/#{gymId}/sent_messages/smses_parts_this_month"
      $http.get(url).then (response) ->
        count = response.data.sms_parts_sent_this_month
