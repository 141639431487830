const rangeSelectionSaveRestore = (() => {
  const markerTextChar = '\ufeff';
  const selectionHasExtend = typeof window.getSelection().extend !== 'undefined';

  function gEBI(id, doc) {
    return (doc || document).getElementById(id);
  }

  function removeNode(node) {
    node.parentNode.removeChild(node);
  }

  function isDirectionBackward(dir) {
    return typeof dir == 'string' ? /^backward(?:s)?$/i.test(dir) : !!dir;
  }

  function isSelectionBackward(sel) {
    let backward = false;
    if (!sel.isCollapsed) {
      const range = document.createRange();
      range.setStart(sel.anchorNode, sel.anchorOffset);
      range.setEnd(sel.focusNode, sel.focusOffset);
      backward = range.collapsed;
    }
    return backward;
  }

  function selectRangeBackwards(sel, range) {
    if (selectionHasExtend) {
      const endRange = range.cloneRange();
      endRange.collapse(false);
      sel.removeAllRanges();
      sel.addRange(endRange);
      sel.extend(range.startContainer, range.startOffset);
      return true;
    } else {
      // Just select the range forwards
      sel.removeAllRanges();
      sel.addRange(range);
      return false;
    }
  }

  function insertRangeBoundaryMarker(range, atStart) {
    const markerId = 'selectionBoundary_' + +new Date() + '_' + ('' + Math.random()).slice(2);
    const doc = range.startContainer.ownerDocument;

    // Clone the Range and collapse to the appropriate boundary point
    const boundaryRange = range.cloneRange();
    boundaryRange.collapse(atStart);

    // Create the marker element containing a single invisible character using DOM methods and insert it
    const markerEl = doc.createElement('span');
    markerEl.id = markerId;
    markerEl.style.lineHeight = '0';
    markerEl.style.display = 'none';
    markerEl.textContent = markerTextChar;

    boundaryRange.insertNode(markerEl);
    return markerEl;
  }

  function setRangeBoundary(doc, range, markerId, atStart) {
    const markerEl = gEBI(markerId, doc);
    if (markerEl) {
      range[atStart ? 'setStartBefore' : 'setEndBefore'](markerEl);
      removeNode(markerEl);
    }
  }

  function compareRanges(r1, r2) {
    return r2.compareBoundaryPoints(r1.START_TO_START, r1);
  }

  function saveRange(range, direction) {
    let startEl, endEl;
    const doc = range.startContainer.ownerDocument;
    const text = range.toString();

    if (range.collapsed) {
      endEl = insertRangeBoundaryMarker(range, false);
      return {
        document: doc,
        markerId: endEl.id,
        collapsed: true,
      };
    } else {
      endEl = insertRangeBoundaryMarker(range, false);
      startEl = insertRangeBoundaryMarker(range, true);

      return {
        document: doc,
        startMarkerId: startEl.id,
        endMarkerId: endEl.id,
        collapsed: false,
        backward: isDirectionBackward(direction),
        toString: () => {
          return "original text: '" + text + "', new text: '" + range.toString() + "'";
        },
      };
    }
  }

  function restoreRange(rangeInfo) {
    const doc = rangeInfo.document;
    const range = doc.createRange(doc);
    if (rangeInfo.collapsed) {
      const markerEl = gEBI(rangeInfo.markerId, doc);
      if (markerEl) {
        markerEl.style.display = 'inline';
        const previousNode = markerEl.previousSibling;

        if (previousNode && previousNode.nodeType == 3) {
          removeNode(markerEl);
          range.setStart(previousNode, previousNode.length);
          range.collapse(true);
        } else {
          range.setEndBefore(markerEl);
          range.collapse(false);
          removeNode(markerEl);
        }
      }
    } else {
      setRangeBoundary(doc, range, rangeInfo.startMarkerId, true);
      setRangeBoundary(doc, range, rangeInfo.endMarkerId, false);
    }

    return range;
  }

  function saveRanges(ranges, direction) {
    // Order the ranges by position within the DOM, latest first, cloning the array to leave the original untouched
    ranges = ranges.slice(0);
    ranges.sort(compareRanges);
    const backward = isDirectionBackward(direction);

    const rangeInfos = ranges.map(function (range) {
      return saveRange(range, backward);
    });

    // Now that all the markers are in place and DOM manipulation is over, adjust each range's boundaries to lie
    // between its markers
    for (let i = ranges.length - 1, range, doc; i >= 0; --i) {
      range = ranges[i];
      doc = range.startContainer.ownerDocument;
      if (range.collapsed) {
        range.setStartAfter(gEBI(rangeInfos[i].markerId, doc));
        range.collapse(true);
      } else {
        range.setEndBefore(gEBI(rangeInfos[i].endMarkerId, doc));
        range.setStartAfter(gEBI(rangeInfos[i].startMarkerId, doc));
      }
    }

    return rangeInfos;
  }

  function saveSelection(win) {
    win = win || window;
    const sel = win.getSelection();
    const ranges = [];
    for (let i = 0; i < sel.rangeCount; ++i) {
      ranges.push(sel.getRangeAt(i));
    }
    const backward = ranges.length == 1 && isSelectionBackward(sel);
    const rangeInfos = saveRanges(ranges, backward);

    // Ensure current selection is unaffected
    sel.removeAllRanges();
    if (backward) {
      selectRangeBackwards(sel, ranges[0]);
    } else {
      ranges.forEach(function (range) {
        sel.addRange(range);
      });
    }

    return {
      win: win,
      rangeInfos: rangeInfos,
      restored: false,
    };
  }

  function restoreRanges(rangeInfos) {
    const ranges = [];

    // Ranges are in reverse order of appearance in the DOM. We want to restore earliest first to avoid
    // normalization affecting previously restored ranges.
    const rangeCount = rangeInfos.length;

    for (let i = rangeCount - 1; i >= 0; i--) {
      ranges[i] = restoreRange(rangeInfos[i]);
    }

    return ranges;
  }

  function restoreSelection(savedSelection, preserveDirection) {
    if (!savedSelection.restored) {
      const rangeInfos = savedSelection.rangeInfos;
      const sel = savedSelection.win.getSelection();
      const ranges = restoreRanges(rangeInfos);
      const rangeCount = rangeInfos.length;

      sel.removeAllRanges();
      if (rangeCount == 1 && preserveDirection && selectionHasExtend && rangeInfos[0].backward) {
        selectRangeBackwards(sel, ranges[0]);
      } else {
        ranges.forEach(function (range) {
          sel.addRange(range);
        });
      }

      savedSelection.restored = true;
    }
  }

  return {
    saveSelection: saveSelection,
    restoreSelection: restoreSelection,
  };
})();

export default rangeSelectionSaveRestore;
