angular
  .module 'shared'
  .directive 'iconRecurring', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m7.44 5.25 1.853 1.853M7.44 5.25l1.853-1.853M10.537 12.75l-1.845-1.852M10.537 12.75l-1.845 1.852M10.537 12.75H5.813c-2.07 0-3.75-1.68-3.75-3.75 0-2.07 1.68-3.75 3.75-3.75M7.44 5.25h4.747c2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.68 3.75-3.75 3.75" stroke="{{color || 'currentColor'}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
    '''
