angular
  .module 'shared'
  .factory 'FormFieldDisplayingService', ->
    isDisplaying: (contactType, field) ->
      return false if field.archived
      switch contactType
        when 'prospect' then field.on_prospect
        when 'member' then field.on_member
        when 'non_attending_contact' then field.on_non_attending_contact
        else false
