angular.module 'shared'
  .directive 'contactSearch', ->
    restrict: 'AE'
    scope: {
      onSelect: '='
      onRemove: '='
      allContacts: '='
      types: '='
      placeholder: '@'
      forBooking: '='
      excludeProspects: '='
      withPaymentMethod: '='
      displaySelected: '='
      selectedContacts: '='
      rollCallId: '='
      eventId: '='
      displaySearchIcon: '='
    }
    template: require('templates/shared/contact_search.html.slim')
    link: ($scope, element) ->
      element.bind 'keydown', $scope.onKeyDown
    controller: (
      $scope,
      $state,
      $stateParams,
      $timeout,
      $q,
      Restangular,
      ContactBadgeService
    ) ->
      $scope.selectedTags = if $scope.selectedContacts then $scope.selectedContacts else []
      $scope.params = {}

      $scope.isFocused = false

      $scope.setFocused = (val) ->
        $scope.isFocused = val
        unless val
          $timeout ->
            $scope.params.search = ''
          , 100

      Contacts = switch
        when $scope.forBooking
          Restangular.one('gyms', $stateParams.gymId).all('contacts').all('for_booking')
        when $scope.excludeProspects
          Restangular.one('gyms', $stateParams.gymId).all('contacts').all('exclude_prospects')
        when $scope.rollCallId
          Restangular.one('gyms', $stateParams.gymId).one('roll_call', $scope.rollCallId).all('contact_search')
        else
          Restangular.one('gyms', $stateParams.gymId).all('contacts')

      $scope.loadContacts = (str) ->
        deferred = $q.defer()
        params = switch
          when $scope.rollCallId
            contact_search: str
          when $scope.allContacts
            all_contacts_search: str
          else
            search: str

        if $scope.eventId
          params.event_id = $scope.eventId

        Contacts.getList(params).then (contacts) ->
          if $scope.types
            contacts = _.filter contacts, (contact) ->
              contact.type in $scope.types
          contacts.forEach (contact) ->
            contact.name = "#{contact.first_name} #{contact.last_name}"
            if $scope.rollCallId
              contact.id = contact.contact_id
              contact.name = contact.contact_name
              contact.type = contact.contact_type.toLowerCase()
              contact.status = contact.contact_status
          deferred.resolve(contacts)
        deferred.promise

      $scope.doSearch = _.debounce ((str) ->
        return unless str
        $scope.isLoading = true
        $scope.loadContacts(str).then (contacts) ->
          $scope.activeIndex = 0
          $scope.collection = contacts
          $scope.isLoading = false
      ), 500

      $scope.onKeyDown = (event) ->
        return unless $scope.isFocused
        switch event.which
          when 38
            if $scope.activeIndex > 0
              $scope.activeIndex = $scope.activeIndex - 1
              event.preventDefault()
          when 40
            if $scope.activeIndex < $scope.collection?.length - 1
              $scope.activeIndex = $scope.activeIndex + 1
              event.preventDefault()
          when 13
            if $scope.collection?[$scope.activeIndex]
              $scope.selectContact $scope.collection[$scope.activeIndex]
        $scope.$apply()

      $scope.getContactBadge = (type, status) ->
        ContactBadgeService.get(type, status)

      $scope.selectContact = (contact) ->
        $scope.params.search = ''
        $scope.onSelect(contact)

      $scope.onTagAdded = (contact) ->
        if $scope.selectedTags.length > 1
          $scope.selectedTags.splice(0, 1)
        $scope.onSelect(contact)

      $scope.onTagRemoved = () ->
        $scope.onRemove()

      $scope.onBlur = () ->
        $timeout () ->
          $scope.setFocused(false)
        , 300
