angular
  .module 'gym.components'
  .directive 'recentDataSeriesRecordings', ->
    restrict: 'E'
    template: require('templates/components/contacts/data_series/recent_data_series_recordings.html.slim')
    scope:
      contact: '='
      record: '&'
    controller: (
      $scope,
      $filter,
      $window,
      flash,
      loadingAnimation,
      EmploymentService,
    ) ->
      $scope.permissions = EmploymentService.get().permissions
      $scope.recordings = []

      $scope.parseMetricValue = (value) ->
        parseFloat value

      $scope.loadRecordings =  _.throttle ( ->
        if !$scope.isLoading and moreResultsAvailable()
          loadRecordings($scope.recordings.meta.page + 1)
      ), 500

      moreResultsAvailable = ->
        recordings = $scope.recordings
        recordings.length and recordings.length < recordings.meta.total

      loadRecordings = (page) ->
        $scope.isLoading = true
        $scope.contact.all('data_series_recordings').getList(count: 50, page: page)
          .then (recordings) ->
            if page > 1
              items = $scope.recordings.concat recordings
              result = _.uniq items, false, (i) -> i.id
              result.meta = recordings.meta
              $scope.recordings = result
            else
              $scope.recordings = recordings

            $scope.isLoading = false
      loadRecordings(1)

      $scope.edit = (recording) ->
        $scope.record(date: recording.recorded_on, editing: true)

      $scope.remove = (recording) ->
        data = $filter('date')(recording.recorded_on)
        message =
          "Are you sure? This will remove all recorded values on #{data} for
          #{$scope.contact.name}"
        if $window.confirm message
          recording.remove().then () ->
            loadRecordings(1)
          , () ->
            flash.error = "Request could not be completed. Please try again"
