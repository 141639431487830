angular
  .module 'gym.components'
  .directive 'trialReminderMessage', ->
    restrict: 'E'
    template: require('templates/components/trialReminderMessage.html.slim')
    scope:
      plan: '='
    controller: (
      $scope,
      $window,
      FreeTrialRemindMessageText
    ) ->

      $scope.freeTrialReminderMessage =
        FreeTrialRemindMessageText.getMessage($scope.plan)

      $scope.isPayor = window.currentUser.account_owner
