angular
  .module 'gym.components'
  .directive 'websiteCalendarNavigationButtons', ->
    restrict: 'E'
    template:
      '''
        <div class="calendar-navigation-buttons">
          <div class="btn-group" dropdown>
            <button class="btn fc-button fc-state-default dropdown-toggle" type="button">
              <span>Agenda</span>
              <i class="icon-chevron-down"></i>
            </button>
            <ul class="dropdown-menu is-user-area" role="menu">
              <li ng-class="{'active': currentView().name == 'basicDay'}">
                <a ng-click="setDailyAgenda()">Daily Agenda</a>
              </li>
              <li>
                <a ng-click="showWeeklyAgenda()">Weekly Agenda</a>
              </li>
              <li>
                <a ng-click="showFreeTrials()"><span ng-bind-html="glossary['free_trials_link']"></span></a>
              </li>
            </ul>
          </div>
        </div>
      '''
