angular.module 'gym.components'
  .controller 'CalendarFiltersModalCtrl', (
    $scope,
    $modalInstance,
    locations,
    instructors,
    eventTypes,
    styles,
    selectedLocations,
    selectedInstructors,
    selectedEventTypes,
    selectedStyles,
    unableToManageOtherContacts,
    disableShowAllInstructors,
    hideInstructor,
    multiSelect,
    eventFilters,
    eventFiltersMap,
    params
  ) ->
    $scope.locations = locations
    $scope.instructors = instructors
    $scope.eventTypes = eventTypes
    $scope.styles = styles
    $scope.eventFilters = eventFilters
    $scope.eventFiltersMap = eventFiltersMap
    $scope.params = params

    $scope.multiSelect = multiSelect
    $scope.unableToManageOtherContacts = unableToManageOtherContacts
    $scope.disableShowAllInstructors = disableShowAllInstructors
    $scope.hideInstructor = hideInstructor

    $scope.data =
      selectedLocations: selectedLocations.map (item) -> item.id
      selectedInstructors: selectedInstructors.map (item) -> item.id
      selectedEventTypes: selectedEventTypes.map (item) -> item.id
      selectedStyles: selectedStyles.map (item) -> item.id

    $scope.submit = ->
      data = {}
      data.general_filter = $scope.params.general_filter
      data.locations = locations.filter (item) -> item.id in $scope.data.selectedLocations
      data.eventTypes = eventTypes.filter (item) -> item.id in $scope.data.selectedEventTypes
      data.styles = styles.filter (item) -> item.id in $scope.data.selectedStyles
      if !hideInstructor && !unableToManageOtherContacts
        data.instructors = instructors.filter (item) -> item.id in $scope.data.selectedInstructors
      $modalInstance.close(data)

    $scope.cancel = ->
      $modalInstance.dismiss('cancel')
