angular
  .module 'gym.components'
  .directive 'subscriptionInvoices', ->
    restrict: 'E'
    template: require('templates/components/subscriptions/subscription_invoices.html.slim')
    scope:
      gym: '='
    controller: (
      $scope,
      $stateParams,
      $http,
      ngTableParams
    ) ->
      $scope.pageParams = {}
      $scope.gymId = $stateParams.gymId

      $scope.invoiceStatus = (invoice) ->
        switch
          when invoice.succeeded
            'Paid'
          when invoice.pending
            'Pending'
          when invoice.failed
            'Failed'

      $scope.tableParams = new ngTableParams
        page: 1
        count: 15
      ,
        counts: []
        total: 0
        getData: ($defer, $params) ->
          url = "/gyms/#{$stateParams.gymId}/gym_invoices"
          $scope.pageParams.isLoading = true
          $http.get(url, params: $params.url()).then (response) ->
            $scope.pageParams.isLoading = false
            $scope.pageParams.total = response.data.total
            $params.total response.data.total
            $defer.resolve response.data.collection
          , ->
            $scope.pageParams.isLoading = false
