import plural from 'helpers/plural'

angular
  .module 'gym.components'
  .directive 'enrolmentLimitSequenceCondition', ->
    restrict: 'EA'
    template: require('templates/components/sequences/enrolment_limit_sequence_condition.html.slim')
    scope:
      sequence: '='
      step: '='
    controller: ($scope, SequenceService) ->
      getCondition = ->
        $scope.condition =
          _.find $scope.step.sequence_conditions_attributes, (condition) ->
            condition.type is 'EnrolmentLimitSequenceCondition' && !condition._destroy
      getCondition()

      $scope.$watch 'sequence.trigger', (trigger, oldTrigger) ->
        if $scope.sequence.trigger not in $scope.sequence.enrolment_limit_triggers
          $scope.remove()

      $scope.getLabel = ->
        limitCountLabel = plural('times', $scope.condition.limit_count, true)
        if $scope.condition.limit_term_number
          termLabel = plural($scope.condition.limit_term_unit, $scope.condition.limit_term_number, $scope.condition.limit_term_number > 1)
        if $scope.condition.limit_to_current_month
          switch
            when $scope.condition.limit_count is 1
              "Contact has not enrolled in this sequence in the current calendar month"
            when $scope.condition.limit_count > 1
              "Contact has enrolled in this sequence fewer than #{limitCountLabel} in the current calendar month"
        else
          switch
            when $scope.condition.limit_count > 1 && !$scope.condition.limit_term_number
              "Contact has enrolled in this sequence fewer than #{limitCountLabel}"
            when $scope.condition.limit_count > 1 && $scope.condition.limit_term_number
              "Contact has enrolled in this sequence fewer than #{limitCountLabel} in the past #{termLabel}"
            when $scope.condition.limit_count is 1 && !$scope.condition.limit_term_number
              "Contact has not been enrolled in this sequence before"
            when $scope.condition.limit_count is 1 && $scope.condition.limit_term_number
              "Contact has not enrolled in this sequence in the past #{termLabel}"

      $scope.remove = ->
        SequenceService.removeEnrolmentLimitSequenceCondition($scope.step)
