angular
  .module 'filters'
  .filter 'replaceWaiverMergeTags', ->
    (content = '', contact, country, waiverDateValue) ->
      replaceContent = ->
        replacedContent = content
        replacedContent =
          if contact.first_name
            content.replace /<mark>{First Name}<\/mark>/g, contact.first_name
          else
            content
        if contact.last_name
          replacedContent =
            replacedContent.replace /<mark>{Last Name}<\/mark>/g, contact.last_name
        if contact.first_name || contact.last_name
          replacedContent =
            replacedContent.replace /<mark>{Full Name}<\/mark>/g, getContactFullName()
        if getContactAddress()
          replacedContent =
            replacedContent.replace /<mark>{Full Address}<\/mark>/g, getContactAddress()
        if waiverDateValue
          replacedContent =
            replacedContent.replace /<mark>{Current Date}<\/mark>/g, waiverDateValue
     
        replacedContent

      getContactFullName = ->
        _.compact([contact.first_name, contact.last_name]).join(' ')

      getContactAddress = ->
        _.compact([
          _.compact([contact.address_line_1, contact.address_line_2]).join(' '),
          contact.address_suburb,
          _.compact([
            contact.address_state,
            contact.address_postcode,
            country
          ]).join(' ')
        ]).join(', ')

      replaceContent()
