angular
  .module 'shared'
  .factory 'WorkoutService', (UnitSymbols) ->
    WorkoutService =
      getPersonalBest: (ex) ->
        return unless ex

        getWeightAndReps = () ->
          return null unless ex.reps || ex.weight
          reps = if ex.reps
            "#{ex.reps} Reps"
          weight = if ex.weight
            "#{parseFloat(ex.weight)}#{UnitSymbols[ex.weight_unit] || ''}"
          _.compact([reps, weight]).join(' ')

        getTimeOrDistance = () ->
          timePresence = ex.hours || ex.minutes || ex.seconds
          return unless ex.distance_number || timePresence
          time = moment().startOf('day')
            .hours(ex.hours || 0).minutes(ex.minutes || 0)
            .seconds(ex.seconds || 0).format('HH:mm:ss')
          distance = if ex.distance_number
            "#{ex.distance_number}#{UnitSymbols[ex.distance_unit]}"
          _.compact([distance, time]).join('/')

        switch ex.exercise_type
          when 'weight_and_reps'
            getWeightAndReps()
          when 'reps'
            ex.reps
          when 'time_or_distance'
            getTimeOrDistance()
          when 'weight_reps_time_distance'
            weight = getWeightAndReps()
            td = getTimeOrDistance()
            _.compact([weight, td]).join(' ')

    return WorkoutService
