angular
  .module 'gym.components'
  .directive 'contactsWidget', ->
    restrict: 'EA'
    template: require('templates/components/dashboard/contacts_widget.html.slim')
    scope:
      privacyModeEnabled: '='
      setLastRefreshedTimestamp: '&'
    controller: ($scope, $rootScope, Dashboard, Members, graphService) ->
      $scope.isLoading = true
      $scope.$on 'dashboard:refresh', () ->
        load()

      memberCountParams =
        lineColor: '#dfebba'
        selector: 'svg#members-chart'
        yFormat: '2d'
        xFormat: '%b'
        tooltipXFormat: '%d-%b'
        fillArea: true
        lineType: 'monotone'
        margin: {top: 10, left: 40, right: 25, bottom: 20}
        hideMaxMin: true

      parseGraphData = (data) ->
        data.map (item) ->
          {x: item.date, y: item.count}

      load = ->
        Dashboard.customGET('contacts').then (data) ->
          $scope.isLoading = false
          $scope.data = data
          $scope.setLastRefreshedTimestamp(timestamp: data.last_cache_refresh)
          membersData = parseGraphData(data.active_members_graph_data)
          graphService.setupGraph(membersData, memberCountParams)

      load()
