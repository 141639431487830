import plural from 'helpers/plural'

angular
  .module 'gym.components'
  .directive 'messageStatusNotice', ->
    restrict: 'E'
    scope:
      item: '='
    template: require('templates/components/contacts/comms/message_status_notice.html.slim')
    controller: ($scope, ReactModal) ->
      badges = $scope.item.data.badges
      $scope.openedBadge = badges?.find (i) -> i.type is 'opened'
      $scope.spamBadge = badges?.find (i) -> i.type is 'flagged_as_spam'
      if $scope.openedBadge
        $scope.timestamp = moment.utc($scope.openedBadge.timestamp).local().format('YYYY-MM-DD HH:mm')
        $scope.formattedTimestamp = moment.utc($scope.openedBadge.timestamp).local().format('DD-MMM-YYYY')

      $scope.openedTooltip =
        """
          <div class="long-content-tooltip-inner">
            Email has been opened #{plural('time', $scope.item.data?.open_count, true)}
          </div>
        """

      $scope.clickedTooltip =
        """
          <div class="long-content-tooltip-inner">
            Link(s) in this email have been clicked #{plural('time', $scope.item.data?.click_count, true)}
          </div>
        """

      $scope.displayActivitySummary = () ->
        ReactModal.open(
          component: 'BulkMessageActivityDrawer'
          drawer: true
          props:
            logEntry: $scope.item
        )
