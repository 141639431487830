import React from 'react';
import ReactLoadable from 'react-loadable';
import DrawerPlaceholder from './drawer_placeholder';
import ModalPlaceholder from './modal_placeholder';
import BlockPlaceholder from './block_placeholder';

interface LoadableProps {
  loader: () => Promise<any>;
  loading?: any;
  type?: 'modal' | 'drawer' | 'block';
}

const Loading = () => <div />;

const Loadable = ({ loader, loading, type }: LoadableProps) => {
  const placeholder = (() => {
    switch (type) {
      case 'drawer':
        return DrawerPlaceholder;
      case 'modal':
        return ModalPlaceholder;
      case 'block':
        return BlockPlaceholder;
      default:
        return loading || Loading;
    }
  })();

  return ReactLoadable({ loader, loading: placeholder });
};

export default Loadable;
