angular
  .module 'website'
  .directive 'websiteWeeklyAgenda', ->
    restrict: 'E'
    scope:
      showCalendar: '&'
      isIframe: '='
      isFreeTrials: '='
      hideNavigation: '='
    template: require('templates/website/calendar/website_weekly_agenda.html.slim')
    controller: (
      $scope,
      $state, # fixes empty $stateParams
      $stateParams,
      $window,
      $modal,
      $location,
      $filter,
      $q,
      $document,
      $timeout,
      DataService,
      CalendarService,
      Restangular,
      ContrastColor
    ) ->
      moment.updateLocale('en', {
        week: { dow : DataService.calendar_start_wday, }
      });

      domain = DataService.domain
      $scope.glossary = DataService.glossary
      $scope.date = moment()
      $scope.filtersMultiSelect = DataService.gym_config.website_calendar_filters is 'multi_select'
      $scope.showInstructor = DataService.gym_config.show_instructor_on_website_calendar
      $scope.showCapacity = DataService.gym_config.show_capacity_on_website_calendar
      $scope.bookingSuccessGtmId = DataService.gym_config.gtm_code_for_website_booking_success
      Website = Restangular.all('websites').all(domain)
      Classrooms = Website.all('classrooms')
      CalendarData = Website.all('calendar').all('data')

      parseIds = (val) ->
        return [] unless val
        if Array.isArray val
          val.map (item) -> +item
        else
          [+val]

      $scope.locations = [];
      $scope.instructors = DataService.instructors
      $scope.eventTypes = DataService.event_types
      $scope.selectedInstructors = []

      $scope.selectedInstructors = $scope.instructors.filter (type) ->
        id = type.id.toString()
        ids = $stateParams['instructor_id[]']
        id is $stateParams.instructor_id || (ids && id in ids)

      $scope.selectedEventTypes = $scope.eventTypes.filter (type) ->
        id = type.id.toString()
        ids = $stateParams['event_type_id[]']
        id is $stateParams.event_type_id || (ids && id in ids)

      getEvents = ->
        start = $scope.date.clone().startOf('week')
        end = $scope.date.clone().endOf('week')

        params =
          start: start.format('X')
          end: end.format('X')
          'location_id[]': $scope.selectedLocations.map (item) -> item.id
          'instructor_id[]': $scope.selectedInstructors.map (item) -> item.id
          'event_type_id[]': $scope.selectedEventTypes.map((item) -> item.id)
          prospect_spaces_available: $scope.isFreeTrials && 1

        $scope.displayOldEventsHiddenWarning =
          window.DATA?.old_event_display_threshold > start.format('X')

        $scope.oldEventDisplayThresholdDate =
          moment(new Date(window.DATA.old_event_display_threshold * 1000)).format('LL')

        $scope.title = "#{start.format('MMM D, YYYY')} - #{end.format('MMM D, YYYY')}"
        CalendarData.getList(params).then (events) ->
          $scope.eventsByDay = groupEvents(events.plain())
          $scope.eventsCount = events.length

      promises = [Classrooms.getList(), CalendarService.getEvent($stateParams.event_id)]
      $q.all(promises).then (result) ->
        $scope.locations = locations = result[0]
        event = result[1]

        defaultLocationIds = if parseIds($stateParams.location_id)?.length
          parseIds($stateParams.location_id)
        else if DataService.gym_config.default_calendar_location_id
          [DataService.gym_config.default_calendar_location_id]
        else []

        $scope.selectedLocations =
          _.filter locations, (location) -> location.id in defaultLocationIds
        if parseIds($stateParams.ex_location_id)?.length
          $scope.selectedLocations =
            _.filter locations, (location) -> location.id not in parseIds($stateParams.ex_location_id)

        if defaultLocationIds?.length && !$scope.selectedLocations?.length
          $scope.selectedLocations = [locations[0]]

        if event
          $scope.date = moment(event.start, 'YYYY/MM/DD HH:mm')
          $scope.onEventSelect(event)
        getEvents()

      $scope.goToCalendar = ->
        if $scope.isIframe
          $scope.showCalendar()
        else
          url = "/websites/#{domain}/calendar"
          $window.open url, '_self'
          true

      $scope.switchDay = (value) ->
        $scope.date = $scope.date.add(value, 'weeks')
        getEvents()

      $scope.today = ->
        $scope.date = moment()
        getEvents()

      $scope.isToday = ->
        moment().format('L') is $scope.date.format('L')

      $scope.onBookingSuccess = (eventParams) ->
        $scope.bookingComplete = true
        $timeout () ->
          return unless window.dataLayer
          window.dataLayer.push(eventParams)
        , 500

      $scope.onEventSelect = (event) ->
        $scope.bookingComplete = false
        $document.scrollTop(0)
        bookingModal = $modal.open
          template: require('templates/create_booking_modal.html')
          controller: 'BookingModalCtrl'
          resolve:
            event: ->
              selectedEvent = angular.extend({}, event)
              selectedEvent.start =
                moment(selectedEvent.start, 'YYYY/MM/DD HH:mm').toDate()
              selectedEvent
            isFreeTrials: -> $scope.isFreeTrials
            onBookingSuccess: -> $scope.onBookingSuccess

        bookingModal.result.then ->
          getEvents()

      groupEvents = (events) ->
        _.chain(events).map((event) ->
          event.date = moment(event.start, 'YYYY/MM/DD HH:mm').startOf('day').format('X')
          event
        ).groupBy('date').value()

      $scope.isFiltersAvailable = ->
        $scope.locations.length > 1 || $scope.instructors.length > 1 || $stateParams.event_type_id || $stateParams['event_type_id[]']

      $scope.onLocationsChange = (selectedLocations) ->
        $location.search 'location_id', selectedLocations.map((item) -> item.id)
        $location.search 'ex_location_id', []
        $scope.selectedLocations = selectedLocations
        getEvents()

      $scope.onInstructorsChange = (selectedInstructors) ->
        $scope.selectedInstructors = selectedInstructors
        getEvents()

      $scope.onEventTypesChange = (selectedEventTypes) ->
        $scope.selectedEventTypes = selectedEventTypes
        $location.search 'event_type_id', selectedEventTypes.map((item) -> item.id)
        getEvents()

      $scope.placesLeft = (event) ->
        if !event.old_event && $scope.isFreeTrials
          event.prospect_vacancies_count
        else if !event.old_event && !$scope.isFreeTrials
          event.vacancies_count
        else
          '-'

      $scope.fontColor = (color) ->
        ContrastColor.get(color)
