angular
  .module 'shared'
  .directive 'iconTrash', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.67 5.2H5.968V19.03c0 1.17.953 2.123 2.123 2.123h8.448c1.17 0 2.122-.953 2.122-2.123V5.199h.01ZM4 5.2h17M15.689 2H9.31v3.189h6.378V2ZM10.367 9.258v7.65M14.622 9.258v7.65" stroke="{{color || 'currentColor'}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg>
    '''
