angular
  .module 'gym.components'
  .directive 'trainerAvailability', () ->
    restrict: 'E'
    template: require('templates/components/profile/trainerAvailability.html.slim')
    scope:
      profile: '='
    controller: (
      $scope,
      $rootScope,
      $stateParams,
      $document,
      loadingAnimation,
      Availability,
      EditAvailabilityService,
      EmploymentService
    ) ->
      $scope.permissions = EmploymentService.get().permissions
      $scope.uiConfig =
        calendar:
          editable: false
          allDayDefault: false
          allDaySlot: false
          lazyFetching: false
          defaultView: 'agendaWeek'
          loading: (isLoading) ->
            if isLoading
              loadingAnimation.show()
            else
              loadingAnimation.hide()
          header: null
          firstDay: $rootScope.gym.gym_booking_detail.calendar_start_wday
          timeFormat:
            day: 'h:mmt{ - h:mmt}'
            basicDay: 'h:mmt{ - h:mmt}'
          eventAfterAllRender: (view) ->
            $scope.calendarView = view
          eventRender: (data, element) ->
            renderTrainerEvents(data, element)
          dayClick: (date, allDay, event, sourceView) ->
            return unless $scope.canManageAvailability()
            container = angular.element('.js-profile-container')[0]
            coords = container.getBoundingClientRect()
            $scope.calendarItemOptions =
              left: event.pageX - coords.x
              top: event.pageY - 130
              date: moment(date).format('YYYY-MM-DD')
              start_time_obj: date
              end_time_obj: moment(date).add(1, 'hours').toDate()
          eventClick: (data, event) ->
            return unless $scope.canManageAvailability()
            container = angular.element('.js-profile-container')[0]
            coords = container.getBoundingClientRect()
            $scope.calendarItemOptions =
              left: event.pageX - coords.x
              top: event.pageY - 145
              slot: data

      $scope.toDate = (date) ->
        $scope.calendarView.calendar.gotoDate(date.toDate())

      $scope.getTitle = ->
        title = $scope.calendarView?.title
        if title
          title.replace('&#8212;', '—')

      $scope.eventSources = [{events: (start, end, callback) ->
        $scope.startDate = moment(start)
        startDate = moment(start).subtract(1, 'days').format('YYYY-MM-DD')
        endDate = moment(end).add(1, 'days').format('YYYY-MM-DD')
        params =
          'filters[user_id]': $stateParams.userId || window.currentUser.id
          'filters[include_events]': true
          'filters[start_date]': startDate
          'filters[end_date]': endDate
          'count': 1000
          '_': new Date().getTime()
        Availability.getList(params).then (data) ->
          collection = data.plain().map (item) ->
            item.id = item.trainer_availability_block_id
            item.start = item.start_at
            item.start_time_obj = moment(item.start_time, 'Hmm').toDate()
            item.end = item.end_at
            item.end_time_obj = moment(item.end_time, 'Hmm').toDate()
            item.title = item.location_name
            item
          $scope.availabilityCollection = collection
          callback collection
      }]

      renderTrainerEvents = (data, element) ->
        availabilityContainer = $(element).find('.fc-event-inner')
        $(element).addClass("available-time")
        trainerEvents =
          '<ul class="trainer-bookings">' +
            data.events_with_bookings.map((event) ->
              time = moment(event.start_at, 'YYYY/MM/DD HH:mm').format('HH:mm')
              "<li class='trainer-bookings__item'>#{time} #{event.name}</li>"
            ).join('') +
          '</ul>'
        availabilityContainer.append(trainerEvents)

      $scope.addNewAvailability = (isRecurring) ->
        date = $scope.calendarItemOptions.date
        newAvailability = EditAvailabilityService.getNewAvailabilityBlock(
          $scope.calendarItemOptions.start_time_obj,
          $scope.calendarItemOptions.end_time_obj
        )
        availabilityBlocks =
          [newAvailability].concat(getAvailabilityBlocks(date))
        createAvailability(isRecurring, date, availabilityBlocks)

      $scope.editAvailability = (isRecurring) ->
        date = $scope.calendarItemOptions.slot.availability_date
        availabilityBlocks = getAvailabilityBlocks(date)
        createAvailability(isRecurring, date, availabilityBlocks)

      $scope.deleteAvailability = (isRecurring) ->
        if window.confirm "Are you sure you want to delete this availability?"
          slot = $scope.calendarItemOptions.slot
          $scope.calendarItemOptions = undefined
          blocks = getAvailabilityBlocks(slot.availability_date)
          EditAvailabilityService
            .removeAvailability(isRecurring, slot, blocks).then ->
              $scope.calendarView.calendar.refetchEvents()

      createAvailability = (isRecurring, date, availabilityBlocks) ->
        $document.scrollTop(0)
        editType = if isRecurring then 'recurring' else 'single'
        $scope.calendarItemOptions = undefined
        $scope.trainerAvailability =
          user_id: $stateParams.userId || window.currentUser.id,
          current_date: date,
          edit_type: editType,
          trainer_availability_blocks: availabilityBlocks

      getAvailabilityBlocks = (date) ->
        _.where $scope.availabilityCollection, availability_date: date

      $scope.showAvailability = ->
        $document.scrollTop(0)
        $scope.trainerAvailability = undefined

      $scope.isCurrentUser = ->
        $scope.profile.id is window.currentUser.id

      $scope.canManageAvailability = ->
        $scope.isCurrentUser() || $scope.permissions.manage_other_trainers_availability
