angular
  .module 'gym.components'
  .directive 'whatsOnTodayWidget', ->
    restrict: 'EA'
    template: require('templates/components/dashboard/whats_on_today_widget.html.slim')
    scope:
      privacyModeEnabled: '='
    controller: (
      $scope,
      $rootScope,
      $q,
      Dashboard,
      Calendar,
      loadingAnimation,
      EventService
    ) ->
      gym = $rootScope.gym
      $scope.isLoading = true
      $scope.count = 5

      load = () ->
        Dashboard.all('whats_on_today').getList(count: $scope.count).then (data) ->
          $scope.isLoading = false
          loadingAnimation.hide()
          $scope.data = data
          $scope.isMoreAvailable = data.meta.total > data.length
      load()

      $scope.loadMore = (count) ->
        $scope.count = $scope.count + count
        loadingAnimation.show()
        load()

      $scope.freeClassTooltipContent =
        '''
        <div class="long-content-tooltip-inner">
          This event does not require an active membership to book, and prospects and cancelled members may attend.
          <br/>
          Bookings made against this event will not count against membership class limits.
        </div>
        '''

      $scope.freeBookingTooltipContent =
        '''
        <div class="long-content-tooltip-inner">
          Free bookings do not count against membership class limits.
        </div>
        '''

      loadEventDetails = (event) ->
        $q.all([Calendar.one('events', event.id).get(), EventService.getBookings(event.id)]).then (response) ->
          event.waitlist = response[0].waitlist_attendees.collection
          event.bookings = response[1]

      $scope.toggleAttendees = (event) ->
        if event.attendeesVisible
          event.attendeesVisible = false
        else
          event.attendeesVisible = true
          event.waitlistVisible = false
          loadEventDetails(event)

      $scope.toggleWaitlist = (event) ->
        if event.waitlistVisible
          event.waitlistVisible = false
        else
          event.attendeesVisible = false
          event.waitlistVisible = true
          loadEventDetails(event)

      $scope.viewEvent = (event) ->
        EventService.showAttendees(event, showCalendarLink: true)
