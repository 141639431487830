import humanize from 'helpers/humanize'
import random from 'helpers/random'

angular.module 'gym.components'
  .controller 'ManageFilterConditionsCtrl', (
    $scope,
    $modalInstance,
    $filter,
    report,
    reports,
    filter,
    inReportOptions,
    inReportFilterAvailable,
    displayInReportFilterDescription
  ) ->
    $scope.report = report
    $scope.reports = reports
    $scope.filter = filter
    $scope.displayInReportFilterDescription = displayInReportFilterDescription
    $scope.filter.join_filters_with ||= 'join_filters_with_and'

    $scope.relativeDateId = random()

    $scope.periods = [
      'days'
      'weeks'
      'months'
      'years'
    ]

    $scope.multipliers = ['from_now', 'ago']

    $scope.displayAddConditionForm = ->
      $scope.newFilter = {}

    $scope.displaySectionName = (section) ->
      $scope.report.column_section_name_map[section] || humanize(section)

    $scope.cancelNewFilter = ->
      $scope.confirmChangesMessageVisible = false
      $scope.newFilter = undefined

    $scope.deleteSubFilter = (index) ->
      filter = $scope.filter.report_sub_filters_attributes[index]
      if filter.id
        filter._destroy = 1
      else
        $scope.filter.report_sub_filters_attributes.splice index, 1

    $scope.hasSubFilters = ->
      _.find $scope.filter.report_sub_filters_attributes, (filter) -> !filter._destroy

    $scope.addFilter = ->
      filter = $scope.newFilter
      $scope.filter.report_sub_filters_attributes ||= []
      report_filter =
        if filter.column.inReport
          operator: filter.column.operator
          operator_label: humanize(filter.column.operator)
          user_value: filter.user_value
          database_value_label: 'Contact'
          database_value: 'contacts:id'
        else
          report_filter_id: filter.id
          database_value:
            filter.column.form_field_id ||
            filter.column.table_and_column ||
            filter.column.unique_id
          database_value_label: filter.column.label
          data_type: filter.column.data_type
          operator: filter.condition[1]
          operator_label: filter.condition[0]
          user_value: filter.user_value
          relative_date: filter.relative_date
          multiplier: filter.multiplier
          period: filter.period
          number: filter.number
      $scope.filter.report_sub_filters_attributes.push report_filter
      $scope.newFilter = undefined
      $scope.confirmChangesMessageVisible = false

    $scope.joinFilterLabel = ->
      if $scope.filter.join_filters_with is 'join_filters_with_or' then 'OR' else 'AND'

    $scope.addCondition = ->
      if !$scope.filter.report_sub_filters_attributes
        $scope.filter.report_sub_filters_attributes = []
      $scope.filter.report_sub_filters_attributes.push {}

    $scope.availableColumns = ->
      columns = $scope.report.report_columns_options?[$scope.newFilter?.section]
      forbiddenForFiltration = ['Contact ID']
      available =  _.filter columns, (column) ->
        column.label not in forbiddenForFiltration
      availableColumns = _.map available, (val) -> val
      if inReportFilterAvailable && $scope.newFilter?.section is 'contact'
        inReportOptions.concat(availableColumns)
      else
        availableColumns

    $scope.onColumnChanged = ->
      $scope.newFilter.condition = ''
      $scope.newFilter.relative_date = false

    $scope.onConditionChanged = ->
      $scope.newFilter.user_value = ''
      $scope.newFilter.number = ''
      $scope.newFilter.relative_date = false
      $scope.newFilter.multiplier = ''
      $scope.newFilter.period = ''

    $scope.onRelativeChange = ->
      if $scope.newFilter.relative_date
        $scope.newFilter.user_value = ''
        $scope.newFilter.relative_date ||= false
        $scope.newFilter.multiplier ||= $scope.multipliers[0]
        $scope.newFilter.period = 'months'

    $scope.requireUserValue = ->
      blankValueConditions = ['Is blank', 'Is not blank']
      $scope.newFilter?.condition?[0] not in blankValueConditions

    relativeDateReady = ->
      filter = $scope.newFilter
      filter.relative_date &&
        (($filter('filterInt')(filter.number) >= 0) && filter.period && filter.multiplier)

    userValueReady = ->
      !$scope.requireUserValue() || $scope.newFilter.user_value?.toString() || relativeDateReady()

    $scope.isFilterReady = ->
      if $scope.newFilter?.column?.inReport
        userValueReady()
      else
        $scope.newFilter?.column && $scope.newFilter.condition && userValueReady()

    $scope.submit = ->
      if $scope.newFilter
        $scope.confirmChangesMessageVisible = true
        return
      $modalInstance.close($scope.filter)

    $scope.cancel = ->
      $modalInstance.dismiss('cancel')
