# coffeelint: disable
angular
  .module 'shared'
  .directive 'scrollableTabs', ($timeout) ->
    restrict: 'E'
    template: require('templates/components/shared/scrollable_tabs.html.slim')
    transclude: true,
    link: ->
      $timeout ->
        SETTINGS =
          navBarTravelling: false
          navBarTravelDirection: ''
          navBarTravelDistance: 150

        pnAdvancerLeft = document.getElementById('pnAdvancerLeft')
        pnAdvancerRight = document.getElementById('pnAdvancerRight')
        scrollableTabs = document.getElementById('scrollableTabs')
        scrollableTabsContents = document.getElementById('scrollableTabsContents')

        doSomething = (scroll_pos) ->
          scrollableTabs.setAttribute 'data-overflowing', determineOverflow(scrollableTabsContents, scrollableTabs)

        determineOverflow = (content, container) ->
          containerMetrics = container.getBoundingClientRect()
          containerMetricsRight = Math.floor(containerMetrics.right)
          containerMetricsLeft = Math.floor(containerMetrics.left)
          contentMetrics = content.getBoundingClientRect()
          contentMetricsRight = Math.floor(contentMetrics.right)
          contentMetricsLeft = Math.floor(contentMetrics.left)
          if containerMetricsLeft > contentMetricsLeft and containerMetricsRight < contentMetricsRight
            'both'
          else if contentMetricsLeft < containerMetricsLeft
            'left'
          else if contentMetricsRight > containerMetricsRight
            'right'
          else
            'none'

        scrollableTabs.setAttribute 'data-overflowing', determineOverflow(scrollableTabsContents, scrollableTabs)

        last_known_scroll_position = 0
        ticking = false
        scrollableTabs.addEventListener 'scroll', ->
          last_known_scroll_position = window.scrollY
          if !ticking
            window.requestAnimationFrame ->
              doSomething last_known_scroll_position
              ticking = false
              return
          ticking = true
        pnAdvancerLeft.addEventListener 'click', ->
          if SETTINGS.navBarTravelling == true
            return
          if determineOverflow(scrollableTabsContents, scrollableTabs) == 'left' or determineOverflow(scrollableTabsContents, scrollableTabs) == 'both'
            availableScrollLeft = scrollableTabs.scrollLeft
            if availableScrollLeft < SETTINGS.navBarTravelDistance * 2
              scrollableTabsContents.style.transform = 'translateX(' + availableScrollLeft + 'px)'
            else
              scrollableTabsContents.style.transform = 'translateX(' + SETTINGS.navBarTravelDistance + 'px)'
            scrollableTabsContents.classList.remove 'scrollableTabs_Contents-no-transition'
            SETTINGS.navBarTravelDirection = 'left'
            SETTINGS.navBarTravelling = true
          scrollableTabs.setAttribute 'data-overflowing', determineOverflow(scrollableTabsContents, scrollableTabs)
        pnAdvancerRight.addEventListener 'click', ->
          if SETTINGS.navBarTravelling == true
            return
          if determineOverflow(scrollableTabsContents, scrollableTabs) == 'right' or determineOverflow(scrollableTabsContents, scrollableTabs) == 'both'
            navBarRightEdge = scrollableTabsContents.getBoundingClientRect().right
            navBarScrollerRightEdge = scrollableTabs.getBoundingClientRect().right
            availableScrollRight = Math.floor(navBarRightEdge - navBarScrollerRightEdge)
            if availableScrollRight < SETTINGS.navBarTravelDistance * 2
              scrollableTabsContents.style.transform = 'translateX(-' + availableScrollRight + 'px)'
            else
              scrollableTabsContents.style.transform = 'translateX(-' + SETTINGS.navBarTravelDistance + 'px)'
            scrollableTabsContents.classList.remove 'scrollableTabs_Contents-no-transition'
            SETTINGS.navBarTravelDirection = 'right'
            SETTINGS.navBarTravelling = true
          scrollableTabs.setAttribute 'data-overflowing', determineOverflow(scrollableTabsContents, scrollableTabs)
        scrollableTabsContents.addEventListener 'transitionend', (->
          styleOfTransform = window.getComputedStyle(scrollableTabsContents, null)
          tr = styleOfTransform.getPropertyValue('-webkit-transform') or styleOfTransform.getPropertyValue('transform')
          amount = Math.abs(parseInt(tr.split(',')[4]) or 0)
          scrollableTabsContents.style.transform = 'none'
          scrollableTabsContents.classList.add 'scrollableTabs_Contents-no-transition'

          if SETTINGS.navBarTravelDirection == 'left'
            scrollableTabs.scrollLeft = scrollableTabs.scrollLeft - amount
          else
            scrollableTabs.scrollLeft = scrollableTabs.scrollLeft + amount
          SETTINGS.navBarTravelling = false
        ), false

        ((root, factory) ->
          factory root.dragscroll = {}
        ) this, (exports) ->
          _window = window
          _document = document
          mousemove = 'mousemove'
          mouseup = 'mouseup'
          mousedown = 'mousedown'
          EventListener = 'EventListener'
          addEventListener = 'add' + EventListener
          removeEventListener = 'remove' + EventListener
          newScrollX = undefined
          newScrollY = undefined
          dragged = []

          reset = (i, el) ->
            i = 0
            while i < dragged.length
              el = dragged[i++]
              el = el.container or el
              el[removeEventListener] mousedown, el.md, 0
              _window[removeEventListener] mouseup, el.mu, 0
              _window[removeEventListener] mousemove, el.mm, 0

            dragged = [].slice.call(_document.getElementsByClassName('dragscroll'))
            i = 0
            while i < dragged.length
              ((el, lastClientX, lastClientY, pushed, scroller, cont) ->
                (cont = el.container or el)[addEventListener] mousedown,
                cont.md = (e) ->
                  if (!el.hasAttribute('nochilddrag') or _document.elementFromPoint(e.pageX, e.pageY) == cont)
                    pushed = 1
                    lastClientX = e.clientX
                    lastClientY = e.clientY
                    e.preventDefault()
                , 0
                _window[addEventListener] mouseup,
                cont.mu = ->
                  pushed = 0
                  return

                , 0
                _window[addEventListener] mousemove,
                cont.mm = (e) ->
                  if pushed
                    (scroller = el.scroller or el).scrollLeft -= newScrollX = -lastClientX + (lastClientX = e.clientX)
                    scroller.scrollTop -= newScrollY = -lastClientY + (lastClientY = e.clientY)
                    if el == _document.body
                      (scroller = _document.documentElement).scrollLeft -= newScrollX
                      scroller.scrollTop -= newScrollY
                  return

                , 0
                return
              ) dragged[i++]
            return

          if _document.readyState == 'complete'
            reset()
          else
            _window[addEventListener] 'load', reset, 0
          exports.reset = reset
# coffeelint: enable
