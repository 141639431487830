import dasherize from 'underscore.string/dasherize';

angular
  .module 'shared'
  .directive 'logIcon', () ->
    restrict: 'E'
    scope:
      metaCategory: '='
      group: '='
    template: '''
      <div class="log-type" ng-class="activityIconClass()"></div>
    '''
    controller: ($scope) ->
      $scope.activityIconClass = ->
        category = dasherize $scope.metaCategory
        group = "activity--#{$scope.group.split('_')[0]}"
        [category, group]
