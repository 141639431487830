import humanize from 'helpers/humanize'

angular
  .module 'gym.components'
  .directive 'reportFilter', ->
    restrict: 'AE'
    template: require('templates/components/reports/report_filter.html.slim')
    replace: true
    scope:
      report: '='
      reports: '='
      aggregate: '='
      index: '='
      editable: '='
      onEdit: '&'
    controller: ($scope, $filter, Months) ->
      $scope.filter = filter = $scope.aggregate || $scope.report?.report_filters_attributes[$scope.index]

      $scope.getTooltip = (filter) ->
        return unless $scope.report
        if filter.operator in ['IN REPORT', 'NOT IN REPORT']
          selectedReport = _.find $scope.reports, (item) -> item.id is +filter.user_value
          if selectedReport
            return "Contact > #{filter.operator_label} #{selectedReport.name}"
          else
            return "Contact > #{filter.operator_label.replace(' report', '')} [report deleted]"
        columnLabel = filter.database_value_label
        section = _.findKey $scope.report.report_columns_options, (columns) ->
          _.find columns, (column) -> column.unique_id is filter.database_value
        value =
          if filter.relative_date
            humanize("#{filter.number} #{filter.period} #{filter.multiplier}")
          else
            filter.user_value
        columnSection = $scope.report.column_section_name_map[section]
        "#{columnSection || humanize(section)} > #{columnLabel} #{filter.operator_label} #{value}"
      $scope.tooltip = $scope.getTooltip($scope.filter)

      if $scope.filter.report_sub_filters_attributes
        $scope.subFilterTooltips = $scope.filter.report_sub_filters_attributes.map $scope.getTooltip

      $scope.deleteFilter = ->
        if filter.id
          filter._destroy = 1
        else
          $scope.report.report_filters_attributes.splice $scope.index, 1

      $scope.joinFilterLabel = ->
        if filter.join_filters_with is 'join_filters_with_or' then 'OR' else 'AND'
