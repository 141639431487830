angular
  .module 'filters'
  .filter 'waiverStatus', ($filter) ->
    (waiver) ->
      switch
        when waiver.prefill_link_expired
          'Link Expired'
        when waiver.status is 'prefilled'
          'Not Signed'
        when waiver.review_status is 'Pending Review'
          'Pending Review'
        else
          'Signed'
