angular
  .module 'shared'
  .directive 'calendarFilter', ($window) ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      dropdownToggle = element[0].querySelector('.dropdown-toggle')
      dropdownMenu = element[0].querySelector('.dropdown-menu')

      adjustDropdownMenuHeight = ->
        dropdownToggleRect = dropdownToggle.getBoundingClientRect()
        dropdownMenuRect = dropdownMenu.getBoundingClientRect()

        dropdownToggleHeight = dropdownToggleRect.bottom - dropdownToggleRect.top
        windowHeight = $window.innerHeight

        dropdownMenuHeight = windowHeight - dropdownToggleHeight - dropdownToggleRect.top
        dropdownMenu.style.maxHeight = dropdownMenuHeight + 'px'


      dropdownToggle.addEventListener('click', adjustDropdownMenuHeight)

      scope.$on '$destroy', () ->
        dropdownToggle.removeEventListener('click', adjustDropdownMenuHeight)
