angular
  .module 'gym.components'
  .directive 'contactImageUploader', ($timeout, flash) ->
    restrict: 'EA'
    template: require('templates/components/contactImageUploader.html.slim')
    scope:
      contact: '='
    link: ($scope) ->
      $scope.id = Math.floor(Math.random() * 10000)

      $scope.selectImage = ->
        angular.element("#contact-image-uploader-#{$scope.id}").click()
        return true

      if not $scope.imageData and $scope.contact.image
        $scope.imageData =
          imagePreview: "data:image/png;base64,#{$scope.contact.image}"

      $scope.$watch 'imageData.file', (file) ->
        if file?.size
          if file.size > 5000000
            flash.error = 'Image size must be 5MB or lower'
            return
          dataUrlReader = new window.FileReader()
          dataUrlReader.readAsDataURL file
          dataUrlReader.onloadend = ->
            $timeout ->
              $scope.imageData.imagePreview = dataUrlReader.result
              $scope.contact.image =
                dataUrlReader.result.replace /^data:image\/\w*;base64,/, ''
