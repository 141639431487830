import plural from 'helpers/plural'

angular
  .module 'filters'
  .filter 'membershipAccessMessageFilter', ($filter) ->
    (plan) ->
      classString = plural('classes', plan.classes_limit, true)
      intervalUnitString =
        plural(plan.classes_interval_unit, plan.classes_interval_number)

      intervalNumberString =
        if plan.classes_interval_number > 1
          plan.classes_interval_number
        else ''

      intervalString =
        if plan.classes_unlimited || !plan.classes_interval_number || !plan.classes_interval_unit
          ''
        else
          "every #{intervalNumberString} #{intervalUnitString}"

      termString =
        if plan.term_number
          termUnit =  plural(plan.term_unit, plan.term_number)
          "- you'll have #{plan.term_number} #{termUnit} to use them!"
        else
          "for the life of the membership!"

      switch
        when plan.classes_unlimited
          "Access to unlimited classes #{termString}"
        when plan.classes_limit
          "Access to #{classString} #{intervalString} #{termString}"
        else
          ''
