angular
  .module 'shared'
  .factory 'ConfirmLeaveUnsavedChangesService', ($rootScope, $window) ->
    watch: ($scope, value, params) ->
      timestamps =
        init: moment()
      $scope.$watch value, (val, prev) ->
        if !$scope.ignoreChangeCheck && !_.isEqual(val, prev)
          $scope.displayConfirmationOnClose = true
          $rootScope.stateChangeConfirmMessage = "Are you sure? This will abandon any unsaved changes."
          $window.onbeforeunload = (e) ->
            e.returnValue = $rootScope.stateChangeConfirmMessage
            $rootScope.stateChangeConfirmMessage
        else
          $scope.displayConfirmationOnClose = false
          $rootScope.stateChangeConfirmMessage = undefined
          $window.onbeforeunload = undefined
      , true

      unsubscribeStateChangeStart = $rootScope.$on '$stateChangeStart', () ->
        timestamps.changeStart = moment()

      unsubscribeStateChangeEnd = $rootScope.$on '$stateChangeSuccess', () ->
        if !params?.closeModal || !timestamps.changeStart || timestamps.changeStart.diff(timestamps.init, 'seconds', true) < 0.3
          return
        params?.closeModal()

      removePageLeaveListeners = ->
        $rootScope.stateChangeConfirmMessage = undefined
        $window.onbeforeunload = undefined

      $scope.$on '$destroy', () ->
        unsubscribeStateChangeStart?()
        unsubscribeStateChangeEnd()
        removePageLeaveListeners()

      removePageLeaveListeners
