angular.module 'website'
  .controller 'website.newProspectCtrl', (
    $scope,
    $http,
    $timeout,
    FormFieldDisplayingService,
    DataService
  ) ->

    $scope.gym =
      currency_symbol: DataService.currency
      units: DataService.units

    $scope.pixelId = DataService.gym_config.facebook_pixel_id_for_contact_us_page
    $scope.pixelEvent = DataService.gym_config.facebook_pixel_event_for_contact_us_page
    $scope.gtmId = DataService.gym_config.gtm_code_for_contact_us_page
    $scope.recaptchaKey = DataService.recaptcha_site_key

    $scope.contactType = 'prospect'
    $scope.showFormField = (field) ->
      FormFieldDisplayingService.isDisplaying($scope.contactType, field)

    $http.get("/gyms/#{DataService.gym_id}/privacy_policies?filters[policy_context]=gym_website").then (response) ->
      $scope.privacyPolicies = response.data.collection
      $scope.editContact.privacy_policy_responses_attributes =
        if $scope.privacyPolicies
          $scope.privacyPolicies.map (item) ->
            privacy_policy_id: item.id, accepted: false
        else
          []
      setLinksTarget()

    setLinksTarget = ->
      $timeout ->
        angular.element('.form-group a').each (i, element) ->
          element.target = '_blank'

    $scope.editContact =
      contact_information: {}

    clearErrors = ->
      _.each $scope.forms, (form) -> $scope.editContact[form.name] = { errors: {} }

    loadForms = ->
      url = "/gyms/#{DataService.gym_id}/forms?contact_type=prospects"
      $http.get(url).then (response) ->
        $scope.forms = _.filter response.data, (form) ->
          form.show_on_new_record && form.on_website && !form.archived
        clearErrors()
    loadForms()

    $scope.isFormInvalid = ->
      _.find $scope.forms, (form) ->
        form.on_website && _.find form.fields, (field) ->
          isRequired = if field.customizable_required_settings
            field.required_on_website
          else
            field.required
          field.on_prospect && field.on_website && isRequired &&
            !field.archived && !$scope.editContact[form.name][field.name]

    $scope.submitForm = ->
      $scope.errorMessage = ""
      $scope.saveVisible = false
      if $scope.recaptchaKey
        $scope.$emit 'recaptcha:execute'
      else
        $scope.save()

    $scope.onRecaptchaError = () ->
      $scope.errorMessage = "Sorry, there seems to be an issue with the reCAPTCHA service. Please try to submit your request again."
      $scope.saveVisible = true

    $scope.saveVisible = true
    $scope.isSaved = false
    $scope.save = (token) ->
      params =
        contact: $scope.editContact,
        recaptcha_token: token

      $http.post("/websites/#{DataService.domain}/prospects", params).then (response) ->
        clearErrors()
        $scope.isSaved = true
        $timeout ->
          if window.dataLayer
            window.dataLayer.push
              event: 'formSubmit'
        , 500
      , (response) ->
        $scope.editContact.errors = response.data.errors
        $scope.errorMessage = "Please ensure that your email is correct and that you've filled out the required fields"
        $scope.saveVisible = true
