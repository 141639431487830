angular.module 'website'
  .controller 'website.newWaiverCtrl', (
    $scope,
    $rootScope,
    $stateParams,
    $location,
    $http,
    $document,
    $q,
    $filter,
    $timeout,
    WaiverValidationService,
    CreditCardValidationService,
    WaiverService,
    waiverPath,
    DataService,
    loadingAnimation,
    flash,
    waiver
  ) ->
    $scope.pageParams = {}
    $scope.signature = {}
    $scope.witnessSignature = {}
    $scope.walletDetails = {}
    $scope.waiver = if waiver.plain then waiver.plain() else waiver
    $scope.waiver.rootObjectName = 'signed_waiver'
    $scope.waiver.withoutNamespace = true
    $scope.currency = DataService.currency
    $scope.country = DataService.gym_country
    $scope.waiver.signed_as = ''
    $scope.gymDomain = DataService.domain
    $scope.gymTz = DataService.gym_tz
    $scope.gymName = DataService.gym_name
    $scope.gymConfig = DataService.gym_config
    $scope.isClickAndPay = !!$stateParams.click_and_pay_plan && DataService.member_portal_enabled
    $scope.pixelId = DataService.gym_config.facebook_pixel_id_for_signed_waiver_page
    $scope.pixelEvent = DataService.gym_config.facebook_pixel_event_for_signed_waiver_page
    $scope.gtmId = DataService.gym_config.gtm_code_for_signed_waiver_page

    $scope.isBankAccountProviderAvailable =
      WaiverService.isBankAccountProviderAvailable
    $scope.isCreditCardProviderAvailable =
      WaiverService.isCreditCardProviderAvailable

    WaiverService.getEzidebitFees($scope.waiver, DataService.gym_id).then (fees) ->
      $scope.ezidebitFees = fees

    $scope.$watch 'waiver.signed_waiver_contacts_attributes[0]', WaiverService.syncContactFields($scope.waiver), true

    checkBypassFamilySelection = ->
      switch
        when $scope.waiver.allow_signing_by is 'adult'
          $scope.signAs('signed_as_adult')
        when $scope.isPrefilledNac()
          $scope.signAs('signed_as_adult_with_family')
        when $scope.gymConfig.bypass_family_waiver_selection
          $scope.signAs('signed_as_adult')
        when $scope.waiver.allow_signing_by is 'adult_with_family'
          $scope.signAs('signed_as_adult_with_family')

    getPreselectedMembership = () ->
      if $scope.isClickAndPay
        clickAndPayId = parseInt($stateParams.click_and_pay_plan, 10)

      WaiverService.getPreselectedMembership($scope.waiver, clickAndPayId: clickAndPayId)

    preselectMembershipPlan = ->
      waiverForm = $scope.waiver.signed_waiver_form_attributes
      plans = waiverForm.membership_plan_options
      return unless waiverForm.membership_options_active && plans.length
      preselectedMembership = getPreselectedMembership()
      $scope.waiver.signed_waiver_contacts_attributes.forEach (c) ->
        c.membership_plan_id = preselectedMembership?.id

    setLinksTarget = ->
      $timeout ->
        angular.element('.panel-body a').each (i, element) ->
          element.target = '_blank'

    createGDPRData = ->
      createPolicyResponse = (policy) ->
        angular.extend {}, policy, {
          id: undefined
          privacy_policy_id: policy.id
          accepted: false
          policy_heading: policy.derived_heading
          policy_content: policy.derived_content
        }

      privacyPolicies = $scope.waiver.privacy_policies.filter (policy) ->
        policy.context isnt 'waiver_payment_authority'
      paymentPrivacyPolicies = $scope.waiver.privacy_policies.filter (policy) ->
        policy.context is 'waiver_payment_authority'
      if privacyPolicies.length
        $scope.waiver.privacy_policy_responses_attributes =
          privacyPolicies.map createPolicyResponse
      if paymentPrivacyPolicies.length
        $scope.waiver.signed_waiver_wallet_detail_attributes.privacy_policy_responses_attributes =
          paymentPrivacyPolicies.map createPolicyResponse

    $scope.signAs = (value) ->
      $scope.waiver.signed_as = value
      initContacts()
      updateMergeTags()
      $scope.walletDetails = WaiverService.initPaymentDetails($scope.waiver)
      WaiverService.prepopulateChildDetails($scope.waiver)
      setLinksTarget()
      createGDPRData()
      preselectMembershipPlan()

    $scope.selectAttendingDisabled = (contact) ->
      contact.contact_id && contact.contact_type.toLowerCase() isnt 'prospect'

    $scope.displayPaymentDetails = (provider) ->
      switch $scope.walletDetails.payment_method
        when 'credit_card'
          $scope.waiver.credit_card_provider is provider
        when 'bank_account'
          $scope.waiver.bank_account_provider is provider

    $scope.isPrefilledNac = ->
      contact = $scope.waiver.signed_waiver_contacts_attributes[0]
      contact and contact.id and !contact.attending

    initContacts = ->
      contacts = $scope.waiver.signed_waiver_contacts_attributes
      contacts.forEach (contact, index) ->
        if $scope.isSignedAsAdult() && index > 0
          contact._destroy = 1
        else
          contact.member_number_read_only = !!contact.member_number

      $scope.addContact() if !contacts.length
      if $scope.isSignedAsAdult()
        $scope.waiver.signed_waiver_contacts_attributes = contacts
      else
        $scope.addContact() if contacts.length is 1

    $scope.isFieldRequired = (field, first) ->
      WaiverService.isFieldRequired($scope.waiver, field, first)

    $scope.isFieldAvailable = (field) ->
      WaiverService.isFieldAvailable($scope.waiver, field)

    $scope.isPaymentMethodPresent = ->
      walletDetails = $scope.waiver.signed_waiver_wallet_detail_attributes
      walletDetails.customer_ref && !!walletDetails.last4

    getDefaultAttending = (isPrimary) ->
      if isPrimary && !$scope.isSignedAsAdult() && !$scope.isNonAttending()
        undefined
      else
        !$scope.isNonAttending()

    $scope.addContact = ->
      contacts = $scope.waiver.signed_waiver_contacts_attributes
      isPrimary = contacts.length is 0
      preselectedMembership = getPreselectedMembership()
      contact =
        primary: isPrimary
        attending: getDefaultAttending(isPrimary)
        membership_plan_id: preselectedMembership?.id

      if !isPrimary
        angular.extend contact, WaiverService.getParentDetails($scope.waiver)
      $scope.waiver.signed_waiver_contacts_attributes.push contact
      recalculateContactsPosition()

    $scope.removeContact = (contact) ->
      if contact.contact_id
        contact._destroy = 1
      else
        index = $scope.waiver.signed_waiver_contacts_attributes.indexOf contact
        $scope.waiver.signed_waiver_contacts_attributes.splice(index, 1)
      recalculateContactsPosition()
      $scope.addContact() if $scope.activeContacts().length is 1

    mergeContactsDetails = (contacts) ->
      originalContacts = $scope.waiver.signed_waiver_contacts_attributes
      $scope.waiver.signed_waiver_contacts_attributes =
        originalContacts.map (contact, index) ->
          updatedContact = contacts.find((c) -> contact.position is c.position) || {}
          angular.extend contact, updatedContact

    recalculateContactsPosition = ->
      $scope.waiver.signed_waiver_contacts_attributes.forEach (contact, index) ->
        contact.position = index + 1

    removeBlankContacts = ->
      $scope.waiver.signed_waiver_contacts_attributes.forEach (contact, index) ->
        unless contact.email || contact.first_name || contact.last_name || index is 0
          if contact.id
            contact._destroy = 1
          else
            $scope.waiver.signed_waiver_contacts_attributes.splice(index, 1)
      recalculateContactsPosition()

    $scope.activeContacts = ->
      _.filter $scope.waiver.signed_waiver_contacts_attributes, (contact) ->
        !contact._destroy

    $scope.isContactPresent = (item) ->
      !item._destroy

    $scope.isSignedAsAdult = ->
      $scope.waiver.signed_as is 'signed_as_adult'

    $scope.isMember = ->
      $scope.waiver.waiver_type is 'member'

    $scope.isNonAttending = ->
      $scope.waiver.waiver_type is 'non_attending_contact'

    $scope.onAttendingToggle = (contact) ->
      unless contact.attending
        contact.member_number = undefined
      if $scope.waiver.signed_waiver_form_attributes.family_membership_strategy in ['every_attendee_pays_membership', 'each_signee_chooses_own_membership']
        contact.membership_plan_id = if contact.attending
          getPreselectedMembership()?.id
        else
          null

    $scope.onContactDetailsChanged = (contactIndex) ->
      return if contactIndex isnt 0
      updateMergeTags()

    $scope.emergencyContactDetailRequiredPlaceholder = ->
      if $scope.waiver.signed_waiver_form_attributes.emergency_contact_details_required
        ' (required)'
      else
        ''

    updateMergeTags = ->
      waiverForm = $scope.waiver.signed_waiver_form_attributes
      contact = $scope.waiver.signed_waiver_contacts_attributes[0]
      country = $scope.waiver.waiver_header_address_country
      waiverDateValue = moment().tz($scope.gymTz).format('MMMM Do YYYY')
      $scope.waiver.mergedContent =
        electronic_consent_content:
          $filter('replaceWaiverMergeTags')(waiverForm.electronic_consent_content, contact, country, waiverDateValue)
        gym_tacs_content:
          $filter('replaceWaiverMergeTags')(waiverForm.gym_tacs_content, contact, country)
        waiver_release_content:
          $filter('replaceWaiverMergeTags')(waiverForm.waiver_release_content, contact, country)

    $scope.hasPayment = ->
      WaiverService.hasPayment($scope.waiver)

    $scope.isFormReady = ->
      waiverForm = $scope.waiver.signed_waiver_form_attributes
      !$scope.pageParams.isLoading && waiverForm.electronic_consent_acceptance &&
        isPaymentFormValid() && isSignatureReady() && isWitnessSignatureReady()

    isSignatureReady = ->
      waiverForm = $scope.waiver.signed_waiver_form_attributes
      if waiverForm.signature_active
        $scope.signature.signatureDataUrl
      else
        true

    isWitnessSignatureReady = ->
      waiverForm = $scope.waiver.signed_waiver_form_attributes
      if waiverForm.witness_signature_active
        $scope.witnessSignature.signatureDataUrl && waiverForm.witness_name
      else
        true

    isPaymentFormActive = ->
      waiverForm = $scope.waiver.signed_waiver_form_attributes
      waiverForm.payment_authority_active && $scope.walletDetails.editable

    isCreditCard = ->
      $scope.walletDetails.payment_method is 'credit_card'

    isPaymentFormValid = ->
      return true unless isPaymentFormActive()
      return false unless isPaymentProviderAgreementAccepted()
      return false unless isPaymentDetailsPresent()
      return true unless isCreditCard()
      (!isStripeFormActive() || $scope.walletDetails.complete) && isCardholderNameProvided()

    isCardholderNameProvided = ->
      if $scope.walletDetails?.nameOnCard
        CreditCardValidationService.nameValid($scope.walletDetails?.nameOnCard)
      else
        !isStripeFormActive() || CreditCardValidationService.nameValid($scope.walletDetails?.nameOnCard)

    isStripeFormActive = ->
      $scope.waiver.credit_card_provider is 'stripe' &&
        $scope.walletDetails.payment_method is 'credit_card'

    isPaymentDetailsPresent = ->
      WaiverValidationService.isPaymentDetailsPresent($scope.walletDetails)

    isPaymentProviderAgreementAccepted = ->
      $scope.pageParams.paymentProviderServiceAgreement

    $scope.isPaymentDetailsInvalid = ->
      WaiverValidationService.isPaymentDetailsInvalid($scope.waiver, $scope.walletDetails)

    $scope.togglePaymentDetails = ->
      if $scope.walletDetails.editable
        $scope.walletDetails = WaiverService.initPaymentDetails($scope.waiver)
      else
        $scope.walletDetails = WaiverService.getNewPaymentDetails($scope.waiver)

    $scope.onRecaptchaSubmitted = (token) ->
      url = "/websites/#{$scope.gymDomain}/waivers/#{$scope.waiver.waiver_code}/signed_waivers/verify_ezidebit"
      $http.post(url, recaptcha_token: token).then (response) ->
        $scope.publicKey = response.data.public_key
        $timeout ->
          onPaymentDetailsSubmitReady()
        , 100
      , (response) ->
        flash.error = response.data?.recaptcha_failure_message || "Request could not be completed. Please try again"
        loadingAnimation.hide()
        $scope.pageParams.isLoading = false

    $scope.onRecaptchaError = () ->
      flash.error = "Unable to perform CAPTCHA check. If enabled, consider disabling VPN / IP tracking software and try again."
      loadingAnimation.hide()
      $scope.pageParams.isLoading = false

    $scope.generateRecaptchaToken = () ->
      $rootScope.$emit 'recaptcha:execute'

    submitPaymentDetails = ->
      if $scope.displayPaymentDetails('ezidebit')
        $scope.generateRecaptchaToken()
      else
        onPaymentDetailsSubmitReady()

    onPaymentDetailsSubmitReady = ->
      WaiverService.setEzidebitCustomerContactDetails($scope.waiver, $scope.country).then () ->
        $timeout ->
          signedWaiverWalletDetail = $scope.waiver.signed_waiver_wallet_detail_attributes

          if signedWaiverWalletDetail.customer_ref && signedWaiverWalletDetail.active_on_ezidebit
            if $scope.walletDetails.payment_method is 'credit_card'
              angular.element('#updateCreditCard').click()
            else
              angular.element('#updateBankAccount').click()
          else
            if $scope.walletDetails.payment_method is 'credit_card'
              angular.element('#submitCreditCard').click()
            else
              angular.element('#submitBankAccount').click()

    checkForMissingDetails = ->
      invalidForm = WaiverValidationService.getInvalidFormName($scope.waiver, $scope.walletDetails)
      if invalidForm
        $document.scrollToElementAnimated(angular.element(invalidForm))
        $scope.pageParams.isLoading = false
      else
        submitPaymentDetails()

    save = ->
      if $scope.waiver.id
        waiverPath.one('signed_waivers', $scope.waiver.prefill_code)
          .doPUT($scope.waiver)
      else
        waiverPath.all('signed_waivers')
          .post($scope.waiver)

    validateRequest = ->
      if $scope.waiver.id
        waiverPath.one('signed_waivers', $scope.waiver.prefill_code).one('validate')
          .doPUT($scope.waiver)
      else
        waiverPath.all('signed_waivers')
          .doPOST($scope.waiver, 'validate')

    validate = ->
      deferred = $q.defer()

      primaryContact = $scope.waiver.signed_waiver_contacts_attributes[0]
      if primaryContact.attending is undefined
        primaryContact.errors =
          attending: ['This field is required.']
        deferred.reject(data: $scope.waiver)
        return deferred.promise

      validateRequest().then () ->
        WaiverValidationService.validateWaiver($scope.waiver).then () ->
          deferred.resolve()
        , (response) ->
          loadingAnimation.hide()
          $scope.waiver.errors = response.data.errors
          checkForMissingDetails()
      , (response) ->
        deferred.reject(response)

      deferred.promise

    $scope.saveWaiver = ->
      save().then (data) ->
        $scope.signedWaiver = data.plain()
        loadingAnimation.hide()
        onWaiverComplete()
      , (response) ->
        loadingAnimation.hide()
        $scope.pageParams.isLoading = false
        $scope.waiver.errors = response.data.errors
        errors = $scope.waiver.errors
        if errors.payment_provider_error?[0] || errors['signed_waiver_wallet_detail.privacy_policy_responses.accepted']?[0]
          $document.scrollToElementAnimated(angular.element('#walletDetails'))

    onWaiverComplete = ->
      $timeout () ->
        if window.dataLayer && $scope.signedWaiver.http_referer_source
          window.dataLayer.push(source: $scope.signedWaiver.http_referer_source)
      , 500

    $scope.submit = ->
      $scope.noConnection = false
      loadingAnimation.show()
      waiverForm = $scope.waiver.signed_waiver_form_attributes
      $scope.pageParams.isLoading = true

      if waiverForm.signature_active and !$scope.signature.isEmpty
        signature = $scope.signature.accept()
        $scope.waiver.signed_waiver_form_attributes.signature =
          signature.dataUrl.replace /^data:image\/\w*;base64,/, ''

      if waiverForm.witness_signature_active and !$scope.witnessSignature.isEmpty
        witnessSignature = $scope.witnessSignature.accept()
        $scope.waiver.signed_waiver_form_attributes.witness_signature =
          witnessSignature.dataUrl.replace /^data:image\/\w*;base64,/, ''

      if !$scope.waiver.signed_waiver_form_attributes.payment_authority_active
        $scope.waiver.signed_waiver_wallet_detail_attributes = undefined

      $scope.waiver.first_membership_attributes = undefined

      removeBlankContacts()

      validate().then (data) ->
        if waiverForm.payment_authority_active && $scope.walletDetails.editable
          submitPaymentDetails()
        else
          $scope.saveWaiver()
      , (response) ->
        loadingAnimation.hide()
        if response.data
          $scope.waiver.errors = response.data.errors
          mergeContactsDetails(response.data.signed_waiver_contacts_attributes)
          checkForMissingDetails()
        else
          $scope.noConnection = true
          $scope.pageParams.isLoading = false

    if $scope.waiver.signed_waiver_form_attributes
      checkBypassFamilySelection()
