angular.module 'shared'
  .factory 'WaiverValidationService', ($q) ->

    invalidContactDetailsPosition = (waiver) ->
      item = _.find waiver.signed_waiver_contacts_attributes, (contact) ->
        errors = _.omit(contact.errors, 'membership_plan', 'membership_plan_name')
        _.size(errors) > 0
      item?.position

    isPaymentDetailsFilled = (waiver, wallet) ->
      waiver.signed_waiver_form_attributes.payment_authority_active &&
        (wallet.last4 && wallet.exp_month && wallet.exp_year && wallet.nameOnCard) ||
        (wallet.bsb && wallet.accountNumber && wallet.accountName)

    isPaymentDetailsInvalid: (waiver, wallet) ->
      errors = waiver.errors
      return true if errors['signed_waiver_wallet_detail.privacy_policy_responses.accepted']?.length
      return if !waiver.signed_waiver_form_attributes.payment_authority_active
      return if isPaymentDetailsFilled(waiver, wallet)
      errors['signed_waiver_wallet_detail']?.length ||
        errors['signed_waiver_wallet_detail.full_name']?.length ||
        errors['signed_waiver_wallet_detail.last4']?.length ||
        errors['signed_waiver_wallet_detail.payment_method']?.length

    isPaymentDetailsPresent: (walletDetails) ->
      walletDetails.accountNumber || walletDetails.cardNumber || walletDetails.nameOnCard

    isQuestionnaireInvalid: (errors) ->
      errorKeys = _.keys(errors)
      _.find errorKeys, (key) ->
        key.indexOf('signed_waiver_questionnaire') > -1

    isGDPRInvalid: (errors) ->
      errors['privacy_policy_responses.accepted']?.length

    getEmergencyContactDetailsErrors: (waiver) ->
      details = waiver.signed_waiver_emergency_contact_detail_attributes
      isRequired = waiver.signed_waiver_form_attributes.emergency_contact_details_required
      if isRequired && (
        !details.name || !details.relationship || !details.email ||
        !details.phone_number || !details.address
      )
        response = data: errors: {}
        fields = ['name', 'relationship', 'email', 'phone_number', 'address']
        fields.forEach (field) ->
          if !details[field]
            response.data.errors["signed_waiver_emergency_contact_detail.#{field}"] =
              ['This field is required']
        response
      else
        false

    getContactErrors: (waiver) ->
      params = hasErrors: false
      contacts = waiver.signed_waiver_contacts_attributes
      response = data: errors: {}
      validatedContacts = contacts.map (contact) ->
        duplicates = _.filter contacts, (c) ->
          contact.member_number && contact.member_number is c.member_number
        if duplicates.length && contact.position isnt duplicates[0].position
          params.hasErrors = true
          contact.errors = member_number: ['Must be unique']
        else
          contact.errors = {}

        contact
      if params.hasErrors
        waiver.signed_waiver_contacts_attributes = validatedContacts
        response
      else
        false

    validateWaiver: (waiver) ->
      deferred = $q.defer()
      details = waiver.signed_waiver_emergency_contact_detail_attributes
      isRequired = waiver.signed_waiver_form_attributes.emergency_contact_details_required
      errors = @getEmergencyContactDetailsErrors(waiver) || @getContactErrors(waiver)
      if errors
        deferred.reject(errors)
      else
        deferred.resolve()
      deferred.promise

    hasEmergencyContactDetailErrors: (errors) ->
      fields = ['name', 'relationship', 'email', 'phone_number', 'address']
      !!_.find fields, (field) -> errors["signed_waiver_emergency_contact_detail.#{field}"]?.length

    getInvalidFormName: (waiver, wallet) ->
      errors = waiver.errors
      switch
        when invalidContactDetailsPosition(waiver) >= 0
          "#contact_details_#{invalidContactDetailsPosition(waiver)}"
        when errors['signed_waiver_form.membership_plan']?.length || errors['signed_waiver_contacts.membership_plan']?.length
          '#membershipOptions'
        when @isPaymentDetailsInvalid(waiver, wallet)
          '#walletDetails'
        when errors['signed_waiver_form.signature']?.length
          '#signatureField'
        when errors['waiver_type']?.length
          '#members_details'
        when @isQuestionnaireInvalid(errors)
          '#questionnaire'
        when @isGDPRInvalid(errors)
          '#gdpr'
        when @hasEmergencyContactDetailErrors(errors)
          '#emergency_contact'
        else
          undefined
