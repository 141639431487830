angular
  .module 'gym.components'
  .directive 'waiverWalletDetailsPrivacyPolicySection', ->
    restrict: 'EA'
    scope:
      waiver: '='
      privacyPolicies: '='
      readOnly: '='
    template: require('templates/components/waivers/waiver_wallet_details_privacy_policy_section.html.slim')
    controller: ($scope) ->
