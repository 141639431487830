angular
  .module 'gym.components'
  .directive 'contactMandates', ->
    restrict: 'E'
    template: require('templates/components/contacts/mandates/contact_mandates.html.slim')
    scope:
      contact: '='
    controller: (
      $scope,
      $rootScope,
      $window,
      loadingAnimation,
      ngTableParams,
      ContactGoCardlessService,
      EmploymentService,
      AdminUserService
    ) ->
      $scope.adminHasPermission = AdminUserService.has
      $scope.permissions = EmploymentService.get().permissions
      $scope.pageParams = {}

      $scope.setDefault = (item) ->
        if $window.confirm 'This will change the Mandate used for payment processing. Are you sure?'
          ContactGoCardlessService.setDefault(item).then () ->
            $scope.tableParams.reload()
            $scope.$emit 'contact:paymentMethod:updated'

      $scope.cancel = (item) ->
        message = "This will cancel the mandate #{item.mandate_reference} on GoCardless. Are you sure?"
        if item.default
          defaultNote = "\nNote: this is the current default mandate for this customer.\nPlease ensure a new default is selected if you wish to continue collecting payments."
          message = "#{message} #{defaultNote}"
        if $window.confirm message
          item.remove().then () ->
            $scope.tableParams.reload()
            $scope.$emit 'contact:paymentMethod:updated'

      $rootScope.$on 'contact:mandates:reload', ->
        $scope.tableParams.reload()

      $scope.tableParams = new ngTableParams
        page: 1
        count: 1000
      ,
        {
          counts: []
          total: 0
          getData: ($defer, $params) ->
            $scope.isLoading = true
            loadingAnimation.show()
            params = angular.extend {}, $params.url()
            $scope.contact.all('mandates').getList(params).then (data) ->
              $params.total data.meta.total
              $defer.resolve data
              $scope.isLoading = false
              loadingAnimation.hide()
        }
