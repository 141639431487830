angular
  .module 'gym.components'
  .directive 'prospectDashlets', ->
    restrict: 'E'
    template: require('templates/components/contacts/prospect_dashlets.html.slim')
    scope:
      contact: '='
      convert: '&'
    controller: ($scope) ->

      $scope.signedUp = $scope.contact.prospect_signed_up_dashlet
      $scope.lastContacted = $scope.contact.prospect_last_contacted_dashlet
