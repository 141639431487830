angular
  .module 'gym.components'
  .directive 'contactWalletSection', ->
    restrict: 'EA'
    scope:
      gym: '='
      contact: '='
      permissions: '='
      paymentMethods: '='
      secondProviderPaymentMethods: '='
      filteredPaymentMethods: '='
      filteredSecondProviderPaymentMethods: '='
      alternatePaymentMethod: '='
      reloadPaymentMethods: '&'
      setTab: '&'
    template: require('templates/components/contacts/wallet/contact_wallet_section.html.slim')
    controller: (
      $scope,
      $modal,
      $window,
      $timeout,
      $document,
      flash,
      loadingAnimation,
      PaymentDetailsSharingService,
      ContactWalletService,
      Contacts,
      MessageService,
      MasqueradingService,
      AdminUserService
    ) ->
      $scope.adminHasPermission = AdminUserService.has

      $scope.getFilteredPaymentMethods = (provider) ->
        switch provider
          when $scope.gym.payment_system
            $scope.filteredPaymentMethods
          when $scope.gym.secondary_payment_system
            $scope.filteredSecondProviderPaymentMethods

      getPaymentMethods = (provider) ->
        switch provider
          when $scope.gym.payment_system
            $scope.paymentMethods
          when $scope.gym.secondary_payment_system
            $scope.secondProviderPaymentMethods

      getPaymentAccount = (provider) ->
        switch provider
          when $scope.gym.payment_system
            $scope.gym.payment_system_account
          when $scope.gym.secondary_payment_system
            $scope.gym.secondary_payment_system_account

      $scope.displayAddPaymentMethodLink = (provider) ->
        cc = $scope.gym.credit_card_provider
        ba = $scope.gym.bank_account_provider
        switch
          when cc is ba
            'Payment Method'
          when provider is ba
            'Bank Account'
          when provider is cc
            'Credit Card'

      $scope.displayPaymentDetails = (provider) ->
        isActive = switch provider
          when 'ezidebit'
            $scope.gym.uses_ezidebit
          when 'stripe'
            $scope.gym.uses_stripe
          when 'bluefin'
            $scope.gym.uses_bluefin
          when 'integrapay'
            $scope.gym.uses_integrapay
          when 'gocardless'
            $scope.gym.uses_gocardless
        return unless isActive
        totalLength = $scope.filteredPaymentMethods.length + $scope.filteredSecondProviderPaymentMethods.length
        $scope.getFilteredPaymentMethods(provider).length ||
          (!totalLength && provider is $scope.gym.payment_system)

      $scope.addEzidebitPaymentMethod = ->
        PaymentDetailsSharingService.getSharingOption($scope.contact, getPaymentMethods('ezidebit')).then (sharingOption) ->
          modalInstance = $modal.open
            template: require('templates/gyms/contacts/payment_details/ezidebit_payment_details_modal.html.slim')
            controller: "AddEzidebitPaymentDetailsCtrl"
            resolve:
              contact: -> $scope.contact
              gym: -> $scope.gym
              customer: -> getPaymentMethods('ezidebit').getList()
              paymentAccount: -> getPaymentAccount('ezidebit')
              sharingOption: -> sharingOption

          modalInstance.result.then ->
            $scope.reloadPaymentMethods()

      $scope.addBluefinPaymentMethod = (type) ->
        template = switch type
          when 'credit_card'
            require('templates/gyms/contacts/payment_details/bluefin_credit_card_modal.html.slim')
          when 'bank_account'
            require('templates/gyms/contacts/payment_details/bluefin_bank_account_modal.html.slim')
        modalInstance = $modal.open
          template: template
          scope: $scope
          controller: "AddBluefinPaymentDetailsCtrl"
          resolve:
            contact: -> $scope.contact
            gym: -> $scope.gym
            paymentAccount: -> getPaymentAccount('bluefin')
            defaultPaymentMethod: ->
              _.find getPaymentMethods('bluefin'), (paymentMethod) -> paymentMethod.default

        modalInstance.result.then ->
          $scope.reloadPaymentMethods()

      $scope.addStripePaymentMethod = ->
        modalInstance = $modal.open
          template: require('templates/gyms/contacts/payment_details/stripe_payment_details_modal.html.slim')
          controller: "AddStripePaymentDetailsCtrl"
          resolve:
            contact: -> $scope.contact
            gym: -> $scope.gym
            paymentAccount: -> getPaymentAccount('stripe')
            customer: ->
              getPaymentMethods('stripe').getList()

        modalInstance.result.then ->
          $scope.reloadPaymentMethods()

      $scope.addIntegrapayPaymentMethod = ->
        modalInstance = $modal.open
          template: require('templates/gyms/contacts/payment_details/integrapay_payment_details_modal.html.slim')
          controller: "AddIntegrapayPaymentDetailsCtrl"
          resolve:
            contact: -> $scope.contact
            gym: -> $scope.gym
            customer: -> getPaymentMethods('integrapay')

        modalInstance.result.then ->
          $scope.reloadPaymentMethods()

      $scope.addGoCardlessPaymentMethod = ->
        unless $scope.contact.gocardless_ready
          message = 'Mandates are currently disabled while your GoCardless account is being verified.'
          MessageService.message({title: 'Action unavailable', message: message})
          return

        modalInstance = $modal.open
          template: require('templates/gyms/contacts/payment_details/gocardless_payment_details_modal.html.slim')
          controller: "AddGoCardlessPaymentMethodCtrl"
          resolve:
            contact: -> $scope.contact
            gym: -> $scope.gym
            redirectUrl: ->
              $scope.contact.all('gocardless').doPOST({}, 'generate_redirect_flow')
        modalInstance.result.then ->
          angular.noop
        , (result) ->
          if result.data?.error
            flash.error = result.data.error

      $scope.viewOtherMandatesLinkVisible = ->
        $scope.gym.bank_account_provider is 'gocardless' &&
          _.find $scope.paymentMethods, (item) -> !item.show_in_wallet

      $scope.showOtherMandates = ->
        $scope.setTab(tab: 'mandates')

      $scope.setDefaultPaymentMethod = (paymentMethod, provider) ->
        allPaymentMethods =
          [].concat $scope.paymentMethods, $scope.secondProviderPaymentMethods
        currentDefault = allPaymentMethods.find (pm) -> pm.default
        if currentDefault.all_contacts?.length > 1
          PaymentDetailsSharingService.getSharingOption($scope.contact, [currentDefault], provider).then (sharingOption) ->
            setDefaultPaymentMethod(paymentMethod, sharingOption.updateThisContactOnly)
        else
          if $window.confirm 'Are you sure you want to set this payment method as default?'
            setDefaultPaymentMethod(paymentMethod, false)
          else
            $timeout ->
              paymentMethod.default = false

      setDefaultPaymentMethod = (paymentMethod, updateThisContactOnly) ->
        loadingAnimation.show()
        paymentMethod.default = true
        paymentMethod.rootObjectName = 'payment_method'
        sendDefaultPaymentMethod(paymentMethod, updateThisContactOnly).then ->
          loadingAnimation.hide()
          $scope.reloadPaymentMethods()
          flash.success = 'Default payment method set successfully'
        , ->
          loadingAnimation.hide()
          flash.error = 'Could not set the default method'

      sendDefaultPaymentMethod = (paymentMethod, updateThisContactOnly) ->
        if updateThisContactOnly
          paymentMethod.doPUT(paymentMethod, 'unlink_and_update')
        else
          paymentMethod.customPUT(paymentMethod)

      $scope.removePaymentMethod = (paymentMethod, provider) ->
        if paymentMethod.all_contacts?.length > 1
          unlinkContactPaymentMethod(paymentMethod, provider)
        else
          ContactWalletService.confirmRemovePaymentMethod(paymentMethod).then (params) ->
            loadingAnimation.show()
            removePaymentMethod(paymentMethod, params).then ->
              loadingAnimation.hide()
              $scope.reloadPaymentMethods()
            , ->
              loadingAnimation.hide()
              flash.error = 'Could not delete the payment method'

      $scope.removeEzidebitLinkAvailable = ->
        return unless MasqueradingService.isMasquerading()
        allItems = $scope.paymentMethods.concat($scope.secondProviderPaymentMethods)
        ezidebitItems = allItems.filter((item) -> item.type is 'ezidebit_customer_detail')
        ezidebitItems.length && _.every ezidebitItems, (item) -> item.customer_ref && !item.last4

      $scope.removeEzidebitLink = ->
        allItems = $scope.paymentMethods.concat($scope.secondProviderPaymentMethods)
        ezidebitItem = allItems.filter((item) -> item.type is 'ezidebit_customer_detail')[0]

        MessageService.message(
          title: 'Remove Link to Ezidebit Customer'
          message: [
            "This contact is currently linked to an Ezidebit Payer (EzidebitCustomerId: #{ezidebitItem.customer_ref}).",
            "Removing this link will allow linking to a new (or different) Ezidebit Payer. Click OK to continue."
          ]
          confirmButtonText: 'Ok'
          cancelButtonText: 'Cancel'
        ).then () ->
          ezidebitItem.customDELETE('clear_ezidebit_link').then ->
            $scope.reloadPaymentMethods()

      removePaymentMethod = (paymentMethod, params) ->
        if $scope.gym.payment_system is 'integrapay'
          $scope.contact.doPOST({}, 'integrapay/remove_payment_method')
        else
          reset_customer_ref = if params.reset_customer_ref then 1 else undefined
          paymentMethod.remove(reset_customer_ref: reset_customer_ref)

      unlinkContactPaymentMethod = (paymentMethod, provider) ->
        modalInstance = $modal.open
          template: require('templates/gyms/contacts/payment_details/remove_payment_method.html.slim')
          controller: 'RemovePaymentMethodCtrl'
          resolve:
            contact: -> $scope.contact
            gym: -> $scope.gym
            paymentMethod: -> paymentMethod
            provider: -> provider

        modalInstance.result.then ->
          $scope.reloadPaymentMethods()

      $scope.alternate = () ->
        currentPaymentMethod = if $scope.alternatePaymentMethod.type is 'stripe'
          'GoCardless Mandate'
        else
          'Stripe credit card'
        availablePaymentMethod = if $scope.alternatePaymentMethod.type is 'stripe'
          'Stripe credit card'
        else
          'GoCardless Mandate'
        buttonText = if $scope.alternatePaymentMethod.type is 'stripe'
          'Switch to Credit Card'
        else
          'Switch to <Mandate'
        MessageService.message(
          title: 'Switch Payment Method'
          message: [
            "#{$scope.contact.name} is currently processing payments using their #{currentPaymentMethod},
            but they also have one or more #{availablePaymentMethod} on file:",
            $scope.alternatePaymentMethod.description
            "Click \"#{buttonText}\" below if you would like to switch to this payment method for all future payments."
          ]
          confirmButtonText: buttonText
          cancelButtonText: 'Cancel'
        ).then () ->
          loadingAnimation.show()
          Contacts.one("#{$scope.contact.id}").one($scope.alternatePaymentMethod.type, $scope.alternatePaymentMethod.id).doPUT({}, 'switch_to_alternate').then () ->
            loadingAnimation.hide()
            $scope.reloadPaymentMethods()
          , () ->
            loadingAnimation.hide()
