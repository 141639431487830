angular
  .module 'shared'
  .factory 'ReactModal', ($modal) ->
    ReactModal =
      open: (params) ->
        modalInstance = $modal.open
          template: require('templates/shared/react_modal.html.slim')
          windowClass: if params.drawer then 'drawer__wrapper' else params.windowClass
          backdropClass: if params.drawer then 'modal-backdrop--drawer' else params.backdropClass
          size: params.size
          resolve:
            component: -> params.component
            props: -> params.props || {}
          controller: ($scope, $modalInstance, component, props) ->
            $scope.component = component
            $scope.props =
              modalInstance: $modalInstance
              cancel: (reason) ->
                $modalInstance.dismiss(reason || 'cancel')
              submitModal: (data) ->
                $modalInstance.close(data)

            angular.extend $scope.props, props
        modalInstance.result

    return ReactModal
