// escape-goat v4.0.0
const _htmlUnescape = (htmlString) =>
  htmlString
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&#0?39;/g, "'")
    .replace(/&quot;/g, '"')
    .replace(/&amp;/g, '&');

function htmlUnescape(strings, ...values) {
  if (typeof strings === 'string') {
    return _htmlUnescape(strings);
  }

  let output = strings[0];
  for (const [index, value] of values.entries()) {
    output = output + _htmlUnescape(String(value)) + strings[index + 1];
  }

  return output;
}

const unescape = (string) => {
  return htmlUnescape(string || '');
};

export default unescape;
