angular
  .module 'gym.components'
  .directive 'displayDataType', () ->
    restrict: 'E'
    template: require('templates/components/shared/display_data_type.html.slim')
    scope:
      value: '='
      type: '='
      units: '='
    controller: ($rootScope, $scope, $filter) ->

      $scope.parseDate = (value) ->
        if value
          date = new Date value
          return value if isNaN(date.valueOf())
          moment(value).format('MMM D, YYYY')

      $scope.parseDatetime = (value) ->
        if value
          date = new Date value
          return value if isNaN(date.valueOf())
          moment(date).format('lll')

      $scope.parseCurrency = (value) ->
        symbol = $rootScope.gym.currency_symbol
        $filter('currency')(value, symbol) if value

      $scope.parseNumber = (value) ->
        $filter('number')(value)

      $scope.parseBoolean = (value) ->
        switch value
          when 't' then 'Yes'
          when 'f' then 'No'
          else value
