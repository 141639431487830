angular
  .module 'gym.components'
  .directive 'editBrivoAccessDetails', ->
    restrict: 'E'
    template: require('templates/components/contacts/access_control/edit_brivo_access_details.html.slim')
    scope:
      contact: '='
      form: '='
      reloadContact: '&'
      addCredential: '&'
    controller: (
      $scope,
      $stateParams,
      $http,
      $modal,
      $window,
      loadingAnimation,
      flash,
      AccessControl
    ) ->
      brivoDetailUrl =
        "/gyms/#{$stateParams.gymId}/contacts/#{$scope.contact.id}/brivo_details"

      loadCredentials = ->
        loadingAnimation.show()
        $scope.contact.all('brivo_credentials').getList().then (data) ->
          loadingAnimation.hide()
          $scope.brivoCredentials = data
        , () ->
          loadingAnimation.hide()

      $scope.loadBrivoDetail = loadBrivoDetail = ->
        $scope.isBrivoDetailLoading = true
        $http.get(brivoDetailUrl).then (response) ->
          $scope.isBrivoDetailLoading = false
          $scope.brivoDetail = response.data[0]
          if $scope.brivoDetail
            $scope.brivoDetail.current_access_enabled = $scope.brivoDetail.access_enabled
        , () ->
          $scope.isBrivoDetailLoading = false

      loadActivity = ->
        params =
          'types[]': 'contact_brivo_detail_log_entry'
          count: 50
        $scope.contact.all('contact_log_entries').getList(params).then (data) ->
          $scope.activity = data.plain()

      loadActivity()
      loadBrivoDetail()
      loadCredentials()

      $scope.credentialFormats =
        AccessControl.all('credential_formats').getList().$object

      $scope.saveDetails = ->
        url = "#{brivoDetailUrl}/#{$scope.brivoDetail.id}"
        loadingAnimation.show()
        $http.put(url, contact_brivo_detail: $scope.brivoDetail).then (response) ->
          flash.success = "Updated successfully"
          $scope.brivoDetail.access_enabled = response.data.access_enabled
          $scope.brivoDetail.current_access_enabled = response.data.access_enabled
          $scope.brivoDetail.access_override = response.data.access_override
          $scope.brivoDetail.override_reason = response.data.override_reason
          loadingAnimation.hide()
        , () ->
          loadingAnimation.hide()
        $scope.brivoDetail.override_form = false
        loadActivity()

      $scope.onCredentialSaved = ->
        loadBrivoDetail() unless $scope.brivoDetail
        loadCredentials()
        loadActivity()
        $scope.reloadContact()

      $scope.overrideAccess = ->
        $scope.brivoDetail.override_form = true
        $scope.brivoDetail.temp_access_override =
          $scope.brivoDetail.access_override
        $scope.brivoDetail.access_override = true

      $scope.cancelSaveDetails = ->
        $scope.brivoDetail.override_form = false
        $scope.brivoDetail.access_override =
          $scope.brivoDetail.temp_access_override
        loadBrivoDetail()

      $scope.removeAccess = ->
        $scope.brivoDetail.access_override = false
        $scope.saveDetails()

      $scope.removeCredential = (credential) ->
        if $window.confirm "Are you sure?"
          loadingAnimation.show()
          credential.remove().then ->
            loadActivity()
            loadCredentials()
