angular
  .module 'shared'
  .directive 'iconBack', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.913 9.17H4.504l2.75-2.742a.837.837 0 1 0-1.183-1.183L1.904 9.412a.833.833 0 0 0-.175.275.833.833 0 0 0 0 .633c.04.102.1.196.175.275l4.167 4.167a.835.835 0 0 0 1.43-.592.833.833 0 0 0-.247-.592l-2.75-2.741h13.409a.833.833 0 1 0 0-1.667Z" fill="{{color || 'currentColor'}}"/></svg>
    '''
