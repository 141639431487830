angular
  .module 'shared'
  .directive 'iconReturn', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1.5c-3.314 0-6.313 1.343-8.485 3.515l-3.515-3.515v9h9l-3.364-3.364c1.629-1.629 3.879-2.636 6.364-2.636 4.97 0 9 4.029 9 9 0 2.688-1.179 5.101-3.047 6.75l1.984 2.25c2.491-2.199 4.063-5.416 4.063-9 0-6.627-5.373-12-12-12z" fill="{{color || 'currentColor'}}"></path></svg>
    '''
