import random from 'helpers/random'

angular
  .module 'gym.components'
  .directive 'timeSequenceCondition', ->
    restrict: 'EA'
    template: require('templates/components/sequences/timeSequenceCondition.html.slim')
    replace: true
    scope:
      sequence: '='
      step: '='
      isTask: '='
      isPortal: '='
      isMobileApp: '='
      isMembership: '='
      isWorkout: '='
      isStyle: '='
      isNote: '='
      isProspectStatus: '='
      isFloatingFollowable: '='
      index: '='
    controller: ($scope) ->
      $scope.id = random()
      conditions = $scope.step.sequence_conditions_attributes
      conditionIndex = _.findIndex conditions, (condition) ->
        condition.type is 'TimeSequenceCondition'
      $scope.condition = $scope.step.sequence_conditions_attributes[conditionIndex]

      $scope.minValue = minValue = () ->
        if $scope.isFloatingFollowable
          1
        else
          0

      if $scope.condition
        $scope.condition.time_since_previous_step ||= minValue()
        $scope.condition.time_since_previous_step_unit ||= 'days'
        $scope.condition.immediate ||= false

      $scope.isFirst = () -> $scope.index is 1

      $scope.getAction = ->
        if $scope.isTask || $scope.isMembership || $scope.isWorkout || $scope.isStyle || $scope.isNote
          return 'add'
        if $scope.isPortal || $scope.isMobileApp || $scope.isProspectStatus
          return 'change'
        'send'

      getTimeUnits = () ->
        $scope.timeUnits = if $scope.isFloatingFollowable && $scope.isFirst()
          ['days']
        else
          ['days', 'hours']

      $scope.$watch 'index', () ->
        getTimeUnits()

      checkHours = ->
        if !$scope.condition.immediate && $scope.condition.time_since_previous_step_unit is 'hours'
          $scope.condition.allowed_hours = []

      $scope.onImmediateChanged = ->
        $scope.condition.time_since_previous_step = 0
        checkHours()

      $scope.onTimeUnitChange = ->
        checkHours()

      $scope.isTimeInvalid = ->
        $scope.sequence.errors?['sequence_steps.sequence_conditions.time_since_previous_step'] &&
          !$scope.condition.immediate && ($scope.condition.time_since_previous_step < minValue() ||
          (!$scope.condition.time_since_previous_step && $scope.condition.time_since_previous_step isnt 0))
