angular.module 'gym.components'
  .controller 'FutureBookingLimitsModal', (
    $scope,
    $rootScope,
    $modalInstance,
    type
  ) ->
    gym = $rootScope.gym
    $scope.type = type

    $scope.limitPlaces = () ->
      availablePlaces = _.compact(['Clubworx website', gym.member_portal_enabled && 'the member portal', gym.mobile_app_enabled && 'the mobile app'])
      places = availablePlaces.join(' or ')
      if availablePlaces.length > 2
        places.replace(' or ', ', ')
      else
        places

    $scope.close = ->
      $modalInstance.dismiss('cancel')
