angular
  .module 'shared'
  .directive 'numericBinding', ($timeout) ->
    restrict: 'A'
    require: 'ngModel'
    scope:
      model: '=ngModel'
    link: (scope) ->

      scope.$watch 'model', ->
        scope.model = parseFloat(scope.model) if scope.model
