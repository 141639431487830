angular
  .module 'gym.components'
  .directive 'signedWaiverContacts', ->
    restrict: 'EA'
    scope:
      waiver: '='
      currency: '='
    template: require('templates/components/waivers/signed_waiver_contacts.html.slim')
    controller: (
      $scope,
    ) ->
      $scope.displayMemberNumber = (contact) ->
        signed_waiver = $scope.waiver.signed_waiver_form_attributes
        signed_waiver.accept_member_number && contact.attending

      $scope.isFieldAvailable = (field) ->
        $scope.waiver.signed_waiver_form_attributes.required_fields[field] isnt 'hidden'
