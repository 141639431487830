angular
  .module 'shared'
  .directive 'toggleTableFiltersButton', ->
    restrict: 'E'
    scope:
      open: '='
      toggle: '&'
      toggleModal: '&'
    template: require('templates/shared/toggle_table_filters_button.html.slim')
    controller: ($scope) ->
      $scope.onClick = () ->
        if window.innerWidth > 991
          $scope.toggle()
        else
          $scope.toggleModal();
