angular
  .module 'shared'
  .directive 'selectAllPages', () ->
    restrict: 'A'
    replace: true
    scope:
      columns: '='
      count: '='
      allPagesSelected: '='
      toggle: '&'
    template: '''
      <tr>
        <th colspan="{{columns}}" class="text-center">
          <span ng-if="allPagesSelected">
            All matching contacts selected.
            <a href="" ng-click="toggle()">
            Click here to clear selection.
          </a>
          </span>
          <span  ng-if="!allPagesSelected">
            {{'contacts' | pluralize : count : true}} selected on this page.
            <a href="" ng-click="toggle()">
              Click here to select all pages.
            </a>
          </span>
        </th>
      </tr>
    '''
