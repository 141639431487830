angular
  .module 'gym.components'
  .directive 'tasksWidget', ->
    restrict: 'EA'
    template: require('templates/components/dashboard/tasks_widget.html.slim')
    scope: {}
    controller: ($scope, $rootScope, Tasks, TasksService) ->
      $scope.defaultTasksList = $rootScope.defaultTasksList
      $scope.statuses = TasksService.getStatuses()

      loadTasks = ->
        Tasks.all('due').getList(count: 3).then (tasks) ->
          $scope.tasks = tasks

      loadTasks()

      $rootScope.$on 'tasks:updated', ->
        loadTasks()

      $scope.changeStatus = (task) ->
        action =
          switch task.status
            when 'unstarted'
              'restart'
            when 'started'
              'start'
            when 'waiting'
              'wait'
            when 'finished'
              'finish'

        Tasks.doPUT(withoutNamespace: true, "#{task.id}/#{action}").then ->
          $scope.$emit 'tasks:updated'

      $scope.hasManagePermission = (task) ->
        TasksService.hasManagePermission(task)

      $scope.displayDate = (date) ->
        moment(date).calendar()

      $scope.editTask = (id) ->
        TasksService.edit(id)
