angular.module 'shared'
  .factory 'SmsValidationService', ($filter) ->
    clean: (content) ->
      cleanContent = @decodeHTML($filter('sanitizeEditorField')(content || ''))
      mergeTagPlaceholder = [0..9].join('')
      cleanContent.replace(/(<mark>.+?<\/mark>)/g, mergeTagPlaceholder)

    decodeHTML: (html) ->
      unless @txt
        @txt = document.createElement('textarea')
      @txt.innerHTML = html
      @txt.value

    getMessagesCount: (content) ->
      optimizedContent = @clean(content)
        .replace(/“|”|’|`|‘/g, '\'')
        .replace(/–|—/g, '-')
        .replace(/•/g, '*')

      if @containsNonLatinCodepoints(optimizedContent)
        if optimizedContent.length <= 70
          1
        else
          Math.ceil(optimizedContent.length / 67)
      else
        if optimizedContent.length <= 160
          1
        else
          Math.ceil(optimizedContent.length / 153)

    containsNonLatinCodepoints: (s) ->
      /[^\u0000-\u00ff]/.test(s)

    getRemainingCharacters: (content) ->
      650 - @clean(content).length

    isLengthValid: (content) ->
      @getRemainingCharacters(content) >= 0

    displayRemainingCharacters: (content) ->
      remaining = @getRemainingCharacters(content)
      charactersLabel = if remaining in [1, -1]
        'character'
      else
        'characters'

      if remaining >= 0
        "#{@clean(content).length} chars (approx. #{@getMessagesCount(content)} messages), #{remaining} chars remaining."
      else
        "Character limit exceeded: please remove #{-remaining} #{charactersLabel}."

    getErrors: (message) ->
      if message.channel is 'sms' && message.content?.toLocaleLowerCase().indexOf('bit.ly') >= 0
        'Please use full URLs instead of bit.ly links.'
