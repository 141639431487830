angular
  .module 'shared'
  .directive 'waiverEzidebitPaymentDetails', ->
    restrict: 'E'
    scope:
      waiver: '='
      walletDetails: '='
      pageParams: '='
      saveWaiver: '&'
      isPaymentDetailsInvalid: '&'
      oneTimePublicKey: '='
    template: require('templates/shared/waivers/payment_details/waiver_ezidebit_payment_details.html.slim')
    controller: (
      $scope,
      $rootScope
    ) ->
      gym = $rootScope.gym
      $scope.isExternal = $scope.waiver.form_type is 'external'

      getPublicKey = ->
        $scope.publicKey = if $scope.waiver.gym_payment_provider is 'ezidebit'
          gym?.payment_system_account.public_key
        else
          gym?.secondary_payment_system_account.public_key

      unless $scope.isExternal
        getPublicKey()

      country = gym?.country || window.DATA.gym_country

      $scope.serviceAgreementUrl = switch country
        when 'AU'
          'https://static.ezidebit.com.au/ServiceAgreement/AU/DDR_Service_Agreement.html'
        when 'NZ'
          'https://static.ezidebit.com.au/ServiceAgreement/NZ/DDR_Service_Agreement.html'
