angular
  .module 'gym.components'
  .directive 'user2fa', () ->
    restrict: 'E'
    template: require('templates/components/profile/user2fa.html.slim')
    scope: {}
    controller: (
      $scope,
      $rootScope,
      $http,
      $modal,
    ) ->
      $scope.gym = $rootScope.gym

      $scope.isDisabled = ->
        !$scope.data || $scope.data.two_factor_auth_required_by_gym || $scope.isLoading

      loadStatus = () ->
        url = "/gyms/#{$scope.gym.id}/profile/two_factor_auth_status"
        $http.get(url).then (response) ->
          $scope.data = response.data

      $scope.confirmUser = () ->
        $modal.open
          template: require('templates/gyms/profile/confirm_password_modal.html.slim')
          controller: ($scope, $modalInstance, EmploymentService) ->

            $scope.cancel = ->
              $modalInstance.dismiss('cancel')

            employmentId = EmploymentService.get().id
            $scope.submit = ->
              url = "/gyms/#{$scope.gym.id}/users/#{employmentId}/disable_two_factor"
              if $scope.isLoading || !$scope.params.password
                return

              $scope.isLoading = true
              params =
                user_password: $scope.params.password

              $http.post(url, params).then () ->
                link = document.createElement('a')
                link.setAttribute('data-method', 'POST')
                link.setAttribute('href', "/users/disable_authy")
                link.setAttribute('target', '_self')
                document.body.appendChild(link)
                link.click()
                $modalInstance.close()
              , () ->
                $scope.isLoading = false
                $scope.errors =
                  password: ['Incorrect password provided']

            $scope.params = {}

      loadStatus()

      $scope.enable = ->
        window.location.href = "/users/enable_authy"
