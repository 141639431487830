angular
  .module 'shared'
  .directive 'patchWebsiteLinks', ($timeout) ->
    restrict: 'A'
    link: (scope) ->
      $timeout ->
        angular.element('a').filter((i, item) ->
          item.host is window.location.host && !item.target && !item.dataset.toggle
        ).each (i, item) ->
          item.target = '_self'
