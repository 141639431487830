angular
  .module 'gym.components'
  .directive 'subscriptionPaymentDetails', ->
    restrict: 'E'
    template: require('templates/components/subscriptions/subscription_payment_details.html.slim')
    scope:
      paymentMethod: '='
      gym: '='
      subscriptionToken: '='
      setPaymentMethod: '&'
    controller: (
      $scope,
      $stateParams,
      $state,
      $window,
      $http,
      $timeout,
      loadingAnimation,
      flash,
      AdminUserService
    ) ->
      $scope.adminHasPermission = AdminUserService.has
      stripe = Stripe($scope.subscriptionToken)
      elements = stripe.elements()
      cardReady = false

      $scope.cardParams =
        name: ''

      cardElement = elements.create('card')
      cardElement.mount('#card')

      $scope.formReady = ->
        $scope.cardParams.name && cardReady

      onCardChange = ->
        cardElement.addEventListener 'change', (event) ->
          $timeout ->
            cardReady = event.complete
            if event.error
              $scope.errors = event.error.message
            else
              $scope.errors = ''

      onCardChange()

      $scope.changePaymentMethod = ->
        $scope.paymentMethod.isEditing = true

      $scope.cancelCardEditing = ->
        $scope.paymentMethod.isEditing = undefined

      getPaymentIntent = ->
        url = "/subscription_payment_methods/generate_intent?gymId=#{$stateParams.gymId}"
        $http.post(url)

      $scope.submitCard = ->
        loadingAnimation.show()
        getPaymentIntent().then (response) ->
          createToken(response.data)
        , () ->
          loadingAnimation.hide()
          flash.error = "There was an error processing the request."

      createToken = (paymentIntent) ->
        options =
          billing_details:
            name: $scope.cardParams.name
        stripe.handleCardSetup(paymentIntent.client_secret, cardElement, {
          payment_method_data: options
        }).then (setupIntentResult) ->
          $timeout ->
            if setupIntentResult.error
              $scope.errors = setupIntentResult.error.message
              loadingAnimation.hide()
            else
              saveCard(setupIntentResult.setupIntent.payment_method)

      saveCard = (token) ->
        url = "/subscription_payment_methods"
        params =
          gym_id: $stateParams.gymId
          token: token
          cardholderName: $scope.cardParams.name

        $http.post(url, params).then (response) ->
          if $scope.gym.on_trial_period || $scope.paymentMethod.last_4
            $scope.gym.subscribed_externally = false
            loadingAnimation.hide()
            $scope.setPaymentMethod(paymentMethod: response.data)
          else
            subscriptionsUrl = $state.href 'gym.subscriptions'
            $window.open subscriptionsUrl, '_self'
        , (response) ->
          loadingAnimation.hide()
          flash.error = response.data.message ||
            "There was an error processing the request."
