angular
  .module 'gym.components'
  .directive 'reportFilterValueInput', ->
    restrict: 'AE'
    template: require('templates/components/reports/report_filter_value_input.html.slim')
    scope:
      newFilter: '='
      filterType: '='
      dropdownOptions: '='
      disableFromNow: '='
      condition: '='
      periods: '='
      multipliers: '='
    controller: ($rootScope, $scope) ->
      $scope.users = $rootScope.gym.users
      $scope.locations = $rootScope.gym.locations

      $scope.displayTextInput = () ->
        $scope.filterType in ['location', 'user', 'dropdown'] &&
          $scope.condition?[0] in ['Contains', 'Does not contain']

      $scope.getFilterType = ->
        if $scope.filterType is 'month' && $scope.newFilter.relative_date
          'relative_month'
        else
          $scope.filterType
