angular
  .module 'gym.components'
  .directive 'calendarNavigation', ->
    restrict: 'E'
    template: require('templates/components/calendar/calendar_navigation.html.slim')
    scope:
      toDate: '&'
      period: '='
      startDate: '='
      page: '@'
      locations: '='
      instructors: '='
      eventTypes: '='
      styles: '='
      selectedLocations: '='
      selectedInstructors: '='
      selectedEventTypes: '='
      selectedStyles: '='
      multiSelect: '='
      displayOldEventsHiddenWarning: '='
      oldEventDisplayThresholdDate: '='
      unableToManageOtherContacts: '='
      disableShowAllInstructors: '='
      hideInstructor: '='
      onLocationsChange: '&'
      onInstructorsChange: '&'
      onEventTypesChange: '&'
      onStylesChange: '&'
      currentViewName: '='
      calendarView: '='
      calendarTitle: '='
      titleInline: '='
      standalone: '='
      timebarOnly: '='
      hidePeriods: '='
      params: '='
      onParamsChange: '&'
    controller: (
      $scope,
      $rootScope,
      $state,
      flash,
      EmploymentService,
      FeatureAvailabilityService,
      GTMService,
      EventService
    ) ->
      $scope.permissions = EmploymentService.get().permissions
      $scope.attendanceAvailable =
        FeatureAvailabilityService.isAvailable('attendance')
      $scope.isDatepickerOpen = false
      $scope.glossary = $rootScope.gym.glossary

      $scope.changeView = (view) ->
        switch view
          when 'calendar'
            if $scope.calendarView
              $scope.calendarView.calendar.changeView('month')
            else
              $state.go 'gym.calendar', view: 'calendar'
          when 'agenda'
            if $scope.calendarView
              $scope.calendarView.calendar.changeView('basicDay')
            else
              $state.go 'gym.calendar', view: 'daily_agenda'
          when 'freeTrials'
            $state.go 'gym.freeTrials'
          when 'trainers'
            $state.go 'gym.trainers'
          when 'onlineMeetings'
            $state.go 'gym.onlineMeetings'

      $scope.changePeriod = (period) ->
        return if $scope.isPeriodActive(period)
        switch period
          when 'month'
            $scope.calendarView.calendar.changeView('month')
          when 'week'
            switch
              when $scope.currentViewName is 'basicDay'
                $state.go 'gym.weeklyAgenda'
              when $scope.currentViewName is 'trainers'
                $state.go 'gym.weeklyTrainers'
              else
                $scope.calendarView.calendar.changeView('agendaWeek')
          when 'day'
            switch
              when $scope.calendarView
                $scope.calendarView.calendar.changeView('agendaDay')
              when $scope.currentViewName is 'weeklyTrainers'
                $state.go 'gym.trainers'
              else
                $state.go 'gym.calendar', view: 'daily_agenda'

      $scope.isViewActive = (view) ->
        switch view
          when 'calendar'
            !!$scope.calendarView && $scope.currentViewName isnt 'basicDay'
          when 'agenda'
            $scope.currentViewName in ['basicDay', 'weeklyAgenda']
          else
            view is $scope.currentViewName

      $scope.isPeriodActive = (period) ->
        switch period
          when 'day'
            $scope.currentViewName in ['agendaDay', 'basicDay', 'trainers']
          when 'week'
            $scope.currentViewName in ['agendaWeek', 'weeklyAgenda', 'weeklyTrainers']
          when 'month'
            $scope.currentViewName is 'month'

      $scope.toggleDatePicker = ->
        $scope.isDatepickerOpen = !$scope.isDatepickerOpen
        if $scope.isDatepickerOpen
          $scope.skipToDate = moment($scope.startDate).clone().toDate()

      $scope.onSkipToDateChange = (date) ->
        $scope.isDatepickerOpen = false
        $scope.toDate(date: moment(date, 'YYYY-MM-DD'))

      $scope.switch = (value) ->
        $scope.isDatepickerOpen = false
        period = $scope.period + 's'
        date = moment($scope.startDate).clone().add(value, period)
        $scope.toDate(date: date)

      $scope.onAddAppointmentClick = ->
        EventService.addAppointment()
        GTMService.trackPage($scope.page, 'Add new appointment')

      $scope.onAddEventClick = ->
        EventService.addEvent()
        GTMService.trackPage($scope.page, 'Add new event')

      $scope.periodButtonsVisible = ->
        !$scope.hidePeriods && $scope.currentViewName isnt 'freeTrials' && $scope.currentViewName isnt 'onlineMeetings'

      $scope.isToday = ->
        $scope.startDate?.isSame(moment(), $scope.period)

      $scope.today = ->
        $scope.toDate(date: moment())
