import plural from 'helpers/plural'

angular
  .module 'filters'
  .filter 'membershipAccessWebsiteBookAndPayMessageFilter', ($filter) ->
    (plan) ->
      classString = plural('classes', plan.classes_limit, true)
      intervalUnitString =
        plural(plan.classes_interval_unit, plan.classes_interval_number)

      intervalNumberString =
        if plan.classes_interval_number > 1
          plan.classes_interval_number
        else ''

      intervalString =
        if plan.classes_unlimited || !plan.classes_interval_number || !plan.classes_interval_unit
          ''
        else
          "every #{intervalNumberString} #{intervalUnitString}"

      switch
        when plan.classes_unlimited
          "Allows: unlimited classes"
        when plan.classes_limit
          "Allows: #{classString} #{intervalString}"
        else
          ''
