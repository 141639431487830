angular
  .module 'shared'
  .directive 'confirmBulkMessage', ->
    restrict: 'E'
    template: require('templates/shared/confirm_bulk_message.html.slim')
    scope:
      bulkMessage: '='
      externalPage: '='
      visible: '='
    controller: (
      $scope,
      $filter,
      $stateParams,
      $timeout,
      ngTableParams,
      MessageService,
      bulkMessageService,
      ReactModal
    ) ->
      $scope.gymId = $stateParams.gymId
      $scope.allRecipientsSelected = true
      getTotal = () ->
        $scope.bulkMessage?.recipientList?.length || 0

      $scope.orderBy =
        display_last_name: 'asc'

      $scope.tableParams = new ngTableParams
        page: 1
        count: 50
      ,
        counts: []
        total: getTotal()
        getData: ($defer, params) ->
          orderKey = _.keys($scope.orderBy)[0]
          reverse = $scope.orderBy[orderKey] is 'desc'
          recipients = $filter('orderBy')($scope.bulkMessage.groupedRecipients, orderKey, reverse)
          params.total getTotal()
          $defer.resolve(
            recipients
              .slice((params.page() - 1) * params.count(), params.page() * params.count())
          )

      $timeout ->
        $scope.$watch 'bulkMessage', ->
          $scope.tableParams.reload()
        , true

      $scope.isEmail = -> $scope.bulkMessage.template.channel is 'email'
      $scope.isSms = -> $scope.bulkMessage.template.channel is 'sms'
      $scope.isPushNotification = -> $scope.bulkMessage.template.channel is 'push_notification'

      $scope.pluralMessageType = ->
        bulkMessageService.pluralMessageType $scope.bulkMessage.template.channel, $scope.bulkMessage.recipientList

      $scope.displayNotNotifiableMessage = ->
        MessageService.message
          title: 'Non-contactable contacts'
          message: [
            'For a push notification to be delivered successfully, a contact will need to have used the mobile app recently.',
            'If a contact has not used the mobile app within the last 2 months, Clubworx is obliged to remove their device ID from our records. In this case, email or SMS messages may be a better choice.'
          ]

      $scope.notNotifiableCount = ->
        notNotifiableContacts = _.filter $scope.bulkMessage.groupedRecipients, (item) ->
          item.push_notification_status not in ['notifiable', 'access_disabled']
        notNotifiableContacts.length

      checkAllRecipientsSelected = () ->
        $scope.allRecipientsSelected =
          _.every $scope.bulkMessage.recipientList, (recipient) ->
            recipient.selected or isContactUnsubscribed(recipient)

      $scope.toggleContact = (contact) ->
        $scope.bulkMessage.recipientList =
          $scope.bulkMessage.recipientList.map (item) ->
            if item.id is contact.id
              item.selected = !item.selected
            item
        checkAllRecipientsSelected()
        getDuplicateCount()

      $scope.isContactSelected = (contact) ->
        item = _.find $scope.bulkMessage.recipientList, (i) -> contact.id is i.id
        item?.selected

      toggleRecipients = (recipients, value) ->
        channel = $scope.bulkMessage.template.channel
        recipients.map (recipient) ->
          selectedSms = channel is 'sms' and !recipient.unsubscribed_from_sms and recipient.phone_number
          selectedEmail = channel is 'email' and !recipient.unsubscribed_from_email and recipient.email
          selectedPushNotification = channel is 'push_notification' and recipient.push_notification_status is 'notifiable'
          if selectedSms || selectedEmail || selectedPushNotification
            recipient.selected = value
          recipient

      $scope.toggleRecipients = ->
        $scope.allRecipientsSelected = !$scope.allRecipientsSelected
        $scope.bulkMessage.recipientList =
          toggleRecipients $scope.bulkMessage.recipientList, $scope.allRecipientsSelected
        getDuplicateCount()

      $scope.showPageRange = ->
        prevPagesCount = ($scope.tableParams.page() - 1) * $scope.tableParams.count()
        "#{ prevPagesCount + 1 } - #{ prevPagesCount + $scope.tableParams.data.length }"

      $scope.toggleSorting = (column) ->
        params = {}
        order = if $scope.orderBy[column] is 'asc' then 'desc' else 'asc'
        params[column] = order
        $scope.orderBy = params
        $scope.tableParams.reload()

      $scope.isSortedBy = (column, order) ->
        $scope.orderBy[column] is order

      $scope.emailNotAccepted = (recipient) ->
        recipient.email_status is 'email_not_accepted'

      $scope.smsNotAccepted = (recipient) ->
        recipient.sms_status is 'sms_not_accepted'

      getDuplicateCount = ->
        $scope.duplicateCount =
          bulkMessageService.getDuplicateCount($scope.bulkMessage.recipientList, $scope.bulkMessage.template.channel)

      $scope.$watch 'visible', ->
        getDuplicateCount()

      $scope.manageDuplicateRecipients = () ->
        modalInstance = ReactModal.open(
          component: 'ManageDuplicateRecipientsModal'
          drawer: true
          props:
            recipients: bulkMessageService.getDuplicatesGroups($scope.bulkMessage.recipientList, {type: $scope.bulkMessage.template.channel, includeDeselected: true})
            channel: $scope.bulkMessage.template.channel
        )
        modalInstance.then () ->
          getDuplicateCount()
          checkAllRecipientsSelected()

      $scope.isContactUnsubscribed = isContactUnsubscribed = (contact) ->
        if $scope.isEmail()
          return contact.unsubscribed_from_email || !contact.email
        if $scope.isSms()
          return contact.unsubscribed_from_sms || !contact.phone_number
        if $scope.isPushNotification()
          return contact.push_notification_status not in ['notifiable', 'access_disabled']
