angular
  .module 'shared'
  .directive 'iconChat', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.78084 18.3056C9.56084 18.3239 9.34084 18.3331 9.12084 18.3331C8.04834 18.3331 7.03083 18.0764 6.12333 17.6273L2.55749 18.1406L3.09834 14.5564H3.1075C2.74084 13.7498 2.52084 12.8606 2.52084 11.9164C2.52084 10.1839 3.22666 8.61643 4.36333 7.46143" stroke="{{color || 'currentColor'}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.955 10.7982C10.36 10.7982 10.6883 10.4781 10.6883 10.0832C10.6883 9.68826 10.36 9.36816 9.955 9.36816C9.55001 9.36816 9.22166 9.68826 9.22166 10.0832C9.22166 10.4781 9.55001 10.7982 9.955 10.7982Z" fill="{{color || 'currentColor'}}"/><path d="M12.8883 10.7982C13.2933 10.7982 13.6217 10.4781 13.6217 10.0832C13.6217 9.68826 13.2933 9.36816 12.8883 9.36816C12.4833 9.36816 12.155 9.68826 12.155 10.0832C12.155 10.4781 12.4833 10.7982 12.8883 10.7982Z" fill="{{color || 'currentColor'}}"/><path d="M15.8125 10.7982C16.2175 10.7982 16.5458 10.4781 16.5458 10.0832C16.5458 9.68826 16.2175 9.36816 15.8125 9.36816C15.4075 9.36816 15.0792 9.68826 15.0792 10.0832C15.0792 10.4781 15.4075 10.7982 15.8125 10.7982Z" fill="{{color || 'currentColor'}}"/><path d="M18.9017 12.7232L19.4425 16.3073L15.8767 15.794C15.235 16.1148 14.5383 16.3348 13.7958 16.4357C13.5025 16.4815 13.1908 16.4998 12.8792 16.4998C9.23083 16.4998 6.28833 13.6307 6.28833 10.0832C6.28833 8.314 7.0125 6.73734 8.20416 5.57317C9.38666 4.39067 11.0458 3.6665 12.8883 3.6665C16.5367 3.6665 19.4792 6.53567 19.4792 10.0832C19.4792 11.0273 19.2592 11.9165 18.8925 12.7232H18.9017Z" stroke="{{color || 'currentColor'}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
    '''
