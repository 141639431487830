angular
  .module 'shared'
  .directive 'reactTemplateEditor', (
  ) ->
    restrict: 'E'
    template: require('templates/shared/react_template_editor.html.slim')
    scope:
      template: '='
      hideName: '='
      displayPurpose: '='
      displaySequenceMergeTags: '='
      displayRollCallMergeTags: '='
      displayBookingMergeTags: '='
      displayMembershipMergeTags: '='
      displayPaymentMergeTags: '='
      displayGradingEventMergeTags: '='
      error: '='
      toolbarStatic: '='
      disabled: '='
      onLoad: '&'
    controller: ($scope, $rootScope) ->
      $scope.mobileAppMeta = $rootScope.mobileAppMeta

      $rootScope.$watch 'mobileAppMeta', (meta) ->
        $scope.mobileAppMeta = meta

      $scope.onChange = (template) ->
        angular.extend $scope.template, template
