angular
  .module 'gym.components'
  .directive 'calendarNavigationButtons', ->
    restrict: 'E'
    scope:
      setDailyAgenda: '&'
      currentView: '='
    template:
      '''
        <div class="calendar-navigation-buttons">
          <div class="btn-group" dropdown>
            <button class="btn fc-button fc-state-default dropdown-toggle" type="button">
              <span>Other Views</span>
              <i class="icon-chevron-down"></i>
            </button>
            <ul class="dropdown-menu is-user-area" role="menu">
              <li ng-if="isOnCustomView()">
                <a ng-click="onDefaultViewClick()">Day / Week / Month</a>
              </li>
              <li ng-class="{'active': currentView().name == 'basicDay'}">
                <a ng-click="onDailyAgendaClick()">Daily Agenda</a>
              </li>
              <li ui-sref-active="active">
                <a ui-sref="gym.weeklyAgenda"">Weekly Agenda</a>
              </li>
              <li ui-sref-active="active">
                <a ui-sref="gym.freeTrials">{{glossary['free_trials_link'] | titleize}}</a>
              </li>
              <li ui-sref-active="active" ng-if="permissions.manage_other_contacts">
                <a ui-sref="gym.trainers">Daily Trainers</a>
              </li>
              <li ui-sref-active="active" ng-if="permissions.manage_other_contacts">
                <a ui-sref="gym.weeklyTrainers">Weekly Trainers</a>
              </li>
              <li ui-sref-active="active" ng-if="permissions.manage_events">
                <a ui-sref="gym.onlineMeetings">Online Meetings</a>
              </li>
            </ul>
          </div>
        </div>
      '''
    controller: ($scope, $rootScope, $state, EmploymentService) ->
      $scope.permissions = EmploymentService.get().permissions
      $scope.glossary = $rootScope.gym.glossary

      $scope.isOnCustomView = () ->
        !$scope.currentView

      $scope.onDefaultViewClick = () ->
        $state.go 'gym.calendar'

      $scope.onDailyAgendaClick = ->
        if $scope.currentView
          $scope.setDailyAgenda()
        else
          $state.go 'gym.calendar', view: 'daily_agenda'
