angular
  .module 'shared'
  .directive 'readMore', ($rootScope, $timeout) ->
    restrict: 'E'
    scope:
      text: '='
      maxHeight: '@'
    template:
      '''
      <div class="read-more" ng-class="{'read-more--trimmed' : isTrimmed}">
        <div class="js-read-more--full-content"
          ng-bind-html="text | linky : '_blank'"
        ></div>
      </div>
      <a ng-click="toggle()" ng-if="isExpanded || shouldBeTrimmed()">
        {{isExpanded ? 'show less' : 'read more'}}
      </a>
      '''
    link:
      post: ($scope, element) ->
        fullContent = ->
          angular.element(element[0].querySelector('.js-read-more--full-content'))

        visibleContent = ->
          angular.element(element[0].querySelector('.read-more'))

        $scope.shouldBeTrimmed = ->
          parseInt(fullContent().css('height')) > parseInt($scope.maxHeight)

        truncateContent = ->
          $timeout ->
            if $scope.shouldBeTrimmed()
              $scope.isTrimmed = true
              visibleContent().css('max-height': $scope.maxHeight)

        $timeout ->
          truncateContent()

        $scope.toggle = ->
          height = if $scope.isExpanded then $scope.maxHeight else 'none'
          visibleContent().css('max-height': height)
          $scope.isTrimmed = height is $scope.maxHeight
          $scope.isExpanded = !$scope.isExpanded
