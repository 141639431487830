angular
  .module 'shared'
  .factory 'FeatureNamesService', ($rootScope) ->
    memberPortal: (p = {}) ->
      defaultParams =
        divider: '/'
      params = _.defaults(p, defaultParams)
      gym = $rootScope.gym

      switch
        when gym.mobile_app_enabled && gym.member_portal_enabled
          "mobile app #{params.divider} member portal"
        when gym.mobile_app_enabled && !gym.member_portal_enabled
          'mobile app'
        else
          'member portal'
