angular
  .module 'shared'
  .directive 'iconCheckCircleInverse', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12.5" cy="12.5" r="12.5" fill="{{color || 'currentColor'}}"/><path fill-rule="evenodd" clip-rule="evenodd" d="m10.938 13.56 3.853-3.853a1 1 0 0 1 1.414 0l.088.088a1 1 0 0 1 0 1.414l-4.648 4.648a1 1 0 0 1-1.414 0l-2.524-2.523a1 1 0 0 1 0-1.414l.088-.088a1 1 0 0 1 1.414 0l1.729 1.728Z" fill="#fff"/></svg>
    '''
