angular
  .module 'filters'
  .filter 'membershipPlanSequenceStartDate', ($rootScope, $filter, Months) ->
    (plan) ->
      if plan.specified_start_date
        return $filter('date')(plan.specified_start_date)

      if plan.default_start_date_strategy is 'start_on_next_upcoming'
        day = $filter('ordinalize')(plan.start_on_next_upcoming_day)
        month = if plan.start_on_next_upcoming_month
          Months[plan.start_on_next_upcoming_month - 1][1]
        else
          'any month'
        return "on the next #{day} day of #{month}"

      if plan.default_start_date_strategy is 'start_relative_to_purchase_date'
        period = $filter('pluralize')(plan.relative_start_date_unit, plan.relative_start_date_number, true)
        return "#{period} after sequence step fires"
