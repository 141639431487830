angular
  .module 'filters'
  .filter 'trialPeriodFilter', ->
    (payor) ->
      now = moment()
      trialEnds = moment.parseZone(payor.trial_period_expires_on)
      isOnTrial = trialEnds > now
      if isOnTrial
        daysLeft = trialEnds.diff(now, 'days')
        "On trial - #{daysLeft} days"
      else
        if payor.braintree_customer.data?.attributes?.clubworx_subscription_id
          'Paying Subscriber'
        else
          'Trial expired'
