import pluralize from 'pluralize'

angular
  .module 'shared'
  .factory 'restangularInterceptors', ($window) ->

    RequestInterceptor: (element, operation, route) ->
      data = angular.copy element
      if operation is 'post' or operation is 'put'
        unless data.withoutNamespace
          wrappedElement = {}
          name = data.rootObjectName or pluralize.singular(route)
          data.rootObjectName = undefined
          wrappedElement[name] = data
          data = wrappedElement
        data.withoutNamespace = undefined
      return data

    ResponseInterceptor: (data, operation, what, url) ->
      $window.location.reload() if data.kiosk_mode
      if operation is 'getList'
        if _.isArray(data)
          data.meta = {} if !data.meta
        else
          collectionName = ''
          collection = _.find data, (value, key) ->
            if _.isArray value
              collectionName = key
              value
          if collectionName
            collection.meta =
              angular.extend _.omit(data, [collectionName, 'meta']), data?.meta
            data = collection
          else
            data = []
            data.meta = {}
            data

      if operation is 'get'
        modelName = pluralize.singular(url.split('/').reverse()[1])
        if data[modelName]
          data = data[modelName]
      data
