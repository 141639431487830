angular
  .module 'shared'
  .factory 'bulkMessageService', (
    $rootScope,
    $state,
    $http,
    $q,
    Restangular,
    TemplateSanitizeService
  ) ->
    bulkMessage =
      recipientList: []
      recipients: []
      groupedRecipients: []
      template: {}

    gymId =
      if $rootScope.toParams then $rootScope.toParams?.gymId else $state.params.gymId

    resource = Restangular.one('gyms', gymId).all('bulk_messages')

    bulkMessageService =
      get: ->
        bulkMessage

      set: (message) ->
        deferred = $q.defer()
        bulkMessage = message
        buildRecipientList(bulkMessage.recipients).then (result) ->
          bulkMessage.recipientList = result.recipientList
          bulkMessage.groupedRecipients = result.groupedRecipients
          deferred.resolve bulkMessage
        deferred.promise

      clear: ->
        bulkMessage =
          recipientList: []
          recipients: []
          groupedRecipients: []
          template: {}

      create: (bulkMessage, params) ->
        template = TemplateSanitizeService.sanitize bulkMessage.template
        contact_ids = _.chain(bulkMessage.recipientList)
          .filter (recipient) -> recipient.selected
          .map (recipient) -> recipient.id
          .value()

        template.event_id = params.event_id if params.event_id
        template.invoice_id = params.invoice_id if params.invoice_id

        message =
          withoutNamespace: true
          bulk_message:
            contact_comms_recipient_ids: contact_ids
            report_ids: bulkMessage.reportIds
            template_options: template
            source: params.source

        @clear()
        resource.post(message)

      setRecipients: (recipients, params) ->
        bulkMessage.recipients = recipients.map (recipient) ->
          recipient.key = recipient.id
          recipient

      getDuplicatesGroups: (recipients, params) ->
        return [] unless params.type in ['email', 'sms']
        keyMap =
          email: 'email'
          sms: 'phone_number'
        key = keyMap[params.type]

        selected = recipients.map((item) -> angular.extend({}, item)).filter (recipient) ->
          params.includeDeselected || recipient.selected

        grouped = _.groupBy(selected, key)
        _.filter(grouped, (group) -> group.length > 1)

      getDuplicateCount: (recipients, type) ->
        withDuplicates = @getDuplicatesGroups(recipients, type: type)
        duplicates = _.flatten(withDuplicates)
        duplicates?.length

      syncDuplicates: (groups) ->
        items = _.chain(groups).flatten().indexBy('id').value()
        syncItem = (item) ->
          if items[item.id]
            item.selected = items[item.id].selected

        bulkMessage.recipientList.forEach (item) ->
          syncItem(item)

        bulkMessage.groupedRecipients.forEach (item) ->
          syncItem(item)
          if item.secondary?.length
            item.secondary.forEach (secondary) ->
              syncItem(secondary)

      pluralMessageType: (channel, recipients) ->
        count = _.filter(recipients, (item) -> item.selected).length
        switch channel
          when 'email'
            if count is 1 then 'Email' else 'Emails'
          when 'sms'
            if count is 1 then 'SMS' else 'SMSes'
          when 'push_notification'
            if count is 1 then 'Push notification' else 'Push notifications'

    buildRecipientList = (recipients) ->
      recipientList = []
      recipients.forEach (recipient) -> recipientList.push recipient
      createContactRecipientsList(recipientList)

    buildRecipient = (recipient, channel) ->
      recipient.primary_contact_id = recipient.contact_id
      if bulkMessage.template.channel is 'push_notification'
        recipient.for_primary_contact = true
      recipient.name = if recipient.for_primary_contact
        recipient.contact_name
      else
        recipient.name || recipient.family_member_name

      recipient.type = if recipient.for_primary_contact
        recipient.contact_type
      else
        recipient.family_member_type

      recipient.status = recipient.status || recipient.contact_status

      recipient.contact_id = if recipient.for_primary_contact
        recipient.contact_id
      else
        recipient.family_member_id

      recipient.email = if recipient.for_primary_contact
        recipient.contact_email
      else
        recipient.recipient_email

      recipient.phone_number = if recipient.for_primary_contact
        recipient.contact_phone_number
      else
        recipient.recipient_phone_number

      recipient.unsubscribed_from_email =
        recipient.email_status is 'email_unsubscribed'
      recipient.unsubscribed_from_sms =
        recipient.sms_status is 'sms_unsubscribed'

      recipient.selected = switch channel
        when 'sms'
          recipient.sms_status is 'sms_accepted' &&
          !recipient.unsubscribed_from_sms && !!recipient.phone_number
        when 'email'
          recipient.email_status is 'email_accepted' &&
          !recipient.unsubscribed_from_email && !!recipient.email
        when 'push_notification'
          recipient.push_notification_status is 'notifiable'
      recipient

    loadContactCommsRecipients = (contacts) ->
      gym = $rootScope.gym || window.DATA
      recipientLimit = gym.gym_config.bulk_comms_recipient_limit
      deferred = $q.defer()
      url = if bulkMessage.template.channel is 'push_notification'
        "/gyms/#{gymId}/mobile_app/push_notification_recipients/list"
      else
        "/gyms/#{gymId}/contact_comms_recipients/list"
      bulkMessage.reportIds =  _.uniq contacts
        .filter((item) -> item.route is 'reports')
        .map((item) -> item.id)
      contactCommsRecipientIds = contacts.find((item) -> item.route is 'bulk_message')?.recipient_ids || []
      ids = _.uniq contacts
        .filter((item) ->
          item.route not in ['reports', 'bulk_message'] && (item.for_primary_contact || item.for_primary_contact is undefined)
        ).map((item) -> item.contact_id || item.id)
      channel = bulkMessage.template.channel
      params =
        filters:
          contact_ids: ids
          report_ids: bulkMessage.reportIds
          contact_comms_recipient_ids: contactCommsRecipientIds
        count: recipientLimit
      $http.post(url, params).then (response) ->
        recipients = _.uniq(response.data.collection, false, (item) -> item.id).map (recipient) ->
          buildRecipient(recipient, channel)
        deferred.resolve recipients
      deferred.promise

    groupRecipients = (recipients) ->
      groups = _.values _.groupBy recipients, (item) -> item.primary_contact_id
      gg = groups.map (contactRecipients) ->
        group = _.groupBy contactRecipients, (item) ->
          if item.for_primary_contact then 'primary' else 'secondary'
        recipient = group.primary[0]
        recipient.secondary = group.secondary
        recipient

    createContactRecipientsList = (recipients) ->
      deferred = $q.defer()
      loadContactCommsRecipients(recipients).then (recipients) ->
        deferred.resolve {recipientList: recipients, groupedRecipients: groupRecipients(recipients)}

      deferred.promise

    return bulkMessageService
