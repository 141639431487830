import underscored from 'underscore.string/underscored';

angular
  .module 'gym.components'
  .directive 'relationshipList', ->
    restrict: 'E'
    template: require('templates/components/contacts/forms/relationship_list.html.slim')
    scope:
      contact: '='
      relationships: '='
      form: '='
      archive: '&'
      editSection: '&'
    controller: ($scope, $state, ContactBadgeService, NewContactService) ->

      $scope.contactType = (rel) ->
        underscored(rel.related_contact_type + 's').toLowerCase()

      $scope.contactBadgeClass = (type, status) ->
        ContactBadgeService.get(type, status)

      $scope.displayLinkToNewContactSelect = ->
        $scope.linkToNewContactSelectVisible = true

      $scope.hideLinkToNewContactSelect = ->
        $scope.linkToNewContactSelectVisible = false

      $scope.linkToNewContactParams =
        contactType: 'members'
        relationshipForType: 'Child'
        relationshipForId: $scope.contact.id

      $scope.contactTypes = [
        ['members', 'Member']
        ['prospects', 'Prospect']
        ['non_attending_contacts', 'Non-attending contact']
      ]

      $scope.goToNewContact = ->
        NewContactService.clear()
        params = angular.extend {}, $scope.linkToNewContactParams
        params.relationshipForType = encodeURIComponent $scope.linkToNewContactParams.relationshipForType
        $state.go 'gym.contacts.new', params
