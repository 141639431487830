angular
  .module 'shared'
  .directive 'iconCopy', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.6668 0.666504H2.66683C1.9335 0.666504 1.3335 1.2665 1.3335 1.99984V11.3332H2.66683V1.99984H10.6668V0.666504ZM12.6668 3.33317H5.3335C4.60016 3.33317 4.00016 3.93317 4.00016 4.6665V13.9998C4.00016 14.7332 4.60016 15.3332 5.3335 15.3332H12.6668C13.4002 15.3332 14.0002 14.7332 14.0002 13.9998V4.6665C14.0002 3.93317 13.4002 3.33317 12.6668 3.33317ZM12.6668 13.9998H5.3335V4.6665H12.6668V13.9998Z" fill="{{color || 'currentColor'}}"/></svg>
    '''
