import { hexToRgb } from 'helpers/colors'

angular
  .module 'shared'
  .factory 'ContrastColor', ->
    get: (bgColor, params) ->
      return null unless bgColor
      rgb = hexToRgb(bgColor)
      return null unless rgb
      R = rgb.r
      G = rgb.g
      B = rgb.b
      C = [
        R / 255
        G / 255
        B / 255
      ]
      C.forEach (Ci, i) ->
        if Ci <= 0.03928
          C[i] = Ci / 12.92
        else
          C[i] = ((Ci + 0.055) / 1.055) ** 2.4
      L = 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2]
      if L > 0.6
        params?.darkColor || '#000'
      else
        '#fff'
