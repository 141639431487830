import React from 'react';

const ModalPlaceholder = () => {
  return (
    <div>
      <div className="modal-header">
        <div className="loading-placeholder" style={{ height: 30 }}></div>
      </div>
      <div className="modal-body">
        <div className="loading-placeholder" style={{ height: 50, marginBottom: 15 }}></div>
        <div className="loading-placeholder" style={{ height: 50, marginBottom: 15 }}></div>
        <div className="loading-placeholder" style={{ height: 50 }}></div>
      </div>
    </div>
  );
};

export default ModalPlaceholder;
