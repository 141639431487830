angular
  .module 'gym.components'
  .directive 'editFormSection', ->
    restrict: 'E'
    template: require('templates/components/contacts/forms/edit_form_section.html.slim')
    scope:
      form: '='
      formValue: '='
      save: '&'
      cancel: '&'
    controller: (
      $scope,
      $rootScope,
      $stateParams,
      FormFieldDisplayingService
    ) ->
      $scope.isFieldAvailable = (field) ->
        contactType = $stateParams.contactType.slice(0, -1)
        FormFieldDisplayingService.isDisplaying(contactType, field)

      $scope.units = $rootScope.gym.units
