angular.module('shared')
  .factory 'AdminUserService', ->
    admin = undefined

    getAdminUser = ->
      el = $('#admin-user-data')
      return unless el.html()

      attrs = $.parseJSON el.html()
      el.remove()
      admin = parseAdminUser attrs
      admin

    parseAdminUser = (admin) ->
      permissions =
      admin.permissions = {}
      admin.admin_permissions.forEach (permission) ->
        admin.permissions[permission] = true
      admin

    AdminUserService =
      get: ->
        admin || getAdminUser()

      has: (permission) ->
        getAdminUser() unless admin

        if !admin
          true
        else
          admin.permissions[permission]

    return AdminUserService
