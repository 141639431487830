angular
  .module 'gym.components'
  .directive 'ezidebitBankAccountForm', ->
    restrict: 'EA'
    scope:
      gym: '='
      contact: '='
      customer: '='
      newCustomer: '='
      updateThisContactOnly: '='
      paymentAccount: '='
      closeModal: '&'
      isCustomerExist: '&'
      onCreate: '&'
      onUpdate: '&'
    template: require('templates/components/contacts/wallet/ezidebit_bank_account_form.html.slim')
    controller: ($scope, $timeout, EzidebitService) ->
      publicKey = $scope.paymentAccount.public_key
      endpoint = $scope.paymentAccount.payment_system_url
      $scope.bankAccount = bankAccount = {
        firstName: $scope.contact.contact_information.first_name
        lastName: $scope.contact.contact_information.last_name
      }
      $scope.customerReference = EzidebitService.getCustomerReference({gymId: $scope.gym.id, contactId: $scope.contact.id, context: 'CP'})

      initChangePaymentDetails = ->
        $timeout ->
          window.initEziDebit()
          eziDebit.init publicKey, {
            submitAction: 'ChangeCustomerPaymentInfo'
            submitButton: 'submit-ezidebit-bank-account-form'
            submitCallback: onUpdate
            submitError: onError
            customerRef: 'customerRef'
            accountName: 'accountName'
            accountBSB: 'bsb'
            accountNumber: 'accountNumber'
          }, endpoint

      initCreateCustomer = ->
        $timeout ->
          params =
            submitAction: 'SaveCustomerAccount'
            submitButton: 'submit-ezidebit-bank-account-form'
            submitCallback: onCreate
            submitError: onError
            customerFirstName: 'firstName'
            customerLastName: 'lastName'
            accountName: 'accountName'
            accountBSB: 'bsb'
            accountNumber: 'accountNumber'
            customerReference: 'customerReference'

          if $scope.customer.clean_contact_email
            params.customerEmail = 'customerEmail'
          if $scope.customer.clean_contact_mobile_number
            params.customerMobile = 'customerMobile'

          window.initEziDebit()
          eziDebit.init publicKey, params, endpoint

      if $scope.isCustomerExist()
        initChangePaymentDetails()
      else
        initCreateCustomer()

      onSuccess = (response) ->
        $scope.newCustomer.noPaymentMethod = undefined
        $scope.bankAccount.error_message = undefined
        enableForm()
        getWalletDetails(response.Data)

      onUpdate = (response) ->
        $timeout ->
          customer = onSuccess(response)
          $scope.onUpdate(customer: customer)

      onCreate = (response) ->
        $timeout ->
          customer = onSuccess(response)
          $scope.onCreate(customer: customer)

      onError = (error) ->
        $timeout ->
          enableForm()
          $scope.bankAccount.error_message = error

      $scope.$watch 'bankAccount.form.$valid', (value) ->
        $scope.$emit 'contact:paymentMethod:formStatusChanged', value

      getWalletDetails = (customer) ->
        length = bankAccount.accountNumber.length
        wallet =
          customer_ref: customer.CustomerRef || $scope.customer.customer_ref
          last4: bankAccount.accountNumber.substr length-4
          full_name: bankAccount.accountName
          bsb: bankAccount.bsb
          rootObjectName: 'payment_method'
          payment_method: 'bank_account'
        wallet.exp_month = ''
        wallet.exp_year = ''
        wallet

      enableForm = ->
        $scope.isFormDisabled = false
