angular
  .module 'shared'
  .factory 'ConfirmBulkMessageService', (
    $rootScope,
    $q,
    $modal,
    $http,
    SmsValidationService,
  ) ->
    confirm: (bulkMessage, gym) ->
      deferred = $q.defer()
      singleCount = SmsValidationService.getMessagesCount(bulkMessage.template.content)
      recipients = bulkMessage.recipientList.filter((recipient) -> recipient.selected).length
      count = singleCount * recipients

      showWarning = gym.has_subscription && gym.charges_for_sms &&
        gym.bulk_sms_warning_threshold > 0 && gym.per_sms_cost_cents > 0 &&
        bulkMessage.template.channel is 'sms' && count >= gym.bulk_sms_warning_threshold

      if showWarning
        modalInstance = $modal.open
          template: require('templates/gyms/bulk_messages/large_message_count_warning.html.slim')
          resolve:
            count: -> count
            fee: -> gym.per_sms_cost_cents / 100
            currency: -> gym.billing_currency_symbol
            freeSmsThreshold: -> gym.free_sms_threshold
            smsPartsSentThisMonth: ->
              url = "/gyms/#{gym.id}/sent_messages/smses_parts_this_month"
              $http.get(url).then (response) ->
                response.data.sms_parts_sent_this_month
          controller: ($scope, $rootScope, $modalInstance, count, fee, currency, freeSmsThreshold, smsPartsSentThisMonth) ->
            gym = $rootScope.gym
            $scope.count = count
            $scope.fee = fee
            $scope.currency = currency
            $scope.freeSmsThreshold = freeSmsThreshold
            $scope.month = moment().format('MMMM')
            freeBalance = (freeSmsThreshold - smsPartsSentThisMonth) * fee
            $scope.freeBalance = if freeBalance >= 0 then freeBalance else 0

            logWarning = ->
              url = "gyms/#{gym.id}/sent_messages/log_excess_sms_warning"
              $http.post(url)

            logWarning()

            $scope.submit = ->
              $modalInstance.close()

            $scope.cancel = ->
              $modalInstance.dismiss('cancel')
        modalInstance.result.then () ->
          deferred.resolve()
      else
        deferred.resolve()

      deferred.promise
