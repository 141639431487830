angular.module 'shared'
  .factory 'FileDownloadService', () ->
    downloadWithPost: (url, params) ->
      authToken = angular.element('meta[name=csrf-token]').attr('content')
      form = angular.element('<form></form>').attr('action', url).attr('method', 'post')
      form.append(angular.element("<input></input>").attr('type', 'hidden').attr('name', 'authenticity_token').attr('value', authToken))
      if params
        _.each params, (val, key) ->
          if Array.isArray(val)
            _.each val, (item) ->
              form.append(angular.element("<input></input>").attr('type', 'hidden').attr('name', "#{key}[]").attr('value', item))
          else
            form.append(angular.element("<input></input>").attr('type', 'hidden').attr('name', key).attr('value', val))
      form.appendTo('body').submit().remove()
