angular
  .module 'gym.components'
  .directive 'gymActivityWidget', ->
    restrict: 'EA'
    scope:
      gym: '='
      privacyModeEnabled: '='
    template: require('templates/components/dashboard/gym_activity_widget.html.slim')
    controller: ($scope, $http, ActivityService) ->
      $scope.isLoading = true
      $scope.activityTypes =
        _.union([[undefined, 'All activities']], $scope.gym.activity_filter_types)

      $scope.getActivities = (type) ->
        $scope.isLoading = true
        params =
          gym_id: $scope.gym.id
          'types[]': type

        $http.get('recent_activity', params: params)
          .then (response) ->
            $scope.isLoading = false
            $scope.activity = response.data

      $scope.getActivities()

      $scope.contactType = (type) ->
        "#{type?.toLowerCase()}s"

      $scope.getFeedHeight = ->
        dashboardHeight = angular.element(".dashboard-module-side").height()
        tasksHeight = if angular.element(".due-tasks-widget")
          angular.element(".due-tasks-widget").outerHeight()
        else
          0
        activityHeight = angular.element(".activity-feed").height()
        feedHeight = dashboardHeight - tasksHeight
        feedHeight if (feedHeight < activityHeight)

      $scope.activityIconClass = (event) ->
        ActivityService.getIconClass(event)
