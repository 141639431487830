angular
  .module 'shared'
  .directive 'iconCheckCircle', ->
    restrict: 'E'
    replace: 'true'
    scope:
      color: '@'
    template: '''
      <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.2292 19.7083C6.545 19.7083 2.75 15.9133 2.75 11.2292C2.75 6.545 6.545 2.75 11.2292 2.75C15.9133 2.75 19.7083 6.545 19.7083 11.2292C19.7083 15.9133 15.9133 19.7083 11.2292 19.7083Z" stroke="{{color || 'currentColor'}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.8075 12.6773L9.19416 11.064" stroke="{{color || 'currentColor'}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.0341 9.45068L10.8075 12.6773" stroke="{{color || 'currentColor'}}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
    '''
