angular
  .module 'gym.components'
  .directive 'financialsWidget', ->
    restrict: 'EA'
    template: require('templates/components/dashboard/financials_widget.html.slim')
    scope:
      privacyModeEnabled: '='
      gym: '='
      netRevenueVisible: '='
      monthlyRevenueVisible: '='
    controller: ($scope, Dashboard, graphService, EmploymentService) ->
      $scope.isLoading = true
      $scope.currency = $scope.gym.currency_symbol
      $scope.permissions = EmploymentService.get().permissions

      $scope.$on 'dashboard:refresh', () ->
        load()

      revenueReportParams =
        lineColor: '#fad1af'
        selector: '#revenue-chart'
        yFormat: '$,2f'
        fillArea: true
        lineType: 'monotone'
        currency: [$scope.currency, ""]
        margin: {top: 10, left: 50, right: 15, bottom: 20}
        hideMaxMin: true

      mrrReportParams =
        lineColor: '#dfebba'
        selector: '#mrr-chart'
        yFormat: '$,2f'
        fillArea: true
        lineType: 'monotone'
        currency: [$scope.currency, ""]
        margin: {top: 10, left: 50, right: 15, bottom: 20}
        hideMaxMin: true

      parseGraphData = (data) ->
        data.map (item) ->
          {x: item.month, y: item.amount}

      load = ->
        Dashboard.customGET('financials').then (data) ->
          $scope.revenue = data.plain()
          graphService.setupGraph(parseGraphData(data.net_revenue_graph_data), revenueReportParams)
          graphService.setupGraph(parseGraphData(data.mrr_graph_data), mrrReportParams)

      load()
