import { hexToRgb } from 'helpers/colors'
import colors from 'constants/colors'

angular
  .module 'gym.components'
  .directive 'trainerEvent', ($rootScope, ContrastColor)->
    restrict: 'EA'
    template: require('templates/components/calendar/trainer_event.html.slim')
    replace: true
    scope:
      event: '='
      isAvailability: '='
    link: ($scope) ->
      gymConfig = $rootScope.gym.gym_config
      startTime = gymConfig.calendar_display_hours_override?[0] || 4
      date =
        moment($scope.event.start, 'YYYY/MM/DD HH:mm').startOf('day').add(startTime, 'hours')

      parseEventTime = (time) ->
        moment(time, 'YYYY/MM/DD HH:mm')

      $scope.event.startMoment = parseEventTime $scope.event.start
      $scope.event.endMoment = parseEventTime $scope.event.end

      $scope.formatEventTime = (time) ->
        time.format('h:mma')

      bgColor = (color) ->
        unless $scope.isAvailability
          rgb = hexToRgb(color || colors.primary) || hexToRgb(colors.primary)
          "rgb(#{rgb.r}, #{rgb.g}, #{rgb.b})"

      $scope.eventColor = eventColor = (color) ->
        unless $scope.isAvailability
          color || colors.primary

      $scope.fontColor = (color) ->
        unless $scope.isAvailability
          ContrastColor.get(eventColor(color), darkColor: '#303030')

      $scope.isFull = ->
        unless $scope.isAvailability
          typeof $scope.event.vacancies_count is 'number' && $scope.event.vacancies_count <= 0

      $scope.hasNote = ->
        unless $scope.isAvailability
          !!$scope.event.notes?.length

      $scope.getTitle = ->
        if $scope.isAvailability
          startTime = $scope.formatEventTime($scope.event.startMoment)
          endTime = $scope.formatEventTime($scope.event.endMoment)
          "#{$scope.event.user_name}'s availability: #{startTime} - #{endTime}"

      $scope.eventStyle = ->
        timeSlotHeight = 25
        minutesInTimeSlot = 30
        event = $scope.event
        minutesFromStartOfDay = event.startMoment.diff(date, 'minutes')
        duration = event.endMoment.diff(event.startMoment, 'minutes')

        style =
          top: "#{ minutesFromStartOfDay / minutesInTimeSlot * timeSlotHeight + timeSlotHeight}px"
          height: "#{duration / minutesInTimeSlot * timeSlotHeight}px"
          'background-color': if $scope.isAvailability then 'transparent' else '#fff'
          color: $scope.fontColor(event.color)

      $scope.eventInnerStyle = ->
        style =
          'background-color': bgColor($scope.event.color)
