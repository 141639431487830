angular
  .module 'shared'
  .directive 'exportButton', ->
    restrict: 'E'
    scope:
      export: '&'
      type: '@'
      size: '@'
      variant: '@'
    controller: ($scope, AdminUserService) ->
      $scope.adminHasPermission = AdminUserService.has

    template: '''
      <button
        type="{{type || 'button'}}"
        ng-if="adminHasPermission('admin_perform_csv_exports') && variant !== 'table-header'"
        class="btn btn-default"
        ng-click="export()"
        ng-class="{'btn-sm': size === 'sm'}"
      >
        <span class="hidden-xs">
          <i class="icon-download"></i>
        </span>
        Export
      </button>
      <button
        type="{{type || 'button'}}"
        ng-if="adminHasPermission('admin_perform_csv_exports') && variant === 'table-header'"
        class="export-button"
        ng-click="export()"
        tooltip="Export"
        tooltip-light=""
      >
        <i class="icon-download"></i>
      </button>
    '''
