import pluralize from 'pluralize'

angular
  .module 'filters'
  .filter 'membershipPlanSequenceRepeatingCost', ($rootScope, $filter) ->
    (plan, currency) ->
      if plan.cost_repeat > 0 && 'recurring' in [plan.type, plan.plan_type]
        symbol = currency || $rootScope.gym.currency_symbol
        cost_repeat = $filter('currency')(plan.cost_repeat, symbol)
        amount = if plan.charge_interval_number > 1
          "#{cost_repeat} every #{plan.charge_interval_number} #{plan.charge_interval_unit}"
        else
          "#{cost_repeat}/#{pluralize.singular(plan.charge_interval_unit)}"

        date = switch plan.default_repeat_date_strategy
          when 'start_repeat_payments_one_period_after_upfront'
            interval = $filter('pluralize')(plan.charge_interval_unit, plan.charge_interval_number, true)
            "#{interval} after initial payment"
          when 'start_repeat_payments_relative_to_start_or_upfront'
            interval = $filter('pluralize')(plan.relative_repeat_date_unit, plan.relative_repeat_date_number, true)
            "#{interval} after membership starts"
          when 'start_repeat_payments_on_membership_start_date'
            'on membership start date'

        "#{amount}, starting #{date}"
      else
        "No recurring payment"
