angular
  .module 'gym.components'
  .directive 'attendanceWidget', ->
    restrict: 'EA'
    template: require('templates/components/dashboard/attendance_widget.html.slim')
    scope:
      privacyModeEnabled: '='
    controller: ($scope, Dashboard) ->
      $scope.isLoading = true

      $scope.$on 'dashboard:refresh', () ->
        load()

      load = ->
        Dashboard.customGET('attendance').then (data) ->
          $scope.isLoading = false
          $scope.data = data

      load()
