angular
  .module 'shared'
  .directive 'sectionToggle', () ->
    restrict: 'A'
    link: ($scope, element, attributes) ->
      sectionCheckboxes = -> element.parent().parent().children()

      element[0].addEventListener 'click', (event) ->
        checked = event.target.checked
        sectionCheckboxes().each (index, node) ->
          input = angular.element(node).find('input')
          if input.attr('section-toggle') isnt ''
            input.prop('checked', checked)

      sectionCheckboxes().each (index, node) ->
        input = angular.element(node).find('input')
        if input.attr('section-toggle') isnt ''
          input[0].addEventListener 'click', () ->
            syncSelectAll()

      syncSelectAll = ->
        allCheckboxChecked = isAllCheckboxesChecked()
        angular.element(element[0]).prop('checked', allCheckboxChecked)

      isAllCheckboxesChecked = ->
        allChecked = true
        sectionCheckboxes().each (index, node) ->
          input = angular.element(node).find('input')
          if !input.prop('checked') and input.attr('section-toggle') isnt ''
            allChecked = false
            return
        allChecked

      syncSelectAll()
