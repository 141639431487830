angular
  .module 'gym.components'
  .directive 'integrapayBankAccountForm', ->
    restrict: 'EA'
    scope:
      bankAccount: '='
      contact: '='
      customer: '='
      form: '='
      gym: '='
      isDisabled: '='
      token: '='
    template: require('templates/components/contacts/wallet/integrapay_bank_account_form.html.slim')
