angular
  .module 'gym.components'
  .directive 'triggerSequenceCondition', ->
    restrict: 'EA'
    template: require('templates/components/sequences/triggerSequenceCondition.html.slim')
    replace: true
    scope:
      sequence: '='
      step: '='
      isForseeable: '='
      isFollowable: '='
      isFloatingFollowable: '='
      isTask: '='
      isMembership: '='
      isWorkout: '='
      isStyle: '='
    controller: ($scope) ->
      conditions = $scope.step.sequence_conditions_attributes
      conditionIndex = _.findIndex conditions, (condition) ->
        condition.type is 'TriggerSequenceCondition'
      $scope.condition = $scope.step.sequence_conditions_attributes[conditionIndex]

      getTriggerTime = ->
        $scope.condition.trigger_time_before ||
          $scope.condition.trigger_time_after || 0

      if $scope.condition
        $scope.condition.trigger_time = getTriggerTime()
        $scope.condition.trigger_time_unit ||= 'days'
        $scope.condition.timeCondition = if $scope.condition.trigger_time_after
          'after'
        else
          'before'

      $scope.getAction = ->
        if $scope.isTask || $scope.isMembership || $scope.isWorkout || $scope.isStyle
          return 'add'
        if $scope.isPortal || $scope.isMobileApp || $scope.isProspectStatus
          return 'change'
        'send'

      isHourlyAdvancedTriggers = ->
        $scope.sequence.trigger in $scope.sequence.hourly_advanced_triggers

      $scope.timeUnits = if ($scope.isForseeable && !isHourlyAdvancedTriggers()) || $scope.isFloatingFollowable
        ['days']
      else
        ['days', 'hours']

      $scope.timeConditions = ['after', 'before']

      $scope.onTimeConditionChanged = ->
        if $scope.condition.timeCondition is 'before'
          $scope.condition.trigger_time_before =
            $scope.condition.trigger_time_after || 0
          $scope.condition.trigger_time_after = 0
        else
          $scope.condition.trigger_time_after =
            $scope.condition.trigger_time_before || 0
          $scope.condition.trigger_time_before = 0

      $scope.onTriggerTimeChanged = ->
        if $scope.condition.timeCondition is 'before'
          $scope.condition.trigger_time_before =
            $scope.condition.trigger_time
        else
          $scope.condition.trigger_time_after =
            $scope.condition.trigger_time

      checkHours = ->
        if !$scope.condition.immediate && $scope.condition.trigger_time_unit is 'hours'
          $scope.condition.allowed_hours = []

      $scope.onImmediateChanged = ->
        $scope.condition.trigger_time = 0
        $scope.condition.trigger_time_after = 0
        $scope.condition.trigger_time_before = 0
        checkHours()

      $scope.onTimeUnitChange = ->
        checkHours()
