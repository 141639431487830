angular
  .module 'gym.components'
  .directive 'renderFormField', ->
    restrict: 'E'
    template: require('templates/components/contacts/forms/render_form_field.html.slim')
    scope:
      field: '='
      value: '='
    controller: ($scope, $rootScope) ->
      $scope.currency = $rootScope.gym.currency_symbol
      $scope.units = $rootScope.gym.units
      locations = $rootScope.gym.locations

      $scope.getLocation = (id) ->
        _.find locations, (location) -> location.id is id

      $scope.displayValue = ->
        $scope.value || '-'

      $scope.displayMass = ->
        if $scope.value
          "#{$scope.value} #{$scope.units.mass}"
        else
          '-'

      $scope.displayMeasurement = ->
        if $scope.value
          "#{$scope.value} #{$scope.units.measurement}"
        else
          '-'

      $scope.checkboxValue = (value) ->
        if value in [null, undefined]
          return '-'
        if value in ['t', true]
          'Yes'
        else
          'No'
