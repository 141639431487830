import {SMS_OPT_OUT_POLICY_HINT_TEXT} from 'react/types/constants'

angular
  .module 'gym.components'
  .directive 'contactMessageSender', ->
    restrict: 'E'
    scope:
      contact: '='
      messageType: '='
      messageParams: '='
      reloadContact: '='
      location: '@'
      close: '&'
    template: require('templates/components/contacts/comms/contact_message_sender.html.slim')
    controller: (
      $scope,
      $rootScope,
      $stateParams,
      $http,
      $filter,
      $timeout,
      $q,
      flash,
      Templates,
      ContactCommunicationService,
      ConversationLogService,
      SmsValidationService,
      PushNotificationValidationService,
      DefaultReplyAddressService,
      TestEmailsService,
      TemplateValidationService,
      ConfirmLeaveUnsavedChangesService,
      PushNotificationOptionsService,
      ReactModal,
    ) ->
      $scope.templates = []
      $scope.recipients = []
      initialTemplates = []
      $scope.gym = $rootScope.gym
      $scope.pushNotificationRoutes = [{label: "No preference (open app to last viewed screen)", value: ''}]

      $scope.loadPushNotificationRoutes = ->
        PushNotificationOptionsService.getOptions($scope.gym.id, {})
          .then (data) ->
            if data.length
              $scope.pushNotificationRoutes = $scope.pushNotificationRoutes.concat(data)
            else
              $scope.pushNotificationRoutes = data
          .catch (error) ->
            flash.error error

      $scope.loadPushNotificationRoutes()
      
      $scope.smsHintText = SMS_OPT_OUT_POLICY_HINT_TEXT

      $scope.isContactTab = ->
        $scope.location is 'tab'

      $scope.emailSenders = $scope.gym._model.attributes.reply_email_addresses
      $scope.replyEmails = (sender.email for sender in $scope.emailSenders)

      $scope.checkEmailAuthorization = (email) ->
        isEmailAuthorized = $scope.emailSenders.find((item) -> item.email == email)
        if isEmailAuthorized
          return isEmailAuthorized.authorized
        else
          return false

      $scope.getSmsOptions = (contactType) ->
        if contactType == 'prospect'
          $scope.prospectSmsOptions
        else if $scope.messageParams == 'debtors_report' || contactType == 'member'
          $scope.membersSmsOptions
        else
          $scope.nacSmsOptions

      $scope.resolveDefaultSmsOptionValue = () ->
        selectedPolicy = $scope.template?.selected?.sms_opt_out_instructions_policy || $scope.gym.presented_gym_sms_detail.sms_opt_out_instructions_policy
        if $scope.contact.type == 'prospect'
          'add_sms_opt_out_instructions_for_prospects_only'
        else if selectedPolicy == 'add_sms_opt_out_instructions_for_all_contacts'
          'add_sms_opt_out_instructions_for_all_contacts'
        else if selectedPolicy in ['add_sms_opt_out_instructions_for_prospects_only', 'add_sms_opt_out_instructions_for_prospects_and_cancelled_members_only'] && $scope.contact.type != 'member'
          'add_sms_opt_out_instructions_for_prospects_only'
        else if selectedPolicy == 'add_sms_opt_out_instructions_for_prospects_and_cancelled_members_only'
          'add_sms_opt_out_instructions_for_prospects_and_cancelled_members_only'
        else
          'add_sms_opt_out_instructions_for_prospects_only'

      $scope.showEmailVerificationWarning = (email) ->
        matchingSender = $scope.emailSenders.find((item) -> item.email == email)
        if matchingSender
          return !matchingSender.skip_warning

      $scope.prospectSmsOptions = [
        { label: "Add opt-out instructions to message (required for Prospects)", value: "add_sms_opt_out_instructions_for_prospects_only" }
      ]

      $scope.nacSmsOptions = [
        { label: "Add opt-out instructions to message", value: "add_sms_opt_out_instructions_for_all_contacts" },
        { label: "Do not add opt-out instructions to message", value: "add_sms_opt_out_instructions_for_prospects_only" }
      ]

      $scope.membersSmsOptions = [
        { label: "Add opt-out instructions to all messages", value: "add_sms_opt_out_instructions_for_all_contacts" },
        { label: "Add opt-out instructions for messages sent to cancelled Members only", value: "add_sms_opt_out_instructions_for_prospects_and_cancelled_members_only" },
        { label: "Do not add opt-out instructions to messages", value: "add_sms_opt_out_instructions_for_prospects_only" }
      ]

      $scope.listenerRegistered = false

      registerListener = () ->
        if $scope.listenerRegistered
          return
        $rootScope.$$listeners['send_message:request_close'] = [(event) ->
          $scope.onClose()
        ]
        $scope.listenerRegistered = true

      unregisterListener = () ->
        if $scope.listenerRegistered
          delete $rootScope.$$listeners['send_message:request_close']
          $scope.listenerRegistered = false

      registerListener()

      $scope.onClose = (skipConfirmation) ->
        if $scope.close
          if $scope.displayConfirmationOnClose && !skipConfirmation
            $scope.openConfirmationModal()
          else
            $scope.close()

      $scope.openConfirmationModal = () =>
        ReactModal.open(
          component: 'CloseUnsavedFormModal'
          props:
            title: 'Close drawer'
            text: 'Are you sure? This will abandon any unsaved changes.'
            cancel: =>
              $scope.displayConfirmationOnClose = false
              $scope.close()
        )

      if !$scope.isContactTab()
        ConfirmLeaveUnsavedChangesService.watch($scope, 'template', closeModal: () -> $scope.onClose(true))

      loadPushNotificationRecipientInfo = ->
        deferred = $q.defer()
        if $scope.gym.mobile_app_enabled
          url = "/gyms/#{$scope.gym.id}/mobile_app/push_notification_recipients/list"
          $http.post(url, filters: contact_ids: [$scope.contact.id]).then (response) ->
            deferred.resolve response.data.collection[0]
        else
          deferred.resolve undefined
        deferred.promise

      $scope.pushNotifcationsEnabled = ->
        $scope.gym.mobile_app_enabled && ($rootScope.mobileAppMeta?.push_notifications_feature_enabled || $scope.gym.push_notifications_feature_enabled) &&
          $scope.pushNotificationRecipient?.push_notification_status in ['notifiable', 'access_disabled']

      templatesCopy = ->
        initialTemplates.map (template) ->
          angular.extend {}, template

      loadTemplates = ->
        params =
          count: 1000
          'filters[for_general_use]': true
        $scope.isTemplatesLoading = true
        Templates.getList(params).then (templates) ->
          $scope.isTemplatesLoading = false
          initialTemplates = templates.plain()
          $scope.templates = templatesCopy()
          $timeout ->
            $scope.displayConfirmationOnClose = false

      preselectRecipients = ->
        $scope.recipients.map (item) ->
          available = switch $scope.template.selected.channel
            when 'sms'
              item.sms_status is 'sms_accepted' && !item.unsubscribed_from_sms &&
              !!item.recipient_phone_number
            when 'email'
              item.email_status is 'email_accepted' && !item.unsubscribed_from_email &&
              !!item.recipient_email
            when 'push_notification'
              $scope.pushNotifcationsEnabled()
          item.selected = available
          item.available = available
          item

      loadSecondaryRecipients = ->
        url = "/gyms/#{$stateParams.gymId}/contact_comms_recipients"
        recipientLimit = $scope.gym.gym_config.bulk_comms_recipient_limit
        params =
          'filters[contact_ids][]': [$scope.contact.id]
          count: recipientLimit
        promises = [
          $http.get(url, params: params),
          loadPushNotificationRecipientInfo()
        ]
        $q.all(promises).then (response) ->
          $scope.pushNotificationRecipient = response[1]
          $scope.recipients = response[0].data.collection.map (item) ->
            item.name = item.name || item.family_member_name || item.contact_name
            item.unsubscribed_from_email =
              item.email_status is 'email_unsubscribed'
            item.unsubscribed_from_sms =
              item.sms_status is 'sms_unsubscribed'
            item.contact_id = if item.for_primary_contact
              item.contact_id
            else
              item.family_member_id
            item.contact_type = if item.for_primary_contact
              item.contact_type
            else
              item.family_member_type
            item
          preselectRecipients()

      loadTemplates()
      loadSecondaryRecipients()

      $scope.isContactUnsubscribed = isContactUnsubscribed = (recipient) ->
        if $scope.template.selected.channel is 'email'
          recipient.unsubscribed_from_email || !recipient.recipient_email
        else
          recipient.unsubscribed_from_sms || !recipient.recipient_phone_number

      getAvailableChannel = ->
        unsubscribes = $scope.contact.unsubscribes
        unsubscribed_from_email = unsubscribes.unsubscribed_from_email
        unsubscribed_from_sms = unsubscribes.unsubscribed_from_sms
        if $scope.messageType
          return $scope.messageType
        channel = switch
          when !unsubscribed_from_email and !unsubscribed_from_sms
            $scope.template?.selected?.channel || 'email'
          when unsubscribed_from_sms and !unsubscribed_from_email then 'email'
          when unsubscribed_from_email and !unsubscribed_from_sms then 'sms'
          when $scope.pushNotifcationsEnabled() then 'push_notification'
          else 'email'
        channel

      $scope.updateChannel = ->
        $scope.template.selected ||= {}
        $scope.template.selected.key = Math.floor(Math.random() * 1000000)
        $scope.template.selected.channel = $scope.template.selected.channel || 'email'
        $scope.template.selected.reply_address = DefaultReplyAddressService.getDefaultEmail()
        $scope.template.selected.sms_opt_out_instructions_policy = $scope.resolveDefaultSmsOptionValue()
        
        if $scope.template?.selected?.push_notification_route_name == null && $scope.pushNotificationRoutes.length > 0
          $scope.template.selected.push_notification_route_name = $scope.pushNotificationRoutes[0].value

        preselectRecipients()
        $timeout ->
          $scope.displayConfirmationOnClose = false

      $scope.onChannelChange = ->
        preselectRecipients()

      $scope.template =
        selected:
          channel: getAvailableChannel()
          reply_address: DefaultReplyAddressService.getDefaultEmail()
          sms_opt_out_instructions_policy: $scope.resolveDefaultSmsOptionValue()
          push_notification_route_name: $scope.template?.selected.push_notification_route_name || $scope.pushNotificationRoutes[0].value

      $scope.submitTemplate = (template) ->
        $scope.isLoading = true
        if template.push_notification_route_name == ''
          template.push_notification_route_name = null
        ContactCommunicationService.send($scope.gym.id, $scope.contact.id, template, getRecipientsIds())
          .then () ->
            flash.success = 'The message was sent'
            $scope.isLoading = false
            $scope.templates = templatesCopy()
            $scope.template = {}
            getEmailSenderAddresses()
            $scope.updateChannel()
            $scope.onClose(true)
          , (error) ->
            flash.error = error
            $scope.isLoading = false

      $scope.sendTemplate = (template) ->
        if isEmailSenderEligibleForWarning(template)
          $scope.openUnverifiedEmailSenderWarningModal(template.reply_address, -> $scope.submitTemplate(template))
          return

        $scope.submitTemplate(template)

      isEmailSenderEligibleForWarning = (template) ->
        template.channel is 'email' &&
          !$scope.checkEmailAuthorization(template.reply_address) &&
          $scope.showEmailVerificationWarning(template.reply_address)

      getEmailSenderAddresses = () ->
        url = "/gyms/#{$scope.gym.id}/reply_email_addresses"
        $http.get(url).then (response) ->
          $scope.emailSenders = response.data
            
      getEmailSenderAddresses()

      getRecipientsIds = () ->
        $scope.recipients.filter((item) -> item.selected).map((item) -> item.id)

      $scope.isFreemiumWithoutPaymentMethod = ->
        $scope.template.selected.channel is 'sms' && $scope.gym.on_freemium &&
          !$scope.gym.subscription_payment_method_present && $scope.gym.sms_enabled

      $scope.phoneNumberInvalid = ->
        template = $scope.template.selected
        template.channel is 'sms' && !getRecipientsIds().length

      getPlainSubject = (subject) ->
        $filter('sanitizeEditorField')(subject || '', true, target: 'subject')

      getPlainContent = (content) ->
        $filter('sanitizeEditorField')(content || '', true)

      $scope.isMessageReady = (template) ->
        return false unless getRecipientsIds().length
        return false if template.requires_object || $scope.isLoading
        $scope.error = SmsValidationService.getErrors(template)
        return false if $scope.error
        if template and template.channel
          subjectValid = TemplateValidationService.subjectIsNotEmpty(template.subject)
          contentValid = TemplateValidationService.contentIsNotEmpty(template.content)
          switch template.channel
            when 'email'
              contentValid && subjectValid && $scope.gym.custom_emails_enabled
            when 'push_notification'
              contentValid && subjectValid && $scope.pushNotifcationsEnabled() &&
                PushNotificationValidationService.isLengthValid(template.content)
            else
              $scope.gym.sms_enabled && contentValid && SmsValidationService.isLengthValid(template.content) &&
                !$scope.isFreemiumWithoutPaymentMethod()

      $scope.sendUnavailable = ->
        $scope.recipients.length && !_.find $scope.recipients, (item) -> item.available

      typeLabel = ->
        switch $scope.template.selected.channel
          when 'email' then 'Emails'
          when 'sms' then 'SMSes'
          when 'push_notification' then 'Push Notification'

      $scope.sendButtonLabel = ->
        if $scope.sendUnavailable()
          "Unable to send: contact does not accept #{typeLabel()}"
        else
          "Send"

      $scope.testEmailReady = ->
        $scope.template.selected.channel is 'email' && $scope.gym.custom_emails_enabled &&
          $scope.isMessageReady($scope.template.selected)

      $scope.sendTestEmail = ->
        params =
          subject: $scope.template.selected.subject
          content: $scope.template.selected.content
          head_tag_content: $scope.template.selected.head_tag_content
          reply_address: window.currentUser.email
          contact: $scope.contact

        TestEmailsService.sendTestEmail(params)

      $scope.openUnverifiedEmailSenderWarningModal = (email, submitFunction) ->
        ReactModal.open(
          component: 'UnverifiedEmailSenderWarningModal'
          props:
            unverifiedEmailAddress: email
            onSubmit: submitFunction
        )
