import humanize from 'helpers/humanize'
import random from 'helpers/random'

angular
  .module 'gym.components'
  .directive 'sequenceReportBuilder', ->
    restrict: 'EA'
    template: require('templates/components/sequences/sequenceReportBuilder.html.slim')
    scope:
      sequence: '='
      step: '='
      reports: '='
      skip: '='
    controller: ($scope, $filter, $modal, SequenceService) ->
      $scope.relativeDateId = random()
      $scope.availableColumnOptions = {}
      nonScopedSections =
        _.keys $scope.sequence.sequence_object_report_options.contact

      linkedReportConditionType =
        if $scope.skip then 'LinkedReportSkipSequenceCondition' else 'LinkedReportSequenceCondition'

      reportConditionType =
        if $scope.skip then 'ReportSkipSequenceCondition' else 'ReportSequenceCondition'

      scopedReportConditionType =
        if $scope.skip then 'ScopedReportSkipSequenceCondition' else 'ScopedReportSequenceCondition'

      $scope.params =
        type: linkedReportConditionType
        exclude_from_report: false

      $scope.excludeFromReportOptions = [
        [false, 'is in']
        [true, 'is not in']
      ]

      $scope.periods = [
        'days'
        'weeks'
        'months'
        'years'
      ]

      $scope.multipliers = ['from_now', 'ago']

      $scope.$watch 'sequence.trigger', (trigger) ->
        $scope.availableColumnOptions = {}
        if $scope.sequence.sequence_object_map[trigger]
          $scope.sequence.sequence_object_map[trigger].forEach (item) ->
            angular.extend(
              $scope.availableColumnOptions,
              $scope.sequence.sequence_object_report_options[item]
            )

        $scope.sectionOptions =
          _.chain($scope.availableColumnOptions).keys().sortBy((item) ->
            $scope.sequence.report_columns_options_keys.indexOf item
          ).map((value) ->
            value: value,
            label: $scope.sequence.column_section_name_map[value] || humanize(value)
          ).value()

        unless $scope.sectionOptions.find((option) -> option.value is $scope.filter?.section)
          $scope.filter = {}

      $scope.requireUserValue = ->
        blankValueConditions = ['Is blank', 'Is not blank']
        $scope.filter?.condition?[0] not in blankValueConditions

      isConditionExists = (type) ->
        if type is reportConditionType
          SequenceService.isConditionTypeExists($scope.step, reportConditionType)
        else
          SequenceService.isConditionTypeExists($scope.step, scopedReportConditionType)

      $scope.add = ->
        filter = $scope.getReportFilter($scope.filter)
        type = getFilterType()
        if isConditionExists(type)
          SequenceService.addReportFilter($scope.step, filter, type)
        else
          SequenceService.addReportCondition($scope.step, filter, type)
        $scope.cancel()

      $scope.cancel = ->
        if $scope.skip
          $scope.step.isSkipConditionCreating = false
        else
          $scope.step.isRunConditionCreating = false
        $scope.filter = {}

      $scope.onSectionChange = ->
        $scope.filter.column = ''

      $scope.onColumnChanged = ->
        $scope.filter.condition = ''
        $scope.filter.relative_date = false

      $scope.onConditionChanged = ->
        $scope.filter.user_value = ''
        $scope.filter.number = ''
        $scope.filter.relative_date = false
        $scope.filter.multiplier = ''
        $scope.filter.period = ''

      $scope.onRelativeChange = ->
        if $scope.filter.relative_date
          $scope.filter.user_value = ''
          $scope.filter.relative_date ||= false
          $scope.filter.multiplier ||= $scope.multipliers[0]
          $scope.filter.period = 'months'

      relativeDateReady = ->
        filter = $scope.filter
        ($filter('filterInt')(filter.number) >= 0) && filter.period &&
          filter.multiplier

      userValueReady = ->
        !$scope.requireUserValue() || $scope.filter.user_value?.toString() ||
          relativeDateReady()

      $scope.isFilterValid = ->
        $scope.filter?.column && $scope.filter.condition && userValueReady()

      $scope.appliedLinkedReport = ->
        condition = {type: linkedReportConditionType}
        _.findWhere $scope.step.sequence_conditions_attributes, condition

      $scope.linkReport = () ->
        $scope.step.sequence_conditions_attributes.push($scope.params)

      $scope.getReportFilter = (filter) ->
        {
          data_type: filter.column.data_type
          database_value: filter.column.table_and_column || filter.column.unique_id
          database_value_label: filter.column.label
          multiplier: filter.multiplier
          operator: filter.condition[1]
          operator_label: filter.condition[0]
          period: filter.period
          relative_date: filter.relative_date
          user_value: filter.user_value
          number: filter.number
        }

      hasEnrolmentLimitSequenceCondition = ->
        SequenceService.isEnrolmentLimitSequenceConditionExists($scope.step)

      $scope.enrolmentLimitSequenceConditionAvailable = ->
        apparentPosition = SequenceService.apparentPosition($scope.step, $scope.sequence)
        apparentPosition is 1 && $scope.sequence.trigger in $scope.sequence.enrolment_limit_triggers &&
          !hasEnrolmentLimitSequenceCondition() && !$scope.skip

      getFilterType = () ->
        if _.contains nonScopedSections, $scope.filter.section
          reportConditionType
        else
          scopedReportConditionType

      $scope.addEnrolmentLimitSequenceCondition = ->
        modalInstance = $modal.open
          template: require('templates/components/sequences/add_enrolment_limit_sequence_condition_modal.html.slim')
          controller: 'AddEnrolmentLimitSequenceConditionCtrl'

        modalInstance.result.then (result) ->
          return unless result.enable_limit_count
          condition =
            type: 'EnrolmentLimitSequenceCondition',
            limit_count: result.limit_count

          if result.enable_time_period
            condition.limit_term_number = result.limit_term_number
            condition.limit_term_unit = result.limit_term_unit
          else
            condition.limit_term_number = null

          condition.limit_to_current_month = result.limit_to_current_month
          currentConditionIndex = _.findIndex $scope.step.sequence_conditions_attributes, (condition) ->
            condition.type is 'EnrolmentLimitSequenceCondition'

          if currentConditionIndex >= 0
            condition.id = $scope.step.sequence_conditions_attributes[currentConditionIndex].id
            $scope.step.sequence_conditions_attributes[currentConditionIndex] = condition
          else
            $scope.step.sequence_conditions_attributes.push condition
