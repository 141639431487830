angular
  .module 'filters'
  .filter 'reportFilterValue', ($filter) ->
    (filter, reports) ->
      withOperator = (value) ->
        "#{filter.operator_label} #{value}"

      if filter.relative_date && filter.operator not in ['IS BLANK', 'IS NOT BLANK']
        period = $filter('singularize')(filter.period)
        multiplier = $filter('humanize')(filter.multiplier)
        withOperator("#{filter.number} #{period}(s) #{multiplier}")
      else
        switch
          when filter.operator in ['IN REPORT', 'NOT IN REPORT']
            report = _.find reports, (item) -> item.id is +filter.user_value
            if report
              withOperator(report.name)
            else
              "#{filter.operator_label.replace(' report', '')} [report deleted]"
          when filter.data_type is 'month'
            withOperator($filter('month')(filter.user_value))
          else
            withOperator(filter.user_value)
