angular
  .module 'gym.components'
  .directive 'sequenceStepAdvancedOptions', ->
    restrict: 'EA'
    template: require('templates/components/sequences/sequence_step_advanced_options.html.slim')
    scope:
      sequence: '='
      sequenceStep: '='
      reports: '='
    controller: ($rootScope, $scope, SequenceService) ->
      gym = $rootScope.gym
      $scope.toggle = ->
        $scope.expanded = !$scope.expanded

      $scope.skipConditionsVisible = ->
        if SequenceService.isForseeable($scope.sequence)
          $scope.sequenceStep.position > 1
        else
          $scope.sequenceStep.position > 2

      $scope.hasSkipConditions = ->
        _.find $scope.sequenceStep.sequence_conditions_attributes, (condition) ->
          !condition._destroy && condition.type in [
            'ReportSkipSequenceCondition',
            'LinkedReportSkipSequenceCondition',
            'ScopedReportSkipSequenceCondition'
          ]

      $scope.isReportConditionExists = (step) ->
        SequenceService.isReportConditionExists(step)

      $scope.isScopedReportConditionExists = (step) ->
        SequenceService.isScopedReportConditionExists(step)

      $scope.isEnrolmentLimitSequenceConditionExists = (step) ->
        SequenceService.isEnrolmentLimitSequenceConditionExists(step)

      $scope.hasReportSkipSequenceCondition = (step) ->
        SequenceService.hasReportSkipSequenceCondition(step)

      $scope.removeCondition = (step, index) ->
        if step.sequence_conditions_attributes[index].id
          step.sequence_conditions_attributes[index]._destroy = 1
        else
          step.sequence_conditions_attributes.splice(index, 1)

      $scope.addRunCondition = (step) ->
        step.isRunConditionCreating = true

      $scope.addSkipCondition = (step) ->
        step.isSkipConditionCreating = true
