angular
  .module 'filters'
  .filter 'utc', ->
    (date, toFormat, fromFormat) ->
      return unless date

      toFormat ||= 'lll'
      parsedDate =
        if fromFormat
          moment.utc(date, fromFormat)
        else
          moment.utc(date)

      parsedDate.format(toFormat)
