angular
  .module 'shared'
  .directive 'waiverStripePaymentDetails', ->
    restrict: 'E'
    scope:
      waiver: '='
      walletDetails: '='
      pageParams: '='
      gymCountry: '='
      saveWaiver: '&'
      isPaymentDetailsInvalid: '&'
    template: require('templates/shared/waivers/payment_details/waiver_stripe_payment_details.html.slim')
    controller: (
      $scope,
      $timeout,
      $document,
      CreditCardValidationService,
      loadingAnimation
    ) ->

      publicKey = if $scope.waiver.gym_payment_provider is 'stripe'
        $scope.waiver.gym_public_key
      else
        $scope.waiver.gym_secondary_public_key

      stripe = Stripe(publicKey)
      elements = stripe.elements()
      cardElement = elements.create('card')
      cardElement.mount('#card')

      $scope.$watch('walletDetails', (newWalletDetails) ->
        cardElement.update({
          disabled: !newWalletDetails.editable
        })
      )

      $scope.nameValid = ->
        CreditCardValidationService.nameValid($scope.walletDetails?.nameOnCard)

      onCardChange = ->
        cardElement.addEventListener('change', (event) ->
          $timeout ->
            displayError = document.getElementById('cardErrors')
            $scope.walletDetails.complete = event.complete
            if event.error
              displayError.textContent = event.error.message
            else if event.complete
              displayError.textContent = ''
        )

      onCardChange()

      $scope.save = ->
        clientSecret = $scope.waiver.stripe_setup_intent_attributes.client_secret
        options = {
          billing_details:
            name: document.getElementById('nameOnCard').value
        }

        $document.scrollTop(0)

        stripe.handleCardSetup(clientSecret, cardElement, {
          payment_method_data: options
        }).then (setupIntentResult) ->
          $timeout ->
            if setupIntentResult.error
              if window.Rollbar
                window.Rollbar.error('Stripe card setup error', setupIntentResult.error)
              onError('We are unable to authenticate your payment method. Please choose a different payment method and try again.')
            else
              stripe.createPaymentMethod('card', cardElement, options).then (result) ->
                $timeout ->
                  saveCardDetails(setupIntentResult.setupIntent.payment_method, result.paymentMethod)

      saveCardDetails = (token, paymentMethod) ->
        $scope.walletDetails.customer_ref = token
        $scope.walletDetails.payment_method = 'credit_card'
        $scope.walletDetails.payment_provider = 'stripe'
        $scope.walletDetails.card_brand = paymentMethod.card.brand
        $scope.walletDetails.exp_month = paymentMethod.card.exp_month
        $scope.walletDetails.exp_year = paymentMethod.card.exp_year
        $scope.walletDetails.full_name = document.getElementById('nameOnCard').value
        $scope.walletDetails.last4 = paymentMethod.card.last4
        $scope.walletDetails.address_zip = paymentMethod.billing_details.address.postal_code

        $scope.waiver.signed_waiver_wallet_detail_attributes =
          angular.extend(
            $scope.waiver.signed_waiver_wallet_detail_attributes, $scope.walletDetails
          )
        $scope.saveWaiver()

      onError = (error) ->
        $timeout ->
          $scope.pageParams.isLoading = false
          loadingAnimation.hide()
          $scope.walletDetails.error = error
          $document.scrollToElementAnimated(angular.element('#walletDetails'))
