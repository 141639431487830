import _ from 'underscore'
import contactType from 'helpers/contact_type'

angular
  .module 'gym.components'
  .directive 'memberStyles', ->
    restrict: 'E'
    template: require('templates/components/contacts/member_styles.html.slim')
    scope:
      contact: '='
      permissions: '='
      form: '='
      addStyle: '&'
      editStyle: '&'
      removeStyle: '&'
      reloadContact: '&'
    controller: ($rootScope, $scope, $http, MessageService) ->
      gym = $rootScope.gym

      $scope.$watch 'contact.member_styles', (memberStyles) ->
        $scope.styles = memberStyles.filter (item) -> item.enabled
        $scope.deactivatedStyles = memberStyles.filter (item) -> !item.enabled

      $scope.toggleList = ->
        $scope.displayDeactivated = !$scope.displayDeactivated

      $scope.undo = (memberStyle) ->
        revertablePromotion = memberStyle.revertable_promotion_details
        performed = moment(revertablePromotion.graded_on).format('MMM D, YYYY')
        performedBy = if revertablePromotion.grading_performed_by
          "by #{_.escape(revertablePromotion.grading_performed_by)}"
        else
          ''
        afterThisChange = if revertablePromotion.previous_rank_name
          "After this change, the contact's new rank will be <strong>#{_.escape(revertablePromotion.previous_rank_name)}</strong>."
        else
          "After this change, the contact will not be ranked for this style."

        MessageService.message({
          title: "Remove last grading for #{_.escape(memberStyle.style.name)}"
          message: [
            "This will remove #{$scope.contact.name}'s most recent <strong>#{_.escape(memberStyle.style.name)}</strong> promotion:",
            "<strong>Promoted</strong>: from #{_.escape(revertablePromotion.previous_rank_name) || '(no rank)'} to #{_.escape(memberStyle.current_rank.name)}<br><strong>Performed</strong>: #{performed} #{performedBy}"
            afterThisChange
          ]
          bindHtml: true
          confirmButtonText: 'Undo promotion'
          displayCancelButton: true
        }).then () ->
          contact = $scope.contact
          memberRankId = memberStyle.revertable_promotion_details.member_rank_id
          url = "/gyms/#{gym.id}/#{contactType(contact.type)}/#{contact.id}/member_styles/#{memberStyle.id}/undo_last_grading"
          $http.put(url, member_rank_id: memberRankId).then () ->
            $scope.reloadContact()
