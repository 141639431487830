angular
  .module 'filters'
  .filter 'waiverSignedAt', ($filter) ->
    (waiver) ->
      switch
        when waiver.prefill_link_expired
          'N/A'
        when waiver.status is 'prefilled'
          'Pending'
        else
          $filter('date')(waiver.signed_at)
