angular
  .module 'filters'
  .filter 'templateNameWithType', ->
    (template) ->
      type = switch template.channel
        when 'sms' then 'SMS'
        when 'email' then 'Email'
        when 'push_notification' then 'Push Notification'

      "[#{type}] #{template.name}"
