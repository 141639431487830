angular
  .module 'shared'
  .factory 'Countries', ->
    [
      ["US", "US"]
      ["CA", "Canada"]
      ["GB", "UK"]
      ["IE", "Ireland"]
      ["NZ", "New Zealand"]
      ["AU", "Australia"]
      ["DK", "Denmark"]
      ["SE", "Sweden"]
      ["NO", "Norway"]
      ["FI", "Finland"]
      ["BH", "Bahrain"]
      ["OM", "Oman"]
      ["TN", "Tunisia"]
      ["ID", "Indonesia"]
      ["MY", "Malaysia"]
      ["MM", "Myanmar"]
      ["CY", "Cyprus"]
      ["GR", "Greece"]
      ["BE", "Belgium"]
      ["NL", "Netherlands"]
      ["LT", "Lithuania"]
      ["LU", "Luxembourg"]
      ["MT", "Malta"]
      ["SG", "Singapore"]
      ["HK", "Hong Kong"]
      ["JP", "Japan"]
      ["IN", "India"]
      ["PK", "Pakistan"]
      ["ES", "Spain"]
      ["DE", "Germany"]
      ["FR", "France"]
      ["IT", "Italy"]
      ["AT", "Austria"]
      ["JM", "Jamaica"]
      ["SA", "Saudi Arabia"]
      ["AE", "UAE"]
      ["CH", "Switzerland"]
      ["PH", "Philippines"]
      ["TH", "Thailand"]
      ["MX", "Mexico"]
      ["MV", "Maldives"]
      ["ZA", "South Africa"]
      ["KE", "Kenya"]
      ["TT", "Trinidad and Tobago"]
    ]
