angular.module('shared').factory 'PushNotificationOptionsService', ($q, $http) ->
  getOptions: (gymId, context) ->
    deferred = $q.defer()
    url = "/gyms/#{gymId}/templates/push_notification_route_names"
    $http
      method: 'GET'
      url: url
      params:
        context: context
    .then (response) ->
      transformedData = for key, value of response.data
        value: key
        label: value
      deferred.resolve transformedData
    .catch (error) ->
      deferred.reject 'Request could not be completed. Please try again'
    deferred.promise
