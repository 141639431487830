import plural from 'helpers/plural';

const membershipPrivileges = (membership) => {
  const classString = plural('classes', membership.classes_limit);
  const intervalUnitString = plural(
    membership.classes_interval_unit,
    membership.classes_interval_number,
  );
  const intervalNumberString =
    membership.classes_interval_number > 1 ? membership.classes_interval_number : '';
  const intervalString = membership.classes_interval_number
    ? `every ${intervalNumberString} ${intervalUnitString}`
    : '';
  switch (false) {
    case !membership.classes_unlimited:
      return 'Unlimited classes';
    case !membership.classes_limit:
      return `${membership.classes_limit} ${classString} ${intervalString}`;
    default:
      return `${membership.classes_limit} ${classString}`;
  }
};

export default membershipPrivileges;
