angular
  .module 'filters'
  .filter 'stateParamsToBoolean', ->
    (obj) ->
      _.mapObject obj, (val) ->
        unless _.isNull(val)
          if val is 'true' or val is 'false'
            val is 'true'
          else
            val
