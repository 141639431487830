angular.module 'shared'
  .factory 'TestEmailsService', (
    $modal,
    $filter
  ) ->

    sendTestEmail: (params) ->
      modalInstance = $modal.open
        template: require('templates/shared/send_test_email_modal.html.slim')
        size: 'lg'
        controller: ($scope, $rootScope, $http, $modalInstance, flash) ->
          $scope.params = params

          if params.contact
            $scope.selectedContacts = [params.contact]
            $scope.params.contact_id = params.contact.id

          $scope.onSelect = (contact) ->
            $scope.params.contact_id = contact.id

          $scope.onRemove = ->
            $scope.params.contact_id = undefined

          $scope.submit = ->
            unless $scope.params.contact_id
              $scope.errors =
                contact: ['This field is required']
              return

            $scope.isLoading = true
            url = "/gyms/#{params.gymId || $rootScope.gym.id}/test_comms/send_email"
            headTagContent = if $rootScope.gym.gym_config.can_set_email_template_head_tag
              $scope.params.head_tag_content
            else
              undefined

            messageParams =
              contact_id: $scope.params.contact_id
              content: $filter('domPurify')($scope.params.content)
              reply_address: $scope.params.reply_address
              subject: $filter('sanitizeEditorField')($scope.params.subject, true, target: 'subject')
              head_tag_content: headTagContent

            $http.post(url, message: messageParams).then () ->
              $modalInstance.close()
              flash.success = "Test email sent to: #{params.reply_address}"
            , () ->
              $scope.isLoading = false

          $scope.cancel = ->
            $modalInstance.dismiss('cancel')

      modalInstance.result
