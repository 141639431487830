angular
  .module 'gym.components'
  .directive 'memberLoginDetails', ->
    restrict: 'E'
    template: require('templates/components/contacts/forms/member_login_details.html.slim')
    scope:
      contact: '='
      form: '='
    controller: ($scope, $rootScope, $timeout, $modal, flash, FreeTrialRemindMessageText) ->

      $scope.loginUrl = "#{$scope.contact.member_portal_details.login_url}"

      $scope.onCopied = ->
        $scope.copied = true
        $timeout ->
          $scope.copied = false
        , 2000
        true

      save = ->
        if $scope.contact.member_portal_details.enabled
          $scope.contact.doPOST({}, 'enable_member_portal_detail')
        else
          $scope.contact.doPOST({}, 'disable_member_portal_detail')

      $scope.onToggleChange = ->
        save().then ->
          flash.success = 'Member Portal Login Details saved'
        , () ->
          flash.error  = 'Unable to update Member Portal Login Details'
          $scope.contact.member_portal_details.enabled =
            !$scope.contact.member_portal_details.enabled

      $scope.isFreeTrialRemindMessageAvailable =
        $rootScope.gym.on_trial_period && $rootScope.gym.trialling_highest_plan

      $scope.sendMemberDetails = ->
        modalInstance = $modal.open
          template: require('templates/gyms/contacts/resend_member_login_details.html.slim')
          size: 'md'
          resolve:
            contact: ->
              $scope.contact
          controller: 'MemberLoginDetailsModalCtrl'

        modalInstance.result.then ->
          flash.success = 'Login details sent successfully'

      $scope.message = FreeTrialRemindMessageText.getMessage('Growing')

      $scope.isReminderMessageShown = false

      $scope.isPayor = window.currentUser.account_owner

      $scope.togglePopover = (event) ->
        $scope.left = event.target.offsetLeft + 250
        $scope.top = event.target.offsetTop - 100

        if event
          $scope.isReminderMessageShown= !$scope.isReminderMessageShown
