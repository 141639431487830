angular
  .module 'shared'
  .directive 'smsDisabledMessage', () ->
    restrict: 'E'
    template: '''
      <div class="alert alert-danger is-sm alert--links-underscored">
        SMSes are currently disabled while your account is under review. If you'd like to know more,
        <a href="mailto:support@clubworx.com?subject=Clubworx SMS access" target="_self">contact us today</a>.
      </div>
    '''
