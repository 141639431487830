angular
  .module 'shared'
  .directive 'fbPixel', ->
    restrict: 'E'
    scope:
      pixelId: '='
      trackEvent: '='
    controller: ($scope, $sce) ->
      $scope.enabled = window.DATA?.embed_pixels && $scope.pixelId && $scope.trackEvent
      $scope.trackParams = if $scope.trackEvent is 'Purchase'
        "'Purchase', {value: 0.00, currency: 'USD'}"
      else
        "'PageView'"
      content = "
        <script>
          !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n; n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;
          s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');

          fbq('init', #{$scope.pixelId});
          fbq('track', #{$scope.trackParams});
        </script>
      "
      $scope.content = $sce.trustAsHtml(content)
    template: '<div ng-if="enabled" ng-bind-html="content"></div>'
