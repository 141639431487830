angular
  .module 'gym.components'
  .directive 'reportAggregateValueFields', ->
    restrict: 'E'
    template: require('templates/components/reports/report_aggregate_value_fields.html.slim')
    scope:
      report: '='
    controller: ($scope, $filter) ->
      $scope.enabled = $scope.report.report_aggregates_attributes?.length
      $scope.params = {}

      $scope.enable = ->
        $scope.enabled = true

      getAvailableColumns = (section) ->
        allColumns = $scope.report.report_columns_options[section]
        _.filter allColumns, (column) ->
          functions = $scope.report.aggregate_functions[column.data_type]
          functions && functions.indexOf($scope.aggregate.aggregate_function) >= 0

      clearFilter = ->
        if $scope.aggregate
          $scope.aggregate.filter_present = false
          $scope.aggregate.operator = undefined
          $scope.aggregate.operator_label = undefined
          $scope.aggregate.user_value = undefined
          $scope.aggregate.relative_date = undefined
          $scope.aggregate.number = undefined
          $scope.aggregate.period = undefined
          $scope.aggregate.multiplier = undefined

      $scope.onTypeChange = (val) ->
        $scope.report.errors['report_aggregates.table_and_column'] = undefined
        $scope.report.errors['report_aggregates.operator'] = undefined
        $scope.report.errors['report_aggregates.user_value'] = undefined
        if val isnt $scope.aggregate?.aggregate_type
          clearFilter()
        if !val
          if $scope.aggregate?.id
            $scope.aggregate._destroy = 1
          else
            $scope.report.report_aggregates_attributes = []
          if $scope.aggregate
            $scope.aggregate.aggregate_type = val
        else
          if !$scope.report.report_aggregates_attributes.length
            $scope.report.report_aggregates_attributes.push {
              position: 1
            }
            $scope.aggregate = $scope.report.report_aggregates_attributes[0]
          else
            $scope.aggregate._destroy = undefined
          $scope.aggregate.aggregate_type = val

          if val is 'record_count'
            $scope.aggregate.aggregate_function = 'count'
            $scope.onFunctionChange()
          else
            $scope.aggregate.aggregate_function = undefined
          $scope.params = {}
          $scope.aggregate.table_and_column = ""

      aggregateFunctionLabelMap =
        sum: 'Total'
        avg: 'Average'
        min: 'Minimum / Earliest'
        max: 'Maximum / Latest'
        count: 'Count'

      sumAvgFunc = [
        {label: 'Total', value: 'sum'},
        {label: 'Average', value: 'avg'}
      ]

      minMaxFunc = [
        {label: 'Minimum / Earliest', value: 'min'},
        {label: 'Maximum / Latest', value: 'max'}
      ]

      $scope.getAggregateFunctions = ->
        switch $scope.aggregate.aggregate_type
          when 'sum_avg' then sumAvgFunc
          when 'min_max' then minMaxFunc

      $scope.onColumnChange = () ->
        $scope.report.errors['report_aggregates.table_and_column'] = undefined
        if $scope.aggregate.aggregate_function isnt 'count'
          columns = $scope.report.report_columns_options[$scope.params.section]
          column = _.find columns, (item) -> item.table_and_column is $scope.aggregate.table_and_column
          $scope.params.column = column
          if column
            $scope.aggregate.label =
              "#{aggregateFunctionLabelMap[$scope.aggregate.aggregate_function]} #{column.label}"

      $scope.onSectionChange = ->
        $scope.report.errors['report_aggregates.table_and_column'] = undefined
        if $scope.aggregate.aggregate_function is 'count' && $scope.params.section
          $scope.aggregate.table_and_column = "#{$scope.report.aggregate_table_names_map[$scope.params.section]}:id"
          columnSection = $scope.report.column_section_name_map[$scope.params.section]
          $scope.aggregate.label = "#{columnSection || $filter('humanize')($scope.params.section)} Count"
        else
          $scope.availableColumns = getAvailableColumns($scope.params.section)

      $scope.onFunctionChange = ->
        $scope.availableSections = _.filter $scope.report.aggregate_sections, (section) ->
          getAvailableColumns(section)?.length > 0

      getAvailableFilterOperators = ->
        dataType =
          if $scope.aggregate.aggregate_function is 'count'
            'number'
          else
            $scope.params.column?.data_type
        return [] unless dataType

        operators = $scope.report.report_filters_operators[dataType]
        _.filter operators, (operator) ->
          $scope.aggregate.aggregate_function in ['min', 'max'] ||
            operator[0] not in ['Is blank', 'Is not blank']

      $scope.onConditionChanged = ->
        $scope.report.errors['report_aggregates.operator'] = undefined
        condition = $scope.params.condition
        $scope.aggregate.operator_label = condition?[0] || undefined
        $scope.aggregate.operator = condition?[1] || undefined

      $scope.getSectionName = (section) ->
        columnSection = $scope.report.column_section_name_map[section]
        name = $filter('humanize')(section)
        if $scope.aggregate.aggregate_function is 'count'
          "#{columnSection} count" || "#{name} count"
        else
          name

      $scope.$watch 'aggregate.table_and_column', () ->
        if $scope.aggregate?.table_and_column
          $scope.availableFilterOperators = getAvailableFilterOperators()

      $scope.$watch 'aggregate.user_value', () ->
        $scope.report.errors['report_aggregates.user_value'] = undefined

      if $scope.report.report_aggregates_attributes.length
        $scope.aggregate = $scope.report.report_aggregates_attributes[0]
        $scope.params.section =
          _.find $scope.report.aggregate_sections, (section) ->
            if $scope.aggregate.table_and_column is "#{$scope.report.aggregate_table_names_map[section]}:id"
              return true
            _.find $scope.report.report_columns_options[section], (column) ->
              column.table_and_column is $scope.aggregate.table_and_column
        $scope.onFunctionChange()
        $scope.onColumnChange()
        $scope.onSectionChange()
        if $scope.aggregate?.table_and_column
          $scope.availableFilterOperators = getAvailableFilterOperators()
        if $scope.aggregate.operator
          $scope.params.condition =
            _.find $scope.availableFilterOperators, (operator) -> operator[0] is $scope.aggregate.operator_label
