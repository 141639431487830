angular
  .module 'shared'
  .factory 'TemplateSanitizeService', ($filter) ->
    sanitize: (template) ->
      t = if template.plain then template.plain() else template
      switch t.channel
        when 'email'
          t.subject = $filter('sanitizeEditorField')(t.subject, true, target: 'subject')
          t.content = $filter('domPurify')(t.content)
        when 'push_notification'
          t.subject = $filter('sanitizeEditorField')(t.subject, true, target: 'subject')
          t.content = $filter('sanitizeEditorField')(t.content, true)
          t.reply_address = undefined
        else
          t.content = $filter('sanitizeEditorField')(t.content, true)
          t.reply_address = undefined
      t
