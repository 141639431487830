angular
  .module 'filters'
  .filter 'stringToDate', ->
    (string, fromFormat, toFormat) ->
      fromFormat ||= 'YYYY-MM-DD'
      toFormat ||= 'D MMM YYYY'
      if string && moment(string, fromFormat, true).isValid()
        moment(string, fromFormat).format(toFormat)
      else
        string
