angular
  .module 'shared'
  .directive 'waiverEzidebitCreditCardPaymentDetails', ->
    restrict: 'E'
    scope:
      waiver: '='
      walletDetails: '='
      publicKey: '='
      pageParams: '='
      saveWaiver: '&'
    template: require('templates/shared/waivers/payment_details/waiver_ezidebit_credit_card.html.slim')
    controller: (
      $scope,
      $rootScope,
      $timeout,
      $document,
      CreditCardValidationService,
      CreditCardExpirationDatesService,
      loadingAnimation,
      EzidebitService
    ) ->
      endpoint = $scope.waiver.ezidebit_public_url
      $scope.years = CreditCardExpirationDatesService.getYears()
      $scope.months = CreditCardExpirationDatesService.getMonths()
      signedWaiverWalletDetail = $scope.waiver.signed_waiver_wallet_detail_attributes
      isCustomerExist = signedWaiverWalletDetail.customer_ref && signedWaiverWalletDetail.active_on_ezidebit

      $scope.customerReference = EzidebitService.getCustomerReference(
        gymId: $rootScope.gym?.id || window.DATA.gym_id,
        contactId: $scope.waiver.signed_waiver_contacts_attributes[0]?.contact_id,
        context: 'SW'
      )

      initSaveCustomer = ->
        $timeout ->
          params =
            submitAction: 'SaveCustomer'
            submitButton: 'submitCreditCard'
            submitCallback: onSaveSuccess
            submitError: onError
            customerFirstName: 'firstName'
            customerLastName: 'lastName'
            nameOnCard: 'nameOnCard'
            cardNumber: 'cardNumber'
            cardExpiryMonth: 'expiryMonth'
            cardExpiryYear: 'expiryYear'
            customerEmail: 'customerEmail'
            customerMobile: 'customerMobile'
            customerReference: 'customerReference'

          window.initEziDebit()
          eziDebit.init $scope.publicKey, params, endpoint

      initUpdateCustomer = ->
        $timeout ->
          params =
            submitAction: 'ChangeCustomerPaymentInfo'
            submitButton: 'updateCreditCard'
            submitCallback: onUpdateSuccess
            submitError: onError
            nameOnCard: 'nameOnCard'
            cardNumber: 'cardNumber'
            cardExpiryMonth: 'expiryMonth'
            cardExpiryYear: 'expiryYear'
            customerRef: 'customerRef'

          window.initEziDebit()
          eziDebit.init $scope.publicKey, params, endpoint

      init = () ->
        if isCustomerExist
          initUpdateCustomer()
        else
          initSaveCustomer()

      $scope.$watch 'publicKey', (key) ->
        init() if key

      onSaveSuccess = (response) ->
        $scope.walletDetails.error =  undefined
        $scope.waiver.signed_waiver_wallet_detail_attributes =
          angular.extend(
            $scope.waiver.signed_waiver_wallet_detail_attributes,
            getCardDetails(),
            {customer_ref: response.Data.CustomerRef}
          )
        $scope.saveWaiver()

      onUpdateSuccess = (response) ->
        $scope.walletDetails.error =  undefined
        $scope.waiver.signed_waiver_wallet_detail_attributes =
          angular.extend(
            $scope.waiver.signed_waiver_wallet_detail_attributes,
            getCardDetails()
          )
        $scope.saveWaiver()

      getCardDetails = ->
        payment_method: 'credit_card'
        payment_provider: 'ezidebit'
        last4: $scope.walletDetails.cardNumber.substr(-4)
        full_name: $scope.walletDetails.nameOnCard
        exp_month: $scope.walletDetails.expiryMonth
        exp_year: $scope.walletDetails.expiryYear
        bsb: undefined

      onError = (error) ->
        $timeout ->
          $scope.pageParams.isLoading = false
          loadingAnimation.hide()
          $scope.walletDetails.error = error
          $document.scrollToElementAnimated(angular.element('#walletDetails'))

      $scope.checkCardNumber = ->
        $scope.walletDetails.cardNumber =
          $scope.walletDetails.cardNumber?.replace(/[^0-9]/g, '')
        $scope.walletDetails.cardValid =
          CreditCardValidationService.check($scope.walletDetails.cardNumber)

      $scope.cardholderNameValid = ->
        CreditCardValidationService.nameValid($scope.walletDetails.nameOnCard)
