angular.module 'shared'
  .factory 'ToggleTableFiltersService', (CwStorage) ->
    getStorageKey: (key) ->
      "#{key}FiltersOpen"

    isOpen: (key) ->
      storageKey = @getStorageKey(key)
      JSON.parse(CwStorage.getItem(storageKey));

    toggle: (val, key) ->
      storageKey = @getStorageKey(key)
      if val
        CwStorage.setItem(storageKey, val);
      else
        CwStorage.removeItem(storageKey);
