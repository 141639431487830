angular
  .module 'gym.components'
  .directive 'signedWaiverCollapsiblePanel', ->
    restrict: 'EA'
    transclude: true
    scope:
      collapsible: '='
      defaultCollapsed: '='
      title: '='
    template: require('templates/components/waivers/signed_waiver_collapsible_panel.html.slim')
    controller: ($scope) ->
      $scope.collapsed = $scope.defaultCollapsed

      $scope.toggle = () ->
        if $scope.collapsible
          $scope.collapsed = !$scope.collapsed
