angular
  .module 'gym.components'
  .directive 'reportSequenceConditions', ->
    restrict: 'EA'
    template: require('templates/components/sequences/reportSequenceConditions.html.slim')
    scope:
      sequence: '='
      step: '='
      skip: '='
    controller: ($scope, SequenceService) ->
      conditions = $scope.step.sequence_conditions_attributes
      $scope.reportConditionType =
        if $scope.skip then 'ReportSkipSequenceCondition' else 'ReportSequenceCondition'
      $scope.scopedReportConditionType =
        if $scope.skip then 'ScopedReportSkipSequenceCondition' else 'ScopedReportSequenceCondition'

      getCondition = ->
        conditionIndex = _.findIndex conditions, (condition) ->
          condition.type is $scope.reportConditionType
        $scope.condition =
          $scope.step.sequence_conditions_attributes[conditionIndex]

      getScopedCondition = ->
        scopedConditionIndex = _.findIndex conditions, (condition) ->
          condition.type is $scope.scopedReportConditionType
        $scope.scopedCondition =
          $scope.step.sequence_conditions_attributes[scopedConditionIndex]

      $scope.$watch 'step', () ->
        getCondition()
        getScopedCondition()
      , true

      $scope.$watch 'sequence.trigger', (trigger, oldTrigger) ->
        if trigger isnt oldTrigger && $scope.scopedCondition
          availableColumnOptions = []
          filters =
            $scope.scopedCondition.report_attributes.report_filters_attributes
          $scope.sequence.sequence_object_map[trigger].forEach (item) ->
            _.each $scope.sequence.sequence_object_report_options[item], (val) ->
              availableColumnOptions = availableColumnOptions.concat val

          filters.forEach (filter, index) ->
            foundColumn = _.find availableColumnOptions, (option) ->
              option.unique_id is filter.database_value

            if !foundColumn
              $scope.remove(
                $scope.scopedCondition,
                index,
                $scope.scopedReportConditionType
              )

      presentFiltersCount = (filters) ->
        _.filter(filters, (filter) -> !filter._destroy).length

      $scope.remove = (condition, index, type) ->
        filters = condition.report_attributes.report_filters_attributes

        if filters[index].id
          filters[index]._destroy = 1

          if presentFiltersCount(filters) is 0
            condition._destroy = 1
        else
          if filters.length > 1
            filters.splice(index, 1)
          else
            SequenceService.removeReportCondition($scope.step, type)
