angular
  .module 'gym.components'
  .directive 'editBookingSection', ->
    restrict: 'E'
    template: require('templates/components/contacts/forms/edit_booking_section.html.slim')
    scope:
      contact: '='
      form: '='
      booking: '='
      params: '='
      close: '&'
      onWaitlistAdded: '&'
    controller: (
      $document,
      $rootScope,
      $scope,
      $state,
      $modal,
      $window,
      $http,
      $filter,
      loadingAnimation,
      Events,
      Contacts,
      EventService,
      flash,
      MessageService
    ) ->
      $scope.gym = gym = $rootScope.gym
      $scope.$emit('contact:scrollToMainSection');
      $scope.attendanceOptions = ['Attended', 'Absent']
      $scope.pageParams =
        search: ''

      $scope.getEventDetail = (id) ->
        url = "/gyms/#{gym.id}/calendar/events/#{id}"
        $http.get(url).then (response) ->
          $scope.event = response.data

      $scope.selectEvent = () ->
        eventModal = $modal.open(
          template: require('templates/components/contacts/forms/select_contact_booking_event.html.slim')
          controller: 'EventSelectCtrl'
          size: 'lg'
        ).result.then (event) ->
          $scope.booking.event = event
          $scope.getEventDetail(event.id)
          $scope.getContactForBooking()

      $scope.getContactForBooking = ->
        EventService.getContactForBooking($scope.contact.name, $scope.contact.id, $scope.booking.event.id).then (contactForBooking) ->
          $scope.contactForBooking = contactForBooking

      $scope.isFormValid = ->
        if $scope.params.bookFriend
          $scope.booking.event && $scope.booking.student_id
        else
          $scope.booking.event

      $scope.toggleFreeClass = (value) ->
        $scope.booking.free_class = value
        $scope.booking.add_makeup_class = false
        $scope.booking.makeup_class_membership_id = undefined
        $scope.booking.makeup_class_reason = undefined

      $scope.onBookingChange = (data) ->
        angular.extend $scope.booking, data
        if data.type && data.type isnt 'one' && $scope.booking.add_makeup_class
          $scope.toggleFreeClass(false)

      $scope.hideError = (field) ->
        $scope.booking[field]?.errors = undefined

      $scope.selectBookingTypeVisible = ->
        $scope.contact.type is 'member' && $scope.booking.event && !$scope.booking.event.free_class

      $scope.eventFullButHasAbsentBookingsMessageVisible = ->
        $scope.booking.event && $scope.booking.event.bookings_count >= $scope.booking.event.participant_limit &&
          $scope.booking.event.non_absent_bookings_count < $scope.booking.event.participant_limit

      $scope.makeupClassOptionVisible = ->
        return if $scope.params.bookFriend
        return unless $scope.contactForBooking?.memberships_for_makeup_classes?.length
        ($scope.contactForBooking?.show_makeup_class_option || $scope.showMakeupClassOption) && $scope.booking.type is 'one'

      $scope.enabledMakeupClass = ->
        $scope.booking.add_makeup_class = true
        $scope.booking.free_class = false
        if !$scope.booking.makeup_class_membership_id && $scope.contactForBooking
          $scope.booking.makeup_class_membership_id = $scope.contactForBooking.memberships_for_makeup_classes[0]?.id

      enableRecurringBooking = ->
        recurringBookingParams =
          contact_id: $scope.contact.id
          book_from_policy: $scope.booking.book_from_policy

        EventService.enableRecurringBooking($scope.booking, recurringBookingParams, $scope.event.id).then () ->
          loadingAnimation.hide()
          $scope.close()
        , (data) ->
          loadingAnimation.hide()
          if data.errors?.message
            $scope.recurringBookingError = data.errors?.message

      bookCurrentContact = ->
        if $scope.booking.type is 'recurring'
          enableRecurringBooking()
          return

        if $scope.booking.add_makeup_class
          params =
            position: $scope.booking.event.bookings_count
            student: $scope.contactForBooking
            add_makeup_class: $scope.booking.add_makeup_class
            makeup_class_membership_id: $scope.booking.makeup_class_membership_id
            makeup_class_reason: $scope.booking.makeup_class_reason

          url = "/gyms/#{gym.id}/calendar/events/#{$scope.event.id}/bookings"

          $http.post(url, booking: params).then (response) ->
            flash.success =
              "Booking for #{response.data.student.name} added successfully"
            $scope.close()
            loadingAnimation.hide()
        else
          url =
            "gyms/#{gym.id}/calendar/events/#{$scope.event.id}/bulk_bookings/book_#{$scope.booking.type}"

          lateAbsent = $scope.booking.type is 'one' && $scope.booking.attendance is 'Absent' && $scope.event.restrict_cancellations && !$scope.event.cancellable && $scope.event.allow_late_cancellations

          params =
            student_id: $scope.contact.id,
            event_id: $scope.event.id,
            number_of_events: $scope.booking.count
            free_class: $scope.booking.free_class
            attendance: if $scope.booking.type is 'one' && !lateAbsent then $scope.booking.attendance else undefined

          $http.post(url, params).then (response) ->
            loadingAnimation.hide()
            bookings = response.data.bookings.valid
            if bookings.length
              lastBookingDate = $filter('date')(_.last(bookings).start_at)
              flash.success = "#{bookings.length} events booked successfully. Last booking: #{lastBookingDate}."
              if lateAbsent
                booking = bookings[0]
                booking.id = response.data.booking_id
                EventService.confirmLateAbsent({
                  event: $scope.event,
                  booking: booking
                  reloadAttendance: $scope.close
                })
                return
            else
              if $scope.booking.type is 'one'
                $scope.booking.event.errors = ['This event is currently full, please try another timeslot.']
                flash.error = "Update failed. Please fix the errors below"
                return
              flash.success = response.data.message
            $scope.close()
          , (response) ->
            loadingAnimation.hide()
            if $scope.event.invite_to_waitlist
              confirmAddToWaitlist()
              return
            $scope.failedBookings = response.data
            eventIsFull = $scope.failedBookings.bookings.invalid[0]?.validation_error is 'Event full'
            if $scope.booking.type is 'one' && $scope.contactForBooking.memberships_for_makeup_classes.length && !eventIsFull
              $scope.showMakeupClassOption = true
              unless $scope.booking.makeup_class_membership_id
                $scope.booking.makeup_class_membership_id = $scope.contactForBooking.memberships_for_makeup_classes[0].id

      $scope.onAttendanceBlocked = (message) ->
        MessageService.message(
          title: 'Attendance blocked (signed waiver required)'
          message: message
        )

      bookFriend = ->
        params =
          position: $scope.booking.event.bookings_count
          student_id: $scope.selectedContact.id
          membership_taken_from_contact_id: $scope.contact.id
          attendance_bypass: $scope.booking.attendance
          free_class:  $scope.booking.free_class

        Events.one("#{$scope.event.id}").all('bookings').post(params).then ->
          flash.success = "Booking added successfully"
          $scope.close()
          loadingAnimation.hide()
        , (response) ->
          loadingAnimation.hide()
          if response.data.waiver_requirements_not_met
            $scope.onAttendanceBlocked(response.data.message)
          else
            flash.error = "Update failed. Please fix the errors below or check membership conditions"
            eventErrors = angular.extend {}, response.data.errors
            eventErrors['bookings.student'] = undefined
            $scope.booking.event.errors =
              response.data.errors['bookings.event'] || eventErrors.event || eventErrors.student || _.flatten _.values eventErrors
            $scope.errors = response.data.errors

      $scope.save = ->
        loadingAnimation.show()
        if $scope.params.bookFriend
          bookFriend()
        else
          bookCurrentContact()

      confirmAddToWaitlist = () ->
        message = "This event is currently full. Add contact to waitlist?"
        if $window.confirm message
          params =
            withoutNamespace: true
            contact_id: $scope.contact.id

          Events.one("#{$scope.event.id}").all('waitlist_attendees')
            .post(params).then (response) ->
              $scope.onWaitlistAdded()
              flash.success =
                "#{response.contact_name} added to waitlist."
              $scope.close()
              loadingAnimation.hide()
            , (response) ->
              message =
                response.data?.error_message || "Request could not be completed. Please try again"
              flash.error = message

      $scope.getContacts = (text) ->
        Contacts.getList(all_contacts_search: text).then (contacts) ->
          _.filter contacts, (contact) ->
            contact.type isnt 'non_attending_contact'

      $scope.onContactSelect = (contact) ->
        $scope.selectedContact = contact
        $scope.booking.student_id = contact.id
        $scope.booking.membership_taken_from_contact_id = $scope.contact.id

      $scope.restoreName = ->
        if $scope.booking.errors
          $scope.booking.errors.contact_id = undefined
        $scope.pageParams.search = if $scope.selectedContact
          $scope.selectedContact.name
        else
          ''

      $scope.cancel = ->
        $scope.close()
